import { css } from "@emotion/css";
import { BeaconInline } from "@octopusdeploy/design-system-components";
import { borderRadius, fontWeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import type { ActionEvent } from "~/analytics/Analytics";
import { useAnalyticActionDispatch } from "~/analytics/Analytics";
import InternalLink from "~/components/Navigation/InternalLink";
import { useIsPortalUrlActive } from "~/components/Navigation/useIsPortalUrlActive";
import type { TaskState } from "./ProjectStatusBar";
import { ProjectStatusIcon } from "./ProjectStatusIcon";
import { ProjectStatusPopover } from "./ProjectStatusPopover";
interface ProjectStatusItemProps {
    name: string;
    href?: LinkHref;
    state: TaskState;
    showIndicator?: boolean;
    popover?: ProjectStatusPopover;
    eventName?: string;
    eventAction?: ActionEvent;
    onClick?: () => void;
}
export const ProjectStatusItem = ({ name, state, href, showIndicator: isInteractive, popover, eventName, eventAction, onClick }: ProjectStatusItemProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
    const [showPopover, setShowPopover] = useState<boolean>(false);
    const ref = React.createRef<HTMLDivElement>();
    useEffect(() => {
        setAnchorEl(ref.current);
    }, [ref]);
    const onMouseEnter = () => {
        setShowPopover(true);
    };
    const onMouseLeave = () => {
        setShowPopover(false);
    };
    const dispatchAction = useAnalyticActionDispatch();
    const isUrlActive = useIsPortalUrlActive();
    const handleItemClick = (domEvent: React.MouseEvent<HTMLAnchorElement>, href: LinkHref) => {
        if (onClick) {
            domEvent.preventDefault();
            onClick();
        }
        if (!eventName || !eventAction) {
            return;
        }
        if (!isUrlActive(href, true)) {
            dispatchAction(eventName, eventAction);
        }
    };
    if (state === "Current") {
        return (<div ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                {href ? (<InternalLink className={cn(styles.statusItemContainer, styles.active, styles.isLink)} to={href} onClick={onClick ? (event: React.MouseEvent<HTMLAnchorElement>) => handleItemClick(event, href) : undefined}>
                        {!isInteractive ? <ProjectStatusIcon state={state}/> : <BeaconInline size={"small"} ripple={0.5} open={true}/>}
                        <span>{name}</span>
                    </InternalLink>) : (<div className={cn(styles.statusItemContainer, styles.active)} onClick={onClick}>
                        {!isInteractive ? <ProjectStatusIcon state={state}/> : <BeaconInline size={"small"} ripple={0.5} open={true}/>}
                        <span>{name}</span>
                    </div>)}
                <ProjectStatusPopover popoverType={popover?.popoverType} unassociatedTargetRoles={popover?.unassociatedTargetRoles} onCustomAction={popover?.onCustomAction} open={showPopover} anchorEl={anchorEl} eventAction={eventAction}/>
            </div>);
    }
    return (<div className={cn(styles.statusItemContainer, { [styles.complete]: state === "Done" })}>
            <ProjectStatusIcon state={state}/>
            <span>{name}</span>
        </div>);
};
const styles = {
    statusItemContainer: css({
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        gap: space[8],
        borderRadius: borderRadius.small,
        whiteSpace: "nowrap",
        color: themeTokens.color.text.tertiary,
        padding: `${space[4]} ${space[8]}`,
    }),
    active: css({
        background: themeTokens.color.background.primary.default,
        fontWeight: fontWeight[500],
        "&&": {
            color: themeTokens.color.text.primary,
        },
    }),
    complete: css({
        "&&": {
            color: themeTokens.color.text.primary,
        },
    }),
    isLink: css({
        cursor: "pointer",
        "&&:hover": {
            color: themeTokens.color.text.primary,
            background: themeTokens.color.background.primary.pressed,
        },
    }),
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, ActionButtonType, RadioButtonGroup, RadioButton, Callout } from "@octopusdeploy/design-system-components";
import { Repository, Permission } from "@octopusdeploy/octopus-server-client";
import type { ProjectResource, IId, IProcessResource, RunbookResource, ProjectSummaryResource, IIdName } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { Dictionary } from "lodash";
import { values, merge, compact, head, property } from "lodash";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { DialogLayoutConnect } from "~/components/Dialog/DialogLayoutConnect";
import type { DialogLayoutCommonProps, DialogLayoutDispatchProps } from "~/components/DialogLayout/DialogLayout";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import InfoDialogLayout from "~/components/DialogLayout/InfoDialogLayout";
import FormComponent from "~/components/FormComponent/FormComponent";
import InternalLink from "~/components/Navigation/InternalLink";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Select from "~/primitiveComponents/form/Select/Select";
import toResourceLookup from "~/utils/toResourceLookup";
import toSelectItem from "~/utils/toSelectItem";
interface CloneStepProps {
    currentProject: ProjectResource;
    currentRunbook?: RunbookResource;
    actionName: string;
    stepId: string;
    actionId?: string;
    onCloneTargetSelected: (definition: CloneSourceDefinition) => Promise<IProcessResource>;
}
interface CloneStepState extends DataBaseComponentState {
    selectedProject: ProjectResource;
    selectedProjectRunbooks: {
        [id: string]: RunbookResource;
    };
    selectedRunbook?: RunbookResource;
    projectSummaries: {
        [id: string]: ProjectSummaryResource;
    };
    processTargetType: ProcessTargetType;
    status: CloneStatus;
}
export enum CloneStepContextType {
    CurrentContext = "CurrentContext",
    DifferentContext = "DifferentContext"
}
export enum ProcessTargetType {
    Deployment = "Deployment",
    Runbook = "Runbook"
}
export interface CloneStepsSource {
    type: ProcessTargetType;
    project: ProjectResource;
}
export interface CloneRunbookProcessSource extends CloneStepsSource {
    type: ProcessTargetType.Runbook;
    runbook: RunbookResource;
}
export interface CloneDeploymentProcessStepsSource extends CloneStepsSource {
    type: ProcessTargetType.Deployment;
}
enum CloneStatus {
    AskingForTarget = "AskingForTarget",
    Success = "Success"
}
export interface CloneSourceDefinition {
    source: CloneStepsSource;
    target: CloneStepsSource;
    targetType: CloneStepContextType;
}
export function isRunbookProcessCloneSource(source: CloneStepsSource | undefined): source is CloneRunbookProcessSource {
    return source !== null && source !== undefined && source.type === ProcessTargetType.Runbook;
}
export function isDeploymentsStepsCloneSource(source: CloneStepsSource | undefined): source is CloneDeploymentProcessStepsSource {
    return source !== null && source !== undefined && source.type === ProcessTargetType.Deployment;
}
interface CloneStepDialogLayoutProps extends DialogLayoutCommonProps {
    onOkClick(): Promise<boolean>;
    disabled: boolean;
}
class CloneStepDialogLayoutInternal extends React.Component<CloneStepDialogLayoutProps & DialogLayoutDispatchProps> {
    okClick = async () => {
        const result = await this.props.onOkClick();
        if (result) {
            this.props.close();
        }
    };
    render() {
        const { children, disabled, ...other } = this.props;
        const ok = <ActionButton key="Ok" label="Ok" onClick={this.okClick} type={ActionButtonType.Primary} disabled={disabled}/>;
        const cancel = <ActionButton key="Cancel" label="Cancel" onClick={() => this.props.close()}/>;
        const actions = [cancel, ok];
        return (<DialogLayout actions={actions} closeDialog={this.props.close} {...other}>
                <FormComponent onFormSubmit={this.okClick}>{children}</FormComponent>
            </DialogLayout>);
    }
    static displayName = "CloneStepDialogLayoutInternal";
}
const getId = property<IId, string>("Id");
const lookupToSelectItems = <T extends Dictionary<TItem>, TItem extends IIdName>(lookup: T, filter?: (item: TItem) => boolean) => {
    return (filter ? values(lookup).filter(filter) : values(lookup)).map(toSelectItem);
};
function exhaustiveCheck(param: never) {
    return;
}
const CloneStepDialogLayout = DialogLayoutConnect.to<CloneStepDialogLayoutProps>(CloneStepDialogLayoutInternal);
CloneStepDialogLayout.displayName = "CloneDialogLayout";
interface RunbookSelectorProps {
    project: ProjectResource;
    runbooks?: {
        [id: string]: RunbookResource;
    };
    runbook?: RunbookResource;
    error?: string;
    onChange: (runbook: RunbookResource) => void;
    filterItems?: (resource: RunbookResource) => boolean;
}
const RunbookSelector: React.FC<RunbookSelectorProps> = ({ project, runbooks, runbook, error, onChange, filterItems }) => {
    const handleChange = (id: string | undefined) => {
        onChange(runbooks![id!]);
    };
    const items = lookupToSelectItems(runbooks!, filterItems);
    if (!project) {
        return null;
    }
    return <Select label="Select Runbook" value={runbook && runbook.Id} error={error} items={items} onChange={handleChange}/>;
};
RunbookSelector.displayName = "RunbookSelector"
class CloneStepInternal extends DataBaseComponent<CloneStepProps, CloneStepState> {
    constructor(props: CloneStepProps) {
        super(props);
        this.state = {
            selectedRunbook: this.props.currentRunbook,
            selectedProjectRunbooks: {},
            selectedProject: this.props.currentProject,
            projectSummaries: {},
            processTargetType: this.props.currentRunbook ? ProcessTargetType.Runbook : ProcessTargetType.Deployment,
            status: CloneStatus.AskingForTarget,
        };
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const projectSummaries = await repository.Projects.summaries();
            const selectedProjectRunbooks = await repository.Runbooks.getRunbooks(this.state.selectedProject, undefined, { take: Repository.takeAll });
            this.setState({
                projectSummaries: toResourceLookup(projectSummaries),
                selectedProjectRunbooks: toResourceLookup(selectedProjectRunbooks.Items),
            });
        });
    }
    renderTargetSelectionView() {
        const hasProcessViewPermissions = isAllowed({
            permission: Permission.ProcessView,
            project: this.props.currentProject.Id,
            tenant: "*",
        });
        const selectVesionControlledProject = this.state.selectedProject.IsVersionControlled;
        return (<CloneStepDialogLayout title="Clone Step" busy={this.state.busy} errors={this.errors} onOkClick={this.onOk} disabled={this.state.processTargetType != ProcessTargetType.Runbook && selectVesionControlledProject}>
                <p>
                    Clone the step <strong>{this.props.actionName}</strong> to:
                </p>

                <Select label="Select project" value={this.state.selectedProject && this.state.selectedProject.Id} error={this.errors && this.errors.fieldErrors.selectedProjectId} items={lookupToSelectItems(this.state.projectSummaries)} allowFilter={true} onChange={this.onSelectProject}/>

                {Object.keys(this.state.selectedProjectRunbooks).length > 0 && hasProcessViewPermissions && (<RadioButtonGroup value={this.state.processTargetType} onChange={(selection) => this.changeProcessTargetType(selection as ProcessTargetType)}>
                        {!selectVesionControlledProject && <RadioButton value={ProcessTargetType.Deployment} label={"Deployment Process"}/>}
                        <RadioButton value={ProcessTargetType.Runbook} label={"Runbook"}/>
                    </RadioButtonGroup>)}

                {this.state.processTargetType === ProcessTargetType.Runbook && (<p>
                        <RunbookSelector error={this.errors && this.errors.fieldErrors.selectedRunbookId} project={this.state.selectedProject} runbooks={this.state.selectedProjectRunbooks} runbook={this.state.selectedRunbook} onChange={this.onSelectRunbook}/>
                    </p>)}
            </CloneStepDialogLayout>);
    }
    changeProcessTargetType = (processTargetType: ProcessTargetType) => {
        const runbooks = values(this.state.selectedProjectRunbooks);
        const nextSelectedRunbook = this.state.selectedRunbook ? this.state.selectedRunbook : processTargetType === ProcessTargetType.Runbook && runbooks.length > 0 ? head(runbooks) : null;
        this.setState({ processTargetType, selectedRunbook: nextSelectedRunbook! });
    };
    onSelectProject = async (id: string | undefined) => {
        if (this.state.selectedProject.Id === id) {
            return;
        }
        await this.doBusyTask(async () => {
            const selectedProject = await repository.Projects.get(id!);
            const selectedProjectRunbooks = await repository.Runbooks.getRunbooks(selectedProject, undefined, { take: Repository.takeAll });
            const nextType = this.state.processTargetType === ProcessTargetType.Runbook && selectedProjectRunbooks.Items.length > 0 ? ProcessTargetType.Runbook : ProcessTargetType.Deployment;
            this.setState({
                selectedProject: selectedProject,
                selectedRunbook: nextType === ProcessTargetType.Runbook && selectedProjectRunbooks.Items.length > 0 ? head(selectedProjectRunbooks.Items) : null!,
                processTargetType: nextType,
                selectedProjectRunbooks: toResourceLookup(selectedProjectRunbooks.Items),
            });
        });
    };
    onSelectRunbook = (runbook: RunbookResource) => {
        this.setState({
            selectedRunbook: runbook,
        });
    };
    getCloneDefinition = (): CloneSourceDefinition | undefined => {
        const baseDetails: Pick<CloneSourceDefinition, "source" | "targetType"> = { source: this.getCurrentContextSource(), targetType: this.getCloneStepContextType() };
        if (this.state.processTargetType === ProcessTargetType.Deployment) {
            return { ...baseDetails, target: this.getDeploymentProcessSource(this.state.selectedProject) };
        }
        else if (this.state.processTargetType === ProcessTargetType.Runbook) {
            return { ...baseDetails, target: this.getRunbookSource(this.state.selectedProject, this.state.selectedRunbook!) };
        }
        exhaustiveCheck(this.state.processTargetType);
    };
    getCloneStepContextType = () => {
        const currentProjectId = getId(this.props.currentProject);
        const selectedProjectId = getId(this.state.selectedProject);
        const currentRunbookId = getId(this.props.currentRunbook!);
        const selectedRunbookId = getId(this.state.selectedRunbook!);
        //If we are cloning from a deployment process
        if (!this.props.currentRunbook && this.state.processTargetType === ProcessTargetType.Deployment && currentProjectId === selectedProjectId) {
            return CloneStepContextType.CurrentContext;
        }
        else if (this.props.currentRunbook && this.state.processTargetType === ProcessTargetType.Runbook && currentRunbookId === selectedRunbookId) {
            return CloneStepContextType.CurrentContext;
        }
        return CloneStepContextType.DifferentContext;
    };
    getDeploymentProcessSource = (project: ProjectResource): CloneDeploymentProcessStepsSource => {
        return { project, type: ProcessTargetType.Deployment };
    };
    getRunbookSource = (project: ProjectResource, runbook: RunbookResource): CloneRunbookProcessSource => {
        return { project, runbook, type: ProcessTargetType.Runbook };
    };
    getCurrentContextSource = (): CloneStepsSource => {
        if (this.props.currentRunbook) {
            return this.getRunbookSource(this.props.currentProject, this.props.currentRunbook);
        }
        else {
            return this.getDeploymentProcessSource(this.props.currentProject);
        }
    };
    renderSuccessView() {
        const source = this.getCloneDefinition()?.target;
        const runbook = isRunbookProcessCloneSource(source) ? source.runbook : null;
        return (<InfoDialogLayout title="Clone Successful" busy={this.state.busy} errors={this.errors}>
                {!runbook && <ClonedToDeploymentProcessSuccess project={source!.project} actionName={this.props.actionName}/>}
                {runbook && <ClonedToRunbookSuccessMessage project={source!.project} runbook={runbook} actionName={this.props.actionName}/>}

                <Callout type={"warning"} title="Variables">
                    No variables were copied - consider reviewing the cloned step and manually copy any required variables.
                </Callout>
            </InfoDialogLayout>);
    }
    render() {
        switch (this.state.status) {
            case CloneStatus.AskingForTarget:
                return this.renderTargetSelectionView();
            case CloneStatus.Success:
                return this.renderSuccessView();
        }
    }
    validateSelectedProject = (): Errors | undefined => {
        if (!this.state.selectedProject) {
            return {
                message: "Please select a project",
                fieldErrors: { selectedProjectId: "Select a project" },
                errors: [],
                details: {},
            };
        }
    };
    validateSelectedRunbook = (): Errors | undefined => {
        if (!this.state.selectedRunbook) {
            return {
                message: "Please select a runbook",
                fieldErrors: { selectedRunbookId: "Select a runbook" },
                errors: [],
                details: {},
            };
        }
    };
    mergeErrors = (errors: Array<Errors | undefined>): Errors | undefined => {
        const compacted = compact(errors);
        if (compacted.length === 0) {
            return;
        }
        else if (compacted.length === 1) {
            return compacted[0];
        }
        return compacted.reduce((prev, current) => {
            return { ...prev, fieldErrors: merge(prev.fieldErrors, current.fieldErrors) };
        }, compacted[0]);
    };
    validate = () => {
        const errors: Array<Errors | undefined> = [];
        if (this.state.processTargetType === ProcessTargetType.Runbook) {
            errors.push(this.validateSelectedProject());
            errors.push(this.validateSelectedRunbook());
        }
        return this.mergeErrors(errors);
    };
    private onOk = async () => {
        const errors = this.validate();
        if (errors) {
            this.setValidationErrors(errors.message, errors.fieldErrors);
            return false;
        }
        const definition = this.getCloneDefinition()!;
        const contextType = this.getCloneStepContextType();
        await this.doBusyTask(() => this.props.onCloneTargetSelected(definition));
        if (contextType === CloneStepContextType.CurrentContext) {
            return true;
        }
        this.setState({ status: CloneStatus.Success });
        return false;
    };
    static displayName = "CloneStepInternal";
}
const ClonedToRunbookSuccessMessage: React.FC<{
    project: ProjectResource;
    runbook: RunbookResource;
    actionName: string;
}> = ({ project, actionName, runbook }) => {
    return (<p>
            Step <strong>{actionName}</strong> has been successfully cloned to project {project.Name} runbook &nbsp;
            <InternalLink to={links.projectRunbookProcessListPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id, processId: runbook.RunbookProcessId })}>{runbook.Name}</InternalLink>
        </p>);
};
ClonedToRunbookSuccessMessage.displayName = "ClonedToRunbookSuccessMessage"
const ClonedToDeploymentProcessSuccess: React.FC<{
    project: ProjectResource;
    actionName: string;
}> = ({ project, actionName }) => {
    return (<p>
            Step <strong>{actionName}</strong> has been successfully cloned to <InternalLink to={links.deploymentProcessPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })}>{project.Name}</InternalLink>.
        </p>);
};
ClonedToDeploymentProcessSuccess.displayName = "ClonedToDeploymentProcessSuccess"
export { CloneStepContextType as CloneStepTarget };
function CloneStep(props: Omit<CloneStepProps, "currentProject">) {
    const projectContext = useProjectContext();
    return <CloneStepInternal currentProject={projectContext.state.model} {...props}/>;
}
export default CloneStep;

/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { Dialog } from "@material-ui/core";
import { ActionButton, ActionButtonType, BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import { toGitRefShort } from "@octopusdeploy/octopus-server-client";
import type { GitRef } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useEffect, useState } from "react";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import KeyboardHandler, { Key } from "~/components/KeyboardHandler";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Note, Text } from "~/components/form";
interface CommitDialogProps {
    open: boolean;
    gitRef: GitRef;
    canCommitToGitRef: boolean;
    defaultSummary: string;
    onCloseWithoutCommit: () => void;
    onCommit: () => void;
    onNewBranchCreating?: (branchName: string) => Promise<void>;
    onCommitMessageChanged: (commitMessage: CommitMessageWithDetails) => void;
    commitMessage: CommitMessageWithDetails;
    commitMessageAccessibleName: string;
    commitDetailsAccessibleName: string;
    hasPermission: PermissionCheckProps;
    hideNewBranchOptions?: boolean;
}
export interface CommitSummaryAndDetailsProps {
    onCommitMessageChanged: (commitMessage: CommitMessageWithDetails) => void;
    commitMessage: CommitMessageWithDetails;
    defaultSummary: string;
    commitMessageAccessibleName?: string;
    commitDetailsAccessibleName?: string;
}
export const CommitSummaryAndDetails: React.FC<CommitSummaryAndDetailsProps> = ({ onCommitMessageChanged, defaultSummary, commitMessageAccessibleName, commitDetailsAccessibleName, commitMessage }) => {
    const updateSummary = (newSummary: string) => {
        onCommitMessageChanged({
            details: commitMessage.details,
            summary: newSummary,
        });
    };
    const updateDetails = (newDetails: string) => {
        onCommitMessageChanged({
            details: newDetails,
            summary: commitMessage.summary,
        });
    };
    return (<>
            <Text key="summary" id="summary" name="Summary" label="Summary" value={commitMessage.summary} onChange={updateSummary} placeholder={defaultSummary} autoFocus={true} accessibleName={commitMessageAccessibleName ?? "Commit summary"}/>
            <Text key="details" id="details" name="Details" label="Optional description" value={commitMessage.details} onChange={updateDetails} multiline={true} accessibleName={commitDetailsAccessibleName ?? "Optional description"}/>
        </>);
};
CommitSummaryAndDetails.displayName = "CommitSummaryAndDetails"
const CommitDialog: React.FC<CommitDialogProps> = (props) => {
    const [isCreatingNewBranch, setIsCreatingNewBranch] = useState(false);
    const actions = [
        <ActionButton key="Cancel" label="Cancel" title="Cancel" onClick={() => props.onCloseWithoutCommit()}/>,
        <ActionButton key="Commit" disabled={isCreatingNewBranch} type={ActionButtonType.Save} label="Commit" title="Commit" onClick={() => commit()}/>,
    ];
    const [changeBranch, setChangeBranch] = useState(!props.canCommitToGitRef);
    const [newBranch, setNewBranch] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    // Select the new branch option if we can't commit to the selected gitRef
    // Otherwise select the existing branch option
    useEffect(() => {
        setChangeBranch(!props.canCommitToGitRef);
    }, [props.canCommitToGitRef]);
    const commit = async () => {
        if (changeBranch) {
            setIsCreatingNewBranch(() => true);
            try {
                if (props.onNewBranchCreating) {
                    await props.onNewBranchCreating(newBranch);
                }
                setIsCreatingNewBranch(() => false);
            }
            catch (err) {
                setErrorMessage(() => err.ErrorMessage);
                setIsCreatingNewBranch(() => false);
            }
        }
        else {
            props.onCommit();
        }
    };
    const onEnter = (event: KeyboardEvent): boolean => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const target: any = event.target ? event.target : event.srcElement;
        const tagName = target.tagName;
        if (tagName.toUpperCase() === "INPUT") {
            commit();
            return true;
        }
        return false;
    };
    const onCtrlEnter = () => {
        commit();
        return true;
    };
    const keyboardRegistrations = [
        { key: Key.Enter, onKeyPressed: onEnter },
        { key: Key.CtrlEnter, onKeyPressed: onCtrlEnter },
    ];
    const canEditProject = isAllowed(props.hasPermission);
    return (<Dialog open={props.open} fullWidth>
            <KeyboardHandler registrations={keyboardRegistrations}>
                <DialogLayout title={"Enter commit details" + (!changeBranch || newBranch ? ` (committing to ${newBranch || toGitRefShort(props.gitRef)})` : "")} actions={actions} closeDialog={() => props.onCloseWithoutCommit()}>
                    <CommitSummaryAndDetails onCommitMessageChanged={props.onCommitMessageChanged} defaultSummary={props.defaultSummary} commitMessage={props.commitMessage} commitDetailsAccessibleName={props.commitDetailsAccessibleName} commitMessageAccessibleName={props.commitMessageAccessibleName}/>
                    {!props.hideNewBranchOptions && (<>
                            <p>Choose a branch Octopus should commit the changes to</p>
                            {<BooleanRadioButtonGroup key="branchOptions" onChange={(newValue) => {
                    setChangeBranch(() => newValue);
                }} value={changeBranch}>
                                    <BooleanRadioButton key="existingBranch" accessibleName={"Commit to current branch"} value={false} disabled={!props.canCommitToGitRef} label={<>
                                                Commit to <code>{props.gitRef}</code>
                                            </>}/>
                                    <BooleanRadioButton key="newBranch" value={true} label="Commit to a new branch" accessibleName={"Commit to new branch"} disabled={!canEditProject}/>
                                    {!canEditProject && <Note> Project Edit permission is required to create branches </Note>}
                                    {changeBranch && canEditProject && (<div style={{ width: "94%" }}>
                                            <Text key="newBranchName" id="newBranchName" name="BranchName" label="Branch name" value={newBranch} onChange={(val) => setNewBranch(() => val)} placeholder="Branch name" accessibleName="Branch name" error={errorMessage}/>
                                            <Note>
                                                The new branch will be based on <code>{props.gitRef}</code>.
                                            </Note>
                                        </div>)}
                                </BooleanRadioButtonGroup>}
                        </>)}
                </DialogLayout>
            </KeyboardHandler>
        </Dialog>);
};
CommitDialog.displayName = "CommitDialog"
export default CommitDialog;

import { css } from "@emotion/css";
import { IconButton, Tooltip } from "@octopusdeploy/design-system-components";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
export const CloseButton = ({ onClick }: {
    onClick: () => void;
}) => (<div className={closeIconStyles} onClick={onClick}>
        <Tooltip content={<div className={tooltipContentStyles}>Once dismissed, project status can’t be re-opened</div>} position="top" distance={25}>
            <IconButton accessibleName="Close Button" icon="Cancel"/>
        </Tooltip>
    </div>);
const closeIconStyles = css({
    marginLeft: "auto",
    position: "relative",
    top: "3px",
    "&& path": {
        color: themeTokens.color.icon.secondary,
    },
    "&& svg": {
        height: "20px",
        width: "20px",
    },
});
const tooltipContentStyles = css({
    position: "relative",
    bottom: "4px",
    width: "174px",
    height: "32px",
    marginTop: "2px",
    marginBottom: "2px",
});

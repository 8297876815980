/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css, cx } from "@emotion/css";
import { IconButton } from "@octopusdeploy/design-system-components";
import { borderRadius, fontSize, lineHeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DeploymentTargetResource, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { EndpointsHelper, isStepPackageEndpointResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { BuiltInEndpointLogo, createStepPackageEndpointLogo } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { repository } from "~/clientInstance";
import { EnvironmentChip, RoleChip } from "~/components/Chips/index";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { useOctopusTheme } from "~/components/Theme/index";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
interface DeploymentTargetsTableProps {
    targetTags: string[];
    deploymentTargetsTotal: number;
    deploymentTargets: DeploymentTargetResource[];
    environments: EnvironmentResource[];
    showTargetTagColumn?: boolean;
    targetTagColumnSecondaryTitle?: string;
    size?: "medium" | "large";
}
export const DeploymentTargetsTable = ({ targetTags, deploymentTargetsTotal, deploymentTargets, environments, showTargetTagColumn, targetTagColumnSecondaryTitle, size = "medium" }: DeploymentTargetsTableProps) => {
    const machinesLeftToList = deploymentTargetsTotal - deploymentTargets.length;
    const container = css({
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
    });
    const tableContainer = css({
        borderLeft: `1px solid ${useOctopusTheme().dividerLight}`,
        borderRight: `1px solid ${useOctopusTheme().dividerLight}`,
        borderTop: `1px solid ${useOctopusTheme().dividerLight}`,
        borderRadius: borderRadius.small,
        width: `${getTableWidth(size)}px`,
    });
    const nameColumnWidth = showTargetTagColumn ? "33.3333%" : "50%";
    const columnWidth = showTargetTagColumn ? "33.3333%" : "50%";
    const baseHeaderColumnStyles = {
        padding: `${space[8]} ${space[12]} !important`,
    };
    const nameHeaderColumn = css({
        ...baseHeaderColumnStyles,
        width: nameColumnWidth,
    });
    const headerColumn = css({
        ...baseHeaderColumnStyles,
        width: columnWidth,
    });
    const secondaryHeaderColumnTitle = css({
        color: themeTokens.color.text.secondary,
    });
    return (<div className={container}>
            <div className={tableContainer}>
                <DataTable title="Deployment Targets Table">
                    <DataTableHeader>
                        <DataTableRow>
                            <DataTableHeaderColumn className={nameHeaderColumn}>Deployment target</DataTableHeaderColumn>
                            {showTargetTagColumn && (<DataTableHeaderColumn className={headerColumn}>
                                    <div>Target tag {targetTagColumnSecondaryTitle && <span className={secondaryHeaderColumnTitle}>{targetTagColumnSecondaryTitle}</span>}</div>
                                </DataTableHeaderColumn>)}
                            <DataTableHeaderColumn className={headerColumn}>Environment</DataTableHeaderColumn>
                        </DataTableRow>
                    </DataTableHeader>
                    <DataTableBody>
                        {deploymentTargets.map((deploymentTarget) => {
            const filteredEnvironments = environments.filter((environment) => deploymentTarget.EnvironmentIds.includes(environment.Id));
            const filteredTargetTags = targetTags.filter((targetTag) => deploymentTarget.Roles.includes(targetTag));
            return <MachineRow key={deploymentTarget.Id} machine={deploymentTarget} environments={filteredEnvironments} targetTags={filteredTargetTags} showTargetTagColumn={showTargetTagColumn}/>;
        })}
                    </DataTableBody>
                </DataTable>
            </div>
            {machinesLeftToList > 0 && (<div>
                    <InternalLink to={links.deploymentTargetsPage.generateUrl({ spaceId: repository.spaceId! }, { roles: targetTags })} openInSelf={false} showOpensInNewTabIcon={true}>
                        See {machinesLeftToList} more in Deployment Targets
                    </InternalLink>
                </div>)}
        </div>);
};
const getTableWidth = (size: "medium" | "large") => {
    const LARGE_TABLE_WIDTH = 732;
    const MEDIUM_TABLE_WIDTH = 452;
    switch (size) {
        case "large":
            return LARGE_TABLE_WIDTH;
        case "medium":
            return MEDIUM_TABLE_WIDTH;
        default:
            return MEDIUM_TABLE_WIDTH;
    }
};
interface MachineRowProps {
    machine: DeploymentTargetResource;
    environments: EnvironmentResource[];
    targetTags?: string[];
    showTargetTagColumn?: boolean;
}
const MachineRow = ({ machine, environments, targetTags, showTargetTagColumn }: MachineRowProps) => {
    const RegistrationLogo = isStepPackageEndpointResource(machine.Endpoint) ? createStepPackageEndpointLogo(machine.Endpoint) : BuiltInEndpointLogo;
    const environmentNames = environments.map((environment) => environment.Name);
    const [isMachineRowExpanded, setIsMachineRowExpanded] = React.useState(false);
    const nameColumnWidth = showTargetTagColumn ? "33.3333%" : "50%";
    const columnWidth = showTargetTagColumn ? "33.3333%" : "50%";
    const machineContainer = css({
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        minHeight: "34px",
        wordBreak: "break-word",
    });
    const machineIcon = css({
        height: "16px",
        marginRight: "0.5rem",
    });
    const machineLabels = css({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: space[4],
    });
    const machineName = css({
        fontSize: fontSize.small,
    });
    const machineType = css({
        fontSize: fontSize.xSmall,
        color: themeTokens.color.text.tertiary,
    });
    const headerColumn = css({
        padding: `${space[8]} ${space[12]} !important`,
        verticalAlign: "middle !important",
        width: nameColumnWidth,
    });
    const column = css({
        padding: `${space[8]} ${space[12]} !important`,
        verticalAlign: "top !important",
        width: columnWidth,
    });
    return (<DataTableRow>
            <DataTableRowColumn className={headerColumn}>
                <InternalLink to={links.deploymentTargetSettingsPage.generateUrl({ spaceId: machine.SpaceId, machineId: machine.Id })} openInSelf={false}>
                    <div className={machineContainer}>
                        <div className={machineIcon}>
                            <RegistrationLogo machine={machine} variant={"small"}/>
                        </div>
                        <div className={machineLabels}>
                            <div className={machineName} title={machine.Name}>
                                {machine.Name}
                            </div>
                            <div className={machineType}>{EndpointsHelper.getFriendlyName(machine)}</div>
                        </div>
                    </div>
                </InternalLink>
            </DataTableRowColumn>
            {showTargetTagColumn && targetTags && (<DataTableRowColumn className={column}>
                    <ExpandableChipsList chips={targetTags} chipRenderer={renderTargetTagChip} isExpanded={isMachineRowExpanded} setIsExpanded={setIsMachineRowExpanded} showExpandOrCollapseButton={false}/>
                </DataTableRowColumn>)}
            <DataTableRowColumn className={column}>
                <ExpandableChipsList chips={environmentNames} chipRenderer={renderEnvironmentChip} isExpanded={isMachineRowExpanded} setIsExpanded={setIsMachineRowExpanded} showExpandOrCollapseButton={environmentNames.length > 1 || (targetTags && targetTags.length > 1)}/>
            </DataTableRowColumn>
        </DataTableRow>);
};
const renderEnvironmentChip = (environmentName: string) => (<div className={chip}>
        <EnvironmentChip environmentName={environmentName} tooltipDisplay="grid"/>
    </div>);
const renderTargetTagChip = (targetTag: string) => (<div className={chip}>
        <RoleChip role={targetTag} tooltipDisplay="grid"/>
    </div>);
const chip = css({
    ".MuiChip-label": {
        paddingRight: space[8],
        paddingLeft: space[8],
    },
});
interface ExpandableChipsListProps {
    chips: string[];
    chipRenderer: (chip: string) => JSX.Element;
    // By default, the chips list cannot be expanded or collapsed
    isExpanded?: boolean; // Controls the expanded state of the chips list
    setIsExpanded?: (expanded: boolean) => void; // Sets the expanded state of the chips list
    showExpandOrCollapseButton?: boolean; // Override the default condition of only showing the expand-or-collapse button when there is more than one chip
}
const ExpandableChipsList = (props: ExpandableChipsListProps) => {
    const showToggle = props.setIsExpanded && (props.showExpandOrCollapseButton === undefined ? props.chips.length > 1 : props.showExpandOrCollapseButton);
    const toggleExpanded = (e: React.MouseEvent) => {
        if (props.setIsExpanded) {
            props.setIsExpanded(!props.isExpanded);
        }
    };
    if (!props.isExpanded) {
        return (<div className={chipWithButton}>
                <div className={expandableChip}>
                    {props.chipRenderer(props.chips[0])}
                    {props.chips.length > 1 && (<div className={moreChipsText} onClick={toggleExpanded}>
                            + {props.chips.length - 1} more
                        </div>)}
                </div>
                {showToggle && <IconButton icon={"ArrowDown"} onClick={toggleExpanded}/>}
            </div>);
    }
    return (<div className={expandedChipsList}>
            {props.chips.map((chip, idx) => (<div key={chip} className={cx({ [chipWithButton]: idx === 0, [chipsListDivider]: idx > 0 })}>
                    {props.chipRenderer(chip)}
                    {idx === 0 && showToggle && <IconButton icon={"ArrowUp"} onClick={toggleExpanded}/>}
                </div>))}
        </div>);
};
const chipWithButton = css({
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
});
const expandableChip = css({
    display: "flex",
    alignItems: "center",
    gap: space["8"],
});
const MORE_CHIPS_TEXT_WIDTH = 3.375;
const moreChipsText = css({
    width: `${MORE_CHIPS_TEXT_WIDTH}rem`,
    whiteSpace: "nowrap",
    fontSize: fontSize.small,
    lineHeight: lineHeight.xSmall,
    color: themeTokens.color.text.secondary,
    cursor: "pointer",
});
const expandedChipsList = css({
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
});
const chipsListDivider = css({
    borderTop: `1px solid ${themeTokens.color.border.primary}`,
    paddingTop: "0.5rem",
});

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { AuditStreamConfigurationResource, StreamConfigurationResource, SensitiveValue } from "@octopusdeploy/octopus-server-client";
import { OtlpProtocol } from "@octopusdeploy/octopus-server-client";
import styles from "app/areas/configuration/components/AuditLayout/AuditStream/AuditStreamCategory.module.less";
import type { Dictionary } from "lodash";
import { keys, keyBy, mapValues, isEqual } from "lodash";
import * as React from "react";
import type { ActionEvent, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import AuditStreamCategory from "~/areas/configuration/components/AuditLayout/AuditStream/AuditStreamCategory";
import { AuditStreamType } from "~/areas/configuration/components/AuditLayout/AuditStream/AuditStreamType";
import { AutoFillOpenTelemetrySettingsButton } from "~/areas/configuration/components/AuditLayout/AuditStream/AutoFillOpenTelemetrySettingsButton";
import { isSplunk, isSumoLogic, createNewAuditStreamConfiguration, isOpenTelemetry } from "~/areas/configuration/components/AuditLayout/AuditStream/utils";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { KeyValueEditList } from "~/components/EditList/KeyValueEditList";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { Note, Select, Sensitive, Text } from "~/components/form/index";
export interface EditAuditStreamDialogLayoutProps {
    close: () => void;
    auditStreamConfiguration: AuditStreamConfigurationResource;
    onUpdate: (value: AuditStreamConfigurationResource, message: string) => void;
}
type InternalEditAuditStreamDialogProps = EditAuditStreamDialogLayoutProps & {
    trackAction: AnalyticTrackedActionDispatcher;
};
interface EditAuditStreamDialogState extends DataBaseComponentState {
    description: string;
    activeCategory: AuditStreamType | null;
    openTelemetryEndpoint: string;
    openTelemetrySecret: SensitiveValue;
    openTelemetryHeaders: Dictionary<string>;
    openTelemetryProtocol: OtlpProtocol;
    splunkEndpoint: string;
    splunkToken: SensitiveValue;
    sumoLogicEndpoint: SensitiveValue;
}
class EditAuditStreamDialogLayoutInternal extends DataBaseComponent<InternalEditAuditStreamDialogProps, EditAuditStreamDialogState> {
    constructor(props: InternalEditAuditStreamDialogProps) {
        super(props);
        if (isOpenTelemetry(props.auditStreamConfiguration.StreamConfigurationResource)) {
            this.state = {
                description: props.auditStreamConfiguration.Description ?? "",
                activeCategory: AuditStreamType.OpenTelemetry,
                openTelemetryEndpoint: props.auditStreamConfiguration.StreamConfigurationResource.OpenTelemetryEndpoint,
                openTelemetryProtocol: props.auditStreamConfiguration.StreamConfigurationResource.OpenTelemetryProtocol,
                openTelemetryHeaders: props.auditStreamConfiguration.StreamConfigurationResource.OpenTelemetryHeaders,
                openTelemetrySecret: props.auditStreamConfiguration.StreamConfigurationResource.OpenTelemetrySecret,
                splunkEndpoint: null!,
                splunkToken: null!,
                sumoLogicEndpoint: null!,
            };
        }
        else if (isSplunk(props.auditStreamConfiguration.StreamConfigurationResource)) {
            this.state = {
                description: props.auditStreamConfiguration.Description ?? "",
                activeCategory: AuditStreamType.Splunk,
                openTelemetryEndpoint: null!,
                openTelemetryHeaders: null!,
                openTelemetryProtocol: null!,
                openTelemetrySecret: null!,
                splunkEndpoint: props.auditStreamConfiguration.StreamConfigurationResource.SplunkEndpoint,
                splunkToken: props.auditStreamConfiguration.StreamConfigurationResource.SplunkEventCollectorToken,
                sumoLogicEndpoint: null!,
            };
        }
        else if (isSumoLogic(props.auditStreamConfiguration.StreamConfigurationResource)) {
            this.state = {
                description: props.auditStreamConfiguration.Description ?? "",
                activeCategory: AuditStreamType.SumoLogic,
                openTelemetryEndpoint: null!,
                openTelemetryHeaders: null!,
                openTelemetryProtocol: null!,
                openTelemetrySecret: null!,
                splunkEndpoint: "",
                splunkToken: null!,
                sumoLogicEndpoint: props.auditStreamConfiguration.StreamConfigurationResource.SumoLogicEndpoint,
            };
        }
        else {
            this.state = {
                description: "",
                activeCategory: null,
                openTelemetryEndpoint: null!,
                openTelemetryHeaders: {},
                openTelemetryProtocol: OtlpProtocol.HttpProtobuf,
                openTelemetrySecret: null!,
                splunkEndpoint: "",
                splunkToken: null!,
                sumoLogicEndpoint: null!,
            };
        }
    }
    createNewAuditStreamConfigurationWithFormValues(): AuditStreamConfigurationResource {
        const { auditStreamConfiguration } = this.props;
        const active = this.isNewAuditStream() ? true : this.props.auditStreamConfiguration.Active;
        const { description } = this.state;
        let streamConfigurationResource: StreamConfigurationResource | null = null;
        if (this.state.activeCategory === AuditStreamType.OpenTelemetry) {
            streamConfigurationResource = {
                OpenTelemetryEndpoint: this.state.openTelemetryEndpoint,
                OpenTelemetryProtocol: this.state.openTelemetryProtocol,
                OpenTelemetryHeaders: this.state.openTelemetryHeaders,
                OpenTelemetrySecret: this.state.openTelemetrySecret,
            };
        }
        if (this.state.activeCategory === AuditStreamType.Splunk) {
            streamConfigurationResource = {
                SplunkEndpoint: this.state.splunkEndpoint,
                SplunkEventCollectorToken: this.state.splunkToken,
            };
        }
        if (this.state.activeCategory === AuditStreamType.SumoLogic) {
            streamConfigurationResource = {
                SumoLogicEndpoint: this.state.sumoLogicEndpoint,
            };
        }
        return createNewAuditStreamConfiguration(auditStreamConfiguration.Id, active, description, streamConfigurationResource, auditStreamConfiguration.Links);
    }
    isNewAuditStream() {
        return this.props.auditStreamConfiguration.StreamConfigurationResource === null;
    }
    async save() {
        const auditStreamConfiguration = this.createNewAuditStreamConfigurationWithFormValues();
        await this.doBusyTask(async () => {
            const saveOrUpdate = this.isNewAuditStream() ? "Save" : "Update";
            const ev: ActionEvent = {
                action: saveOrUpdate === "Save" ? Action.Save : Action.Update,
                resource: "Audit Stream",
                siemProvider: this.state.activeCategory!,
            };
            await this.props.trackAction(`${saveOrUpdate} Audit Stream`, ev, async () => {
                await repository.AuditStreamConfiguration.modify(auditStreamConfiguration);
                if (saveOrUpdate === "Save") {
                    this.props.onUpdate({ ...auditStreamConfiguration }, `Streaming to ${this.state.activeCategory}`);
                }
                else {
                    this.props.onUpdate({ ...auditStreamConfiguration }, `Stream updated successfully`);
                }
                this.props.close();
                return true;
            });
        });
        return false;
    }
    isSaveButtonDisabled() {
        const { auditStreamConfiguration } = this.props;
        if (this.state.activeCategory === null) {
            return true;
        }
        if (this.isInvalidOpenTelemetryForm()) {
            return true;
        }
        if (this.isInvalidSplunkForm()) {
            return true;
        }
        if (this.isInvalidSumoLogicForm()) {
            return true;
        }
        if (this.isUnchangedOpenTelemetryProvider(auditStreamConfiguration) || this.isUnchangedSplunkProvider(auditStreamConfiguration) || this.isUnchangedSumoLogicProvider(auditStreamConfiguration)) {
            return true;
        }
        return false;
    }
    isInvalidOpenTelemetryForm() {
        return this.state.activeCategory === AuditStreamType.OpenTelemetry && this.state.openTelemetryEndpoint === "";
    }
    isInvalidSplunkForm() {
        return this.state.activeCategory === AuditStreamType.Splunk && (this.state.splunkEndpoint === "" || !this.state.splunkToken?.HasValue || this.state.splunkToken.NewValue === "");
    }
    isInvalidSumoLogicForm() {
        return this.state.activeCategory === AuditStreamType.SumoLogic && (!this.state.sumoLogicEndpoint?.HasValue || this.state.sumoLogicEndpoint.NewValue === "");
    }
    isUnchangedOpenTelemetryProvider(auditStreamConfiguration: AuditStreamConfigurationResource) {
        return (isOpenTelemetry(auditStreamConfiguration.StreamConfigurationResource) &&
            this.state.activeCategory === AuditStreamType.OpenTelemetry &&
            this.state.openTelemetryEndpoint === auditStreamConfiguration.StreamConfigurationResource.OpenTelemetryEndpoint &&
            this.state.openTelemetryProtocol === auditStreamConfiguration.StreamConfigurationResource.OpenTelemetryProtocol &&
            isEqual(this.state.openTelemetryHeaders, auditStreamConfiguration.StreamConfigurationResource.OpenTelemetryHeaders) &&
            isEqual(this.state.openTelemetrySecret, auditStreamConfiguration.StreamConfigurationResource.OpenTelemetrySecret) &&
            this.state.description === auditStreamConfiguration.Description);
    }
    isUnchangedSplunkProvider(auditStreamConfiguration: AuditStreamConfigurationResource) {
        return (isSplunk(auditStreamConfiguration.StreamConfigurationResource) &&
            this.state.activeCategory === AuditStreamType.Splunk &&
            this.state.splunkEndpoint === auditStreamConfiguration.StreamConfigurationResource.SplunkEndpoint &&
            isEqual(this.state.splunkToken, auditStreamConfiguration.StreamConfigurationResource.SplunkEventCollectorToken) &&
            (!this.state.splunkToken?.NewValue || this.state.splunkToken.NewValue === auditStreamConfiguration.StreamConfigurationResource.SplunkEventCollectorToken.NewValue) &&
            this.state.description === auditStreamConfiguration.Description);
    }
    isUnchangedSumoLogicProvider(auditStreamConfiguration: AuditStreamConfigurationResource) {
        return (isSumoLogic(auditStreamConfiguration.StreamConfigurationResource) &&
            this.state.activeCategory === AuditStreamType.SumoLogic &&
            isEqual(this.state.sumoLogicEndpoint, auditStreamConfiguration.StreamConfigurationResource.SumoLogicEndpoint) &&
            this.state.description === auditStreamConfiguration.Description);
    }
    handleCancel() {
        this.clearErrors();
        this.props.close();
        return true;
    }
    render() {
        return (<SaveDialogLayout title="Configure Audit Stream" busy={this.state.busy} errors={this.errors} saveButtonDisabled={this.isSaveButtonDisabled()} onSaveClick={() => this.save()} onCancelClick={() => this.handleCancel()}>
                <div>
                    <h4 style={{ margin: "0px", paddingTop: "0.5rem" }}>Select a provider</h4>
                    <ol className={styles.categories}>
                        <div>
                            {[AuditStreamType.OpenTelemetry, AuditStreamType.Splunk, AuditStreamType.SumoLogic].map((category) => (<AuditStreamCategory key={category} category={category} name={category} active={this.state.activeCategory ? this.state.activeCategory === category : false} onCategorySelected={() => this.selectCategory(category)}/>))}
                        </div>
                    </ol>
                    <Note>
                        Looking for a provider not shown here? Let us know in our <ExternalLink href="AuditStreamFeedbackForm">feedback form</ExternalLink>.
                    </Note>
                </div>
                {this.state.activeCategory === AuditStreamType.OpenTelemetry && (<div>
                        <div style={{ marginTop: "1rem", marginBottom: "1rem", float: "right" }}>
                            <AutoFillOpenTelemetrySettingsButton onFill={(values) => this.setState(values)}/>
                        </div>

                        <Text label={"OpenTelemetry Endpoint URL"} value={this.state.openTelemetryEndpoint} onChange={(openTelemetryEndpoint) => this.setState({ openTelemetryEndpoint })} helperText={<>
                                    Your OpenTelemetry log endpoint, including `/v1/logs` (in most cases). E.g. <code>https://mycollector.example.com:4317/v1/logs</code>. If the url contains the authentication token replace it with{" "}
                                    <code>#&#123;Secret&#125;</code> and enter the authentication token into the Secret field below.
                                </>}/>
                        <Select value={this.state.openTelemetryProtocol} 
            /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
            onChange={(openTelemetryProtocol) => this.setState({ openTelemetryProtocol: openTelemetryProtocol as OtlpProtocol })} items={[
                    { text: "HTTP/protobuf", value: OtlpProtocol.HttpProtobuf },
                    { text: "gRPC", value: OtlpProtocol.Grpc },
                ]} label="OTLP Protocol"/>
                        <Sensitive value={this.state.openTelemetrySecret} onChange={(openTelemetrySecret) => this.setState({ openTelemetrySecret })} label={"Secret"} helperText={<>
                                    Authentication token to be replaced using the <code>#&#123;Secret&#125;</code> placeholder token in the endpoint url or header values.
                                </>}/>
                        <KeyValueEditList items={() => keys(this.state.openTelemetryHeaders).map((key) => ({ key, value: this.state.openTelemetryHeaders[key] }))} separator="=" name="Header" onChange={(kvps) => {
                    this.setState({
                        openTelemetryHeaders: mapValues(keyBy(kvps, (kvp) => kvp.key), (v) => v.value),
                    });
                }} keyLabel="Header" valueLabel="Value" hideBindOnKey={true} hideBindOnValue={true}/>
                        <Text label={"Description"} value={this.state.description} onChange={(description) => this.setState({ description })}/>
                    </div>)}
                {this.state.activeCategory === AuditStreamType.Splunk && (<div>
                        <Text helperText={<React.Fragment>
                                    Your Splunk HTTP Collector Endpoint. E.g. <code>https://mysplunkserver.example.com</code>
                                </React.Fragment>} label={"Splunk Endpoint URL"} value={this.state.splunkEndpoint} onChange={(splunkEndpoint) => this.setState({ splunkEndpoint })}/>
                        <Sensitive value={this.state.splunkToken} onChange={(splunkToken) => this.setState({ splunkToken })} label={"Token"} helperText={"Authentication token to send data to the event collector."}/>
                        <Text label={"Description"} value={this.state.description} onChange={(description) => this.setState({ description })}/>
                    </div>)}
                {this.state.activeCategory === AuditStreamType.SumoLogic && (<div>
                        <Sensitive helperText={<React.Fragment>
                                    Your Sumo Logic HTTP Collector Endpoint. E.g. <code>https://collectors.sumologic.com/receiver/...</code>
                                </React.Fragment>} label={"Sumo Logic Endpoint URL"} value={this.state.sumoLogicEndpoint} onChange={(sumoLogicEndpointUrl) => this.setState({ sumoLogicEndpoint: sumoLogicEndpointUrl })}/>
                        <Text label={"Description"} value={this.state.description} onChange={(description) => this.setState({ description })}/>
                    </div>)}
            </SaveDialogLayout>);
    }
    selectCategory(category: AuditStreamType) {
        this.clearErrors();
        // If the already selected category has been selected again, we should de-select it instead
        const newCategory = this.state.activeCategory === category ? null : category;
        this.setState({
            activeCategory: newCategory,
            description: "",
            splunkEndpoint: "",
            splunkToken: null!,
            sumoLogicEndpoint: null!,
        });
    }
    static displayName = "EditAuditStreamDialogLayoutInternal";
}
const EditAuditStreamDialogLayout: React.FC<EditAuditStreamDialogLayoutProps> = (props) => {
    const trackAction = useAnalyticTrackedActionDispatch();
    return <EditAuditStreamDialogLayoutInternal {...props} trackAction={trackAction}/>;
};
EditAuditStreamDialogLayout.displayName = "EditAuditStreamDialogLayout"
export default EditAuditStreamDialogLayout;

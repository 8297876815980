/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { SettingsMetadataItemResource, TypeMetadata, PropertyMetadata, MetadataTypeCollection, DataContext, SettingsId } from "@octopusdeploy/octopus-server-client";
import { Permission, OctopusError } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import DynamicForm from "~/components/DynamicForm/DynamicForm";
import MetadataTypeValidator from "~/components/DynamicForm/MetadataTypeValidator";
import FormBaseComponent from "~/components/FormBaseComponent/FormBaseComponent";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface SettingsEditModel {
    id: string;
    name: string;
}
interface SettingsEditState extends OptionalFormBaseComponentState<SettingsEditModel> {
    settingsMetadata?: SettingsMetadataItemResource;
    types?: MetadataTypeCollection;
    values?: DataContext;
    validator: MetadataTypeValidator;
}
export class EditSettingsPage extends FormBaseComponent<{
    settingId: SettingsId;
}, SettingsEditState, SettingsEditModel> {
    constructor(props: {
        settingId: SettingsId;
    }) {
        super(props);
        this.state = {
            settingsMetadata: null!,
            types: null!,
            values: null!,
            validator: new MetadataTypeValidator(),
        };
    }
    currentSettingsId() {
        return this.props.settingId;
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const settingsResponse = await repository.Settings.getById(this.currentSettingsId());
            const metadata = await repository.Settings.getMetadata(settingsResponse);
            const values = await repository.Settings.getValues(settingsResponse);
            this.setState({ settingsMetadata: settingsResponse, types: metadata, values });
            this.setModel(values);
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            this.validate();
            try {
                const saveResponse = await repository.Settings.saveValues(this.state.settingsMetadata!, this.state.values);
                this.setState({
                    settingsMetadata: this.state.settingsMetadata,
                    types: this.state.types,
                    values: saveResponse,
                });
                this.setModel(saveResponse);
                this.clearError();
            }
            catch (ex) {
                const fieldErrors: {
                    [other: string]: string;
                } = {};
                if (ex.Details?.Errors) {
                    for (const key in ex.Details.Errors) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const errors = ex.Details.Errors[key].map((value: any) => value.ErrorMessage);
                        fieldErrors[key] = errors.join("\n");
                    }
                    this.setValidationErrors("Validation failed. Please check the errors messages below.", fieldErrors);
                }
                else {
                    throw ex;
                }
            }
            return false;
        });
    };
    render() {
        const parentSection = this.state.types && (<div>
                <DynamicForm description={this.state.types.Description} types={this.state.types.Types} values={this.state.values} onChange={(c) => this.setState({
                settingsMetadata: this.state.settingsMetadata,
                types: this.state.types,
                values: c,
            })}/>
            </div>);
        if (this.state.types && this.areAllTypesReadonly(this.state.types.Types)) {
            return (<PaperLayoutVNext breadcrumbsItems={[{ label: "Settings", pageUrl: links.settingsListPage.generateUrl() }]} title={this.state.settingsMetadata && this.state.settingsMetadata.Name}>
                    {parentSection}
                </PaperLayoutVNext>);
        }
        else {
            return (<LegacyForm model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{ permission: Permission.ConfigureServer }} onSaveClick={this.handleSaveClick} saveText="Settings changed">
                    {({ FormContent, createSaveAction }) => (<PaperLayoutVNext primaryAction={createSaveAction({})} breadcrumbsItems={[{ label: "Settings", pageUrl: links.settingsListPage.generateUrl() }]} busy={this.state.busy} errors={this.errors} title={this.state.settingsMetadata && this.state.settingsMetadata.Name}>
                            <FormContent>{parentSection}</FormContent>
                        </PaperLayoutVNext>)}
                </LegacyForm>);
        }
    }
    private validate() {
        const validations = this.state.validator.validateType(this.state.types!.Types, this.state.values!);
        if (validations && validations.length > 0) {
            const exception = new OctopusError(0, "There were validation errors:");
            exception.Errors = validations;
            throw exception;
        }
    }
    private areAllTypesReadonly(types: TypeMetadata[]) {
        let isReadonly: boolean = true;
        types.map((t) => (isReadonly = isReadonly && this.areAllPropertiesReadonly(t)));
        return isReadonly;
    }
    private areAllPropertiesReadonly(type: TypeMetadata) {
        let isReadonly: boolean = true;
        type.Properties.map((p) => (isReadonly = isReadonly && this.isTypeReadonly(p)));
        return isReadonly;
    }
    private isTypeReadonly(type: PropertyMetadata) {
        return type.DisplayInfo.ReadOnly;
    }
    static displayName = "EditSettingsPage";
}

import { ActionButton, ActionButtonType, CustomMenu, MenuItemButton, useMenuState } from "@octopusdeploy/design-system-components";
import { OtlpProtocol } from "@octopusdeploy/octopus-server-client";
import type { Dictionary } from "lodash";
import { keys } from "lodash";
import * as React from "react";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
export interface AutoFillOpenTelemetrySettings {
    openTelemetryEndpoint: string;
    openTelemetryHeaders: Dictionary<string>;
    openTelemetryProtocol: OtlpProtocol;
}
export interface AutoFillOpenTelemetrySettingsButtonProps {
    onFill: (values: AutoFillOpenTelemetrySettings) => void;
}
export function AutoFillOpenTelemetrySettingsButton({ onFill }: AutoFillOpenTelemetrySettingsButtonProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    return (<>
            <ActionButton type={ActionButtonType.Secondary} icon={<DropDownIcon />} iconPosition="right" label="Fill presets for..." onClick={openMenu} menuButtonAttributes={buttonAriaAttributes}/>
            <CustomMenu accessibleName={"Fill presets for"} menuState={menuState}>
                {keys(settings).map((key) => (<MenuItemButton key={key} onClick={() => {
                onFill(settings[key]);
                menuState.onClose();
            }}>
                        {key}
                    </MenuItemButton>))}
            </CustomMenu>
        </>);
}
interface SettingsMap {
    [index: string]: AutoFillOpenTelemetrySettings;
}
const settings: SettingsMap = {
    "OpenTelemetry Collector (HTTP)": {
        openTelemetryEndpoint: "http://<your_collector_hostname>:4318/v1/logs",
        openTelemetryHeaders: {},
        openTelemetryProtocol: OtlpProtocol.HttpProtobuf,
    },
    "OpenTelemetry Collector (gRPC)": {
        openTelemetryEndpoint: "http://<your_collector_hostname>:4317/v1/logs",
        openTelemetryHeaders: {},
        openTelemetryProtocol: OtlpProtocol.Grpc,
    },
    "New Relic": {
        openTelemetryEndpoint: "https://otlp.<eu01.>nr-data.net/v1/logs",
        openTelemetryHeaders: {
            "api-key": "#{Secret}",
        },
        openTelemetryProtocol: OtlpProtocol.HttpProtobuf,
    },
    Seq: {
        openTelemetryEndpoint: "https://<your_seq_server>/ingest/otlp/v1/logs",
        openTelemetryHeaders: {
            "X-Seq-ApiKey": "#{Secret}",
        },
        openTelemetryProtocol: OtlpProtocol.HttpProtobuf,
    },
    "Sumo Logic": {
        openTelemetryEndpoint: "https://collectors.<region>.sumologic.com/receiver/v1/otlp/#{Secret}",
        openTelemetryHeaders: {},
        openTelemetryProtocol: OtlpProtocol.HttpProtobuf,
    },
};

/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ActionTemplateSelector from "../ActionTemplateSelector";
import type { ActionTemplateSelectorProps } from "../ActionTemplateSelector/ActionTemplateSelector";
export const EnhancedActionTemplateSelectionPage: React.FC<Omit<ActionTemplateSelectorProps, "processId" | "processContext" | "projectContext" | "processQueryStringContext"> & {
    processType: ProcessType;
}> = ({ children, ...rest }) => {
    return <ActionTemplateSelector {...rest}>{children}</ActionTemplateSelector>;
};
EnhancedActionTemplateSelectionPage.displayName = "EnhancedActionTemplateSelectionPage"

import type { AuditStreamConfigurationResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import EditAuditStreamDialogLayout from "~/areas/configuration/components/AuditLayout/AuditStream/EditAuditStreamDialogLayout";
import newStreamImage from "~/areas/configuration/components/AuditLayout/assets/img-newstream.svg";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import OnboardingDialog from "~/components/OnboardingDialog/OnboardingDialog";
export interface EditAuditStreamDialogProps {
    open: boolean;
    close: () => void;
    auditStreamConfiguration: AuditStreamConfigurationResource;
    onUpdate: (value: AuditStreamConfigurationResource, message: string) => void;
}
export function EditAuditStreamDialog({ open, close, auditStreamConfiguration, onUpdate }: EditAuditStreamDialogProps) {
    const helpPanelContent = (<>
            <p>
                <strong>Audit streaming</strong> lets you stream audit and Octopus events data from Octopus to an external management system
            </p>
            <p>
                See the <ExternalLink href="AuditStream">Audit Stream documentation</ExternalLink> for more configuration details.
            </p>
        </>);
    return (<OnboardingDialog name="edit-audit-stream" helpPanelImage={{ src: newStreamImage, altText: "Configure Audit Stream" }} helpPanelContent={helpPanelContent} open={open} close={close} useBackwardsCompatiblePadding={true}>
            <EditAuditStreamDialogLayout close={close} onUpdate={onUpdate} auditStreamConfiguration={auditStreamConfiguration}/>
        </OnboardingDialog>);
}

import { css } from "@emotion/css";
import type { SummaryView } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import MissingVariablesIcon from "~/areas/tenants/MissingVariablesIcon/MissingVariablesIcon";
import { Leftovers } from "~/areas/tenants/components/DataTable/Cells/Leftovers";
import { EnvironmentChip } from "~/components/Chips/index";
interface TenantDataTableMultipleProjectsEnvironmentsCellProps {
    hasMissingVariables: boolean;
    environments: string[];
}
export function TenantDataTableMultipleProjectsEnvironmentsCell({ hasMissingVariables, environments }: TenantDataTableMultipleProjectsEnvironmentsCellProps) {
    if (environments.length === 1) {
        return (<div className={containerStyles}>
                <MissingVariablesIcon show={hasMissingVariables}/>
                <EnvironmentChip environmentName={environments[0]} key={environments[0]}/>
            </div>);
    }
    return (<div className={containerStyles}>
            <MissingVariablesIcon show={hasMissingVariables}/>
            <EnvironmentChip noTooltip={true} environmentName={"Multiple environments"} key={"Multiple environments"}/>
        </div>);
}
interface TenantDataTableEnvironmentsCellProps {
    environments?: SummaryView<string>;
    allEnvironments?: string[];
    hasMissingVariables: boolean;
    onShowAll?: (category: string) => Promise<void>;
}
export function TenantDataTableEnvironmentsSummaryCell({ environments, allEnvironments, hasMissingVariables, onShowAll }: TenantDataTableEnvironmentsCellProps) {
    const [showAll, setShowAll] = useState(false);
    async function handleOnShowAll() {
        if (onShowAll) {
            await onShowAll("Environment");
        }
        setShowAll(!showAll);
    }
    const environmentsToShow = showAll && allEnvironments ? allEnvironments : environments?.Items ?? [];
    return (<div className={containerStyles}>
            <TenantDataTableAllEnvironmentsCell environments={environmentsToShow} hasMissingVariables={hasMissingVariables}/>
            <Leftovers count={environments?.MoreItems} name={"environment"} onShowAllChanged={handleOnShowAll} showAll={showAll}/>
        </div>);
}
interface TenantDataTableAllEnvironmentsCellProps {
    environments: string[];
    hasMissingVariables: boolean;
}
export function TenantDataTableAllEnvironmentsCell({ environments, hasMissingVariables }: TenantDataTableAllEnvironmentsCellProps) {
    if (environments.length === 0) {
        return <div>No environments</div>;
    }
    const displayEnvironments = environments.map((e) => <EnvironmentChip environmentName={e} key={e}/>);
    return (<div className={containerStyles}>
            <MissingVariablesIcon show={hasMissingVariables}/>
            {displayEnvironments}
        </div>);
}
const containerStyles = css({
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
});

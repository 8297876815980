import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { PaperLayoutVNextProps } from "~/components/PaperLayout/PaperLayoutVNext";
import { RunbooksPaperLayout } from "../../Runbooks/Layouts";
import { ProjectContextPaperLayout } from "./ProjectContextPaperLayout";
type ProcessPaperLayoutProps = PaperLayoutVNextProps & {
    processType: ProcessType;
};
export const ProcessPaperLayout: React.FC<ProcessPaperLayoutProps> = ({ children, ...rest }) => {
    if (rest.processType === ProcessType.Runbook) {
        return (<RunbooksPaperLayout fullWidth={true} {...rest}>
                {children}
            </RunbooksPaperLayout>);
    }
    else {
        return (<ProjectContextPaperLayout fullWidth={true} {...rest}>
                {children}
            </ProjectContextPaperLayout>);
    }
};
ProcessPaperLayout.displayName = "ProcessPaperLayout"

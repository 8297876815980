import { Callout, Checkbox, Tooltip } from "@octopusdeploy/design-system-components";
import type { ActionTemplateResource, ActionTemplateSearchResource, FeedResource, GitRefResource } from "@octopusdeploy/octopus-server-client";
import { HasManualInterventionResponsibleTeams, RunConditionForAction } from "@octopusdeploy/octopus-server-client";
import { generateSlug, noOp } from "@octopusdeploy/utilities";
import { Section } from "app/components/Section/Section";
import { difference, intersection, keyBy, uniq } from "lodash";
import { useMemo, useState } from "react";
import * as React from "react";
import { ContainersFeedbackCallout } from "~/areas/ContainersFeedbackCallout";
import { getBlueprintIdFromAction } from "~/areas/projects/components/Process/Blueprints/blueprintId";
import { ActionContainerImageSelectorFormSection } from "~/areas/projects/components/Process/Common/ActionContainerImageSelectorFormSection";
import { isRunOnServerOrWorkerPool, runsOnServer, whereConfiguredToRun } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import Environments from "~/areas/projects/components/Process/Common/Environments";
import ExecutionPlan from "~/areas/projects/components/Process/Common/ExecutionPlan";
import { RollingDeploymentExpander } from "~/areas/projects/components/Process/Common/RollingDeploymentExpander";
import RunTriggerExpander from "~/areas/projects/components/Process/Common/RunTriggerExpander";
import RunTriggerForChildActionExpander from "~/areas/projects/components/Process/Common/RunTriggerForChildActionExpander";
import StartTriggerExpander from "~/areas/projects/components/Process/Common/StartTriggerExpander";
import StepName from "~/areas/projects/components/Process/Common/StepName";
import { TargetRolesFormSection } from "~/areas/projects/components/Process/Common/TargetRolesFormSection";
import TenantsExpander from "~/areas/projects/components/Process/Common/TenantsExpander";
import { useProcessBlueprintsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessBlueprintsContextProvider";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import type { EnvironmentSelection } from "~/areas/projects/components/Process/ProcessActionDetails";
import type { FailedPermissionCheck, ProcessStepActionState, ProcessStepLookupState } from "~/areas/projects/components/Process/ProcessStepsLayoutTypes";
import { ProcessSubPageLayout } from "~/areas/projects/components/Process/ProcessSubPageLayout";
import styles from "~/areas/projects/components/Process/style.module.less";
import type { StoredAction } from "~/areas/projects/components/Process/types";
import { isDeploymentOrRunbookProcessIdentifier, EnvironmentOption, ExecutionLocation } from "~/areas/projects/components/Process/types";
import getActionLogoUrl from "~/areas/projects/components/getActionLogoUrl";
import { usePersistenceSettingsContext } from "~/areas/projects/context/PersistenceSettingsContext";
import { ProjectActionPropertiesEditor } from "~/components/ActionPropertiesEditor/ProjectActionPropertiesEditor";
import ActionTemplateEditor from "~/components/ActionTemplateEditor/ActionTemplateEditor";
import { HelmV2DeprecationCallout } from "~/components/Actions/helmChartUpgrade/HelmV2DeprecationCallout";
import pluginRegistry, { type ActionEditProps, type AdditionalActions, type MixedExecutionLocationsConfig } from "~/components/Actions/pluginRegistry";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { ExpandableContainer } from "~/components/Expandable/index";
import FeatureEditor from "~/components/FeatureEditor/FeatureEditor";
import { Feature, FeatureToggle } from "~/components/FeatureToggle/index";
import Logo from "~/components/Logo/index";
import Markdown from "~/components/Markdown/index";
import convertPropertyValueResourceToString from "~/components/convertPropertyValueResourceToString";
import { ExpandableFormSection, ExpansionButtons, FormSectionHeading, Note, Summary, UnstructuredFormSection } from "~/components/form/index";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon/index";
import NumberPicker from "~/primitiveComponents/form/Number/NumberPicker";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import ParseHelper from "~/utils/ParseHelper/index";
import PackageRequirementExpander from "./Common/PackageRequirementExpander";
export type ProcessBlueprintActionDetailsProps = {
    blueprintId: string;
    blueprintActionId: string;
    doBusyTask: DoBusyTask;
    busy: boolean;
    stepLookups: ProcessStepLookupState;
    stepOther: ProcessStepActionState;
    actionTemplates: ActionTemplateSearchResource[];
    gitRefResource: GitRefResource | undefined;
    isTenanted: boolean;
};
function ProcessBlueprintActionDetails({ blueprintId, blueprintActionId, doBusyTask, busy, stepLookups, stepOther, actionTemplates, gitRefResource, isTenanted }: ProcessBlueprintActionDetailsProps) {
    const processContext = useProcessContext();
    const processIdentifier = processContext.state.processIdentifier;
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const blueprints = useProcessBlueprintsFromContext();
    const [action, setAction] = useState<StoredAction | null>(null);
    const persistenceSettings = usePersistenceSettingsContext();
    const blueprint = blueprints.find((b) => b.Id === blueprintId);
    const step = blueprint?.Steps.find((s) => s.Actions.some((a) => a.Id === blueprintActionId));
    useDoBusyTaskEffect(doBusyTask, async () => {
        const blueprintAction = step?.Actions.find((a) => a.Id === blueprintActionId);
        if (!step || !blueprintAction) {
            return;
        }
        const plugin = await pluginRegistry.getAction(blueprintAction.ActionType);
        setAction({ ...blueprintAction, ParentId: step?.Id, plugin });
    }, [step, blueprintActionId]);
    const blueprintStepNumberInProcess = useMemo(() => {
        const actionIdInProcess = Object.values(processContext.state.model.actions.byId).find((a) => getBlueprintIdFromAction(a) === blueprintId)?.Id;
        const stepIdInProcess = Object.values(processContext.state.model.steps.byId).find((s) => s.ActionIds.some((a) => a === actionIdInProcess))?.Id;
        if (!stepIdInProcess) {
            return null;
        }
        return processContext.state.model.steps.allIds.indexOf(stepIdInProcess) + 1;
    }, [processContext.state.model.actions.byId, processContext.state.model.steps, blueprintId]);
    if (!blueprint || !step || !blueprintStepNumberInProcess) {
        return (<Section>
                <Callout type={"information"} title={"Blueprint could not be found"}>
                    {<span>The requested blueprint could not be found. Please select from the available steps.</span>}
                </Callout>
            </Section>);
    }
    if (!action) {
        return null;
    }
    const isChildAction = step.Actions.length > 1;
    const stepNumberInBlueprint = blueprint.Steps.map((s) => s.Id).indexOf(step.Id) + 1;
    const actionNumberInBlueprintStep = step.Actions.map((a) => a.Id).indexOf(action.Id) + 1;
    const topEditSection = action.plugin.editSections.top;
    const defaultEditSection: React.ComponentType<ActionEditProps> = action.plugin.editSections.default;
    const actionEditorAdditionalActions: AdditionalActions = {
        packageAcquisition: {
            stepPackageRequirement: step.PackageRequirement,
            onStepPackageRequirementChanged: noOp,
        },
        stepTargetRoles: convertPropertyValueResourceToString(step.Properties["Octopus.Action.TargetRoles"]),
        actionType: action.plugin.actionType,
    };
    const runOn = whereConfiguredToRun(!!step.Properties["Octopus.Action.TargetRoles"], action, stepLookups.availableWorkerPools, action.plugin);
    const runsOnServerOrWorkerPoolCopy = isRunOnServerOrWorkerPool(runOn) ? runOn : null;
    const allAvailableRoles = uniq([...stepLookups.machineRoles, ...ParseHelper.parseCSV(convertPropertyValueResourceToString(step.Properties["Octopus.Action.TargetRoles"]))]);
    const mixedExecutionLocationsConfig: MixedExecutionLocationsConfig = (action.plugin.mixedExecutionLocations && action.plugin.mixedExecutionLocations()) || { enabled: false };
    const disableInlineExecutionContainers: boolean = action.plugin.disableInlineExecutionContainers || false;
    const shouldRunOnOrOnBehalfOfTargets = runOn.executionLocation === ExecutionLocation.DeploymentTarget || runOn.executionLocation === ExecutionLocation.OctopusServerForRoles || runOn.executionLocation === ExecutionLocation.WorkerPoolForRoles;
    const shouldRenderTargetRolesAndRollingDeploymentExpanders = !mixedExecutionLocationsConfig.enabled && !isChildAction && shouldRunOnOrOnBehalfOfTargets;
    const isKubernetesStep = actionTemplates.filter((template) => template.Categories.includes("Kubernetes")).some((template: ActionTemplateSearchResource) => template.Name === stepOther.actionTypeName);
    const hasManualInterventionResponsibleTeams = HasManualInterventionResponsibleTeams(action);
    const environmentOption = (action.Environments || []).length > 0 ? EnvironmentOption.Include : (action.ExcludedEnvironments || []).length > 0 ? EnvironmentOption.Exclude : EnvironmentOption.All;
    const maximumRetries = Number(action.Properties["Octopus.Action.AutoRetry.MaximumCount"] ?? 0);
    const actionExecutionTimeOut = Number(action.Properties["Octopus.Action.ExecutionTimeout.Minutes"] ?? 0);
    const canRunOnWorker = action.plugin.canRunOnWorker ?? true;
    const canBeRetried = action.plugin.canBeRetried ?? true;
    const canUseExecutionTimeouts = action.plugin.canUseExecutionTimeouts ?? true;
    const canRunInContainer = action.plugin.canRunInContainer ?? true;
    const loadFeeds = async (callback?: (feeds: FeedResource[]) => void) => {
        await doBusyTask(async () => {
            await refreshFeeds();
            if (callback) {
                callback(feeds);
            }
        });
    };
    const getStepTemplateName = (): string => {
        if (isStepTemplate(stepLookups.actionTemplate)) {
            return stepLookups.actionTemplate.Name;
        }
        else if (action.StepPackageVersion) {
            return action.plugin.stepPackage?.name ?? action.ActionType;
        }
        return actionTemplates.find((x) => x.Type === action.ActionType)?.Name ?? "";
    };
    const environmentSelection = (): EnvironmentSelection => {
        const environmentsByKey = keyBy(stepLookups.environments, "Id");
        const knownEnvironments = Object.keys(environmentsByKey);
        const unavailableEnvironments = difference(action.Environments || [], knownEnvironments);
        const unavailableExcludedEnvironments = difference(action.ExcludedEnvironments || [], knownEnvironments);
        const inclusiveEnvironments = intersection(action.Environments || [], knownEnvironments);
        const exclusiveEnvironments = intersection(action.ExcludedEnvironments || [], knownEnvironments);
        return {
            unavailable: unavailableEnvironments,
            unavailableExclusive: unavailableExcludedEnvironments,
            inclusive: inclusiveEnvironments,
            exclusive: exclusiveEnvironments,
        };
    };
    const { unavailableExclusive, unavailable } = environmentSelection();
    const hasHiddenEnvironments = unavailableExclusive.length > 0 || unavailable.length > 0;
    return (<ExpandableContainer containerKey={blueprintActionId}>
            <ProcessSubPageLayout title={<StepName name={action.Name} number={`${blueprintStepNumberInProcess}.${stepNumberInBlueprint}${isChildAction ? `.${actionNumberInBlueprintStep}` : ""}`} stepType={action.ActionType}/>} titleLogo={action && <Logo url={getActionLogoUrl(action)}/>}>
                <ExpansionButtons containerKey={action.Id} errors={undefined} expandAllOnMount={false}/>
                <Callout title="Blueprint steps are read-only" type={"information"}>
                    This step is part of a process blueprint and cannot be modified. Any changes made here won't be persisted.
                </Callout>
                <ContainersFeedbackCallout onTaskPage={false} actionTypes={[action.ActionType]} action={action}/>
                <HelmV2DeprecationCallout action={action}/>
                {action.IsDisabled && (<UnstructuredFormSection stretchContent={true}>
                        <Callout type={"warning"} title={"This step is currently disabled"}/>
                    </UnstructuredFormSection>)}
                <ExpandableFormSection isExpandedByDefault={false} errorKey="Name" title="Step Name" focusOnExpandAll summary={Summary.summary(nameSummary(action.Name, action.Slug ?? null, action.Notes))} help="A short, memorable, unique name for this step.">
                    <DebounceText value={action.Name} onChange={noOp} label="Step name" disabled={true}/>
                    <SlugEditor value={action.Slug ?? generateSlug(action.Name)} name={action.Name} originalSlug={action.Slug ?? ""} onChange={noOp} label="Step slug" readOnly={true}/>
                </ExpandableFormSection>
                {mixedExecutionLocationsConfig.enabled && (<>
                        <TargetRolesFormSection projectSlug={isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectSlug : processIdentifier.blueprintId} expandedByDefault={false} availableRoles={allAvailableRoles} targetRoles={convertPropertyValueResourceToString(step.Properties["Octopus.Action.TargetRoles"])} onTargetRolesChanged={noOp} runOn={runOn} errorMessage={undefined} analyticsStepEditorDispatch={noOp} title={"Target Tags"} isKubernetesStep={isKubernetesStep} doBusyTask={doBusyTask} userOnboarding={null} analyticsArea="Top Section" stepTemplateName={getStepTemplateName()} isGuidedSetup={false}/>
                        {mixedExecutionLocationsConfig.allLocationsRequireActionContainer && (<ActionContainerImageSelectorFormSection projectId={isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectId : undefined} runOn={runOn} canRunInContainer={canRunInContainer} expandedByDefault={false} feeds={feeds} loadFeeds={loadFeeds} onRunOnChanged={noOp} imageNameError={""} doBusyTask={doBusyTask} disableInlineExecutionContainers={disableInlineExecutionContainers}/>)}
                    </>)}
                {!stepLookups.actionTemplate && topEditSection && (<ProjectActionPropertiesEditor editor={topEditSection} plugin={action.plugin} deploymentActionSlug={action.Slug || ""} isNew={false} doBusyTask={doBusyTask} busy={busy} inputs={action.Inputs} properties={action.Properties} packages={action.Packages} gitDependencies={action.GitDependencies} runOn={runOn} setInputs={noOp} setProperties={noOp} setPackages={noOp} setGitDependencies={noOp} additionalActions={actionEditorAdditionalActions} getFieldError={emptyFieldError} errors={undefined} expandedByDefault={false} refreshRunOn={noOp} getProcessResource={processContext.selectors.getProcessResource} projectTriggers={stepLookups.projectTriggers} gitRefResource={gitRefResource}/>)}
                <ExecutionPlan projectId={isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectId : undefined} projectSlug={isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectSlug : processIdentifier.blueprintId} gitRef={gitRefResource} expandedByDefault={false} doBusyTask={doBusyTask} executionLocation={action.plugin.executionLocation} runOnServerOrWorkerPoolCopy={runsOnServerOrWorkerPoolCopy} runOn={runOn} onRunOnChanged={noOp} targetRoleOption={action.plugin.targetRoleOption(action)} targetRoles={convertPropertyValueResourceToString(step.Properties["Octopus.Action.TargetRoles"])} onTargetRolesChanged={noOp} targetRolesError={""} isChildStep={isChildAction} maxParallelism={convertPropertyValueResourceToString(step.Properties["Octopus.Action.MaxParallelism"])} onMaxParallelismChanged={noOp} availableRoles={allAvailableRoles} availableWorkerPools={stepLookups.availableWorkerPools} canRunOnWorker={canRunOnWorker} isBuiltInWorkerEnabled={stepOther.isBuiltInWorkerEnabled} targetWorkerPool={action.WorkerPoolId} targetWorkerPoolVariable={action.WorkerPoolVariable} onTargetWorkerPoolChanged={noOp} runsOnServer={runsOnServer(action, action.plugin.executionLocation)} getFieldError={emptyFieldError} feeds={feeds} loadFeeds={loadFeeds} canRunInContainer={canRunInContainer} containerImageRecommendation={undefined} imageNameError={""} analyticsStepEditorDispatch={noOp} mixedExecutionLocationsConfig={mixedExecutionLocationsConfig} disableInlineExecutionContainers={disableInlineExecutionContainers} isKubernetesStep={isKubernetesStep} userOnboarding={null} shouldRenderTargetRolesExpander={shouldRenderTargetRolesAndRollingDeploymentExpanders} stepTemplateName={getStepTemplateName()}/>
                {/*Blueprints don't allow configuring cloud target discovery since they're reliant on project variables. It's also a bit tricky to prevent this from appearing configurable*/}
                {/*{action.plugin.targetDiscoveryCloudConnectionProviders && action.plugin.targetDiscoveryCloudConnectionProviders().length > 0 && stepLookups.projectVariables && (*/}
                {/*    <CloudConnections*/}
                {/*        connectionType={CloudConnectionUsageType.TargetDiscovery}*/}
                {/*        isExpandedByDefault={false}*/}
                {/*        providers={action.plugin.targetDiscoveryCloudConnectionProviders()}*/}
                {/*        sectionErrorKey="Octopus.TargetDiscovery.CloudConnections"*/}
                {/*        sectionHelpText="Configure a cloud connection to enable cloud target discovery"*/}
                {/*        projectVariables={stepLookups.projectVariables}*/}
                {/*        libraryVariableSets={stepLookups.libraryVariableSets}*/}
                {/*        refreshVariables={noOp}*/}
                {/*        saveVariables={(variables) => new Promise(() => variables)} // What would be done here?*/}
                {/*        project={projectContext.state.model}*/}
                {/*        isDefaultBranch={projectContext.state.isDefaultBranch}*/}
                {/*        gitRef={gitRefResource?.CanonicalName}*/}
                {/*        haveVariablesChanged={() => new Promise(() => false)} // Same here*/}
                {/*    />*/}
                {/*)}*/}
                {!stepLookups.actionTemplate && (<div>
                        <ProjectActionPropertiesEditor editor={defaultEditSection} plugin={action.plugin} deploymentActionSlug={action.Slug || ""} isNew={false} doBusyTask={doBusyTask} busy={busy} inputs={action.Inputs} properties={action.Properties} packages={action.Packages} gitDependencies={action.GitDependencies} runOn={runOn} setInputs={noOp} setProperties={noOp} setPackages={noOp} setGitDependencies={noOp} additionalActions={actionEditorAdditionalActions} getFieldError={emptyFieldError} errors={undefined} expandedByDefault={false} refreshRunOn={noOp} getProcessResource={processContext.selectors.getProcessResource} projectTriggers={stepLookups.projectTriggers} gitRefResource={gitRefResource}/>

                        {pluginRegistry.hasFeaturesForAction(action.ActionType) && (<FeatureEditor plugin={action.plugin} projectId={isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectId : undefined} gitRef={gitRefResource} isNew={false} doBusyTask={doBusyTask} busy={busy} properties={action.Properties} gitDependencies={action.GitDependencies} packages={action.Packages} runOn={runOn} setProperties={noOp} setPackages={noOp} setGitDependencies={noOp} enabledFeatures={convertPropertyValueResourceToString(action.Properties["Octopus.Action.EnabledFeatures"])} getFieldError={emptyFieldError} errors={undefined} expandedByDefault={false} openFeaturesElement={undefined} refreshRunOn={noOp}/>)}
                        {stepLookups.actionTemplate && (<ActionTemplateEditor actionTemplate={stepLookups.actionTemplate} process={processContext.state.model} processIdentifier={processContext.state.processIdentifier} processType={processContext.selectors.getProcessType()} actionId={action.Id} properties={action.Properties} setProperties={noOp} setPackages={noOp} setGitDependencies={noOp} doBusyTask={doBusyTask} onActionTemplateUpdate={processContext.actions.refreshFromServer} gitRefResource={gitRefResource}/>)}
                        <FormSectionHeading title="Conditions"/>
                        <Environments environmentOption={environmentOption} hasHiddenEnvironments={hasHiddenEnvironments} environments={stepLookups.environments} inclusiveEnvironments={action.Environments} exclusiveEnvironments={action.ExcludedEnvironments} onEnvironmentOptionChanged={noOp} onInclusiveEnvironmentsChanged={noOp} onExclusiveEnvironmentsChanged={noOp} onInclusiveEnvironmentRemoved={noOp} onExclusiveEnvironmentRemoved={noOp} persistenceSettings={persistenceSettings} processType={processIdentifier.type}/>
                        {(mixedExecutionLocationsConfig.enabled || shouldRenderTargetRolesAndRollingDeploymentExpanders) && (<RollingDeploymentExpander expandedByDefault={false} maxParallelism={convertPropertyValueResourceToString(step.Properties["Octopus.Action.MaxParallelism"])} onMaxParallelismChanged={noOp}/>)}
                        {/*Excluding channel selector*/}
                        <FeatureToggle feature={Feature.MultiTenancy}>
                            {(isTenanted || action.TenantTags.length > 0) && <TenantsExpander doBusyTask={doBusyTask} tenantTags={action.TenantTags} tagIndex={stepLookups.tagIndex} onTenantTagsChange={noOp}/>}
                        </FeatureToggle>
                        {!isChildAction && step && (<RunTriggerExpander isFirstStep={blueprintStepNumberInProcess === 1 && stepNumberInBlueprint === 1} condition={step.Condition} onConditionChange={noOp} variableExpression={convertPropertyValueResourceToString(step.Properties["Octopus.Step.ConditionVariableExpression"])} onVariableExpressionChange={noOp} variableExpressionError={undefined} localNames={undefined}/>)}
                        {isChildAction && action && (<RunTriggerForChildActionExpander isFirstStep={actionNumberInBlueprintStep === 1} condition={action.Condition || RunConditionForAction.Success} onConditionChange={noOp} variableExpression={convertPropertyValueResourceToString(action.Properties["Octopus.Action.ConditionVariableExpression"])} onVariableExpressionChange={noOp} variableExpressionError={undefined} localNames={undefined}/>)}
                        {blueprintStepNumberInProcess > 1 && !isChildAction && <StartTriggerExpander startTrigger={step.StartTrigger} onChange={noOp}/>}

                        {/*The logic for hiding PackageRequirementExpander is complicated - see processModelSelectors.shouldShowPackageRequirementOptionForAction*/}
                        {/*Always showing it for now since it's not configurable anyway*/}
                        <PackageRequirementExpander packageRequirement={step.PackageRequirement} onChange={noOp}/>

                        <ExpandableFormSection title="Required" summary={action.IsRequired || hasManualInterventionResponsibleTeams
                ? Summary.summary(<span>
                                              This step is <strong>required</strong> and cannot be skipped
                                          </span>)
                : Summary.summary(<span>
                                              This step is <strong>not required</strong> and can be skipped
                                          </span>)} help="Required steps cannot be skipped when deploying a release" errorKey="required">
                            {hasManualInterventionResponsibleTeams && <Note>Responsible teams are specified, therefore this step is always required.</Note>}

                            <Checkbox value={action.IsRequired || hasManualInterventionResponsibleTeams} label="Prevent this step from being skipped when deploying" disabled={hasManualInterventionResponsibleTeams} onChange={noOp}/>
                        </ExpandableFormSection>
                        {canBeRetried && (<ExpandableFormSection title="Retries" summary={maximumRetries > 0 ? Summary.summary(<span>Upon failure, this step will automatically retry</span>) : Summary.default(<span>No</span>)} help="Upon failure, this step will automatically retry" errorKey="retries">
                                <Checkbox value={maximumRetries > 0} label="Allow retries (max. 3)" onChange={noOp}/>
                            </ExpandableFormSection>)}
                        {canUseExecutionTimeouts && (<ExpandableFormSection title="Time out" summary={actionExecutionTimeOut > 0 ? Summary.summary(<span>This step will timeout after {actionExecutionTimeOut} minutes.</span>) : Summary.default(<span>Never</span>)} help="If this step is still running after the set time has lapsed, we will assume the task is stuck and force failure. We recommend using this option in conjunction with retries where appropriate." errorKey="timeout">
                                <NumberPicker value={actionExecutionTimeOut} label="Time out after minutes (0 means never time out)" onChange={noOp} note="The execution timeout encompasses all processes involved in executing a step, such as connecting to a target, bootstrapper scripts, execution container startup, package cache cleanups, etc but is contained to each machine"/>
                            </ExpandableFormSection>)}
                    </div>)}
            </ProcessSubPageLayout>
        </ExpandableContainer>);
}
const isStepTemplate = (stepTemplate: ActionTemplateResource | null | FailedPermissionCheck): stepTemplate is ActionTemplateResource => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return stepTemplate !== null && !(stepTemplate as FailedPermissionCheck).type;
};
// Since these steps are mostly readonly there shouldn't be any errors
const emptyFieldError = () => "";
const nameSummary = (stepName: string, stepSlug: string | null, notes: string | null) => (<div className={styles.nameSummaryContainer}>
        <NameSummaryWithSlug name={stepName} slug={stepSlug}/>
        {!!notes && (<Tooltip content={<Markdown markup={notes}/>} position="right">
                <ThirdPartyIcon iconType={ThirdPartyIconType.Note} className={styles.icon}/>
            </Tooltip>)}
    </div>);
export default ProcessBlueprintActionDetails;

import { css } from "@emotion/css";
import { EmptyStateProcessIllustration, NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GitRef } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type * as H from "history";
import * as React from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { processScopedEditPermission } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import type { DeploymentProcessIdentifier } from "~/areas/projects/components/Process/types";
import ActionList from "~/components/ActionList/ActionList";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import { PermissionCheck } from "~/components/PermissionCheck";
import SampleProcessButton from "~/components/ProjectBasedActivation/SampleProcessButton";
const actionButtons = css({
    display: "flex",
    gap: space[8],
});
interface OnboardingProps {
    location: H.Location;
    history: H.History;
    processIdentifier: DeploymentProcessIdentifier;
    gitRef: GitRef | undefined;
}
const Onboarding = ({ location, history, processIdentifier, gitRef }: OnboardingProps) => {
    const dispatchAction = useProjectScopedAnalyticActionDispatch(processIdentifier.projectId);
    const processContext = useProcessContext();
    const createProcessHref = gitRef
        ? links.branchDeploymentProcessStepsPage.generateUrl({ spaceId: processIdentifier.spaceId, projectSlug: processIdentifier.projectSlug, branchName: gitRef }, { stepTemplates: true })
        : links.deploymentProcessStepsPage.generateUrl({ spaceId: processIdentifier.spaceId, projectSlug: processIdentifier.projectSlug }, { stepTemplates: true });
    const sampleProcessHref = gitRef
        ? links.branchDeploymentProcessPage.generateUrl({ spaceId: processIdentifier.spaceId, projectSlug: processIdentifier.projectSlug, branchName: gitRef })
        : links.deploymentProcessPage.generateUrl({ spaceId: processIdentifier.spaceId, projectSlug: processIdentifier.projectSlug });
    return (<OnboardingPage title="Create your deployment process" intro={`The deployment process is like a recipe for deploying your software. You define the recipe by adding steps and variables to a project.`} learnMore={<>
                    <ExternalLink href="OnboardingDeploymentProcessLearnMore">Docs</ExternalLink>
                    <ExternalVideoLink href="OnboardingDeploymentProcessVideo">Video (3 mins)</ExternalVideoLink>
                </>} image={<EmptyStateProcessIllustration />} actionButtons={<PermissionCheck permission={processScopedEditPermission(processContext.selectors.getProcessType())} project={processIdentifier.projectId} wildcard={true}>
                    <ActionList alignStart={true} actions={[
                <NavigationButton label={"Create Process"} href={createProcessHref} onClick={() => dispatchAction("Add Deployment Step", { resource: "Deployment Process", action: Action.Add })} type={NavigationButtonType.Primary}/>,
                <SampleProcessButton href={sampleProcessHref} location={location} history={history}/>,
            ]}/>
                </PermissionCheck>}/>);
};
export default Onboarding;

import { Callout } from "@octopusdeploy/design-system-components";
import type { ChannelResource, DeploymentProcessResource, EnvironmentResource, GitHubAppConnectionSummary, GitHubRepository, LifecycleResource, ProjectResource, ReleaseResource, ResourcesById } from "@octopusdeploy/octopus-server-client";
import { isExistingTriggerResource, Permission } from "@octopusdeploy/octopus-server-client";
import type { TriggerExecutionResource } from "@octopusdeploy/octopus-server-client/src/resources/triggerExecutionResource";
import { links } from "@octopusdeploy/portal-routes";
import pluralize from "pluralize";
import * as React from "react";
import type { Errors } from "~/components/DataBaseComponent";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, FormSectionHeading, MarkdownEditor, Note, required, Select, Summary, Text, UnstructuredFormSection } from "~/components/form";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import { LastReleaseCreatedSummary } from "../Feed/LastReleaseCreatedSummary";
import { TriggerLifecyclePreview } from "../TriggerLifecyclePreview";
import type { GitTrigger } from "./CreateOrEditGitTrigger";
import { GitTriggerFeedbackCallout } from "./GitTriggerFeedbackCallout";
import { GitTriggerSources, getGitTriggerSourceItems } from "./GitTriggerSources";
import { LastGitTriggerExecutionSummary } from "./LastGitTriggerExecutionSummary";
type CreateOrEditGitTriggerFormProps = {
    busy?: Promise<void>;
    errors: Errors | undefined;
    getFieldError: (name: string) => string | undefined;
    project: ProjectResource;
    trigger: GitTrigger;
    existingTriggers: GitTrigger[];
    deploymentProcess?: DeploymentProcessResource;
    channels: ChannelResource[];
    lifecyclesById: ResourcesById<LifecycleResource>;
    environmentsById: ResourcesById<EnvironmentResource>;
    projectTriggerLastExecution: TriggerExecutionResource | "never-run" | undefined;
    lastCreatedRelease: ReleaseResource | undefined;
    connection: GitHubAppConnectionSummary | undefined;
    repository: GitHubRepository | undefined;
    onSave: (trigger: GitTrigger) => Promise<void>;
    onEnable: () => Promise<void>;
    onDisable: () => Promise<void>;
    onDelete: () => Promise<void>;
};
export function CreateOrEditGitTriggerForm({ busy, errors, getFieldError, project, trigger, existingTriggers, deploymentProcess, channels, lifecyclesById, environmentsById, projectTriggerLastExecution, lastCreatedRelease, connection, repository, onSave, onEnable, onDisable, onDelete, }: CreateOrEditGitTriggerFormProps) {
    const [triggerState, setTriggerState] = React.useState<GitTrigger>(trigger);
    const [isSaving, setIsSaving] = React.useState(false);
    const channelsAlreadyUsedByOtherGitTriggers = existingTriggers.map((t) => t.Action.ChannelId);
    const allChannelsAlreadyUsed = channels.length === channelsAlreadyUsedByOtherGitTriggers.length;
    const channel = channels.find((c) => c.Id === triggerState.Action.ChannelId);
    const lifecycle = channel ? lifecyclesById[channel?.LifecycleId ?? project.LifecycleId] : lifecyclesById[project.LifecycleId];
    const legacyOverflowActions = [];
    if (isExistingTriggerResource(trigger)) {
        legacyOverflowActions.push(OverflowMenuItems.item(trigger.IsDisabled ? "Enable" : "Disable", async () => {
            if (trigger.IsDisabled) {
                await onEnable();
                setTriggerState({ ...triggerState, IsDisabled: false });
            }
            else {
                await onDisable();
                setTriggerState({ ...triggerState, IsDisabled: true });
            }
        }, {
            permission: Permission.TriggerEdit,
            project: project.Id,
        }));
        legacyOverflowActions.push(OverflowMenuItems.deleteItemDefault("Release trigger", async () => {
            await onDelete();
            return true;
        }, { permission: Permission.TriggerDelete, project: project.Id }));
        legacyOverflowActions.push([
            OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [trigger.Id] }), {
                permission: Permission.EventView,
                wildcard: true,
            }),
        ]);
    }
    const overflowMenu = OverflowMenuConverterVNext.convertAll(legacyOverflowActions);
    const onSaveInternal = async () => {
        setIsSaving(true);
        await onSave(triggerState);
        setIsSaving(false);
    };
    const createTriggerSourcesSummary = () => {
        const sources = getGitTriggerSourceItems(project, triggerState.Filter.Sources, deploymentProcess);
        if (sources.length === 0) {
            return "No sources selected";
        }
        return Summary.summary(`${pluralize("Git repository", sources.filter((s) => s.selected).length, true)} will be watched for updates`);
    };
    const isNewTrigger = !isExistingTriggerResource(trigger);
    return (<LegacyForm model={triggerState} cleanModel={trigger} savePermission={{
            permission: isNewTrigger ? Permission.TriggerCreate : Permission.TriggerEdit,
            project: project.Id,
        }} onSaveClick={onSaveInternal} disableDirtyFormChecking={isNewTrigger && isSaving}>
            {({ FormContent, createSaveAction }) => (<PaperLayoutVNext busy={busy} errors={errors} title={isNewTrigger ? "New Git Repository Trigger" : trigger.Name} breadcrumbsItems={[{ label: "Triggers", pageUrl: links.deploymentTriggersPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) }]} overflowActions={overflowMenu.menuItems} primaryAction={createSaveAction({})}>
                    <FormContent expandAllOnMount={isNewTrigger}>
                        {overflowMenu.dialogs}
                        <TransitionAnimation>
                            {trigger.IsDisabled && (<UnstructuredFormSection stretchContent={true}>
                                    <Callout type={"warning"} title={"This trigger is currently disabled"}/>
                                </UnstructuredFormSection>)}
                            <GitTriggerFeedbackCallout />
                            <ExpandableFormSection errorKey="Name" title="Name" focusOnExpandAll summary={triggerState.Name ? Summary.summary(triggerState.Name) : Summary.placeholder("Please enter a name for your release trigger")} help="Enter a name for your release trigger.">
                                <Text value={triggerState.Name} onChange={(Name) => setTriggerState({ ...triggerState, Name })} label="Release trigger name" validate={required("Please enter a release trigger name")} error={getFieldError("Name")} autoFocus={true}/>
                                <Note>
                                    A short, memorable, unique name for this release trigger. Example: <i>Watch for new commits pushed to the main branch</i>
                                </Note>
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="Description" title="Description" summary={triggerState.Description ? Summary.summary(triggerState.Description) : Summary.placeholder("Please enter a description for your trigger")} help="Enter a description for your trigger.">
                                <MarkdownEditor label="Trigger description" value={triggerState.Description} onChange={(description) => setTriggerState({ ...triggerState, Description: description })}/>
                            </ExpandableFormSection>
                            <FormSectionHeading title="Trigger Action"/>
                            {project.IsVersionControlled && (<Callout type={"generic"} hideTitle>
                                    Git triggers create releases on the default branch in version controlled projects.
                                </Callout>)}
                            <ExpandableFormSection errorKey="Channel" title="Channel" focusOnExpandAll summary={Summary.summary(channel ? (<span>
                                            Latest commits for Git repositories in channel <strong>{channel?.Name}</strong> will be used to create the release
                                        </span>) : ("Please select a channel"))} help={"Select the channel to use when selecting Git resources for the release"}>
                                <Select allowClear={false} items={channels.map((c) => ({ text: c.Name, value: c.Id, disabled: channelsAlreadyUsedByOtherGitTriggers.includes(c.Id), getDisabledText: (text) => `${text} - Already in use` }))} value={triggerState.Action.ChannelId} onChange={(channelId) => setTriggerState({ ...triggerState, Action: { ...triggerState.Action, ChannelId: channelId ?? "" } })} error={allChannelsAlreadyUsed ? "No available channels" : undefined}/>
                                <Note>Releases will be created in this channel and must satisfy the selected channel's rules.</Note>
                                {lifecycle && <TriggerLifecyclePreview lifecycle={lifecycle} environmentLookup={environmentsById} spaceId={project.SpaceId}/>}
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="Trigger sources" title="Trigger sources" focusOnExpandAll summary={createTriggerSourcesSummary()} help="Select the Git repositories that will be used to trigger release creation." fillCardWidth={CardFill.FillRight}>
                                <GitTriggerSources project={project} channel={channel} deploymentProcess={deploymentProcess} value={triggerState.Filter.Sources} connection={connection} repository={repository} onChange={(items) => {
                setTriggerState({
                    ...triggerState,
                    Filter: {
                        ...triggerState.Filter,
                        Sources: items,
                    },
                });
            }}/>
                                <Note>New commits pushed to the repositories you select will trigger release creation.</Note>
                            </ExpandableFormSection>
                            {projectTriggerLastExecution && <FormSectionHeading title="History"/>}
                            {projectTriggerLastExecution && <LastGitTriggerExecutionSummary lastExecution={projectTriggerLastExecution}/>}
                            {projectTriggerLastExecution && <LastReleaseCreatedSummary spaceId={project.SpaceId} projectSlug={project.Slug} release={lastCreatedRelease}/>}
                        </TransitionAnimation>
                    </FormContent>
                </PaperLayoutVNext>)}
        </LegacyForm>);
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, CircularProgress } from "@octopusdeploy/design-system-components";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import type { MouseEvent } from "react";
import * as React from "react";
import { useState } from "react";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type { SelectProps } from "../../../primitiveComponents/form/Select/Select";
import { withBoundField } from "../BoundField/BoundField";
import { SelectWithActions } from "../SelectWithActions/SelectWithActions";
interface SelectWithAddRefreshProps extends SelectProps {
    addUrl: LinkHref;
    addAction?: (e?: MouseEvent) => void;
    disableAdd?: boolean;
    addButtonTooltipText?: string;
    onRequestRefresh(): Promise<void>;
    inlineAddButtonLabel?: string;
}
export default function SelectWithAddRefresh({ addUrl, addAction, disableAdd, addButtonTooltipText, onRequestRefresh, inlineAddButtonLabel, ...selectProps }: SelectWithAddRefreshProps) {
    const [refreshing, setRefreshing] = useState(false);
    const spaceAwareNavigation = useSpaceAwareNavigation();
    const handleRequestRefresh = async () => {
        setRefreshing(true);
        try {
            await onRequestRefresh();
        }
        finally {
            setRefreshing(false);
        }
    };
    const openAddWindow = () => {
        spaceAwareNavigation.open(addUrl);
    };
    const actions = [
        refreshing ? <CircularProgress size="small"/> : <IconButtonWithTooltip onClick={handleRequestRefresh} toolTipContent="Refresh" icon="Refresh"/>,
        <IconButtonWithTooltip disabled={disableAdd} toolTipContent={addButtonTooltipText ? addButtonTooltipText : "Add"} onClick={(e) => (addAction ? addAction(e) : openAddWindow())} icon="Add"/>,
    ];
    return <SelectWithActions {...selectProps} actions={actions} inlineButton={inlineAddButtonLabel ? <Button importance="tertiary" label={inlineAddButtonLabel} onClick={(e) => (addAction ? addAction(e) : openAddWindow())}/> : null}/>;
}
export const BoundSelectWithAddRefresh = withBoundField(SelectWithAddRefresh);

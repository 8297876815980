import { Level2PageLayout } from "@octopusdeploy/design-system-components";
import type { GetDynamicEnvironmentOverviewResponseV1 } from "@octopusdeploy/octopus-server-client";
import { GetDynamicEnvironmentOverviewRequestV1SortField, GetDynamicEnvironmentOverviewRequestV1SortOrder, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import { compact } from "lodash";
import React, { useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import { useCreatePageSideNavLink } from "~/hooks/useCreatePageSideNavLink";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import type { DynamicEnvironmentOverviewAssociatedProjectsFilter, DynamicEnvironmentOverviewProjectSortOptions } from "./DynamicEnvironmentOverview";
import { defaultDynamicEnvironmentOverviewAssociatedProjectsFilter, DynamicEnvironmentOverview } from "./DynamicEnvironmentOverview";
const defaultTake = 10;
function getSortField(sortOrder: DynamicEnvironmentOverviewProjectSortOptions): GetDynamicEnvironmentOverviewRequestV1SortField {
    switch (sortOrder) {
        case "latestActivityAsc":
        case "latestActivityDesc":
            return GetDynamicEnvironmentOverviewRequestV1SortField.LatestActivity;
        case "projectNameAsc":
        case "projectNameDesc":
            return GetDynamicEnvironmentOverviewRequestV1SortField.ProjectName;
        case "deprovisioningOrder":
            return GetDynamicEnvironmentOverviewRequestV1SortField.DeprovisioningOrder;
        default:
            exhaustiveCheck(sortOrder, "Unhandled sort option");
    }
}
function getSortOrder(sortOrder: DynamicEnvironmentOverviewProjectSortOptions): GetDynamicEnvironmentOverviewRequestV1SortOrder {
    switch (sortOrder) {
        case "latestActivityAsc":
        case "projectNameAsc":
        case "deprovisioningOrder":
            return GetDynamicEnvironmentOverviewRequestV1SortOrder.Ascending;
        case "latestActivityDesc":
        case "projectNameDesc":
            return GetDynamicEnvironmentOverviewRequestV1SortOrder.Descending;
        default:
            exhaustiveCheck(sortOrder, "Unhandled sort option");
    }
}
interface DynamicEnvironmentPageProps {
    spaceId: string;
    environmentId: string;
}
interface DynamicEnvironmentPageInternalProps extends DynamicEnvironmentPageProps {
    errors: Errors | undefined;
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
}
type LayoutProps = {
    spaceId: string;
    environmentId: string;
    errors: Errors | undefined;
    renderContent: React.ReactElement | null;
};
function Layout({ spaceId, environmentId, errors, renderContent }: LayoutProps) {
    const createPageSideNavLink = useCreatePageSideNavLink();
    if (!renderContent) {
        return (<main>
                <SkeletonLoadingLayout errors={errors}/>
            </main>);
    }
    const navLinks = compact([
        createPageSideNavLink("Overview", links.dynamicEnvironmentPage.generateUrl({ spaceId, environmentId }), undefined, {
            permission: Permission.EnvironmentView,
        }),
    ]);
    return (<main>
            <Level2PageLayout navItems={navLinks} content={renderContent} header={{ title: "Dynamic Environments", breadcrumbs: [{ label: "Infrastructure", pageUrl: links.infrastructureRootRedirect.generateUrl({ spaceId }) }] }}/>
        </main>);
}
function DynamicEnvironmentPageInternal({ spaceId, environmentId, doBusyTask, busy, errors }: DynamicEnvironmentPageInternalProps) {
    const [environment, setEnvironment] = useState<GetDynamicEnvironmentOverviewResponseV1 | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<DynamicEnvironmentOverviewProjectSortOptions>("latestActivityDesc");
    const [filter, setFilter] = useState<DynamicEnvironmentOverviewAssociatedProjectsFilter>(defaultDynamicEnvironmentOverviewAssociatedProjectsFilter);
    const [take, setTake] = useState<number>(defaultTake);
    const navigation = useSpaceAwareNavigation();
    const refresh = useDoBusyTaskEffect(doBusyTask, async () => {
        const environment = await repository.DynamicEnvironments.getDynamicEnvironmentOverview({
            environmentId: environmentId,
            take: take,
            skip: 0,
            sortOrder: getSortOrder(sortOrder),
            sortField: getSortField(sortOrder),
            projectName: filter.projectName,
            projectState: filter.projectState,
        });
        setEnvironment(environment);
        if (environment.EnvironmentState !== "Active" && (sortOrder === "latestActivityAsc" || sortOrder === "latestActivityDesc")) {
            setSortOrder("deprovisioningOrder");
        }
    }, [environmentId, take, filter, sortOrder]);
    useRefreshLoop(refresh, 6000);
    async function onDeprovisionProject(environmentId: string, projectId: string) {
        await repository.DynamicEnvironments.deprovisionProject(environmentId, projectId);
        await refresh();
    }
    async function onDeprovisionEnvironment(environmentId: string) {
        await repository.DynamicEnvironments.deprovision(environmentId);
        await refresh();
    }
    async function onDeleteEnvironment(environmentId: string) {
        await repository.DynamicEnvironments.del(environmentId);
        navigation.navigate(links.dynamicEnvironmentsPage.generateUrl({ spaceId }, { environmentDeleted: "true" }));
    }
    function onLoadMore() {
        setTake(take + defaultTake);
    }
    return (<Layout spaceId={spaceId} environmentId={environmentId} errors={errors} renderContent={<DynamicEnvironmentOverview spaceId={spaceId} environment={environment} busy={busy} sortOrder={sortOrder} filter={filter} onSortOrderChange={(sortOrder) => setSortOrder(sortOrder)} onFilterChange={(filter) => setFilter(filter)} onDeprovisionEnvironment={onDeprovisionEnvironment} onDeleteEnvironment={onDeleteEnvironment} onDeprovisionProject={onDeprovisionProject} onLoadMore={onLoadMore}/>}></Layout>);
}
export class DynamicEnvironmentPage extends DataBaseComponent<DynamicEnvironmentPageProps> {
    constructor(props: DynamicEnvironmentPageProps) {
        super(props);
        this.state = {};
    }
    render() {
        return <DynamicEnvironmentPageInternal spaceId={this.props.spaceId} environmentId={this.props.environmentId} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "DynamicEnvironmentPage";
}

import type { PersistenceSettings, ProcessType } from "@octopusdeploy/octopus-server-client";
export type StepPackageContext = {};
export type ProjectStepPackageContext = StepPackageContext & {
    persistenceSettings: PersistenceSettings;
    processType: ProcessType;
};
export function isProjectStepPackageContext(context: StepPackageContext): context is ProjectStepPackageContext {
    const key: keyof ProjectStepPackageContext = "persistenceSettings";
    return key in context;
}

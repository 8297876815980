import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { useAggregateAPIOperationStatus, useQuery } from "@octopusdeploy/octopus-react-client";
import type { ActionTemplateParameterResource, BlueprintResource, GitBranchResource, GitRef, GitRefResource, IconMetadataResource, IconSvgResource, IProcessResource, ModifyBlueprintCommand, Repository, WorkerPoolsSummaryResource, } from "@octopusdeploy/octopus-server-client";
import { canCommitTo, GetPrimaryPackageReference, Permission, ProcessType, TenantedDeploymentMode, UseDefaultBranch } from "@octopusdeploy/octopus-server-client";
import { links, optionalStringQueryParam, useQueryStringParam } from "@octopusdeploy/portal-routes";
import { noOp } from "@octopusdeploy/utilities";
import { isEqual, keyBy } from "lodash";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { BlueprintParametersTab, buildBlueprintParametersActions } from "~/areas/blueprints/BlueprintParametersTab";
import type { BlueprintSettings } from "~/areas/blueprints/EditBlueprintSettingsPage";
import { EditBlueprintSettingsPage } from "~/areas/blueprints/EditBlueprintSettingsPage";
import ProcessListLayoutForBlueprint from "~/areas/blueprints/processEditor/ProcessListLayoutForBlueprint";
import ProcessStepsLayoutForBlueprint, { intentFromFilter, ProcessPageIntent } from "~/areas/blueprints/processEditor/ProcessStepsLayoutForBlueprint";
import type { ProcessBlueprintContextProps } from "~/areas/projects/components/Process/Blueprints/BlueprintContext";
import { buildProcessBlueprintContextProps, ProcessBlueprintContext } from "~/areas/projects/components/Process/Blueprints/BlueprintContext";
import { isRunOnServerOrWorkerPool, loadAvailableWorkerPools, whereConfiguredToRun } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { ProcessController } from "~/areas/projects/components/Process/Contexts/ProcessController";
import { useProcessErrorActions } from "~/areas/projects/components/Process/Contexts/ProcessErrors/ProcessErrorsContext";
import { createDefaultFilter, ProcessQueryStringController, useProcessQueryStringContext } from "~/areas/projects/components/Process/Contexts/ProcessQueryString/ProcessQueryStringContext";
import { useProcessWarningActions } from "~/areas/projects/components/Process/Contexts/ProcessWarnings/ProcessWarningsContext";
import { hasSteps } from "~/areas/projects/components/Process/Contexts/processModelSelectors";
import type { ProcessStepsLayoutLoaderLookupData } from "~/areas/projects/components/Process/ProcessStepsLayoutLoader";
import type { BlueprintProcessIdentifier } from "~/areas/projects/components/Process/types";
import { isBlueprintProcessIdentifier, ExecutionLocation, toBlueprintProcessIdentifier } from "~/areas/projects/components/Process/types";
import { ContextAddStepButton } from "~/areas/projects/components/Steps/index";
import type { GetCommitButtonProps } from "~/areas/projects/components/VersionControl/CommitButton";
import { GetCommitButton } from "~/areas/projects/components/VersionControl/CommitButton";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import { getFormattedCommitMessage } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import { PersistenceSettingsContextProvider, usePersistenceSettingsContext } from "~/areas/projects/context/PersistenceSettingsContext";
import { ProjectContextProvider, useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import type { ActionPlugin } from "~/components/Actions/pluginRegistry";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { useLegacyDoBusyTask } from "~/components/DataBaseComponent/useLegacyDoBusyTask";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import * as tenantTagsets from "~/components/tenantTagsets";
import { TabItem, UrlNavigationTabsContainer } from "~/primitiveComponents/navigation/Tabs/index";
import type { GlobalState } from "~/store/reducers";
interface EditBlueprintsProps {
    loaderData: LoaderData;
    blueprintId: string;
    spaceId: string;
}
interface EditWithAllContextProps extends EditBlueprintsProps {
    doBusyTask: DoBusyTask;
    errors: Error[];
    busy: boolean;
}
interface EditBlueprintsInternalProps {
    blueprint: BlueprintResource;
    doBusyTask: DoBusyTask;
    errors: Error[];
    busy: boolean;
    lookupData: ProcessStepsLayoutLoaderLookupData;
    icons: IconSvgResource[];
    iconMetadata: IconMetadataResource | undefined;
    onChange: (blueprint: BlueprintResource) => void;
    gitRefResource: GitRefResource | undefined;
    isTenanted: boolean;
}
function EditWithAllContext({ loaderData, blueprintId, doBusyTask, busy, errors, spaceId }: EditWithAllContextProps) {
    const [lookupData, setLookupData] = useState<ProcessStepsLayoutLoaderLookupData>(defaultLookups);
    const [icons, setIcons] = useState<IconSvgResource[]>([]);
    const [iconMetadata, setIconMetadata] = useState<IconMetadataResource>();
    const { result: blueprint, refetch: reloadBlueprint } = useQuery((repository) => repository.Blueprints.get(blueprintId), [blueprintId], "Get blueprint", { initialResult: loaderData.blueprint });
    const [blueprintContextProps, setBlueprintContextProps] = useState<ProcessBlueprintContextProps>(buildProcessBlueprintContextProps(blueprint));
    const projectId = blueprintId.split("|")[0];
    const blueprintSlug = blueprintContextProps?.blueprint.Slug ?? "";
    const refreshLookups = useCallback(async () => {
        const environments = repository.Environments.all();
        const workerPools = repository.WorkerPools.all();
        const tagSets = tenantTagsets.getAll();
        setLookupData({
            ...defaultLookups,
            environmentsById: keyBy(await environments, "Id"),
            tagSets: await tagSets,
            workerPoolsById: keyBy(await workerPools, "Id"),
            machineRoles: await repository.MachineRoles.all(),
            tagIndex: await tenantTagsets.getTagIndex(),
            workerPoolsSummary: await repository.WorkerPools.summary(),
        });
    }, []);
    const onBlueprintChange = useCallback((blueprint: BlueprintResource) => {
        const context = buildProcessBlueprintContextProps(blueprint);
        setBlueprintContextProps(context);
    }, []);
    useDoBusyTaskEffect(doBusyTask, async () => {
        await refreshLookups();
        const icons = await repository.Icons.getIcons();
        setIcons(icons);
        const iconMetadata = await repository.Icons.getIconMetadata();
        setIconMetadata(iconMetadata);
    }, [refreshLookups]);
    const processIdentifier: BlueprintProcessIdentifier = useMemo(() => toBlueprintProcessIdentifier(blueprintId, spaceId), [blueprintId, spaceId]);
    return (<ProcessBlueprintContext.Provider value={blueprintContextProps}>
            <ProjectContextProvider doBusyTask={doBusyTask} projectIdOrSlug={projectId} gitRef={UseDefaultBranch}>
                {({ state }) => {
            return state.model ? (<PersistenceSettingsContextProvider {...state.model.PersistenceSettings}>
                            <ProcessController layoutActions={{ refreshLookupData: refreshLookups }} doBusyTask={doBusyTask} processIdentifier={processIdentifier} process={blueprint} reloadProcess={reloadBlueprint} // Only used when updating step templates which blueprints can't do yet, so this is actually unused
             modifyProcess={() => new Promise(noOp)} // This function is never used for blueprints since the save logic is handled outside the process context
            >
                                {() => {
                    return (<ProcessQueryStringController initialQueryFilter={createDefaultFilter()}>
                                            {() => {
                            return (<EditBlueprintPageInternal blueprint={blueprint} doBusyTask={doBusyTask} busy={busy} errors={errors} lookupData={lookupData} gitRefResource={state.gitRef} icons={icons} iconMetadata={iconMetadata} onChange={onBlueprintChange} isTenanted={state.model.TenantedDeploymentMode === TenantedDeploymentMode.Tenanted}/>);
                        }}
                                        </ProcessQueryStringController>);
                }}
                            </ProcessController>
                        </PersistenceSettingsContextProvider>) : (<PaperLayoutVNext busy={true} fullWidth={true}></PaperLayoutVNext>);
        }}
            </ProjectContextProvider>
        </ProcessBlueprintContext.Provider>);
}
const defaultLookups = {
    includedScriptModules: [],
    lifecyclePreview: null,
    environmentsById: {},
    channelsById: null,
    tagSets: [],
    workerPoolsById: {},
    machineRoles: [],
    tagIndex: {},
    userOnboarding: null,
    workerPoolsSummary: {
        WorkerPoolSummaries: [],
        TotalMachines: 0,
        TotalDisabledMachines: 0,
        MachineHealthStatusSummaries: {
            Healthy: 0,
            Unavailable: 0,
            Unknown: 0,
            HasWarnings: 0,
            Unhealthy: 0,
        },
        MachineEndpointSummaries: {
            None: 0,
            TentaclePassive: 0,
            TentacleActive: 0,
            Ssh: 0,
            OfflineDrop: 0,
            AzureWebApp: 0,
            AzureCloudService: 0,
            AzureServiceFabricCluster: 0,
            Kubernetes: 0,
            KubernetesTentacle: 0,
            StepPackage: 0,
        },
        DeploymentTargetSummaries: {
            Healthy: 0,
            Unavailable: 0,
            Unknown: 0,
            HasWarnings: 0,
            Unhealthy: 0,
        },
        TentacleUpgradesRequired: false,
        MachineIdsForCalamariUpgrade: [],
        MachineIdsForTentacleUpgrade: [],
    },
    projectTriggers: [],
};
interface ProcessStepsOrListLayoutProps {
    lookupData: ProcessStepsLayoutLoaderLookupData;
    doBusyTask: DoBusyTask;
    errors: Error[];
    busy: boolean;
    gitRefResource: GitRefResource | undefined;
    isTenanted: boolean;
}
function ProcessStepsOrListLayout({ lookupData, busy, doBusyTask, errors, gitRefResource, isTenanted }: ProcessStepsOrListLayoutProps) {
    const processQueryStringContext = useProcessQueryStringContext();
    const isBuiltInWorkerEnabled = useSelector((state: GlobalState) => state.configurationArea.features.isBuiltInWorkerEnabled);
    const intent = intentFromFilter(processQueryStringContext.state.queryFilter);
    if (intent === ProcessPageIntent.Unknown) {
        return <ProcessListLayoutForBlueprint processType={ProcessType.Blueprint} lookups={lookupData} errors={errors} busy={!!busy} doBusyTask={doBusyTask} isBuiltInWorkerEnabled={isBuiltInWorkerEnabled} gitRefResource={gitRefResource}/>;
    }
    return <ProcessStepsLayoutForBlueprint lookups={lookupData} errors={errors} busy={busy} doBusyTask={doBusyTask} isBuiltInWorkerEnabled={isBuiltInWorkerEnabled} gitRefResource={gitRefResource} isTenanted={isTenanted}/>;
}
function applyCommonLogicToProcessResource(process: IProcessResource, workerPoolsSummary: WorkerPoolsSummaryResource, getPluginForAction: (actionId: string) => ActionPlugin) {
    const availableWorkerPools = loadAvailableWorkerPools(workerPoolsSummary);
    process.Steps.forEach((step) => {
        step.Actions.forEach((action) => {
            const plugin = getPluginForAction(action.Id);
            const runOn = whereConfiguredToRun(!!step.Properties["Octopus.Action.TargetRoles"], action, availableWorkerPools, plugin);
            if (runOn) {
                if (!isRunOnServerOrWorkerPool(runOn)) {
                    action.Container = { FeedId: null, Image: null, GitUrl: null, Dockerfile: null };
                }
                else {
                    if (runOn.executionLocation === ExecutionLocation.OctopusServer || runOn.executionLocation === ExecutionLocation.WorkerPool) {
                        step.Properties["Octopus.Action.TargetRoles"] = "";
                    }
                    if (runOn.executionLocation !== ExecutionLocation.WorkerPool && runOn.executionLocation !== ExecutionLocation.WorkerPoolForRoles) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        action.WorkerPoolId = null!;
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        action.WorkerPoolVariable = null!;
                    }
                    action.Container = runOn.container;
                }
            }
            if (!action.Name || action.Name.length === 0) {
                const primaryPackage = GetPrimaryPackageReference(action.Packages);
                if (primaryPackage) {
                    action.Name = primaryPackage.PackageId;
                }
            }
        });
    });
}
function EditBlueprintPageInternal({ blueprint, doBusyTask, busy, errors, lookupData, icons, iconMetadata, onChange, gitRefResource, isTenanted }: EditBlueprintsInternalProps) {
    const project = useProjectContext();
    const persistenceSettings = usePersistenceSettingsContext();
    const process = useProcessContext();
    const processIdentifier = process.state.processIdentifier;
    const errorActions = useProcessErrorActions();
    const warningActions = useProcessWarningActions();
    const navigation = useSpaceAwareNavigation();
    const [commitMessage, setCommitMessage] = useState<CommitMessageWithDetails>({ summary: "", details: "" });
    const [disableDirtyFormChecking, setDisableDirtyFormChecking] = useState<boolean>(false);
    const [blueprintSettings, setBlueprintSettings] = useState<BlueprintSettings>({ Name: blueprint.Name, Description: blueprint.Description, Icon: blueprint.Icon });
    const [cleanBlueprintSettings, setCleanBlueprintSettings] = useState<BlueprintSettings>({ Name: blueprint.Name, Description: blueprint.Description, Icon: blueprint.Icon });
    const [parameters, setParameters] = useState<ActionTemplateParameterResource[]>(blueprint.Parameters);
    const [cleanParameters, setCleanParameters] = useState<ActionTemplateParameterResource[]>(blueprint.Parameters);
    const [secondaryActions, setSecondaryActions] = useState<PageAction[]>([addStepButton]);
    const activeTabQueryParameter = optionalStringQueryParam("activeTab");
    const [activeTab, _] = useQueryStringParam(activeTabQueryParameter);
    const changeGitRef = (gitRef: string) => project.actions.changeGitRef(gitRef);
    const refreshModel = (gitRef?: GitRef) => project.actions.refreshModel(gitRef);
    const onParametersChanged = useCallback((parameters: ActionTemplateParameterResource[]) => {
        setParameters(parameters);
        if (blueprint) {
            const resource: BlueprintResource = { ...blueprint, Parameters: parameters };
            onChange(resource);
        }
    }, [onChange, blueprint]);
    const onSettingsChange = useCallback((settings: BlueprintSettings) => {
        setBlueprintSettings(settings);
        if (blueprint) {
            const resource: BlueprintResource = { ...blueprint, ...settings };
            onChange(resource);
        }
    }, [onChange, blueprint]);
    const refresh = useCallback((resource: BlueprintResource) => {
        setCleanBlueprintSettings(resource);
        setBlueprintSettings(resource);
        setCleanParameters(resource.Parameters);
        setParameters(resource.Parameters);
        onChange(resource);
    }, [onChange]);
    useEffect(() => {
        if (activeTab === "process") {
            setSecondaryActions([addStepButton]);
        }
        else if (activeTab === "settings") {
            setSecondaryActions([]);
        }
        else if (activeTab === "parameters") {
            const parametersActions = buildBlueprintParametersActions({ parameters: parameters || [], onChange: onParametersChanged });
            setSecondaryActions(parametersActions);
        }
    }, [activeTab, parameters, onParametersChanged]);
    useEffect(() => {
        if (blueprint && (blueprintSettings === undefined || parameters === undefined)) {
            refresh(blueprint);
        }
    }, [blueprint, blueprintSettings, parameters, refresh]);
    if (!project.state.model) {
        return null;
    }
    let openCommitDialog: (() => void) | undefined = undefined;
    function getDefaultCommitMessage(): string {
        return "Update blueprint";
    }
    const saveProcess = async (isNavigationConfirmation: boolean, newBranch?: GitBranchResource) => {
        let isValid = true;
        const onSuccess: () => void = () => {
            errorActions.clearErrors();
            warningActions.clearWarnings();
        };
        const onError: (errors: Errors) => void = (errors) => {
            errorActions.setErrors(errors, process.selectors);
            isValid = false;
            warningActions.clearWarnings();
        };
        if (isNavigationConfirmation && openCommitDialog) {
            await openCommitDialog();
            return isValid;
        }
        else {
            await doBusyTask(async () => {
                //TODO: this merge conflict logic can be moved into a side effect in the reducer if we
                if (!process.state.model.process?.Id) {
                    throw Error("Failed to find processId");
                }
                const { modelProcess: clientProcessResource } = process.selectors.getProcessesForMerge();
                applyCommonLogicToProcessResource(clientProcessResource, lookupData.workerPoolsSummary, process.selectors.getActionPlugin);
                if (blueprintSettings && blueprint) {
                    const updatedBlueprint: ModifyBlueprintCommand = {
                        ...blueprint,
                        Name: blueprintSettings.Name,
                        Description: blueprintSettings.Description,
                        Icon: blueprintSettings.Icon,
                        Steps: clientProcessResource.Steps,
                        ChangeDescription: getFormattedCommitMessage(commitMessage, getDefaultCommitMessage()),
                        Parameters: parameters || [],
                    };
                    const result = await repository.Blueprints.modify(updatedBlueprint);
                    if (result !== null) {
                        setCommitMessage({ summary: "", details: "" });
                        refresh(result);
                        await refreshModel();
                    }
                }
            }, { onError, onSuccess });
            if (isValid && !hasSteps(process.state.model)()) {
                if (!isBlueprintProcessIdentifier(processIdentifier)) {
                    throw new Error("This page only supports process templates");
                }
                navigation.navigate(links.editBlueprintPage.generateUrl({ blueprintId: processIdentifier.blueprintId, spaceId: processIdentifier.spaceId }));
            }
            return isValid;
        }
    };
    const onNewBranchCreating = async (branchName: string) => {
        throw new Error("Process templates don't support branch creating yet");
        // let newBranchResource: GitBranchResource | null = null;
        //
        // try {
        //     newBranchResource = await repository.ProjectBranches.createBranch(process.state.ownerId, branchName, gitRefResource?.CanonicalName ?? "");
        // } catch (ex) {
        //     setDisableDirtyFormChecking(false);
        //     throw ex;
        // }
        //
        // if (newBranchResource) {
        //     setDisableDirtyFormChecking(true);
        //     if (await saveProcess(false, newBranchResource)) {
        //         changeGitRef(branchName);
        //     }
        //     setDisableDirtyFormChecking(false);
        // }
    };
    function getCommitButtonProps(): GetCommitButtonProps {
        return {
            hasPermission: { permission: Permission.None },
            gitRef: gitRefResource?.CanonicalName,
            persistenceSettings: persistenceSettings,
            canCommitToGitRef: canCommitTo(gitRefResource),
            defaultCommitMessage: getDefaultCommitMessage(),
            commitMessage: commitMessage,
            updateCommitMessage: (commitMessage: CommitMessageWithDetails) => setCommitMessage(commitMessage),
            commitMessageAccessibleName: `Commit message for saving the blueprint process`,
            commitDetailsAccessibleName: `Commit details for saving the blueprint process`,
            commitButtonAccessibleName: `Commit changes to the blueprint process`,
            onInitializing: (openDialog) => (openCommitDialog = openDialog),
            label: "Commit",
            busyLabel: "Committing",
            onClick: (e) => saveProcess(false),
            disabled: disableDirtyFormChecking ? false : isEqual({ ...process.state.model, ...blueprintSettings, Parameters: parameters }, { ...process.state.cleanModel, ...cleanBlueprintSettings, Parameters: cleanParameters }),
        };
    }
    const connectRepositoryAction: PrimaryPageAction = {
        type: "custom",
        content: <GetCommitButton {...getCommitButtonProps()} onNewBranchCreating={onNewBranchCreating}/>,
        key: "Get Commit",
    };
    if (blueprintSettings === undefined) {
        return <PaperLayoutVNext busy={true} fullWidth={true}/>;
    }
    return (<PaperLayoutVNext title={blueprintSettings.Name ?? "Edit Blueprint"} errors={errors} busy={busy} fullWidth={true} primaryAction={connectRepositoryAction} pageActions={secondaryActions}>
            <UrlNavigationTabsContainer defaultValue={"process"}>
                <TabItem label="Process" value="process">
                    <ProcessStepsOrListLayout lookupData={lookupData} errors={errors} busy={busy} doBusyTask={doBusyTask} gitRefResource={gitRefResource} isTenanted={isTenanted}/>
                </TabItem>
                <TabItem label="Parameters" value="parameters">
                    <BlueprintParametersTab parameters={parameters || []} onChange={onParametersChanged}/>
                </TabItem>
                <TabItem label="Settings" value="settings">
                    <EditBlueprintSettingsPage blueprintSettings={blueprintSettings} onChange={onSettingsChange} iconSvgResources={icons} iconMetadata={iconMetadata}/>
                </TabItem>
            </UrlNavigationTabsContainer>
        </PaperLayoutVNext>);
}
const addStepButton: PageAction = {
    type: "custom",
    content: <ContextAddStepButton />,
    key: "Add Step",
};
export async function editBlueprintLoader(repository: Repository, blueprintId: string): Promise<LoaderData> {
    const blueprint = await repository.Blueprints.get(blueprintId);
    return {
        blueprint,
    };
}
interface LoaderData {
    blueprint: BlueprintResource;
}
export default function EditBlueprintPage(props: EditBlueprintsProps) {
    const { doBusyTask, status: doBusyTaskStatus } = useLegacyDoBusyTask();
    const { isInProgress, errors } = useAggregateAPIOperationStatus();
    return <EditWithAllContext loaderData={props.loaderData} spaceId={props.spaceId} blueprintId={props.blueprintId} doBusyTask={doBusyTask} busy={isInProgress} errors={errors}/>;
}

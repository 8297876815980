import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { k8sIconStyles } from "~/areas/projects/components/Observability/K8ResourceIcons/k8sIconStyles";
export function SecretIcon() {
    return (<svg className={k8sIconStyles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M30.8434 17.0054H28.7526C28.6158 12.6402 24.7899 9.14076 20.0902 9.14076C15.3905 9.14076 11.5645 12.6402 11.4277 17.0054H9.28968V30.6613H30.8434V17.0054ZM24.6951 17.0054H15.4853C15.6278 14.8795 17.635 13.1941 20.0902 13.1941C22.5454 13.1941 24.5526 14.8795 24.6951 17.0054ZM22.1968 24.0248C22.1968 25.2013 21.243 26.1551 20.0664 26.1551C18.8899 26.1551 17.9361 25.2013 17.9361 24.0248C17.9361 22.8482 18.8899 21.8944 20.0664 21.8944C21.243 21.8944 22.1968 22.8482 22.1968 24.0248Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.59631 9.33944H4V6.29346H9.59631V9.33944Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M27.6624 14.4161H24.1079C24.6326 15.004 24.9991 15.7131 25.1431 16.4978H28.2095C28.1326 15.77 27.9451 15.071 27.6624 14.4161Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M21.7694 16.4978V14.4161H22.5624C23.3387 14.925 23.8919 15.6646 24.1021 16.4978H21.7694Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M28.7584 14.4161C29.0026 15.0762 29.1639 15.7736 29.2301 16.4978H31.3511V17.462H36V14.4161H28.7584Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4241 17.2474C11.4241 12.7702 15.304 9.14076 20.0902 9.14076C24.8764 9.14076 28.7565 12.7702 28.7565 17.2474H24.7033C24.7033 15.0088 22.6378 13.1941 20.0902 13.1941C17.5425 13.1941 15.4774 15.0088 15.4774 17.2474H11.4241Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M8.80569 30.6613H4V33.7073H9.59631V31.5887H8.80569V30.6613Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M23.733 9.33991H36V6.29297H13.6468V9.33991H16.4476C17.5665 8.88479 18.799 8.63309 20.0903 8.63309C21.3816 8.63309 22.6141 8.88479 23.733 9.33991Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M31.6608 25.5847H36V22.5387H31.6608V25.5847Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M30.7131 25.5847H22.7173V22.5387H30.7131V25.5847Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M13.6468 31.5845V33.7073H36V31.5845H13.6468Z" fill={themeTokens.color.icon.tertiary}/>
        </svg>);
}

import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { NavigationButtonProps } from "@octopusdeploy/design-system-components";
import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { isBlueprintProcessIdentifier, isDeploymentProcessIdentifier, isRunbookProcessIdentifier } from "~/areas/projects/components/Process/types";
import { PermissionCheck } from "~/components/PermissionCheck";
import { processScopedEditPermission } from "../Process/Common/CommonProcessHelpers";
import { useProcessContext } from "../Process/Contexts/ProcessContext";
import { useProcessQueryStringContext } from "../Process/Contexts/ProcessQueryString/ProcessQueryStringContext";
type AddRunbookStepButtonPropChanges = Omit<NavigationButtonProps, "href" | "label"> & {
    href?: NavigationButtonProps["href"];
    label?: NavigationButtonProps["label"];
};
type AddRunbookStepButtonProps = {
    projectId: string;
    processId: string;
    runbookId: string;
    projectSlug: string;
} & AddRunbookStepButtonPropChanges;
const AddRunbookStepButton: React.FC<AddRunbookStepButtonProps> = ({ projectId, projectSlug, processId, runbookId, ...rest }) => {
    const processQueryStringContext = useProcessQueryStringContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectId);
    return (<PermissionCheck permission={processScopedEditPermission(ProcessType.Runbook)} project={projectId} wildcard={true}>
            <ActionButton type={ActionButtonType.Secondary} label="Add Step" onClick={() => {
            processQueryStringContext.actions.showProcessStepTemplates();
            dispatchAction("Add Deployment Step", { resource: "Runbook Process", action: Action.Add });
        }}/>
        </PermissionCheck>);
};
AddRunbookStepButton.displayName = "AddRunbookStepButton"
type AddDeploymentsStepButtonProps = {
    projectId: string;
    projectSlug: string;
};
const AddDeploymentsStepButton: React.FC<AddDeploymentsStepButtonProps> = ({ projectId, projectSlug, ...rest }) => {
    const processQueryStringContext = useProcessQueryStringContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectId);
    return (<PermissionCheck permission={processScopedEditPermission(ProcessType.Deployment)} project={projectId} wildcard={true}>
            <ActionButton type={ActionButtonType.Secondary} label="Add Step" onClick={() => {
            processQueryStringContext.actions.showProcessStepTemplates();
            dispatchAction("Add Deployment Step", { resource: "Deployment Process", action: Action.Add });
        }}/>
        </PermissionCheck>);
};
AddDeploymentsStepButton.displayName = "AddDeploymentsStepButton"
function AddBlueprintsStepButton() {
    const processQueryStringContext = useProcessQueryStringContext();
    return (<ActionButton type={ActionButtonType.Secondary} label="Add Step" onClick={() => {
            processQueryStringContext.actions.showProcessStepTemplates();
        }}/>);
}
export type ContextAddStepButtonProps = AddRunbookStepButtonPropChanges;
const ContextAddStepButton: React.FC<ContextAddStepButtonProps> = (props) => {
    const processContext = useProcessContext();
    const processIdentifier = processContext.state.processIdentifier;
    if (isDeploymentProcessIdentifier(processIdentifier)) {
        return <AddDeploymentsStepButton projectSlug={processIdentifier.projectSlug} projectId={processIdentifier.projectId} {...props}/>;
    }
    if (isRunbookProcessIdentifier(processIdentifier)) {
        return <AddRunbookStepButton projectSlug={processIdentifier.projectSlug} projectId={processIdentifier.projectId} processId={processIdentifier.id} runbookId={processIdentifier.runbookId} {...props}/>;
    }
    if (isBlueprintProcessIdentifier(processIdentifier)) {
        return <AddBlueprintsStepButton />;
    }
    throw new Error(`Unhandled process identifier ${processIdentifier}`);
};
ContextAddStepButton.displayName = "ContextAddStepButton"
export default ContextAddStepButton;

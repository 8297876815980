import { Level2PageLayout } from "@octopusdeploy/design-system-components";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import * as React from "react";
import { useCreatePageSideNavLink } from "~/hooks/useCreatePageSideNavLink";
interface PlatformHubLayoutProps {
    spaceId: string;
}
export function PlatformHubLayout({ spaceId, children }: React.PropsWithChildren<PlatformHubLayoutProps>) {
    const createPageSideNavLink = useCreatePageSideNavLink();
    const navItems = compact([createPageSideNavLink("Templates", links.blueprintsPage.generateUrl({ spaceId: spaceId }), true), createPageSideNavLink("Version Control Settings", links.platformHubConnectionPage.generateUrl({ spaceId }), true)]);
    return (<main>
            <Level2PageLayout navItems={navItems} content={children} header={{ title: "Platform Hub" }}/>
        </main>);
}

import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { k8sIconStyles } from "./k8sIconStyles";
export function RoleIcon() {
    return (<svg className={k8sIconStyles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.1405 22.0527C21.001 22.0527 21.6986 21.3607 21.6986 20.5071C21.6986 19.6534 21.001 18.9614 20.1405 18.9614C19.28 18.9614 18.5824 19.6534 18.5824 20.5071C18.5824 21.3607 19.28 22.0527 20.1405 22.0527Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M20.3275 7.97309L10.7634 12.2017V18.5116C10.7634 24.3616 14.84 29.8296 20.3275 31.1586C25.8073 29.8296 29.8878 24.3616 29.8878 18.5116V12.2017L20.3275 7.97309ZM25.3902 24.954H15.2609V16.4499H16.7147V15.5223C16.7147 13.5191 18.3516 11.8938 20.3743 11.8938C22.397 11.8938 24.0339 13.5191 24.0339 15.5223V16.4499H25.3902V24.954Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M20.3238 13.244C19.1117 13.2483 18.1296 14.2222 18.1296 15.4226V16.3346H22.5219V15.4071C22.5102 14.2105 21.532 13.2479 20.3238 13.244Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4 6.68006C4 6.28141 4.32316 5.95825 4.7218 5.95825H6.17687V7.40186H5.44361V8.16006H4V6.68006ZM7.63194 5.95825H10.5421V7.40186H7.63194V5.95825ZM11.9971 5.95825H14.9073V7.40186H11.9971V5.95825ZM16.3623 5.95825H19.2725V7.40186H16.3623V5.95825ZM20.7275 5.95825H23.6377V7.40186H20.7275V5.95825ZM25.0927 5.95825H28.0029V7.40186H25.0927V5.95825ZM29.4579 5.95825H32.3681V7.40186H29.4579V5.95825ZM33.8231 5.95825H35.2782C35.6768 5.95825 36 6.28141 36 6.68006V8.16006H34.5564V7.40186H33.8231V5.95825ZM4 12.6001V9.64006H5.44361V12.6001H4ZM36 9.64006V12.6001H34.5564V9.64006H36ZM4 17.0401V14.0801H5.44361V17.0401H4ZM36 14.0801V17.0401H34.5564V14.0801H36ZM36 18.5201V21.4801H34.5564V18.5201H36ZM4 21.4801V18.5201H5.44361V21.4801H4ZM4 25.9201V22.9601H5.44361V25.9201H4ZM36 22.9601V25.9201H34.5564V22.9601H36ZM4 30.3601V27.4001H5.44361V30.3601H4ZM36 27.4001V30.3601H34.5564V27.4001H36ZM4 33.3201V31.8401H5.44361V32.5983H6.17687V34.0419H4.7218C4.32316 34.0419 4 33.7187 4 33.3201ZM36 31.8401V33.3201C36 33.7187 35.6768 34.0419 35.2782 34.0419H33.8231V32.5983H34.5564V31.8401H36ZM10.5421 34.0419H7.63194V32.5983H10.5421V34.0419ZM14.9073 34.0419H11.9971V32.5983H14.9073V34.0419ZM19.2725 34.0419H16.3623V32.5983H19.2725V34.0419ZM23.6377 34.0419H20.7275V32.5983H23.6377V34.0419ZM28.0029 34.0419H25.0927V32.5983H28.0029V34.0419ZM32.3681 34.0419H29.4579V32.5983H32.3681V34.0419Z" fill={themeTokens.color.icon.tertiary}/>
        </svg>);
}

import { css } from "@emotion/css";
import type { SimpleMenuItem, PageAction } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { UserResource, Repository } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { UserChangePasswordDialogLayout } from "~/areas/configuration/components/Users/UserChangePasswordDialogLayout";
import { client, repository } from "~/clientInstance";
import Dialog from "~/components/Dialog/Dialog";
import type { DialogControls } from "~/components/Dialog/DialogTrigger";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { Text, Summary, ExpandableFormSection, FormSection } from "~/components/form";
import Avatar from "~/primitiveComponents/dataDisplay/Avatar";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface UserEditModel {
    displayName: string;
    username: string;
    emailAddress: string;
    password?: string;
    confirmPassword?: string;
}
interface UserProfileState extends FormBaseComponentState<UserEditModel> {
    user: UserResource;
}
export async function userProfilePageLoader(repository: Repository, context: {
    currentUser: UserResource;
}): Promise<UserProfileLoaderData> {
    // We avoid using the user from the context, because we want to always get the latest version of the user resource when we load this page
    const user = repository.Users.get(context.currentUser.Id);
    return {
        user: await user,
    };
}
interface UserProfileLoaderData {
    user: UserResource;
}
interface UserProfilePageProps {
    loaderData: UserProfileLoaderData;
}
interface UserProfileInternalPageProps extends UserProfilePageProps {
    changePasswordDialogControls: DialogControls;
}
export function UserProfilePage({ loaderData }: UserProfilePageProps) {
    const changePasswordDialogControls = useDialogTrigger();
    return <UserProfileInternalPage loaderData={loaderData} changePasswordDialogControls={changePasswordDialogControls}/>;
}
class UserProfileInternalPage extends FormBaseComponent<UserProfileInternalPageProps, UserProfileState, UserEditModel> {
    constructor(props: UserProfileInternalPageProps) {
        super(props);
        this.state = {
            user: props.loaderData.user,
            model: this.buildModel(props.loaderData.user),
            cleanModel: this.buildModel(props.loaderData.user),
        };
    }
    buildModel(user: UserResource): UserEditModel {
        return {
            displayName: user.DisplayName,
            username: user.Username,
            emailAddress: user.EmailAddress,
        };
    }
    handleSaveClick = async () => {
        const userDisplaNameChanged = this.state.cleanModel.displayName !== this.state.model.displayName;
        const user: UserResource = {
            ...this.state.user,
            DisplayName: this.state.model.displayName,
            EmailAddress: this.state.model.emailAddress,
            Username: this.state.model.username,
        };
        await this.doBusyTask(async () => {
            const result = await repository.Users.save(user);
            this.setState({
                cleanModel: this.buildModel(user),
                model: this.buildModel(user),
            });
            if (userDisplaNameChanged) {
                // we did this in old portal, and we do it for the feature toggles
                window.location.reload();
            }
        }, { timeOperationOptions: timeOperationOptions.forSave() });
    };
    render() {
        return (<LegacyForm model={this.state.model} cleanModel={this.state.cleanModel} onSaveClick={this.handleSaveClick}>
                {({ FormContent, createSaveAction }) => (<PaperLayoutVNext primaryAction={createSaveAction({})} title={userProfilePageTitle} busy={this.state.busy} errors={this.errors} overflowActions={this.createOverflowMenuItems()} pageActions={this.getPageActions()}>
                        {this.getDialogs()}
                        <FormContent>
                            <TransitionAnimation>
                                <FormSection title="Profile Picture" help={<div className={profilePictureHelpStyles}>
                                            <Avatar avatarLink={this.state.user.Links.Avatar} isService={this.state.user.IsService} size={36}/>
                                            <span>
                                                You can change your picture at <ExternalLink href="Gravatar">Gravatar.com</ExternalLink>
                                            </span>
                                        </div>}></FormSection>
                                <ExpandableFormSection errorKey="displayName" title="Display Name" focusOnExpandAll summary={Summary.summary(this.state.model.displayName ? this.state.model.displayName : "No display name specified yet.")} help="Your name. This does not need to be unique.">
                                    <Text value={this.state.model.displayName} onChange={(displayName) => this.setModelState({ displayName })} label="Display name" autoFocus={true}/>
                                </ExpandableFormSection>

                                <ExpandableFormSection errorKey="emailAddress" title="Email Address" summary={Summary.summary(this.state.model.emailAddress ? this.state.model.emailAddress : "Your email is not specified yet.")} help={this.state.model.emailAddress ? "Your email address." : "Enter an email address."}>
                                    <Text value={this.state.model.emailAddress} onChange={(emailAddress) => this.setModelState({ emailAddress })} label="Email address"/>
                                </ExpandableFormSection>
                            </TransitionAnimation>
                        </FormContent>
                    </PaperLayoutVNext>)}
            </LegacyForm>);
    }
    createOverflowMenuItems(): SimpleMenuItem[] {
        const isTestPermissionsAllowed = isAllowed({
            permission: [Permission.TeamEdit, Permission.UserView],
        });
        const menuItems: (SimpleMenuItem | null)[] = [
            { type: "download-link", label: "Download my personal details", downloadFileName: this.state.model.username + "-user.json", href: client.resolveLinkTemplate("Users", { id: `${this.state.user.Id}` }) },
            isTestPermissionsAllowed ? { type: "internal-link", label: "Test Permissions", path: links.testPermissionsForUserPage.generateUrl({ userId: this.state.user.Id }) } : null,
            { type: "button", label: "Revoke Sessions", onClick: () => repository.Users.revokeSessions(this.state.user) },
        ];
        return menuItems.filter((item) => item !== null);
    }
    getPageActions(): PageAction[] {
        return [
            {
                type: "button",
                buttonType: "secondary",
                label: "Change password",
                onClick: this.props.changePasswordDialogControls.openDialog,
            },
        ];
    }
    getDialogs() {
        return (<Dialog open={this.props.changePasswordDialogControls.isOpen}>
                <UserChangePasswordDialogLayout userId={this.state.user.Id}/>
            </Dialog>);
    }
    static displayName = "UserProfileInternalPage";
}
const profilePictureHelpStyles = css({
    display: "flex",
    gap: space[16],
    alignItems: "center",
});
export const userProfilePageTitle = "My Profile";

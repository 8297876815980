import type { ProcessType } from "@octopusdeploy/octopus-server-client";
import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
interface UnassignedTargetTagsWarningRaisedEvent extends AnalyticsEvent<"Unassigned Target Tags Warning Raised"> {
    processType: string;
    numberOfTags: number;
    numberOfSteps: number;
}
export function createUnassignedTargetTagsWarningRaisedEvent(processType: ProcessType, numberOfTags: number, numberOfSteps: number): UnassignedTargetTagsWarningRaisedEvent {
    return {
        eventName: "Unassigned Target Tags Warning Raised",
        processType,
        numberOfTags,
        numberOfSteps,
    };
}

import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
interface UnassignedTargetTagsWarningCalloutClosedEvent extends AnalyticsEvent<"Unassigned Target Tags Warning Callout Closed"> {
}
export function createUnassignedTargetTagsWarningCalloutClosedEvent(): UnassignedTargetTagsWarningCalloutClosedEvent {
    return {
        eventName: "Unassigned Target Tags Warning Callout Closed",
    };
}
interface UnassignedTargetTagWarningSettingChangedEvent extends AnalyticsEvent<"Unassigned Target Tag Warning Setting Changed"> {
    value: boolean;
}
export function createUnassignedTargetTagWarningSettingChangedEvent(settingValue: boolean): UnassignedTargetTagWarningSettingChangedEvent {
    return {
        eventName: "Unassigned Target Tag Warning Setting Changed",
        value: settingValue,
    };
}

import { css } from "@emotion/css";
import { Callout } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { BlueprintRepositoryResource, SpaceResource } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, Repository } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import DataLoader from "~/components/DataLoader/index";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { MarkdownEditor, required, Select, Text } from "~/components/form/index";
const InitialDataLoader = DataLoader<InitialData>();
export function AddProcessTemplateDialog() {
    return (<InitialDataLoader load={loadInitialData.bind(null)} operationName="AddProcessBlueprint" renderWhenLoaded={(data) => <AddProcessBlueprintDataBaseComponent {...data}/>} renderAlternate={({ busy, errors }) => <SaveDialogLayout title={"Add Process Blueprint"} busy={busy} errors={errors} onSaveClick={() => Promise.resolve(true)}/>}/>);
}
interface AddProcessBlueprintDialogProps {
    repositories: BlueprintRepositories;
}
class AddProcessBlueprintDataBaseComponent extends DataBaseComponent<AddProcessBlueprintDialogProps> {
    constructor(props: AddProcessBlueprintDialogProps) {
        super(props);
        this.state = {};
    }
    render() {
        return <AddProcessBlueprint repositories={this.props.repositories} busy={this.state.busy} doBusyTask={this.doBusyTask} errors={this.errors}/>;
    }
    static displayName = "AddProcessBlueprintDataBaseComponent";
}
interface AddProcessBlueprintProps {
    repositories: BlueprintRepositories;
    busy: Promise<void> | undefined;
    doBusyTask: DoBusyTask;
    errors: Errors | undefined;
}
function AddProcessBlueprint({ repositories, busy, doBusyTask, errors }: AddProcessBlueprintProps) {
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [selectedRepository, setSelectedRepository] = useState<BlueprintRepositoryResource>();
    const navigation = useSpaceAwareNavigation();
    const add = async () => doBusyTask(async () => {
        if (!selectedRepository) {
            return false;
        }
        const scopedRepository = await repository.forSpace(selectedRepository.SpaceId);
        const savedBlueprint = await scopedRepository.Blueprints.add({ Name: name, Description: description, ProjectId: selectedRepository.ProjectId, ChangeDescription: `Add process blueprint '${name}'` });
        navigation.navigate(links.editBlueprintPage.generateUrl({ spaceId: selectedRepository.SpaceId, blueprintId: savedBlueprint.Id }));
        return true;
    });
    const onRepositorySelected = (projectId: string | undefined) => {
        const selected = projectId ? repositories.lookup.get(projectId) : undefined;
        setSelectedRepository(selected);
    };
    const hasMissingValues = !(name && selectedRepository);
    return (<SaveDialogLayout title={"Add Process Blueprint"} busy={busy} errors={errors} onSaveClick={add} saveButtonLabel={"Add"} busyButtonLabel={"Adding..."} saveButtonDisabled={hasMissingValues}>
            <div className={styles.content}>
                <Text value={name} onChange={(value) => setName(value)} label="Name"/>
                <MarkdownEditor value={description} label="Description" onChange={(value) => setDescription(value)}/>
                <RepositoriesCallout />
                <Select value={selectedRepository?.ProjectId} allowClear={false} onChange={onRepositorySelected} items={repositories.options} label="Select repository" validate={required("Please select repository")}/>
            </div>
        </SaveDialogLayout>);
}
function RepositoriesCallout() {
    return (<Callout title={`Only existing repositories can be selected`} type={"information"}>
            Go to {<InternalLink to={links.platformHubConnectionPage.generateUrl({ spaceId: repository.spaceId || "" })}>Version Control Settings</InternalLink>} to add and manage the platform hub repository.
        </Callout>);
}
interface BlueprintRepositoryOption {
    value: string;
    text: string;
}
interface InitialData {
    repositories: BlueprintRepositories;
}
async function loadInitialData(): Promise<InitialData> {
    return {
        repositories: await loadAllRepositories(),
    };
}
interface BlueprintRepositories {
    options: BlueprintRepositoryOption[];
    lookup: Map<string, BlueprintRepositoryResource>;
}
async function loadAllRepositories(): Promise<BlueprintRepositories> {
    const spaces = await repository.Spaces.all();
    const repositories = await Promise.all(spaces.map((space: SpaceResource) => tryLoadSpaceRepositories(space)));
    const all = repositories.flatMap((r: BlueprintRepositoryResource[]) => r);
    const lookup = all.reduce((map, r) => {
        map.set(r.ProjectId, r);
        return map;
    }, new Map<string, BlueprintRepositoryResource>());
    return {
        lookup,
        options: all.map((r) => {
            return {
                value: r.ProjectId,
                text: r.Name,
            };
        }),
    };
}
async function getSpaceRepositories(space: SpaceResource): Promise<BlueprintRepositoryResource[]> {
    const scopedRepository = await repository.forSpace(space.Id);
    const response = await scopedRepository.Projects.list({ take: Repository.takeAll, partialName: "Blueprints -" });
    const repositories: BlueprintRepositoryResource[] = [];
    for (const project of response.Items) {
        if (HasGitPersistenceSettings(project.PersistenceSettings)) {
            const repository: BlueprintRepositoryResource = {
                ProjectId: project.Id,
                Name: project.Name.replace("Blueprints - ", ""),
                SpaceId: project.SpaceId,
                SpaceName: space.Name,
                StorageDirectory: project.PersistenceSettings.BasePath,
                DefaultBranch: project.PersistenceSettings.DefaultBranch,
            };
            repositories.push(repository);
        }
    }
    return repositories;
}
async function tryLoadSpaceRepositories(space: SpaceResource): Promise<BlueprintRepositoryResource[]> {
    try {
        return await getSpaceRepositories(space);
    }
    catch (error) {
        logger.warn(error, "Unable to fetch projects for space {space}", { space: space.Name });
    }
    return [];
}
const styles = {
    content: css({
        display: "flex",
        flexDirection: "column",
        gap: "0.6rem",
    }),
};

import { css } from "@emotion/css";
import { Callout } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, HasRunbooksInGit, isGitBranch } from "@octopusdeploy/octopus-server-client";
import type { GetMigratedDraftRunbooksBffResponse } from "@octopusdeploy/octopus-server-client/src/resources/getMigratedDraftRunbooksBffResponse";
import React, { useEffect, useState } from "react";
import { repository } from "~/clientInstance";
export interface UnmigratedDraftsCalloutProps {
    project: ProjectResource;
    gitRef: string | undefined;
}
export function UnmigratedDraftsCallout({ project, gitRef }: UnmigratedDraftsCalloutProps) {
    const [migratedDrafts, setMigratedDrafts] = useState<GetMigratedDraftRunbooksBffResponse>();
    // Only show the callout if the project has Git Runbooks and if we are on a
    // branch. You can't edit commits and tags, so there is no way to get rid of
    // this prompt, so it might just be annoying.
    const enableCallout = HasGitPersistenceSettings(project.PersistenceSettings) && HasRunbooksInGit(project.PersistenceSettings) && isGitBranch(gitRef);
    const styles = {
        list: css({
            paddingLeft: "2rem",
            marginBottom: 0,
            listStyleType: "disc",
        }),
    };
    useEffect(() => {
        async function getMigratedDrafts() {
            if (!enableCallout) {
                return;
            }
            const migratedDraftResponse = await repository.Runbooks.getMigratedDraftRunbooks(project, gitRef);
            setMigratedDrafts(migratedDraftResponse);
        }
        getMigratedDrafts();
    }, [enableCallout, project, gitRef]);
    if (!enableCallout || !migratedDrafts || migratedDrafts.DraftRunbookNames.length === 0 || !HasGitPersistenceSettings(project.PersistenceSettings)) {
        // We don't really need the HasGitPersistenceSettings in here because we're covered by enableCallout,
        // but the type system doesn't know that, so we need this so that it knows that we have GitPersistenceSettings
        // below.
        return null;
    }
    return (<Callout type="information" title="There are unmigrated Runbooks drafts on this branch">
            <p>
                When the Runbooks in this Project were migrated to your Git repository, draft versions of these Runbooks were migrated to the <code>{migratedDrafts.DraftRunbookFolder}</code> folder. The changes in these Runbook files will need to be
                merged into the <code>runbooks</code> folder (or removed if they're not needed):
                <ul className={styles.list}>
                    {migratedDrafts.DraftRunbookNames.map((draft) => (<li>
                            <code>{draft}</code>
                        </li>))}
                </ul>
            </p>
        </Callout>);
}

import { Callout } from "@octopusdeploy/design-system-components";
import * as React from "react";
interface DeleteProcessTemplateProps {
    processTemplateName: string;
}
export default ({ processTemplateName }: DeleteProcessTemplateProps) => (<div>
        <Callout title="This is a destructive action" type={"danger"}>
            This action <strong>cannot</strong> be undone. This will permanently delete the process template <strong>{processTemplateName}</strong>.
        </Callout>
    </div>);

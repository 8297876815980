/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { DataCube } from "~/areas/projects/components/DashboardDataSource/DataCube";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
export type RenderDashboardProps = DataBaseComponentState & {
    projectLimit?: number;
    cube?: DataCube;
    totalItems?: number;
    isFiltered?: boolean;
};
export const dashboardRefreshIntervalInMs = 6000;

import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { k8sIconStyles } from "~/areas/projects/components/Observability/K8ResourceIcons/k8sIconStyles";
export function ClusterRoleIcon() {
    return (<svg className={k8sIconStyles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6518 23.4323C20.8395 23.4323 21.8023 22.4772 21.8023 21.299C21.8023 20.1208 20.8395 19.1657 19.6518 19.1657C18.4641 19.1657 17.5013 20.1208 17.5013 21.299C17.5013 22.4772 18.4641 23.4323 19.6518 23.4323Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M20.0027 4L6.80249 9.83628V18.545C6.80249 26.6189 12.429 34.1657 20.0027 36C27.5656 34.1657 33.1975 26.6189 33.1975 18.545V9.83628L20.0027 4ZM26.9901 27.4365H13.0099V15.6994H15.0163V14.4192C15.0163 11.6544 17.2755 9.41131 20.0672 9.41131C22.859 9.41131 25.1182 11.6544 25.1182 14.4192V15.6994H26.9901V27.4365Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M19.9045 11.2748C18.2316 11.2807 16.8761 12.6249 16.8761 14.2817V15.5403H22.9383V14.2602C22.9222 12.6088 21.572 11.2801 19.9045 11.2748Z" fill={themeTokens.color.icon.tertiary}/>
        </svg>);
}

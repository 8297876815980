import classnames from "classnames";
import { sortBy } from "lodash";
import * as React from "react";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import UseLabelStrategy from "~/components/LabelStrategy/LabelStrategy";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import type { DropdownMenuOption } from "~/primitiveComponents/form/Select/DropDownMenu";
import type FormFieldProps from "../../../components/form/FormFieldProps";
import { SelectField } from "./SelectField";
import styles from "./style.module.less";
export type Item = DropdownMenuOption;
export interface OtherSelectProps {
    items: Item[];
    placeholder?: string;
    allowClear?: boolean;
    allowFilter?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    warning?: string | null;
    error?: string | null;
    empty?: string;
    selectionRenderer?: (value: string, menuItem: Item) => React.ReactNode;
    validate?(value: string): string;
    onFilterChanged?(value: string): Promise<Item[]>;
    sortItems?: boolean;
    inlineButton?: React.ReactNode;
}
export type SelectProps = OtherSelectProps & FormFieldProps<string | undefined>;
interface SelectState {
    error?: string;
    filter?: string;
    filteredItems: Item[];
}
class SelectInternal extends React.Component<OtherSelectProps & FormFieldProps<string | undefined>, SelectState> {
    constructor(props: OtherSelectProps & FormFieldProps<string | undefined>) {
        super(props);
        this.state = {
            filter: undefined,
            filteredItems: [],
            error: undefined,
        };
    }
    render() {
        const { value, label, items, error, onChange, validate, placeholder, allowClear, allowFilter, sortItems = true, disabled, empty, selectionRenderer, onFilterChanged, warning, autoFocus, helperText, children, inlineButton } = this.props;
        // Respect items with numeric prefixes, like Step names - eg "1. Do thing", "2. Do another", "10. Do a tenth thing". If doing purely string sort order, the "10" would end up in the wrong place.
        const itemsInList = sortItems
            ? sortBy(this.state.filteredItems.length > 0 ? this.state.filteredItems : items, [(x) => parseInt(x.text ?? "") || x.text?.toLowerCase(), (x) => x.text?.toLowerCase()])
            : this.state.filteredItems.length > 0
                ? this.state.filteredItems
                : items;
        const errorText = this.state.error ?? error ?? warning ?? undefined;
        return (<div className={styles.container}>
                <SelectField className={classnames(this.props.disabled ? styles.isDisabled : styles.select)} value={value} selectedValueAccessibleName={items.find((i) => i.value === value)?.text ?? value} allowClear={allowClear} onChange={this.handleChange} floatingLabelText={label} placeholder={placeholder} errorText={errorText} onCloseDropdown={this.handleOnClose} filter={allowFilter && (<div className={styles.filter}>
                                <FilterSearchBox placeholder={"Find..."} autoFocus={true} value={this.state.filter} onChange={this.handleFilterChanged} fullWidth={true}/>
                            </div>)} disabled={disabled} selectionRenderer={selectionRenderer} autoFocus={autoFocus} items={itemsInList} empty={empty} inlineButton={inlineButton}/>
            </div>);
    }
    private handleChange = (value: string) => {
        if (this.props.validate) {
            const result = this.props.validate(value);
            this.setState((prev) => ({ ...prev, error: result }));
        }
        this.setState((prev) => ({
            ...prev,
            filter: undefined,
            filteredItems: [],
            showExternalError: false,
        }));
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };
    private handleFilterChanged = async (value: string) => {
        let filteredItems: Item[] = [];
        if (this.props.onFilterChanged) {
            filteredItems = await this.props.onFilterChanged(value);
        }
        else {
            filteredItems = this.props.items.filter((item) => {
                return item.text.toLowerCase().search(value.toLowerCase()) !== -1;
            });
        }
        this.setState((prev) => {
            return {
                ...prev,
                filter: value,
                filteredItems,
            };
        });
    };
    private handleOnClose = () => {
        if (this.state.filter) {
            this.setState({
                filter: undefined,
                filteredItems: [],
            });
        }
    };
    static displayName = "SelectInternal";
}
export const Select = UseLabelStrategy(SelectInternal, (fieldName) => `Select ${fieldName}`);
export { SelectInternal };
export const BoundSelect = withBoundField(Select);
export default Select;

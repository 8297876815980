import { Action, type ActionEvent } from "~/analytics/Analytics";
import type { ProjectStatusPopover } from "./ProjectStatusPopover";
import type { ProjectStatusAction } from "./actions";
import { projectStatusActions } from "./actions";
export type ProjectStatusState = {
    items: StatusItem[];
    indexOfActiveItem: number;
    serverState: ServerState;
    isK8sProject: boolean;
    intendsToUseConfigAsCode: boolean;
    hasLoadedServerState: boolean;
    statusRequestedBeforeProjectExists: boolean;
    projectInitiatedViaGuidedSetup: boolean;
    guidedSetupResumed: boolean;
};
export type StatusItem = {
    label: string;
    href?: string;
    showIndicator?: boolean;
    popover?: ProjectStatusPopover;
    eventName?: string;
    eventAction?: ActionEvent;
};
export const initialServerState = {
    HasBeenSuccessfullyDeployed: false,
    HasSteps: false,
    HasRunbooks: false,
    SpaceHasSuccessfulDeployments: false,
    SpaceHasEnvironments: false,
    HasStepsThatRequireTargets: false,
    IsVersionControlled: false,
    SpaceHasKubernetesTarget: false,
    CloudTargetDiscoveryEnabled: false,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    UnassociatedTargetRoles: [] as string[],
};
export type ServerState = typeof initialServerState;
export const statusItems: Record<string, StatusItem> = {
    vcs: { label: "Version Control", eventAction: { action: Action.Configure, resource: "Project" } },
    project: { label: "Project", eventAction: { action: Action.Add, resource: "Project" } },
    environments: { label: "Environments", eventAction: { action: Action.Add, resource: "Environment" } },
    process: { label: "Deployment Process", eventAction: { action: Action.Add, resource: "Deployment Process" } },
    target: { label: "Deployment Target", eventAction: { action: Action.Add, resource: "Deployment Target" } },
    release: { label: "Release and Deploy", eventAction: { action: Action.Add, resource: "Release" } },
};
export const initialProjectStatusState: ProjectStatusState = {
    items: [statusItems.project, statusItems.process, statusItems.release],
    indexOfActiveItem: 0,
    serverState: initialServerState,
    isK8sProject: false,
    intendsToUseConfigAsCode: false,
    hasLoadedServerState: false,
    statusRequestedBeforeProjectExists: false,
    projectInitiatedViaGuidedSetup: false,
    guidedSetupResumed: false,
};
export function projectStatusReducer(state: ProjectStatusState, action: ProjectStatusAction) {
    // logger.info("Project Status action dispatched: {action}", { action });
    const { project, process, environments, target, vcs, release } = statusItems;
    switch (action.type) {
        case projectStatusActions.projectInitiated: {
            const items = cloneStatusItems(initialProjectStatusState.items);
            if (!action.hasEnvironments) {
                items.insertAfter(environments, project);
            }
            return {
                ...initialProjectStatusState,
                items: items,
                projectInitiatedViaGuidedSetup: true,
                statusRequestedBeforeProjectExists: state.statusRequestedBeforeProjectExists,
            };
        }
        case projectStatusActions.newProjectFormSubmitted: {
            const items = cloneStatusItems(state.items);
            const environmentsItemExists = items.exists(environments);
            const statusItemToComeBeforeTarget = environmentsItemExists ? environments : project;
            if (action.intendsToUseConfigAsCode) {
                items.insertBefore(vcs, process);
            }
            if (action.intendsToUseK8s && !action.k8sTargetAlreadyExistsInSpace && !environmentsItemExists) {
                items.insertAfter(target, statusItemToComeBeforeTarget);
            }
            return {
                ...state,
                items,
                indexOfActiveItem: state.indexOfActiveItem + 1,
                isK8sProject: action.intendsToUseK8s,
                intendsToUseConfigAsCode: action.intendsToUseConfigAsCode,
            };
        }
        case projectStatusActions.environmentsAdded: {
            const items = cloneStatusItems(state.items);
            const shouldHaveDeploymentTargetItem = state.isK8sProject && !state.serverState.SpaceHasKubernetesTarget;
            const doesNotHaveDeploymentTargetItem = !items.exists(target);
            if (shouldHaveDeploymentTargetItem && doesNotHaveDeploymentTargetItem) {
                items.insertAfter(target, environments);
            }
            return {
                ...state,
                items,
                indexOfActiveItem: state.indexOfActiveItem + 1,
                serverState: {
                    ...state.serverState,
                    SpaceHasEnvironments: true,
                },
            };
        }
        case projectStatusActions.environmentsSkipped: {
            const items = cloneStatusItems(state.items);
            if (state.intendsToUseConfigAsCode) {
                // the CaC setup screen will be presented first
                items.swap(vcs, environments);
            }
            // User will land on Process page, so "Environments" should come after "Deployment Process"
            // However, if "Deployment Process" is already complete (which can happen if guided setup is resumed,
            // then "Environments" already comes after it, so do nothing
            const indexOfProcessItem = items.getIndex(process);
            const indexOfEnvironmentsItem = items.getIndex(environments);
            if (indexOfEnvironmentsItem < indexOfProcessItem) {
                items.swap(environments, process);
            }
            return {
                ...state,
                items,
            };
        }
        case projectStatusActions.k8sClusterAdded: {
            const items = cloneStatusItems(state.items);
            if (items.isActive(target, state.indexOfActiveItem)) {
                return {
                    ...state,
                    indexOfActiveItem: items.getIndex(target) + 1,
                };
            }
            return state;
        }
        case projectStatusActions.k8sClusterSkipped: {
            const items = cloneStatusItems(state.items);
            // the user will be on the Process Editor page, with "Deployment Target" as their next step
            // so we want the "Deployment Target" after "Deployment Process"
            items.remove(target);
            items.insertAfter(target, process);
            return {
                ...state,
                items,
            };
        }
        case projectStatusActions.serverStateLoaded: {
            const items = cloneStatusItems(state.items);
            const { HasSteps, SpaceHasEnvironments, HasStepsThatRequireTargets, UnassociatedTargetRoles, IsVersionControlled } = action.serverState;
            const indexOfEnvironmentsItem = items.getIndex(environments);
            const indexOfProcessItem = items.getIndex(process);
            const environmentsWereJustAddedAndWouldBeTheActiveItem = !state.serverState.SpaceHasEnvironments && SpaceHasEnvironments && indexOfEnvironmentsItem === state.indexOfActiveItem;
            let newActiveItemIndex = environmentsWereJustAddedAndWouldBeTheActiveItem ? indexOfEnvironmentsItem + 1 : state.indexOfActiveItem;
            if (HasSteps) {
                newActiveItemIndex = indexOfProcessItem + 1;
            }
            else if (!HasSteps && state.indexOfActiveItem >= indexOfProcessItem) {
                newActiveItemIndex = indexOfProcessItem;
            }
            if (!SpaceHasEnvironments && !items.exists(environments)) {
                state.guidedSetupResumed && !HasSteps ? items.insertBefore(environments, process) : items.insertAfter(environments, process);
            }
            // Mark "Environments" as complete when it would be the active item
            if (SpaceHasEnvironments && items.isActive(environments, newActiveItemIndex)) {
                newActiveItemIndex++;
            }
            if (HasSteps && !HasStepsThatRequireTargets && items.exists(target)) {
                items.remove(target);
            }
            const shouldIncludeDeploymentTargetItem = HasStepsThatRequireTargets && UnassociatedTargetRoles.length > 0 && !items.exists(target);
            if (shouldIncludeDeploymentTargetItem) {
                items.insertBefore(target, release);
            }
            // Insert "Version Control" after "Project" if configured, but only if it doesn't already exist
            const indexOfProjectItem = items.getIndex(project);
            let indexOfVcsItem = items.getIndex(vcs);
            if (IsVersionControlled && !items.exists(vcs)) {
                items.insertAfter(vcs, project);
                // if "Version Control" would be the active item, mark it complete
                indexOfVcsItem = items.getIndex(vcs);
                if (indexOfVcsItem >= newActiveItemIndex) {
                    newActiveItemIndex = indexOfVcsItem + 1;
                }
            }
            else if (items.isActive(vcs, newActiveItemIndex) && IsVersionControlled) {
                // mark "Version Control" as complete when it's the active item and we find out it's been configured
                newActiveItemIndex = indexOfVcsItem + 1;
            }
            // mark "Process" as complete if it's the active item
            if (items.isActive(process, newActiveItemIndex) && HasSteps) {
                newActiveItemIndex = indexOfProcessItem + 1;
            }
            // mark "Project" as complete if it's the active item
            if (items.isActive(project, newActiveItemIndex)) {
                newActiveItemIndex = indexOfProjectItem + 1;
            }
            return {
                ...state,
                items,
                indexOfActiveItem: newActiveItemIndex,
                serverState: action.serverState,
                hasLoadedServerState: true,
            };
        }
        case projectStatusActions.serverStateChanged: {
            return {
                ...state,
                serverState: {
                    ...state.serverState,
                    [action.key]: action.value,
                },
            };
        }
        case projectStatusActions.guidedSetupDismissed: {
            if (state.guidedSetupResumed && !state.serverState.HasSteps) {
                const items = cloneStatusItems(state.items);
                if (items.exists(environments)) {
                    items.swap(environments, process);
                }
                return {
                    ...state,
                    items,
                    guidedSetupResumed: false,
                };
            }
            if (state.intendsToUseConfigAsCode) {
                let newItems = [];
                const items = cloneStatusItems(state.items);
                if (items.exists(environments)) {
                    newItems = [project, vcs, process, environments, release];
                }
                else {
                    newItems = [project, vcs, process, release];
                }
                return {
                    ...initialProjectStatusState,
                    items: newItems,
                    indexOfActiveItem: 1,
                };
            }
            return { ...state };
        }
        case projectStatusActions.guidedSetupResumed: {
            const items = cloneStatusItems(state.items);
            // remove "Environments" item so that it can be properly recalculated for the correct position
            // when the server state is loaded (this is handled in the server_state_loaded case)
            if (items.exists(environments)) {
                items.remove(environments);
            }
            // same for "Version Control" item
            if (items.exists(vcs)) {
                items.remove(vcs);
            }
            return {
                ...state,
                items,
                guidedSetupResumed: true,
            };
        }
        case projectStatusActions.statusRequestedBeforeProjectExists: {
            return {
                ...state,
                statusRequestedBeforeProjectExists: true,
            };
        }
        default:
            return state;
    }
}
class ClonedStatusItemsArray extends Array<StatusItem> {
    getIndex(item: StatusItem): number {
        return this.indexOf(item);
    }
    isActive(item: StatusItem, index: number): boolean {
        return this.indexOf(item) === index;
    }
    insertBefore(newElement: StatusItem, afterElement: StatusItem) {
        const index = this.indexOf(afterElement);
        if (index !== -1) {
            this.splice(index, 0, newElement);
        }
    }
    insertAfter(newElement: StatusItem, afterElement: StatusItem) {
        const index = this.indexOf(afterElement);
        if (index !== -1) {
            this.splice(index + 1, 0, newElement);
        }
    }
    exists(element: StatusItem): boolean {
        return this.indexOf(element) !== -1;
    }
    remove(element: StatusItem) {
        const index = this.indexOf(element);
        if (index !== -1) {
            this.splice(index, 1);
        }
    }
    swap(item1: StatusItem, item2: StatusItem) {
        const index1 = this.indexOf(item1);
        const index2 = this.indexOf(item2);
        if (index1 !== -1 && index2 !== -1) {
            [this[index1], this[index2]] = [this[index2], this[index1]];
        }
    }
}
function cloneStatusItems(items: StatusItem[]): ClonedStatusItemsArray {
    const clonedItems = new ClonedStatusItemsArray(...items);
    return clonedItems;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Callout } from "@octopusdeploy/design-system-components";
import { ActionExecutionLocation, FeedType, GetPrimaryPackageReference, InitialisePrimaryPackageReference, PackageSelectionMode, SetPrimaryPackageReference } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import PackageDownloadOptions from "~/components/PackageDownloadOptions/PackageDownloadOptions";
import DeferredPackageSelector from "~/components/PackageSelector/DeferredPackageSelector";
import { ExpandableFormSection } from "~/components/form";
import ExpanderSectionHeading from "~/components/form/Sections/FormSectionHeading";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper/CommonSummaryHelper";
import { ProcessFeedLookup, useFeedsFromContext, useRefreshFeedsFromContext } from "../../../areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { DisplayFeedName } from "../DisplayFeedName";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionWithFeeds } from "../commonActionHelpers";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
interface TentaclePackageActionSummaryState {
    feedName: string | null;
}
class TentaclePackageAction extends BaseComponent<ActionSummaryProps, TentaclePackageActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }
    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg ? (<div>
                {"Deploy package"} <strong>{pkg.PackageId} </strong> {"from"} <DisplayFeedName pkg={pkg}/>
                {this.props.targetRolesAsCSV && (<span>
                        {" "}
                        to deployment targets with <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>{" "}
                    </span>)}
            </div>) : (<Callout type={"warning"} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>);
    }
    static displayName = "TentaclePackageAction";
}
type TentaclePackageActionEditState = {};
type TentaclePackageActionEditProps = ActionEditProps;
type TentaclePackageActionEditInternalProps = TentaclePackageActionEditProps & ActionWithFeeds;
class TentaclePackageActionEditInternal extends BaseComponent<TentaclePackageActionEditInternalProps, TentaclePackageActionEditState> {
    constructor(props: TentaclePackageActionEditInternalProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds), true);
    }
    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }
        const pkg = GetPrimaryPackageReference(this.props.packages);
        const help = <span>Select the package containing your application.</span>;
        return (<div>
                <ExpanderSectionHeading title="Package Details"/>
                <ExpandableFormSection errorKey="package" isExpandedByDefault={this.props.expandedByDefault} title="Package" summary={CommonSummaryHelper.deferredPackageSummary(pkg!, this.props.feeds)} help={help}>
                    <DeferredPackageSelector 
        // Standard package selector properties
        packageId={pkg?.PackageId} feedId={pkg?.FeedId} packageVersion={pkg?.Version} onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))} onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))} onPackageVersionChange={(version) => this.props.setPackages(SetPrimaryPackageReference({ Version: version }, this.props.packages))} packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")} feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")} projectId={this.props.projectId} feeds={this.props.feeds} localNames={this.props.localNames} feedType={[FeedType.Nuget, FeedType.BuiltIn, FeedType.Maven, FeedType.GitHub, FeedType.S3, FeedType.ArtifactoryGeneric]} refreshFeeds={this.loadFeeds} 
        // Deferred package selection properties
        parameters={this.props.parameters} packageSelectionMode={pkg?.Properties["SelectionMode"]} packageSelectionModeError={this.props.getFieldError("SelectionMode")} onPackageSelectionModeChange={(value) => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))} packageParameterName={pkg?.Properties["PackageParameterName"]} packageParameterError={this.props.getFieldError("PackageParameterName")} onPackageParameterChange={(packageParameter) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg?.Properties, PackageParameterName: packageParameter } }, this.props.packages))}/>
                    {pkg?.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (<ProcessFeedLookup feedId={pkg.FeedId}>
                            {(feed) => (<PackageDownloadOptions packageAcquisitionLocation={pkg.AcquisitionLocation} onPackageAcquisitionLocationChanged={(acquisitionLocation) => this.props.setPackages(SetPrimaryPackageReference({ AcquisitionLocation: acquisitionLocation }, this.props.packages))} feed={feed} projectId={this.props.projectId} localNames={this.props.localNames}/>)}
                        </ProcessFeedLookup>)}
                </ExpandableFormSection>
            </div>);
    }
    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };
    static displayName = "TentaclePackageActionEditInternal";
}
export function TentaclePackageActionEdit(props: React.PropsWithChildren<TentaclePackageActionEditProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    return <TentaclePackageActionEditInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds}/>;
}
pluginRegistry.registerAction({
    actionType: "Octopus.TentaclePackage",
    summary: (properties, targetRolesAsCSV, packages) => <TentaclePackageAction properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages}/>,
    canHaveChildren: (step) => true,
    canBeChild: true,
    editSections: { default: TentaclePackageActionEdit },
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
    features: {
        optional: [
            "Octopus.Features.ConfigurationTransforms",
            "Octopus.Features.ConfigurationVariables",
            "Octopus.Features.CustomDirectory",
            "Octopus.Features.CustomScripts",
            "Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.SubstituteInFiles",
            "Octopus.Features.IisHome",
            "Octopus.Features.IISWebSite",
            "Octopus.Features.RedGateDatabase",
            "Octopus.Features.WindowsService",
            "Octopus.Features.Nginx",
            "Octopus.Features.SelectPowerShellEditionForWindows",
        ],
        initial: ["Octopus.Features.ConfigurationTransforms", "Octopus.Features.ConfigurationVariables"],
    },
    docsLink: "deployPackage",
});

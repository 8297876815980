import { css } from "@emotion/css";
import { CircularProgress } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import Card from "~/components/Card";
import { WelcomeDialogSignIcon } from "../WelcomeDialogSignIcon";
import type { WelcomeDialogCardProps } from "./WelcomeDialogCardProps";
import { cardStyles } from "./styles";
type SampleProjectCardProps = WelcomeDialogCardProps & {
    newCopy?: boolean;
};
export function SampleProjectCard({ busy, onClick, newCopy }: SampleProjectCardProps) {
    const getContent = () => {
        return newCopy ? "Learn the basics with a fully editable sample project." : "Explore a sample project to learn about key Octopus features.";
    };
    const LoadingContent = () => {
        return (<div className={loadingStyles}>
                <CircularProgress size="small"/>
                <div>Loading sample project</div>
            </div>);
    };
    return (<Card link={!busy && (<a href="#" onClick={onClick} className={cardStyles.link}>
                        {newCopy ? "Octopus 101" : "Create sample project"}
                    </a>)} logo={<div className={cardStyles.logo(!busy)}>
                    <WelcomeDialogSignIcon />
                </div>} header={<div className={cardStyles.header}>Sample project</div>} content={busy ? <LoadingContent /> : getContent()} className={cardStyles.card} contentClassName={cardStyles.content} leftAlign={true}/>);
}
const loadingStyles = css({
    display: "flex",
    alignItems: "center",
    gap: space[12],
    height: "100%",
    paddingTop: space[12],
    paddingBottom: space[12],
});

import { css } from "@emotion/css";
import { TriangleExclamationIcon } from "@octopusdeploy/design-system-components";
import { fontSize, lineHeight, space } from "@octopusdeploy/design-system-tokens";
import { Permission, type DeploymentTargetResource, type EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import React, { useEffect, useState } from "react";
import { repository } from "~/clientInstance";
import Chip from "~/components/Chips/Chip";
import { IconStyledForChip } from "~/components/Chips/IconStyledForChips";
import { CloudTargetDiscoveryContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import { MachineIcon } from "~/primitiveComponents/dataDisplay/Icon";
import { ConnectedDeploymentTargetsPopover, getUnassignedTargetTags } from "./ConnectedDeploymentTargetsPopover";
interface ConnectedDeploymentTargetsStatusProps {
    targetTags: string[];
    isCloudTargetDiscoveryEnabled: boolean;
    projectId: string;
    clearWarnings: () => void;
}
export const canShowConnectedDeploymentTargetsStatus = () => {
    return isAllowed({ permission: Permission.MachineView }) && isAllowed({ permission: Permission.EnvironmentView, environment: "*" });
};
export const ConnectedDeploymentTargetsStatus = (props: ConnectedDeploymentTargetsStatusProps) => {
    const MAX_DEPLOYMENT_TARGETS_TO_DISPLAY = 4;
    const [deploymentTargets, setDeploymentTargets] = useState<DeploymentTargetResource[] | undefined>(undefined);
    const [environments, setEnvironments] = useState<EnvironmentResource[] | undefined>(undefined);
    const [deploymentTargetsTotal, setDeploymentTargetsTotal] = useState<number | undefined>(undefined);
    const isImprovedTargetFeedbackFeatureEnabled = useOctopusFeatureToggle("improved-target-feedback", false);
    const showConnectedDeploymentTargetsStatus = isImprovedTargetFeedbackFeatureEnabled && canShowConnectedDeploymentTargetsStatus();
    useEffect(() => {
        const getDeploymentTargets = async () => {
            if (props.targetTags.length > 0) {
                const response = await repository.Machines.list({ roles: props.targetTags.join(","), take: MAX_DEPLOYMENT_TARGETS_TO_DISPLAY });
                setDeploymentTargetsTotal(response.TotalResults);
                const deploymentTargets = response.Items;
                const environmentIds = new Set<string>();
                deploymentTargets.forEach((target) => {
                    target.EnvironmentIds.forEach((id) => environmentIds.add(id));
                });
                const environments = await repository.Environments.all({ ids: Array.from(environmentIds) });
                environments.sort((a, b) => a.SortOrder - b.SortOrder);
                setDeploymentTargets(deploymentTargets);
                setEnvironments(environments);
            }
            else {
                setDeploymentTargetsTotal(undefined);
                setDeploymentTargets(undefined);
                setEnvironments(undefined);
            }
        };
        getDeploymentTargets();
    }, [props.targetTags]);
    if (!showConnectedDeploymentTargetsStatus || (!props.isCloudTargetDiscoveryEnabled && deploymentTargetsTotal === undefined)) {
        return <></>;
    }
    return (<div className={containerStyles.root}>
            {deploymentTargets !== undefined && environments !== undefined && deploymentTargetsTotal !== undefined && !props.isCloudTargetDiscoveryEnabled && (<div className={containerStyles.connectedDeploymentTargets}>
                    {getUnassignedTargetTags(props.targetTags, deploymentTargets).length > 0 && (<div className={iconStyles}>
                            <TriangleExclamationIcon size={24} color="warning"/>
                        </div>)}
                    <div className={statusTextStyles}>Can deploy to</div>
                    <div className={chipContainerStyles}>
                        <ConnectedDeploymentTargetsPopover targetTags={props.targetTags} triggerElement={<Chip noTooltip={true} noMargin={true} icon={<IconStyledForChip iconComponent={MachineIcon}/>}>
                                    {deploymentTargetsTotal} {deploymentTargetsTotal === 1 ? "deployment target" : "deployment targets"}
                                </Chip>} deploymentTargetsTotal={deploymentTargetsTotal} deploymentTargets={deploymentTargets} environments={environments} projectId={props.projectId} clearWarnings={props.clearWarnings}/>
                    </div>
                </div>)}
            {props.isCloudTargetDiscoveryEnabled && (<div className={containerStyles.cloudTargetDiscoveryEnabled}>
                    <div className={statusTextStyles}>Cloud target discovery enabled</div>
                    <div className={contextualHelpContainerStyles}>
                        <CloudTargetDiscoveryContextualHelp />
                    </div>
                </div>)}
        </div>);
};
const ICON_WIDTH = 50;
const ICON_MARGIN_RIGHT = 4;
const containerStyles = {
    root: css({
        marginRight: space[16],
        marginLeft: space[16],
        marginBottom: "22.5px",
        paddingLeft: `${ICON_WIDTH + ICON_MARGIN_RIGHT}px`,
    }),
    connectedDeploymentTargets: css({
        display: "flex",
        alignItems: "center",
    }),
    cloudTargetDiscoveryEnabled: css({
        display: "flex",
        alignItems: "center",
    }),
};
css({
    display: "flex",
    alignItems: "center",
    marginRight: space[16],
    marginLeft: space[16],
    marginBottom: "22.5px",
    paddingLeft: `${ICON_WIDTH + ICON_MARGIN_RIGHT}px`,
});
const iconStyles = css({
    marginRight: space[8],
});
const statusTextStyles = css({
    fontSize: fontSize.medium,
    lineHeight: lineHeight.xSmall,
});
const chipContainerStyles = css({
    marginLeft: space["8"],
});
const contextualHelpContainerStyles = css({
    marginLeft: space["8"],
});

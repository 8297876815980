import { css } from "@emotion/css";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import React from "react";
export const RightArrowIcon = () => (<svg className={rightArrowIconStyles} width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.25 10.75C1.03906 10.75 0.851562 10.6797 0.710938 10.5391C0.40625 10.2578 0.40625 9.76562 0.710938 9.48438L4.67188 5.5L0.710938 1.53906C0.40625 1.25781 0.40625 0.765625 0.710938 0.484375C0.992188 0.179688 1.48438 0.179688 1.76562 0.484375L6.26562 4.98438C6.57031 5.26562 6.57031 5.75781 6.26562 6.03906L1.76562 10.5391C1.625 10.6797 1.4375 10.75 1.25 10.75Z"/>
    </svg>);
const rightArrowIconStyles = css({
    color: colorScales.grey[500],
    fill: colorScales.grey[500],
});

import type { TerraformProperties } from "@octopusdeploy/legacy-action-properties";
import { ActionExecutionLocation } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import Roles from "~/components/Actions/Roles";
import type { ActionSummaryProps } from "~/components/Actions/actionSummaryProps";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import type { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
import TerraformActionEdit from "./terraformAction";
class TerraformDestroyActionSummary extends BaseComponent<ActionSummaryProps> {
    render() {
        return (<div>
                Destroy Terraform resources
                {this.props.targetRolesAsCSV && (<span>
                        {" "}
                        on behalf of targets with <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>
                    </span>)}
            </div>);
    }
    static displayName = "TerraformDestroyActionSummary";
}
class TerraformApplyActionEdit extends BaseComponent<ActionEditProps<TerraformProperties, ScriptPackageProperties>, never> {
    render() {
        return (<TerraformActionEdit projectId={this.props.projectId} gitRef={this.props.gitRef} properties={this.props.properties} packages={this.props.packages} setProperties={this.props.setProperties} setPackages={this.props.setPackages} plugin={this.props.plugin} errors={this.props.errors} getFieldError={this.props.getFieldError} doBusyTask={this.props.doBusyTask} busy={this.props.busy} actionName={"destroy"} expandedByDefault={this.props.expandedByDefault} additionalParametersLink="TerraformDestroy" parameters={this.props.parameters} gitDependencies={this.props.gitDependencies} setGitDependencies={this.props.setGitDependencies}/>);
    }
    static displayName = "TerraformApplyActionEdit";
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.TerraformDestroy",
    summary: (properties, targetRolesAsCSV) => <TerraformDestroyActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    canHaveChildren: (step) => true,
    canBeChild: true,
    editSections: { default: TerraformApplyActionEdit },
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => false,
    getInitialProperties: () => ({
        "Octopus.Action.GoogleCloud.UseVMServiceAccount": "True",
        "Octopus.Action.GoogleCloud.ImpersonateServiceAccount": "False",
    }),
    docsLink: "applyTerraformChanges",
});

import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context/index";
import StringHelper from "~/utils/StringHelper";
import RunbooksNavigationTabs from "../RunbooksNavigationTabs";
import type { TabbedPaperLayoutProps } from "./TabbedPaperLayout";
import TabbedPaperLayout from "./TabbedPaperLayout";
type RunbooksPaperLayoutProps = TabbedPaperLayoutProps;
const RunbooksPaperLayout: React.FC<RunbooksPaperLayoutProps> = ({ tabNavigation = RunbooksNavigationTabs, title = StringHelper.ellipsis, fullWidth = true, ...rest }) => {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    return (<TabbedPaperLayout breadcrumbsItems={rest.breadcrumbsItems ?? [{ label: "Runbooks", pageUrl: links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) }]} title={title} tabNavigation={tabNavigation} fullWidth={fullWidth} {...rest}/>);
};
RunbooksPaperLayout.displayName = "RunbooksPaperLayout"
export default RunbooksPaperLayout;
export { RunbooksPaperLayout };

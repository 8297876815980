import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { k8sIconStyles } from "~/areas/projects/components/Observability/K8ResourceIcons/k8sIconStyles";
export function PodIcon() {
    return (<svg className={k8sIconStyles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 8.79019L20 4.15039L35.9999 8.79019L20 13.43L4 8.79019Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4 10.5669V27.5918L18.9075 35.8496L18.9813 15.0226L4 10.5669Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M36 10.5669V27.5918L21.0925 35.8496L21.0187 15.0226L36 10.5669Z" fill={themeTokens.color.icon.tertiary}/>
        </svg>);
}

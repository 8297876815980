import { Callout } from "@octopusdeploy/design-system-components";
import type { ActionProperties, GitRefResource } from "@octopusdeploy/octopus-server-client";
import { generateSlug } from "@octopusdeploy/utilities";
import * as React from "react";
import { useState } from "react";
import { BlueprintActionDetails } from "~/areas/projects/components/Process/Blueprints/BlueprintActionDetails";
import { BlueprintSelector } from "~/areas/projects/components/Process/Blueprints/BlueprintSelector";
import { getBlueprintIdFromAction } from "~/areas/projects/components/Process/Blueprints/blueprintId";
import { deleteActionAndRedirect, getDeleteProcessMenuItem, processScopedEditPermission } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import StepName from "~/areas/projects/components/Process/Common/StepName";
import { useProcessBlueprintsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessBlueprintsContextProvider";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useProcessErrorSelectors } from "~/areas/projects/components/Process/Contexts/ProcessErrors/ProcessErrorsContext";
import { useProcessQueryStringContext } from "~/areas/projects/components/Process/Contexts/ProcessQueryString/ProcessQueryStringContext";
import type { ProcessStepActionState } from "~/areas/projects/components/Process/ProcessStepsLayoutTypes";
import { ProcessSubPageLayout } from "~/areas/projects/components/Process/ProcessSubPageLayout";
import styles from "~/areas/projects/components/Process/style.module.less";
import type { StoredAction, StoredStep } from "~/areas/projects/components/Process/types";
import { isDeploymentOrRunbookProcessIdentifier } from "~/areas/projects/components/Process/types";
import getActionLogoUrl from "~/areas/projects/components/getActionLogoUrl";
import ActionList from "~/components/ActionList/index";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { ExpandableContainer } from "~/components/Expandable/index";
import Logo from "~/components/Logo/index";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import convertPropertyValueResourceToString from "~/components/convertPropertyValueResourceToString";
import { ExpandableFormSection, required, Summary, UnstructuredFormSection } from "~/components/form/index";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
interface ProcessBlueprintActionDetailsProps {
    step: StoredStep;
    action: StoredAction;
    stepOther: ProcessStepActionState;
    isNew: boolean;
    cleanAction: StoredAction | null;
    doBusyTask: DoBusyTask;
    busy?: boolean;
    gitRefResource: GitRefResource | undefined;
    releaseCreationPackageStepId: string | undefined;
}
export function ProcessBlueprintStepDetails({ step, action, stepOther, isNew, cleanAction, doBusyTask, busy, gitRefResource, releaseCreationPackageStepId }: ProcessBlueprintActionDetailsProps) {
    const processContext = useProcessContext();
    const processErrorSelectors = useProcessErrorSelectors();
    const [blueprintBranchHeadCommit, setBlueprintBranchHeadCommit] = useState<string>(convertPropertyValueResourceToString(action.Properties["Octopus.Action.Blueprint.Reference.Commit"]));
    const blueprintContext = useProcessBlueprintsFromContext();
    const blueprintId = getBlueprintIdFromAction(action);
    const blueprint = blueprintContext.find((b) => b.Id === blueprintId);
    const getFieldError = (value: string) => {
        return processErrorSelectors.getActionFieldError(action.Id, processContext.selectors, value);
    };
    const setActionMetaProperties: <K extends keyof StoredAction>(state: Pick<StoredAction, K> | StoredAction, callback?: () => void) => void = (state, callback) => {
        processContext.actions.setActionMetaProperties(action.Id, (prev) => ({ ...prev, ...state }));
        callback && callback();
    };
    const setName = (value: string) => {
        setActionMetaProperties({ Name: value });
    };
    const setSlug = (value: string) => {
        setActionMetaProperties({ Slug: value });
    };
    const setDisabled = (value: boolean) => {
        setActionMetaProperties({ IsDisabled: value });
    };
    const setActionProperties = (properties: Partial<ActionProperties>, callback?: () => void) => {
        processContext.actions.setActionProperties(action.Id, properties);
        callback && callback();
    };
    const setSelectedBlueprintOption = (projectId: string, slug: string) => {
        const properties: ActionProperties = { "Octopus.Action.Blueprint.Reference.ProjectId": projectId, "Octopus.Action.Blueprint.Reference.Slug": slug };
        setActionProperties(properties);
    };
    const setSelectedBranch = (value: string | undefined) => {
        if (value) {
            const properties: ActionProperties = { "Octopus.Action.Blueprint.Reference.Branch": value };
            setActionProperties(properties);
        }
        else {
            processContext.actions.removeActionProperties(action.Id, ["Octopus.Action.Blueprint.Reference.Branch"]);
        }
    };
    const setSelectedCommit = (value: string | undefined) => {
        if (value) {
            const properties: ActionProperties = { "Octopus.Action.Blueprint.Reference.Commit": value };
            setActionProperties(properties);
        }
        else {
            processContext.actions.removeActionProperties(action.Id, ["Octopus.Action.Blueprint.Reference.Commit"]);
        }
    };
    const setParameterValue = (parameterName: string, value: string) => {
        const properties: ActionProperties = { [parameterName]: value };
        setActionProperties(properties);
    };
    return (<>
            <ExpandableContainer containerKey={action.Id}>
                <ActionLayout step={step} action={action} stepOther={stepOther} setDisabled={setDisabled} releaseCreationPackageStepId={releaseCreationPackageStepId}>
                    <>
                        <ActionDisabledWarning action={action}/>
                        <UpdateBlueprintCallout action={action} currentHeadCommit={blueprintBranchHeadCommit}/>
                        <ExpandableFormSection isExpandedByDefault={isNew} errorKey="Step Name" title="Step Name" summary={buildNameSummary(action)} key="Step Name" help="A short, memorable, unique name for this step.">
                            <DebounceText value={action.Name} onChange={setName} label="Step name" error={getFieldError("Name")} validate={required("Please enter a step name")} autoFocus={true}/>
                            {!isNew && (<SlugEditor value={action.Slug ?? generateSlug(action.Name)} name={action.Name} originalSlug={cleanAction?.Slug ?? ""} onChange={setSlug} label="Step slug" error={getFieldError("Slug")} validate={required("Please enter a step slug")}/>)}
                        </ExpandableFormSection>
                        <BlueprintSelector actionProperties={action.Properties} doBusyTask={doBusyTask} onBlueprintOptionSelected={setSelectedBlueprintOption} onBranchSelected={setSelectedBranch} onCommitSelected={setSelectedCommit} onBranchHeadCommitChanged={setBlueprintBranchHeadCommit} isNew={isNew} currentBlueprint={blueprint} busy={busy}/>
                        <BlueprintActionDetails action={action} doBusyTask={doBusyTask} onParameterValueChanged={setParameterValue} blueprint={blueprint} gitRefResource={gitRefResource}></BlueprintActionDetails>
                    </>
                </ActionLayout>
            </ExpandableContainer>
        </>);
}
interface ActionLayoutProps {
    step: StoredStep;
    action: StoredAction;
    stepOther: ProcessStepActionState;
    setDisabled: (value: boolean) => void;
    children: JSX.Element;
    releaseCreationPackageStepId: string | undefined;
}
function ActionLayout({ step, action, setDisabled, stepOther, releaseCreationPackageStepId, children }: ActionLayoutProps) {
    const processContext = useProcessContext();
    const processIdentifier = processContext.state.processIdentifier;
    const { actions: contextActions, selectors: contextSelectors } = processContext;
    const { actions: queryStringActions } = useProcessQueryStringContext();
    const stepActionNumber = `${contextSelectors.getStepNumber(step.Id)}`;
    const title = <StepName name={action.Name} number={stepActionNumber} stepType={stepOther.actionTypeName}/>;
    const logo = <Logo url={getActionLogoUrl(action)}/>;
    const menuItems: MenuItem[] = [];
    if (step && step.Id) {
        const processEditPermission = {
            permission: processScopedEditPermission(processContext.selectors.getProcessType()),
            project: isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectId : undefined,
            wildcard: true,
        };
        const enableItem = OverflowMenuItems.item(action.IsDisabled ? "Enable" : "Disable", () => setDisabled(!action.IsDisabled), processEditPermission);
        const deleteItem = getDeleteProcessMenuItem("step", async () => deleteActionAndRedirect(step, action, true, contextActions, contextSelectors, queryStringActions), processEditPermission, releaseCreationPackageStepId, processIdentifier, step, action, processContext);
        menuItems.push(enableItem, deleteItem);
    }
    const controlActions = [<OverflowMenu menuItems={menuItems} accessibleName="More Step Actions"/>];
    const sectionControl = <ActionList actions={controlActions}/>;
    return (<ProcessSubPageLayout title={title} titleLogo={logo} sectionControl={sectionControl}>
            {children}
        </ProcessSubPageLayout>);
}
function buildNameSummary(action: StoredAction) {
    if (!action.Name) {
        return Summary.placeholder("Please enter a name for your step");
    }
    const name = (<div className={styles.nameSummaryContainer}>
            <NameSummaryWithSlug name={action.Name} slug={action.Slug || null}/>
        </div>);
    return Summary.summary(name);
}
interface ActionDisabledWarningProps {
    action: StoredAction;
}
function ActionDisabledWarning({ action }: ActionDisabledWarningProps) {
    if (!action.IsDisabled) {
        return null;
    }
    return (<UnstructuredFormSection stretchContent={true}>
            <Callout type={"warning"} title={"This step is currently disabled"}/>
        </UnstructuredFormSection>);
}
interface UpdateBlueprintCalloutProps {
    action: StoredAction;
    currentHeadCommit: string;
}
function UpdateBlueprintCallout({ action, currentHeadCommit }: UpdateBlueprintCalloutProps) {
    const selectedCommit = convertPropertyValueResourceToString(action.Properties["Octopus.Action.Blueprint.Reference.Commit"]);
    const blueprintBranch = convertPropertyValueResourceToString(action.Properties["Octopus.Action.Blueprint.Reference.Branch"]);
    const displayUpdateBlueprintCallout = selectedCommit && currentHeadCommit !== selectedCommit;
    return (<>
            {displayUpdateBlueprintCallout && (<Callout type={"information"} title={"Newer version available"}>
                    <div className={styles.calloutContainer}>
                        <div>
                            There is a newer version of the blueprint available on the <code>{blueprintBranch}</code> branch
                        </div>
                    </div>
                </Callout>)}
        </>);
}

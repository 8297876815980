import type { DeploymentTargetResource, EnvironmentResource, ProjectResource, Repository } from "@octopusdeploy/octopus-server-client";
import type { KubernetesLiveStatusResource } from "@octopusdeploy/octopus-server-client/src/resources/kubernetesLiveStatusResource";
import * as React from "react";
import { LiveStatusTable } from "~/areas/projects/components/Observability/LiveStatusTable";
import { ProjectPaperLayout } from "~/areas/projects/components/ProjectPaperLayout";
import type { ProjectContextProps } from "~/areas/projects/context/index";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import { NotFound } from "~/components/NotFound/NotFound";
export async function liveStatusPageLoader(repository: Repository, environmentId: string, projectContext: Promise<ProjectContextProps>, tenantIdOrUntenanted: string): Promise<LoaderData> {
    const { state: { model: project }, } = await projectContext;
    const kubernetesLiveStatusResources = await repository.KubernetesLiveStatus.getForApplicationInstance(project.Id, environmentId, tenantIdOrUntenanted);
    const deploymentTargets = await repository.Machines.all({ ids: kubernetesLiveStatusResources.Resources.map((r) => r.MachineId) });
    return {
        environment: await repository.Environments.get(environmentId),
        kubernetesLiveStatusResources,
        deploymentTargets,
    };
}
interface LoaderData {
    environment: EnvironmentResource;
    kubernetesLiveStatusResources: KubernetesLiveStatusResource;
    deploymentTargets: DeploymentTargetResource[];
}
type LiveStatusPageProps = {
    loaderData: LoaderData;
    project: ProjectResource;
    tenantIdOrUntenanted: string;
};
export function LiveStatusPage(props: LiveStatusPageProps) {
    return (<FeatureToggleVisibility toggle="KubernetesLiveObjectStatusFeatureToggle" disabledContent={<NotFound />}>
            <ProjectPaperLayout title={`${props.project.Name} ${props.loaderData.environment.Name} Live Status`} fullWidth={true}>
                <LiveStatusTable kubernetesLiveStatusResources={props.loaderData.kubernetesLiveStatusResources} deploymentTargets={props.loaderData.deploymentTargets} environmentId={props.loaderData.environment.Id} projectId={props.project.Id} tenantIdOrUntenanted={props.tenantIdOrUntenanted}></LiveStatusTable>
            </ProjectPaperLayout>
        </FeatureToggleVisibility>);
}

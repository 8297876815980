import { RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import { RunConditionForAction } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { Note, ExpandableFormSection, Summary } from "~/components/form";
import { VariableLookupText } from "~/components/form/VariableLookupText";
export const ChildActionRunConditionText = {
    [RunConditionForAction.Success]: "Success: only run when previous child steps succeed",
    [RunConditionForAction.Variable]: "Variable: only run when the variable expression is true",
};
export const FirstChildActionRunConditionText = {
    ...ChildActionRunConditionText,
    [RunConditionForAction.Success]: "Success: runs when the previous child steps succeed (or is first step)",
};
export interface RunTriggerForActionExpanderProps {
    isFirstStep: boolean;
    condition: RunConditionForAction;
    variableExpression: string;
    localNames?: string[];
    variableExpressionError: string | undefined;
    onConditionChange(condition: RunConditionForAction): void;
    onVariableExpressionChange(expression: string): void;
}
const getRunConditionText = (isFirstStep: boolean, condition: RunConditionForAction) => {
    return isFirstStep ? FirstChildActionRunConditionText[condition] : ChildActionRunConditionText[condition];
};
class RunTriggerForChildActionExpander extends React.Component<RunTriggerForActionExpanderProps> {
    shouldComponentUpdate(nextProps: RunTriggerForActionExpanderProps) {
        return nextProps.condition !== this.props.condition || nextProps.variableExpression !== this.props.variableExpression || nextProps.variableExpressionError !== this.props.variableExpressionError;
    }
    render() {
        const conditions = [RunConditionForAction.Success, RunConditionForAction.Variable];
        return (<ExpandableFormSection title="Run Condition" help="Limit when this step runs by setting a condition." errorKey="runcondition" summary={this.props.condition === RunConditionForAction.Success ? Summary.default(getRunConditionText(this.props.isFirstStep, this.props.condition)) : Summary.summary(getRunConditionText(this.props.isFirstStep, this.props.condition))}>
                <RadioButtonGroup value={this.props.condition} onChange={(condition) => {
                if (condition !== RunConditionForAction.Variable) {
                    this.props.onVariableExpressionChange("");
                }
                this.props.onConditionChange(condition);
            }} accessibleName="Limit the conditions on which this step is run">
                    {conditions.map((rc) => (<RadioButton value={rc} label={getRunConditionText(this.props.isFirstStep, rc)} key={rc}/>))}
                </RadioButtonGroup>
                {this.props.condition === RunConditionForAction.Variable && (<div>
                        <VariableLookupText localNames={this.props.localNames} label="Variable expression" value={this.props.variableExpression} onChange={this.props.onVariableExpressionChange} error={this.props.variableExpressionError}/>
                        <Note>
                            Limit when this step runs by setting a boolean variable expression that will be evaluated at run-time. See the <ExternalLink href="VariableSubstitutionSyntax">Variable Substitution Syntax</ExternalLink> documentation for
                            examples.
                        </Note>
                    </div>)}
            </ExpandableFormSection>);
    }
    static displayName = "RunTriggerForChildActionExpander";
}
export default RunTriggerForChildActionExpander;

import { NavigationButtonType, NavigationButton, Tooltip } from "@octopusdeploy/design-system-components";
import { getGitRefDescription, Permission, toGitRefShort } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { PermissionCheck } from "~/components/PermissionCheck";
export type RunNowButtonProps = {
    spaceId: string;
    projectSlug: string;
    projectId: string;
    runbookId: string;
    isDisabled?: boolean;
    tooltip?: string;
    buttonType?: NavigationButtonType;
};
export function RunNowButton(props: RunNowButtonProps) {
    return (<PermissionCheck permission={Permission.RunbookRunCreate} project={props.projectId} wildcard={true}>
            <RunNowButtonInternal {...props}/>
        </PermissionCheck>);
}
export function RunNowButtonInternal({ projectSlug, isDisabled, runbookId, spaceId, tooltip, buttonType }: RunNowButtonProps) {
    if (tooltip) {
        return (<Tooltip content={tooltip}>
                <NavigationButton type={buttonType ?? NavigationButtonType.CreateRelease} label="Run..." href={links.runbookRunNowPage.generateUrl({ spaceId, projectSlug, runbookId })} disabled={isDisabled}/>
            </Tooltip>);
    }
    return <NavigationButton type={buttonType ?? NavigationButtonType.CreateRelease} label="Run..." href={links.runbookRunNowPage.generateUrl({ spaceId, projectSlug, runbookId })} disabled={isDisabled}/>;
}
export function getPermissionDeniedTooltipText(gitRef?: string) {
    if (!gitRef)
        return;
    return `You don't have permission to run Runbooks from ${getGitRefDescription(gitRef)} '${toGitRefShort(gitRef)}'. Try running from the default branch instead.`;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "@emotion/css";
import { Callout } from "@octopusdeploy/design-system-components";
import type { ArtifactResource, GitRefResource, IHaveSnapshotGitReference, InterruptionResource, ResourceCollection, TaskDetailsResource, TaskResource } from "@octopusdeploy/octopus-server-client";
import { TaskName, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { AnalyticViewDispatcher } from "~/analytics/Analytics";
import { useAnalyticViewDispatch } from "~/analytics/Analytics";
import { ProcessSnapshotSection } from "~/areas/projects/components/Releases/ProcessSnapshot/ProcessSnapshot";
import QueuedTaskDetails from "~/areas/tasks/components/Task/QueuedTaskDetails";
import { client, repository } from "~/clientInstance";
import type { DataBaseComponentState, Refresh } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import InternalLink from "~/components/Navigation/InternalLink/index";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import DateFormatter from "~/utils/DateFormatter";
import ExternalLink from "../../../../../components/Navigation/ExternalLink/ExternalLink";
import { SimplePagingList } from "../../../../../components/PagingList";
import { Section } from "../../../../../components/Section/Section";
import type { UniqueActivityElement } from "../../../../../components/TaskLogLines/TaskLogBlock";
import ArtifactLink from "../Artifacts/ArtifactLink";
import Interruption from "../Interruption/Interruption";
import { goToLogElement } from "../TaskLog/TaskLogUtil";
import TaskSummaryLine from "../TaskSummary/TaskSummaryLine";
import styles from "./style.module.less";
interface TaskSummaryComponentProps {
    task: TaskResource<any>;
    projectId?: string;
    environmentId?: string;
    tenantId?: string;
    artifacts?: ArtifactResource[];
    interruptions?: ResourceCollection<InterruptionResource>;
    activityElements?: UniqueActivityElement[];
    taskDetails?: TaskDetailsResource;
    doRefresh: Refresh;
    snapshot: IHaveSnapshotGitReference;
    stepsCorrelationIds?: {
        [key: string]: string;
    };
    gitRef?: GitRefResource;
}
class ArtifactsList extends SimplePagingList<ArtifactResource> {
}
type TaskSummaryProps = TaskSummaryComponentProps & RouteComponentProps<any>;
type TaskSummaryInternalProps = TaskSummaryComponentProps & RouteComponentProps<any> & {
    dispatchView: AnalyticViewDispatcher;
};
const RanOnSection: React.FC<{
    task: TaskResource;
}> = ({ task }) => task!.ServerNode ? (<div>
            <h4>Ran on</h4>
            {task!.ServerNode}
        </div>) : null;
RanOnSection.displayName = "RanOnSection"
const RunDetailsSection: React.FC<{
    task: TaskResource;
}> = ({ task }) => {
    const startTime = task.StartTime ? DateFormatter.dateToShortFormat(task.StartTime) : "Not started";
    const endTime = task.CompletedTime ? DateFormatter.dateToShortFormat(task.CompletedTime) : "N/A";
    const renderRow = (label: string, value: string | null) => (<div>
            <span className={styles.taskDurationLabel}>{label}:</span>
            <span>{value}</span>
        </div>);
    return (<div>
            <h4>Run Details</h4>
            <Section>
                {renderRow("Start", startTime)}
                {task.IsCompleted && renderRow("End", endTime)}
                <div>Total Duration: {task.Duration || "N/A"}</div>
            </Section>
        </div>);
};
RunDetailsSection.displayName = "RunDetailsSection"
class TaskSummaryInternal extends DataBaseComponent<TaskSummaryInternalProps, DataBaseComponentState> {
    componentDidMount() {
        this.props.dispatchView("View Task Summary", { resource: "Task Summary" });
    }
    renderInterruptions = () => {
        if (this.props.task!.IsCompleted || !this.props.interruptions || !this.props.interruptions.Items) {
            return null;
        }
        return this.props.interruptions.Items.filter((interruption) => interruption.IsPending).map((interruption) => (<div key={interruption.Id} style={{ marginBottom: "1rem" }}>
                <Interruption interruption={interruption} takeResponsibility={async () => {
                await this.doBusyTask(async () => repository.Interruptions.takeResponsibility(interruption));
                await this.props.doRefresh();
            }} submitInterruption={async (values: {
                [name: string]: any;
            }) => {
                await this.doBusyTask(async () => repository.Interruptions.submit(interruption, values));
                await this.props.doRefresh();
            }}/>
            </div>));
    };
    render() {
        const { task, taskDetails } = this.props;
        const sidebar = (<div>
                <RanOnSection task={task}/>
                <RunDetailsSection task={task}></RunDetailsSection>
                <ProcessSnapshotSection resource={this.props.snapshot}/>
                {task.SpaceId ? (<PermissionCheck spaceScope={{ spaceId: task.SpaceId }} permission={Permission.ArtifactView} project={this.props.projectId} environment={this.props.environmentId} tenant={this.props.tenantId} alternate={<Callout type={"information"} title={"Permission required"}>
                                The {Permission.ArtifactView} permission is required to view artifacts
                            </Callout>}>
                        <div>
                            <h4>Artifacts</h4>
                            <ArtifactsList items={this.props.artifacts!} pageSize={10} onRow={(a) => <ArtifactLink artifact={a}/>} empty={<span>
                                        No artifacts have been added. Learn more about <ExternalLink href="Artifacts">collecting artifacts</ExternalLink>.
                                    </span>}/>
                        </div>
                    </PermissionCheck>) : null}
            </div>);
        const showFatalsInline = this.props.activityElements!.length !== 1;
        const taskIsWaitingToStart = !(task.HasBeenPickedUpByProcessor || task.HasPendingInterruptions);
        return (<SidebarLayout sideBar={sidebar} extendContentToEdges={true} flexBasis="25rem" sidebarMaxWidth="25rem" hideTopDivider={true}>
                <Section>{this.renderInterruptions()}</Section>
                <Section className={summaryTopLevel}>
                    {task.Name === TaskName.BulkDeployment && client.spaceId && task.ProjectId ? (<Callout hideTitle={true} type={"information"}>
                            <InternalLink to={links.projectTasksPage.generateUrl({ spaceId: client.spaceId, projectSlug: task.ProjectId }, { batch: task.Arguments.BulkDeploymentId })}>View all deployments </InternalLink>
                            created by this task.
                        </Callout>) : null}
                    {this.props.activityElements!.map((e) => (<TaskSummaryLine key={e.uniqueId} element={e} artifacts={this.props.artifacts!} onLogNavigation={(elementId) => goToLogElement(this.props, elementId)} showFatalsInline={showFatalsInline} stepsCorrelationIds={this.props.stepsCorrelationIds} gitRef={this.props.gitRef}/>))}
                    {taskIsWaitingToStart && taskDetails ? <QueuedTaskDetails task={task} taskDetails={taskDetails} doBusyTask={this.doBusyTask}/> : null}
                </Section>
            </SidebarLayout>);
    }
    static displayName = "TaskSummaryInternal";
}
const summaryTopLevel = css({
    paddingTop: "1rem",
});
function TaskSummary(props: TaskSummaryProps) {
    const dispatchView = useAnalyticViewDispatch(props.projectId);
    return <TaskSummaryInternal dispatchView={dispatchView} {...props}/>;
}
export default withRouter(TaskSummary);

/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Checkbox, Callout } from "@octopusdeploy/design-system-components";
import type { BreadcrumbItem, PageAction } from "@octopusdeploy/design-system-components";
import type { AccountResource, AzureCloudServiceEndpointResource, AzureServiceFabricClusterEndpointResource, AzureWebAppEndpointResource, BasicRepository, CertificateConfigurationResource, CertificateResource, CloudRegionEndpointResource, DeploymentTargetResource, EnvironmentResource, FeedResource, KubernetesTentacleEndpointResource, KubernetesEndpointResource, ListeningTentacleEndpointResource, MachinePolicyResource, MachineResource, NewMachineResource, OfflineDropEndpointResource, PollingTentacleEndpointResource, ProxyResource, ResourceWithSlug, SshEndpointResource, StepPackageEndpointResource, TenantResource, VariablesScopedToDocumentResource, WorkerMachineResource, WorkerPoolResource, WorkerPoolsSummaryResource, } from "@octopusdeploy/octopus-server-client";
import { AgentCommunicationMode, CommunicationStyle, EndpointsHelper, Permission } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { asResourceInputs, convertFromJsonSchemaToInputSchema } from "@octopusdeploy/step-runtime-inputs";
import { cloneDeep, find } from "lodash";
import * as React from "react";
import { IsStepPackageEndpoint } from "~/areas/infrastructure/components/BaseMachineSettings/IsStepPackageEndpoint";
import { TargetDiscoveryCallout } from "~/areas/infrastructure/components/BaseMachineSettings/TargetDiscoveryCallout";
import { DeleteMachineDialogLayout } from "~/areas/infrastructure/components/MachineLayout/DeleteMachineDialogLayout";
import KubernetesNamespacePicker from "~/areas/infrastructure/components/MachineSettings/Endpoints/DefaultNamespacePicker";
import { repository } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { type MenuItem, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { UnknownStepPackageDeploymentTarget } from "~/components/StepPackageDeploymentTargetEditor/StepPackageDeploymentTarget";
import { mapRootInitialInputs } from "~/components/StepPackageEditor/Inputs/mapInitialInputs";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, FormSectionHeading, required, Select, Summary, Text, UnstructuredFormSection } from "~/components/form";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import MachinePolicySummary from "../MachinePolicySummary";
import AzureCloudServiceEndpoint from "../MachineSettings/Endpoints/AzureCloudServiceEndpoint";
import AzureServiceFabricClusterEndpoint from "../MachineSettings/Endpoints/AzureServiceFabricClusterEndpoint";
import AzureWebAppEndpoint from "../MachineSettings/Endpoints/AzureWebAppEndpoint";
import CloudRegionEndpoint from "../MachineSettings/Endpoints/CloudRegionEndpoint";
import DeprecatedEndpoint from "../MachineSettings/Endpoints/DeprecatedEndpoint";
import KubernetesEndpoint from "../MachineSettings/Endpoints/KubernetesEndpoint";
import OfflineDropEndpoint from "../MachineSettings/Endpoints/OfflineDropEndpoint";
import SshEndpoint from "../MachineSettings/Endpoints/SshEndpoint";
import StepPackageEndpoint from "../MachineSettings/Endpoints/StepPackageEndpoint";
import TentacleActiveEndpoint from "../MachineSettings/Endpoints/TentacleActiveEndpoint";
import TentaclePassiveEndpoint from "../MachineSettings/Endpoints/TentaclePassiveEndpoint";
import endpointRegistry from "../MachineSettings/Endpoints/endpointRegistry";
export interface MachineSettingsSearchParams {
    type: string;
    uri: string;
    proxyId: string;
    thumbprint: string;
    host: string;
    port: string;
}
const IsNew = "IsNew";
export interface MachineSettingsInitialData {
    tenants: TenantResource[];
    machineRoles: string[];
    environments: EnvironmentResource[];
    machine: WorkerMachineResource | DeploymentTargetResource | typeof IsNew;
    machinePolicies: MachinePolicyResource[];
    workerPools: WorkerPoolResource[];
    workerPoolSummaries: WorkerPoolsSummaryResource;
    proxies: ProxyResource[];
    globalCertificate: CertificateConfigurationResource | null;
    accounts: AccountResource[];
    communicationStyle: CommunicationStyle;
}
export const LoadMachineSettingsData = async (machine: WorkerMachineResource | DeploymentTargetResource | typeof IsNew, communicationStyle: CommunicationStyle): Promise<MachineSettingsInitialData> => {
    const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
    const machineRolesPromise = repository.MachineRoles.all();
    const environmentsPromise = repository.Environments.all();
    const workerPoolsSummariesPromise = repository.WorkerPools.summary();
    const proxiesPromise = repository.Proxies.all();
    const globalCertificatePromise = isAllowed({ permission: Permission.MachineEdit, wildcard: true }) ? repository.CertificateConfiguration.global() : Promise.resolve(null);
    const accountsPromise = repository.Accounts.all();
    const machinePoliciesPromise = repository.MachinePolicies.all();
    return {
        machine,
        communicationStyle,
        tenants: await tenantsPromise,
        machineRoles: await machineRolesPromise,
        environments: await environmentsPromise,
        machinePolicies: await machinePoliciesPromise,
        workerPools: [], // Note: WorkerPools differ between Deployment Targets and Worker Machines, so let those components retrieve their specific collections
        workerPoolSummaries: await workerPoolsSummariesPromise,
        proxies: await proxiesPromise,
        globalCertificate: await globalCertificatePromise,
        accounts: await accountsPromise,
    };
};
export interface DispatchProps<TResource extends MachineResource> {
    onMachineSaved(machine: TResource): void;
}
export interface GlobalProps<TResource extends MachineResource, TNewResource extends NewMachineResource> {
    rootLink: LinkHref;
    repository: BasicRepository<TResource, TNewResource>;
    isMultiTenancyEnabled: boolean;
    isWorkerMachine: boolean;
    isBuiltInWorkerEnabled: boolean;
    breadcrumbItems: BreadcrumbItem[];
}
export type BaseMachineSettingsProps<TResource extends MachineResource, TNewResource extends NewMachineResource> = {
    initialData: MachineSettingsInitialData;
    query: URI;
} & GlobalProps<TResource, TNewResource> & DispatchProps<TResource>;
export interface MachineSettingsState<TResource extends MachineResource, Model extends NewMachineResource> extends FormBaseComponentState<Model> {
    machine?: TResource | undefined;
    deleted: boolean;
    saved: boolean;
    accounts: AccountResource[];
    certificates: CertificateResource[] | undefined;
    feeds: FeedResource[] | undefined;
    defaultMachinePolicy: MachinePolicyResource;
    machinePolicy: MachinePolicyResource | null;
    deploymentTargetFromAStepPackage: UnknownStepPackageDeploymentTarget | undefined;
    variablesScopedToThisMachine: VariablesScopedToDocumentResource | undefined;
}
export abstract class BaseMachineSettingsLayout<TProps extends BaseMachineSettingsProps<TResource, TModel>, TResource extends MachineResource, TModel extends NewMachineResource> extends FormBaseComponent<TProps, MachineSettingsState<TResource, TModel>, TModel> {
    constructor(props: TProps) {
        super(props);
        const defaultMachinePolicy = this.props.initialData.machinePolicies.find((x) => {
            return x.IsDefault;
        });
        if (defaultMachinePolicy === undefined) {
            throw new Error("Could not locate a default machine policy");
        }
        if (this.props.initialData.machine === IsNew) {
            const newModel = this.getModel(this.props.query, defaultMachinePolicy.Id);
            this.state = {
                model: newModel as TModel,
                cleanModel: cloneDeep(newModel) as TModel,
                deleted: false,
                saved: false,
                accounts: this.props.initialData.accounts,
                certificates: undefined,
                defaultMachinePolicy,
                machinePolicy: null,
                feeds: undefined,
                deploymentTargetFromAStepPackage: undefined,
                variablesScopedToThisMachine: undefined,
            };
        }
        else {
            const machine = this.props.initialData.machine as unknown as TResource;
            this.state = {
                machine,
                model: this.mapToModel(machine) as TModel,
                cleanModel: cloneDeep(this.mapToModel(machine)) as TModel,
                deleted: false,
                saved: false,
                accounts: this.props.initialData.accounts,
                certificates: undefined,
                defaultMachinePolicy,
                machinePolicy: null,
                feeds: undefined,
                deploymentTargetFromAStepPackage: undefined,
                variablesScopedToThisMachine: undefined,
            };
        }
    }
    async componentDidMount() {
        await this.refreshFeeds();
        await this.loadStepPackage();
    }
    getMachineTypeFriendlyName(): string | undefined {
        if (this.state.model) {
            if (this.state.deploymentTargetFromAStepPackage) {
                return this.state.deploymentTargetFromAStepPackage.name;
            }
            if (this.props.initialData.machine === IsNew) {
                return;
            }
            return EndpointsHelper.getFriendlyName(this.props.initialData.machine);
        }
    }
    render() {
        const isNew = this.state.machine === undefined;
        const machineTypeFriendlyName = this.getMachineTypeFriendlyName() ?? "";
        const title = !isNew ? this.state.model && "Settings" : this.state.model && "Create " + machineTypeFriendlyName;
        const saveText: string = this.state.machine ? machineTypeFriendlyName + " details updated" : machineTypeFriendlyName + " created";
        const legacyOverflowActions: Array<MenuItem | MenuItem[]> = [];
        const machine = this.state.machine;
        if (machine !== undefined) {
            legacyOverflowActions.push(OverflowMenuItems.item(this.state.model.IsDisabled ? "Enable" : "Disable", this.handleEnabledToggle, { permission: this.enableDisablePermission(), wildcard: true }));
            legacyOverflowActions.push(OverflowMenuItems.dialogItem("Delete", <DeleteMachineDialogLayout machine={machine} machineTypeFriendlyName={machineTypeFriendlyName} repository={this.props.repository} onDelete={this.handleDeleteConfirm}/>, {
                permission: this.deletePermission(),
                wildcard: true,
            }));
            legacyOverflowActions.push([
                OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [machine.Id] }), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }
        const overflowMenu = OverflowMenuConverterVNext.convertAll(legacyOverflowActions);
        return (<LegacyForm savePermission={{ permission: isNew ? this.createPermission() : this.editPermission(), environment: "*", tenant: "*" }} model={this.state.model} cleanModel={this.state.cleanModel} saveText={saveText} onSaveClick={() => this.handleSaveClick()}>
                {({ FormContent, createSaveAction }) => (<PaperLayoutVNext primaryAction={createSaveAction({})} breadcrumbsItems={this.props.breadcrumbItems} title={title} busy={this.state.busy} errors={this.errors} pageActions={!isNew ? this.getSecondaryPageActions() : undefined} overflowActions={overflowMenu.menuItems}>
                        {overflowMenu.dialogs}
                        <FormContent expandAllOnMount={isNew}>
                            <TargetDiscoveryCallout endpoint={this.state.model.Endpoint} doBusyTask={this.doBusyTask} isNew={isNew}/>
                            {this.state.deleted && <InternalRedirect to={this.props.rootLink}/>}
                            {this.state.saved && this.state.machine && <InternalRedirect to={this.machineLink(this.state.machine.SpaceId, this.state.machine.Id)}/>}
                            {this.state.model && (!IsStepPackageEndpoint(this.state.model.Endpoint) || this.state.deploymentTargetFromAStepPackage) && (<TransitionAnimation>
                                    {this.state.cleanModel.IsDisabled && (<UnstructuredFormSection stretchContent={true}>
                                            <Callout type={"warning"} title={<span> This {machineTypeFriendlyName} is currently disabled.</span>}/>
                                        </UnstructuredFormSection>)}

                                    <ExpandableFormSection errorKey="Display name" title="Display Name" focusOnExpandAll summary={this.state.model.Name
                        ? Summary.summary(<NameSummaryWithSlug name={this.state.model.Name} slug={(this.state.model as ResourceWithSlug)?.Slug}/>)
                        : Summary.placeholder("Please enter a name for your " + machineTypeFriendlyName)} help={"A short, memorable, unique name for this " + machineTypeFriendlyName + "."}>
                                        <Text value={this.state.model.Name} onChange={(Name) => this.setModelState({ Name })} label="Display name" validate={required("Please enter a " + machineTypeFriendlyName + " name")} error={this.getFieldError("Display name")} autoFocus={true}/>
                                        {this.renderSlugEditor()}
                                    </ExpandableFormSection>

                                    {!isNew && (<ExpandableFormSection errorKey="IsDisabled" title="Enabled" summary={this.state.model.IsDisabled ? Summary.summary("No") : Summary.default("Yes")} help={"Disable this " + machineTypeFriendlyName + " to prevent it from being included in any deployments."}>
                                            <Checkbox value={!this.state.model.IsDisabled} onChange={(IsDisabled) => this.setModelState({ IsDisabled: !IsDisabled })} label="Enabled"/>
                                        </ExpandableFormSection>)}

                                    {this.renderTypeSpecificComponents()}

                                    {EndpointsHelper.hasMachinePolicy(this.props.initialData.communicationStyle) && (<ExpandableFormSection errorKey="Policy" title="Policy" summary={this.machinePolicySummary()} help={"Select the machine policy."}>
                                            <Select label="Machine policy" onChange={(x) => {
                            const policy = x ? x : this.state.defaultMachinePolicy.Id;
                            this.setModelState({ MachinePolicyId: policy });
                            this.refreshMachinePolicy(policy);
                        }} value={this.state.model.MachinePolicyId} items={this.props.initialData.machinePolicies.map((x) => ({ value: x.Id, text: x.Name }))}/>
                                            {this.state.machinePolicy && <MachinePolicySummary machinePolicy={this.state.machinePolicy} hideDescription={false} conciseView={true}/>}
                                        </ExpandableFormSection>)}

                                    {this.renderCommunicationSectionHeader()}

                                    {this.renderEndpointSpecificComponent(isNew)}

                                    {this.renderTenantComponent()}
                                </TransitionAnimation>)}
                        </FormContent>
                    </PaperLayoutVNext>)}
            </LegacyForm>);
    }
    private renderCommunicationSectionHeader() {
        /* Communication section only shown for cloud regions when there are more than one worker pool. icky.*/
        if (this.isCommunicationStyleNoneAndMoreThanOneWorker() || this.isNotCommunicationStyleNoneOrStepPackageOrKubernetesTentacle()) {
            return (<div>
                    <FormSectionHeading title="Communication"/>
                </div>);
        }
    }
    private isCommunicationStyleNoneAndMoreThanOneWorker() {
        return this.props.initialData.communicationStyle === CommunicationStyle.None && this.props.initialData.workerPools.length > 1;
    }
    private isNotCommunicationStyleNoneOrStepPackageOrKubernetesTentacle() {
        return (this.props.initialData.communicationStyle !== CommunicationStyle.None && this.props.initialData.communicationStyle !== CommunicationStyle.StepPackage && this.props.initialData.communicationStyle !== CommunicationStyle.KubernetesTentacle);
    }
    protected abstract getModel(location: URI, defaultMachinePolicyId: string): TModel;
    protected abstract mapToModel(model: TResource): TModel;
    protected abstract enableDisablePermission(): Permission;
    protected abstract createPermission(): Permission;
    protected abstract editPermission(): Permission;
    protected abstract deletePermission(): Permission;
    protected abstract machineLink(spaceId: string, machineId: string): LinkHref;
    protected abstract renderTypeSpecificComponents(): JSX.Element;
    protected abstract getSecondaryPageActions(): PageAction[];
    protected abstract renderTenantComponent(): JSX.Element | null;
    private renderSlugEditor() {
        const isNew = this.state.machine === undefined;
        return (<>
                {!isNew && (<SlugEditor value={this.state.model.Slug || ""} name={this.state.model.Name} originalSlug={this.state.cleanModel?.Slug ?? ""} onChange={(Slug) => this.setModelState({ Slug })} label="Slug" validate={required("Please enter a slug")} error={this.getFieldError("Slug")}/>)}
            </>);
    }
    private renderEndpointSpecificComponent(isNew: boolean) {
        switch (this.props.initialData.communicationStyle) {
            case CommunicationStyle.None:
                return <CloudRegionEndpoint endpoint={this.state.model.Endpoint as CloudRegionEndpointResource} workerPools={this.props.initialData.workerPools} onChange={(Endpoint) => this.setModelState({ Endpoint })}/>;
            case CommunicationStyle.TentacleActive:
                return (<TentacleActiveEndpoint endpoint={this.state.model.Endpoint as PollingTentacleEndpointResource} serverThumbprint={this.props.initialData.globalCertificate && this.props.initialData.globalCertificate.Thumbprint} onChange={(Endpoint) => this.setModelState({ Endpoint: Endpoint as PollingTentacleEndpointResource })}/>);
            case CommunicationStyle.TentaclePassive:
                return (<TentaclePassiveEndpoint endpoint={this.state.model.Endpoint as ListeningTentacleEndpointResource} serverThumbprint={this.props.initialData.globalCertificate && this.props.initialData.globalCertificate.Thumbprint} proxies={this.props.initialData.proxies} onChange={(Endpoint) => this.setModelState({ Endpoint: Endpoint as ListeningTentacleEndpointResource })}/>);
            case CommunicationStyle.KubernetesTentacle:
                const kubernetesTentacleEndpoint = this.state.model.Endpoint as KubernetesTentacleEndpointResource;
                return (<>
                        <FormSectionHeading title={`Communication (${kubernetesTentacleEndpoint.TentacleEndpointConfiguration.CommunicationMode})`}/>
                        {this.renderKubernetesTentacleEndpointConfiguration(kubernetesTentacleEndpoint)}
                    </>);
            case CommunicationStyle.Ssh:
                return (<SshEndpoint endpoint={this.state.model.Endpoint as SshEndpointResource} proxies={this.props.initialData.proxies} refreshAccounts={this.refreshAccounts} accounts={this.state.accounts} onChange={(Endpoint) => this.setModelState({ Endpoint })}/>);
            case CommunicationStyle.OfflineDrop:
                return <OfflineDropEndpoint endpoint={this.state.model.Endpoint as OfflineDropEndpointResource} onChange={(Endpoint) => this.setModelState({ Endpoint })}/>;
            case CommunicationStyle.AzureWebApp:
                return (<AzureWebAppEndpoint doBusyTask={this.doBusyTask} busy={this.state.busy || false} endpoint={this.state.model.Endpoint as AzureWebAppEndpointResource} refreshAccounts={this.refreshAccounts} accounts={this.state.accounts} workerPools={this.props.initialData.workerPools} onChange={(Endpoint) => this.setModelState({ Endpoint })} getFieldError={this.getFieldError}/>);
            case CommunicationStyle.AzureCloudService:
                return (<AzureCloudServiceEndpoint doBusyTask={this.doBusyTask} busy={this.state.busy || false} endpoint={this.state.model.Endpoint as AzureCloudServiceEndpointResource} refreshAccounts={this.refreshAccounts} accounts={this.state.accounts} workerPools={this.props.initialData.workerPools} onChange={(Endpoint) => this.setModelState({ Endpoint })} getFieldError={this.getFieldError}/>);
            case CommunicationStyle.AzureServiceFabricCluster:
                return (<AzureServiceFabricClusterEndpoint doBusyTask={this.doBusyTask} busy={this.state.busy || false} endpoint={this.state.model.Endpoint as AzureServiceFabricClusterEndpointResource} workerPools={this.props.initialData.workerPools} refreshCertificates={this.refreshCertificates} certificates={this.getCertificates} onChange={(Endpoint) => this.setModelState({ Endpoint })} getFieldError={this.getFieldError}/>);
            case CommunicationStyle.Kubernetes:
                return (<KubernetesEndpoint isBuiltInWorkerEnabled={this.props.isBuiltInWorkerEnabled} feeds={this.state.feeds || []} refreshFeeds={this.refreshFeeds} doBusyTask={this.doBusyTask} busy={this.state.busy || false} endpoint={this.state.model.Endpoint as KubernetesEndpointResource} refreshAccounts={this.refreshAccounts} accounts={this.state.accounts} workerPools={this.props.initialData.workerPools} refreshCertificates={this.refreshCertificates} certificates={this.getCertificates} onChange={(Endpoint) => this.setModelState({ Endpoint })} getFieldError={this.getFieldError} proxies={this.props.initialData.proxies}/>);
            case CommunicationStyle.StepPackage:
                return (<StepPackageEndpoint accounts={this.state.accounts} endpoint={this.state.model.Endpoint as StepPackageEndpointResource} getFieldError={this.getFieldError} isNew={isNew} onChange={(endpoint) => this.setModelState({ Endpoint: endpoint })} refreshAccounts={this.refreshAccounts} stepPackage={this.state.deploymentTargetFromAStepPackage} workerPools={this.props.initialData.workerPools}/>);
            default: {
                return <DeprecatedEndpoint />;
            }
        }
    }
    private renderKubernetesTentacleEndpointConfiguration(endpoint: KubernetesTentacleEndpointResource) {
        const endpointConfiguration = endpoint.TentacleEndpointConfiguration.CommunicationMode === AgentCommunicationMode.Polling ? (<TentacleActiveEndpoint endpoint={endpoint.TentacleEndpointConfiguration} serverThumbprint={this.props.initialData.globalCertificate && this.props.initialData.globalCertificate.Thumbprint} onChange={(TentacleEndpointConfiguration) => this.setModelState({ Endpoint: { TentacleEndpointConfiguration, ...this.state.model.Endpoint } as KubernetesTentacleEndpointResource })}/>) : (<TentaclePassiveEndpoint endpoint={endpoint.TentacleEndpointConfiguration} serverThumbprint={this.props.initialData.globalCertificate && this.props.initialData.globalCertificate.Thumbprint} proxies={this.props.initialData.proxies} onChange={(TentacleEndpointConfiguration) => this.setModelState({ Endpoint: { TentacleEndpointConfiguration, ...this.state.model.Endpoint } as KubernetesTentacleEndpointResource })}/>);
        return (<>
                {endpointConfiguration}
                {!this.props.isWorkerMachine && (<>
                        <FormSectionHeading title="Kubernetes Details"/>
                        {this.defaultNamespaceComponent(endpoint, (e) => this.setModelState({ Endpoint: e }))}
                    </>)}
            </>);
    }
    private defaultNamespaceComponent(endpoint: KubernetesTentacleEndpointResource, onChange: (newValue: KubernetesTentacleEndpointResource) => void) {
        return (<ExpandableFormSection errorKey="DefaultNamespace" title="Default Namespace" focusOnExpandAll summary={endpoint.DefaultNamespace ? Summary.summary(endpoint.DefaultNamespace) : Summary.placeholder("No default namespace specified")} help="The namespace used for Kubernetes steps if no other namespace is provided">
                <KubernetesNamespacePicker namespace={endpoint.DefaultNamespace ?? ""} onChange={(n) => {
                endpoint.DefaultNamespace = n;
                onChange(endpoint);
            }} getFieldError={this.getFieldError}/>
            </ExpandableFormSection>);
    }
    private refreshAccounts = () => {
        return this.doBusyTask(async () => {
            this.setState({ accounts: await repository.Accounts.all() });
        });
    };
    private getCertificates = async () => {
        let certificates: CertificateResource[] | undefined = this.state.certificates;
        if (!certificates) {
            certificates = await repository.Certificates.all();
            this.setState({ certificates });
        }
        return certificates;
    };
    private refreshCertificates = () => {
        return this.doBusyTask(async () => {
            this.setState({ certificates: await repository.Certificates.all() });
        });
    };
    private refreshFeeds = async () => {
        await this.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() });
        });
    };
    private loadStepPackage = async () => {
        await this.doBusyTask(async () => {
            if (!IsStepPackageEndpoint(this.state.model.Endpoint))
                return;
            const stepPackage = await endpointRegistry.getStepPackageDeploymentTarget(this.state.model.Endpoint.DeploymentTargetTypeId, this.state.model.Endpoint.StepPackageVersion);
            const initialInputs = stepPackage.ui.createInitialInputs();
            const inputSchema = convertFromJsonSchemaToInputSchema(stepPackage.inputJsonSchema, initialInputs);
            const mappedInputs = mapRootInitialInputs(inputSchema, initialInputs);
            const initialResourceInputs = asResourceInputs(inputSchema.properties, mappedInputs);
            this.setState((previous) => ({
                deploymentTargetFromAStepPackage: stepPackage,
                model: {
                    ...previous.model,
                    Endpoint: {
                        ...previous.model.Endpoint,
                        StepPackageVersion: stepPackage.version,
                        ...(IsStepPackageEndpoint(previous.model.Endpoint) && this.props.initialData.machine === "IsNew" ? { Inputs: initialResourceInputs } : {}),
                    },
                },
                cleanModel: cloneDeep({
                    ...previous.cleanModel,
                    Endpoint: {
                        ...previous.cleanModel.Endpoint,
                        StepPackageVersion: stepPackage.version,
                        ...(IsStepPackageEndpoint(previous.cleanModel.Endpoint) && this.props.initialData.machine === "IsNew" ? { Inputs: initialResourceInputs } : {}),
                    },
                }),
            }));
        });
    };
    private refreshMachinePolicy(machinePolicyId: string) {
        const machinePolicy = find(this.props.initialData.machinePolicies, (x) => {
            return x.Id === machinePolicyId;
        });
        if (!machinePolicy) {
            throw Error("Machine policy not found");
        }
        this.setState({ machinePolicy });
    }
    private machinePolicySummary() {
        const machinePolicy = this.props.initialData.machinePolicies.find((x) => x.Id === this.state.model.MachinePolicyId);
        if (machinePolicy) {
            return Summary.summary(machinePolicy.Name);
        }
        return Summary.placeholder("Unknown machine policy");
    }
    protected async handleSaveClick(onError?: (errors: Errors) => void, onSuccess?: () => void): Promise<boolean> {
        return await this.doBusyTask(async () => {
            let toSave = this.state.model;
            if (this.state.machine !== undefined) {
                toSave = {
                    ...toSave,
                    Id: this.state.machine.Id,
                    Links: this.state.machine.Links,
                };
            }
            const machine = await this.props.repository.save(toSave);
            this.props.onMachineSaved(machine);
            this.setState({
                saved: true,
                machine,
                model: this.mapToModel(machine),
                cleanModel: cloneDeep(this.mapToModel(machine)),
            });
        }, { onError, onSuccess });
    }
    private handleDeleteConfirm = () => {
        this.setState({ deleted: true });
    };
    private handleEnabledToggle = async () => {
        this.state.model.IsDisabled = !this.state.model.IsDisabled;
        await this.handleSaveClick();
    };
    static displayName = "BaseMachineSettingsLayout";
}

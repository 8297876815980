import React from "react";
import { OverviewContainer, OverviewHighlightText, TermsText } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { Note } from "~/components/form/index";
export const CommonConfigurationDeploymentFreezesOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="DeploymentFreezes">Deployment freezes</ExternalLink> allow you to prevent deployments for a configured amount of time. This is useful when you want to prevent certain projects deploying to certain environments
                    within a specific time frame.
                </p>
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"Pre-requisites"}>Projects and environments will need to be created before a deployment freeze can be created.</Note>
                <Note heading={"Scoping deployment freezes"}>
                    Deployment freezes can be scoped to projects and environments. During deployment, Octopus will block deployments for projects to a certain environment if a freeze is scoped to that project and environment.{" "}
                </Note>
                <Note heading={"Permissions"}>
                    To create and edit a deployment freeze, the DeploymentFreezeAdminister system permission is required. In addition, permissions will need to be scoped to all requested projects and environments when creating, editing or overriding
                    a deployment freeze. The DeploymentFreezeAdminister permission is included in the System Administrator, System Manager and Space Manager roles.
                </Note>
            </TermsText>
        </OverviewContainer>);
};

import { Permission } from "@octopusdeploy/octopus-server-client";
import type { AuditStreamConfigurationResource, OpenTelemetryAuditStreamConfigurationResource, SplunkAuditStreamConfigurationResource, StreamConfigurationResource, SumoLogicAuditStreamConfigurationResource, LinksCollection, } from "@octopusdeploy/octopus-server-client";
import { AuditStreamType } from "~/areas/configuration/components/AuditLayout/AuditStream/AuditStreamType";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
export function isOpenTelemetry(resource: StreamConfigurationResource | null): resource is OpenTelemetryAuditStreamConfigurationResource {
    return resource !== null && "OpenTelemetryEndpoint" in resource;
}
export function isSplunk(resource: StreamConfigurationResource | null): resource is SplunkAuditStreamConfigurationResource {
    return resource !== null && "SplunkEndpoint" in resource;
}
export function isSumoLogic(resource: StreamConfigurationResource | null): resource is SumoLogicAuditStreamConfigurationResource {
    return resource !== null && "SumoLogicEndpoint" in resource;
}
export function getAuditStreamType(resource: StreamConfigurationResource): AuditStreamType {
    if (isOpenTelemetry(resource)) {
        return AuditStreamType.OpenTelemetry;
    }
    if (isSplunk(resource)) {
        return AuditStreamType.Splunk;
    }
    if (isSumoLogic(resource)) {
        return AuditStreamType.SumoLogic;
    }
    throw new Error(`Unknown audit stream configuration: ${resource}`);
}
export function createNewAuditStreamConfiguration(id: string, active: boolean, description: string | null, streamConfigurationResource: StreamConfigurationResource | null, links: LinksCollection<{
    Self: string;
}>): AuditStreamConfigurationResource {
    return {
        Id: id,
        Active: active,
        Description: description ?? "",
        StreamConfigurationResource: streamConfigurationResource,
        Links: links,
    };
}
export function isAuditStreamAccessible(): boolean {
    return isAllowed({ permission: Permission.ConfigureServer });
}

import type { AnonymousVcsCredentials, GitCredentialResource, GitHubAppConnections, GitHubAppConnectionSummary, GitHubVcsCredentials, ReferenceVcsCredentials, UsernamePasswordVcsCredentials } from "@octopusdeploy/octopus-server-client";
import { Permission, Repository } from "@octopusdeploy/octopus-server-client";
import React, { useCallback, useState } from "react";
import { repository } from "~/clientInstance";
import { type DoBusyTask, type Errors, useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import VersionControlSettingsConnectionDetails, { GitConnectionOwner } from "./VersionControlSettingsConnectionDetails";
export interface VersionControlSettingsConnectionDetailsWithDataLoadingProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown> | boolean;
    errors?: Errors;
    getFieldError: (key: string) => string | undefined;
    spaceId: string;
    isNew: boolean;
    url: string;
    setUrl: (url: string) => void;
    credential: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials | GitHubVcsCredentials;
    setCredential: (credentials: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials | GitHubVcsCredentials) => void;
}
export function VersionControlSettingsConnectionDetailsWithDataLoading({ doBusyTask, busy, errors, getFieldError, spaceId, isNew, url, setUrl, credential, setCredential }: VersionControlSettingsConnectionDetailsWithDataLoadingProps) {
    const gitHubConnectionsAreEnabled = isFeatureToggleEnabled("GitHubConnectionsFeatureToggle");
    const [connections, setConnections] = useState<GitHubAppConnections | undefined>(undefined);
    const [gitCredentials, setGitCredentials] = useState<GitCredentialResource[] | undefined>(undefined);
    const refreshGitConnections = useDoBusyTaskEffect(doBusyTask, async () => {
        if (gitHubConnectionsAreEnabled && hasPermission(Permission.GitCredentialView)) {
            const connections = await repository.GitHubApp.getConnections(0, Repository.takeAll);
            setConnections(connections);
        }
        else {
            setConnections(undefined);
        }
    }, [gitHubConnectionsAreEnabled]);
    const refreshGitCredentials = useDoBusyTaskEffect(doBusyTask, async () => {
        if (hasPermission(Permission.GitCredentialView)) {
            // SelectWithAddRefresh does not have a filter re-search capability. Not going to break that
            // ground here, instead just get all of the credentials and show them in the list.
            const gitCredentials = await repository.GitCredentials.list({ take: Repository.takeAll });
            setGitCredentials(gitCredentials.Items);
        }
        else {
            setGitCredentials([]);
        }
    }, []);
    const getRepositoriesForConnection = useCallback(async (connection: GitHubAppConnectionSummary) => {
        return await repository.GitHubApp.getRepositoriesForConnection(connection.Id ?? "");
    }, []);
    return (<VersionControlSettingsConnectionDetails busy={busy} getFieldError={getFieldError} spaceId={spaceId} isNew={isNew} gitCredentials={gitCredentials} refreshGitCredentials={refreshGitCredentials} connections={connections} refreshGitConnections={async () => {
            await refreshGitConnections();
        }} getRepositoriesForConnection={getRepositoriesForConnection} url={url} setUrl={setUrl} credential={credential} setCredential={setCredential} gitConnectionOwner={GitConnectionOwner.Project}/>);
}

import { useAggregateAPIOperationStatus, useMutation, useQuery } from "@octopusdeploy/octopus-react-client";
import type { GitHubAppConnectionSummary, GitPersistenceSettings, PlatformHubConnectionConfigurationResource } from "@octopusdeploy/octopus-server-client";
import { IsUsingUsernamePasswordAuth, Permission, Repository } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useCallback } from "react";
import VersionControlSettingsConnectionDetails, { GitConnectionOwner } from "~/areas/projects/components/ProjectSettings/VersionControl/Connection/VersionControlSettingsConnectionDetails";
import { repository } from "~/clientInstance";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent/index";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, Note, required, Summary, Text } from "~/components/form";
interface PlatformHubConnectionInternalPageProps {
    spaceId: string;
    loaderData: PlatformHubConnectionLoaderData;
    model: PlatformHubConnectionModel;
    cleanModel: PlatformHubConnectionModel;
    setModelState: <K extends keyof PlatformHubConnectionModel>(state: Pick<PlatformHubConnectionModel, K>, callback?: () => void) => void;
    getFieldError: (name: string) => string;
    setState: (state: FormBaseComponentState<PlatformHubConnectionModel>) => void;
}
interface PlatformHubConnectionLoaderData {
    connectionConfiguration: PlatformHubConnectionConfigurationResource;
    model: PlatformHubConnectionModel;
}
export async function platformHubConnectionLoader(repository: Repository): Promise<PlatformHubConnectionLoaderData> {
    const result = await repository.PlatformHubConnectionRepository.get();
    const connectionConfiguration = {
        PersistenceSettings: {
            ...result.PersistenceSettings,
            BasePath: result.PersistenceSettings.BasePath.replace(".octopus/", ""),
        },
    };
    const platformHubConnectionModel: PlatformHubConnectionModel = {
        ...connectionConfiguration.PersistenceSettings,
    };
    return { connectionConfiguration, model: platformHubConnectionModel };
}
//using current space id temporarily while we figure the Platform Hub
function PlatformHubConnectionInternalPage({ loaderData, getFieldError, spaceId, model, cleanModel, setModelState, setState }: PlatformHubConnectionInternalPageProps) {
    const gitHubConnectionsAreEnabled = isFeatureToggleEnabled("GitHubConnectionsFeatureToggle");
    const { isInProgress, errors } = useAggregateAPIOperationStatus();
    const { result: connectionConfiguration } = useQuery(async (repository) => await repository.PlatformHubConnectionRepository.get(), [], "Get platform hub connection configuration", {
        initialResult: loaderData.connectionConfiguration,
    });
    const [isNew, setIsNew] = React.useState(!connectionConfiguration.PersistenceSettings.Url);
    const { execute: handleSave } = useMutation({
        name: "Save Platform Hub Connection Configuration",
        action: async (repository: Repository) => {
            if (IsUsingUsernamePasswordAuth(model.Credentials) && model.Credentials.Password.NewValue === null) {
                throw new Error("Password is required");
            }
            await repository.PlatformHubConnectionRepository.modify({
                PersistenceSettings: {
                    ...model,
                    BasePath: `.octopus/${model.BasePath}`,
                },
            });
        },
        afterComplete: async () => {
            setState({ model: model, cleanModel: model });
            setIsNew(false);
        },
    });
    const disabled = isInProgress;
    const { result: gitConnections, refetch: refreshGitConnections } = useQuery(async (repository) => {
        if (gitHubConnectionsAreEnabled && hasPermission(Permission.GitCredentialView)) {
            return await repository.GitHubApp.getConnections(0, Repository.takeAll);
        }
        return undefined;
    }, [gitHubConnectionsAreEnabled], "Get GitHub connections");
    const { result: gitCredentials, refetch: refreshGitCredentials } = useQuery(async (repository) => {
        if (hasPermission(Permission.GitCredentialView)) {
            // SelectWithAddRefresh does not have a filter re-search capability. Not going to break that
            // ground here, instead just get all of the credentials and show them in the list.
            return await repository.GitCredentials.list({ take: Repository.takeAll });
        }
        else {
            return undefined;
        }
    }, [], "Get GitHub credentials");
    const getRepositoriesForConnection = useCallback(async (connection: GitHubAppConnectionSummary) => {
        const scopedRepository = await repository.forSpace(spaceId);
        return await scopedRepository.GitHubApp.getRepositoriesForConnection(connection.Id ?? "");
    }, [spaceId]);
    return (<LegacyForm model={model} cleanModel={cleanModel} onSaveClick={handleSave} saveText="Connection updated">
            {({ FormContent, createSaveAction }) => (<>
                    {model && (<PaperLayoutVNext title={isNew ? "Create Git Connection" : "Version Control Settings"} primaryAction={createSaveAction({})} busy={isInProgress} errors={errors}>
                            <FormContent>
                                <TransitionAnimation>
                                    <VersionControlSettingsConnectionDetails gitConnectionOwner={GitConnectionOwner.PlatformHub} busy={isInProgress} getFieldError={getFieldError} spaceId={spaceId} isNew={isNew} gitCredentials={gitCredentials?.Items} refreshGitCredentials={async () => {
                    refreshGitCredentials();
                    return true;
                }} connections={gitConnections ?? undefined} refreshGitConnections={async () => {
                    refreshGitConnections();
                }} getRepositoriesForConnection={getRepositoriesForConnection} url={model.Url} setUrl={(Url) => setModelState({ Url })} credential={model.Credentials} setCredential={(Credentials) => setModelState({ Credentials })}/>
                                </TransitionAnimation>
                                <ExpandableFormSection errorKey="BasePath" title="Git File Storage Directory" summary={Summary.summary(`.octopus/${model.BasePath}`)} help="The directory where Octopus should store the files in the repository" isExpandedByDefault={isNew}>
                                    <Text inputProps={{
                    startAdornment: (<>
                                                    <code>.octopus</code>
                                                    &nbsp;/
                                                </>),
                }} key="BasePath" value={model.BasePath} onChange={(BasePath) => setModelState({ BasePath })} label="Git File Storage Directory" error={getFieldError("BasePath")} disabled={disabled} accessibleName={"Directory in the repository where files relating to the platform hub should be stored"}/>
                                    <Note>This controls where in your repository the files related to the platform hub will be stored.</Note>
                                    <Note>
                                        It’s recommended to connect a dedicated Git repository to the platform hub, in this case the default directory, <code>.octopus</code>, is a good option. If managing multiple configurations within the same Git
                                        repository, consider organizing them in subdirectories, e.g. <code>.octopus/platform-hub</code>.
                                    </Note>
                                </ExpandableFormSection>
                                <ExpandableFormSection errorKey="DefaultBranch" title="Branch Settings" summary={Summary.summary(<span>
                                            Default branch is <strong>{model.DefaultBranch}</strong>
                                        </span>)} help="Enter the details for your branches" isExpandedByDefault={isNew}>
                                    <Text key="DefaultBranch" value={model.DefaultBranch} onChange={(DefaultBranch) => setModelState({ DefaultBranch })} label="Default Branch" error={getFieldError("DefaultBranch")} validate={required("Enter a default branch.")} disabled={disabled} accessibleName={"Name of the default branch on the Git repository"}/>
                                </ExpandableFormSection>
                            </FormContent>
                        </PaperLayoutVNext>)}
                </>)}
        </LegacyForm>);
}
interface PlatformHubConnectionPageProps {
    spaceId: string;
    loaderData: PlatformHubConnectionLoaderData;
}
interface PlatformHubConnectionModel extends GitPersistenceSettings {
}
export class PlatformHubConnectionPage extends FormBaseComponent<PlatformHubConnectionPageProps, FormBaseComponentState<PlatformHubConnectionModel>, PlatformHubConnectionModel> {
    constructor(props: PlatformHubConnectionPageProps) {
        super(props);
        this.state = {
            model: this.props.loaderData.model,
            cleanModel: this.props.loaderData.model,
        };
    }
    render() {
        return (<PlatformHubConnectionInternalPage loaderData={this.props.loaderData} spaceId={this.props.spaceId} model={this.state.model} cleanModel={this.state.cleanModel} setModelState={this.setModelState.bind(this)} getFieldError={this.getFieldError.bind(this)} setState={this.setState.bind(this)}></PlatformHubConnectionInternalPage>);
    }
    static displayName = "PlatformHubConnectionPage";
}

import { css, cx } from "@emotion/css";
import { borderRadius, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DeploymentTargetResource, EnvironmentResource, EnvironmentsSummaryResource, ResourceCollection, TenantResource } from "@octopusdeploy/octopus-server-client";
import { CommunicationStyle, Permission, Repository, TaskRestrictedTo } from "@octopusdeploy/octopus-server-client";
import { EndpointRegistrationKeyValues, links } from "@octopusdeploy/portal-routes";
import { isEqual } from "lodash";
import type { ReactNode } from "react";
import * as React from "react";
import { orderedHealthStatuses } from "~/areas/infrastructure/InfrastructureDetails";
import type { EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import endpointRegistry from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { repository } from "~/clientInstance";
import FormPage from "~/components/FormPage/FormPage";
import { Loading } from "~/components/Loading/Loading";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { withTheme } from "~/components/Theme";
import { CardTitle, UnstructuredFormSection } from "~/components/form/Sections";
import type { TagIndex } from "~/components/tenantTagsets";
import { OctopusIcon, OctopusIconType } from "~/primitiveComponents/dataDisplay/Icon";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import type { EnvironmentSummaryFilter } from "../EnvironmentsLayout/EnvironmentSummaryFilter";
import { defaultEnvironmentSummaryFilter } from "../EnvironmentsLayout/EnvironmentSummaryFilter";
import MachineRow from "../MachineRow/MachineRow";
import type { BaseAllMachinesSummaryProps, BaseAllMachinesSummaryState, HealthStatusRecord } from "./BaseAllMachinesSummary";
import BaseAllMachinesSummary from "./BaseAllMachinesSummary";
import { createMachineHealthMap, createMachinesListRequestArgs } from "./MachineFilter";
import styles from "./style.module.less";
interface DeploymentTargetsSummarySectionProps extends BaseAllMachinesSummaryProps<EnvironmentSummaryFilter> {
    environmentsSummary: EnvironmentsSummaryResource;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    tagIndex: TagIndex;
    filter: EnvironmentSummaryFilter;
}
interface DeploymentTargetsSummarySectionInnerProps extends DeploymentTargetsSummarySectionProps {
    initialData: InitialData;
}
interface InitialData {
    machinesResponse: ResourceCollection<DeploymentTargetResource>;
    machineHealthStatusFastLookup: HealthStatusRecord<DeploymentTargetResource>;
    endpointRegistrations: EndpointRegistration[];
}
const DeploymentTargetsSummarySectionFormPage = FormPage<InitialData>();
const DeploymentTargetsSummarySection: React.FC<DeploymentTargetsSummarySectionProps> = (props: DeploymentTargetsSummarySectionProps) => {
    return (<div className={cx({ [summarySectionContainerStyles]: props.showV2Page })}>
            <DeploymentTargetsSummarySectionFormPage title={"Deployment Targets"} load={async () => {
            const machineRequestArgs = createMachinesListRequestArgs(defaultEnvironmentSummaryFilter, null, true);
            const machinesResponsePromise = repository.Machines.list(machineRequestArgs);
            const endpointRegistrationsPromise = endpointRegistry.getAllRegistrations();
            const machinesResponse = await machinesResponsePromise;
            const machineHealthStatusFastLookup = createMachineHealthMap(machinesResponse, Repository.takeDefaultPageSize);
            const endpointRegistrations = await endpointRegistrationsPromise;
            return { machinesResponse, machineHealthStatusFastLookup, endpointRegistrations };
        }} operationName="DeploymentTargetsSummary" renderWhenLoaded={(data) => <DeploymentTargetsSummarySectionInner initialData={data} {...props}/>}/>
        </div>);
};
DeploymentTargetsSummarySection.displayName = "DeploymentTargetsSummarySection"
interface DeploymentTargetsSummarySectionInnerState extends BaseAllMachinesSummaryState<DeploymentTargetResource> {
    endpointRegistrations: EndpointRegistration[];
}
interface SetFilters {
    environmentIds: Set<string>;
    roles: Set<string>;
    tenantIds: Set<string>;
    tenantTags: Set<string>;
    shellNames: Set<string>;
    deploymentTargetTypes: Set<string>;
    hideEmptyEnvironments: boolean;
    machinePartialName: string | undefined;
}
class DeploymentTargetsSummarySectionInner extends BaseAllMachinesSummary<DeploymentTargetResource, DeploymentTargetsSummarySectionInnerProps, EnvironmentSummaryFilter, DeploymentTargetsSummarySectionInnerState> {
    private requestRaceConditioner = new RequestRaceConditioner();
    constructor(props: DeploymentTargetsSummarySectionInnerProps) {
        super(props);
        this.state = {
            ...this.commonInitialState,
            machinesResponse: this.props.initialData.machinesResponse,
            machineHealthStatusFastLookup: this.props.initialData.machineHealthStatusFastLookup,
            endpointRegistrations: this.props.initialData.endpointRegistrations,
            redirectToTasks: false,
        };
    }
    componentDidMount() {
        this.reloadDataAndCurrentPageIndex();
    }
    componentDidUpdate(prevProps: BaseAllMachinesSummaryProps<EnvironmentSummaryFilter>) {
        if (!isEqual(prevProps.filter, this.props.filter)) {
            this.reloadDataAndCurrentPageIndex();
        }
    }
    protected renderTableHeader(): React.ReactNode {
        return (<thead>
                <tr>
                    <th>Deployment target</th>
                    <th>Health</th>
                    <th>Environment</th>
                    <th>Target tag</th>
                    <th>Tenant</th>
                    <th>Tenant tag set</th>
                </tr>
            </thead>);
    }
    filterMachines(machines: DeploymentTargetResource[], filter: EnvironmentSummaryFilter) {
        let filteredMachines = machines;
        if (filter.environmentIds.length > 0) {
            const environmentIdsFilterSet = new Set(filter.environmentIds);
            filteredMachines = filteredMachines.filter((machine) => machine.EnvironmentIds.some((envId) => environmentIdsFilterSet.has(envId)));
        }
        if (filter.isDisabled) {
            filteredMachines = filteredMachines.filter((machine) => machine.IsDisabled);
        }
        if (filter.roles.length > 0) {
            const rolesFilterSet = new Set(filter.roles.map((role) => role.toLowerCase()));
            filteredMachines = filteredMachines.filter((machine) => machine.Roles.some((roleName) => rolesFilterSet.has(roleName.toLowerCase())));
        }
        if (filter.machinePartialName) {
            const partialNameFilter = filter.machinePartialName;
            filteredMachines = filteredMachines.filter((machine) => machine.Name.toLocaleLowerCase().includes(partialNameFilter.toLocaleLowerCase()));
        }
        if (filter.tenantIds.length > 0) {
            const tenantIdsFilterSet = new Set(filter.tenantIds);
            filteredMachines = filteredMachines.filter((machine) => machine.TenantIds.some((tenantId) => tenantIdsFilterSet.has(tenantId)));
        }
        if (filter.tenantTags.length > 0) {
            const tenantTagsFiltersSet = new Set(filter.tenantTags);
            filteredMachines = filteredMachines.filter((machine) => machine.TenantTags.some((tenantTag) => tenantTagsFiltersSet.has(tenantTag)));
        }
        if (filter.shellNames.length > 0) {
            filteredMachines = filteredMachines.filter((machine) => filter.shellNames.includes(machine.ShellName));
        }
        if (filter.healthStatuses.length > 0) {
            filteredMachines = filteredMachines.filter((machine) => filter.healthStatuses.includes(machine.HealthStatus));
        }
        if (filter.deploymentTargetTypes.length > 0) {
            filteredMachines = filteredMachines.filter((machine) => filter.deploymentTargetTypes.includes(machine.Endpoint.CommunicationStyle) || (filter.deploymentTargetTypes.includes(EndpointRegistrationKeyValues.CloudRegion) && machine.Endpoint.CommunicationStyle === CommunicationStyle.None));
        }
        return filteredMachines;
    }
    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={links.taskPage.generateUrl({ taskId: this.state.redirectToTaskId })} push={true}/>;
        }
        const environmentsSummary = this.props.environmentsSummary;
        const filteredMachines = this.filterMachines(this.state.machinesResponse.Items, this.props.filter);
        const machineIds = new Set(filteredMachines.map((x) => x.Id));
        const totalFilteredMachines = machineIds.size;
        const machineIdsForTentacleUpgrade = environmentsSummary.MachineIdsForTentacleUpgrade ? environmentsSummary.MachineIdsForTentacleUpgrade.filter((id) => machineIds.has(id)) : [];
        const machineIdsForCalamariUpgrade = environmentsSummary.MachineIdsForCalamariUpgrade.filter((id) => machineIds.has(id));
        const machineStatusesLinks = orderedHealthStatuses.map((status) => this.renderMachineSummaryLinks(filteredMachines, status));
        const machinesDisabledLinks = this.renderMachineDisabledSummaryLinks(filteredMachines);
        const summaryComponents = [...machineStatusesLinks, machinesDisabledLinks];
        const componentKey = "allMachines";
        const overflowMenuItems = [];
        // Only show machine-related actions if they actually have some machines in this environment.
        if (totalFilteredMachines > 0) {
            overflowMenuItems.push(OverflowMenuItems.item(`Check Health for ${machineIds && totalFilteredMachines} Deployment Target${machineIds && totalFilteredMachines === 1 ? "" : "s"}`, () => this.performHealthCheck(TaskRestrictedTo.DeploymentTargets, machineIds), {
                permission: Permission.MachineEdit,
                wildcard: true,
            }));
            if (machineIdsForTentacleUpgrade && machineIdsForTentacleUpgrade.length > 0) {
                overflowMenuItems.push(OverflowMenuItems.confirmUpgrade(`Upgrade ${machineIdsForTentacleUpgrade.length} Tentacle${machineIdsForTentacleUpgrade.length === 1 ? "" : "s"}`, () => this.performTargetUpgrade(), {
                    permission: Permission.MachineEdit,
                    wildcard: true,
                }));
            }
            if (machineIdsForCalamariUpgrade && machineIdsForCalamariUpgrade.length > 0) {
                overflowMenuItems.push(OverflowMenuItems.confirmUpgrade(`Upgrade Calamari on ${machineIdsForCalamariUpgrade.length} Deployment Target${machineIdsForCalamariUpgrade.length === 1 ? "" : "s"}`, () => this.performCalamariUpgradeOnTargets(machineIdsForCalamariUpgrade), {
                    permission: Permission.MachineEdit,
                    wildcard: true,
                }));
            }
        }
        const titleContainer = (<div className={styles.cardTitleContainer}>
                <div className={styles.environmentIcon}>
                    {withTheme((theme) => (<OctopusIcon iconType={OctopusIconType.Machine} color={theme.iconDark}/>))}
                </div>
                <div className={styles.environmentName}>Deployment targets</div>
                <div className={styles.environmentMachinesCount}>({totalFilteredMachines.toLocaleString()})</div>
                <div className={styles.environmentSummaryCounts}>{summaryComponents}</div>
                <div className={styles.environmentOverflowActions}>
                    <OverflowMenu menuItems={overflowMenuItems}/>
                </div>
            </div>);
        return (<Loading key={componentKey} busy={this.state.busy} errors={this.errors}>
                <CardTitle title={titleContainer}/>
                <UnstructuredFormSection stretchContent={true}>{this.renderMachinesList(filteredMachines, machineIdsForTentacleUpgrade, machineIdsForCalamariUpgrade)}</UnstructuredFormSection>
            </Loading>);
    }
    protected async loadData() {
        const machineArgs = createMachinesListRequestArgs(this.props.filter, this.state.healthStatusFilter, this.state.isDisabledFilter);
        const registrations = endpointRegistry.getAllRegistrations();
        const machines = repository.Machines.list(machineArgs);
        const promises = Promise.all([registrations, machines]);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(promises, ([endpointRegistrations, machinesResponse]) => {
            this.setMachineResponseState(machinesResponse);
            this.setState({ endpointRegistrations });
        });
    }
    protected renderMachine(machine: DeploymentTargetResource, needsUpgrading: boolean = false): ReactNode {
        return (<MachineRow registrations={this.state.endpointRegistrations} machine={machine} environments={this.props.environments} tenants={this.props.tenants} tagIndex={this.props.tagIndex} needsUpgrading={needsUpgrading} showV2Page={this.props.showV2Page}/>);
    }
}
export default DeploymentTargetsSummarySection;
const summarySectionContainerStyles = css({
    border: `1px solid ${themeTokens.color.border.primary}`,
    borderRadius: borderRadius.medium,
});

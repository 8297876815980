import { Level2PageLayout } from "@octopusdeploy/design-system-components";
import * as React from "react";
import type { PaperLayoutProps } from "~/components/PaperLayout";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
interface InfrastructureLayoutProps {
    showV2Page?: boolean;
}
export function InfrastructureLayout({ children, showV2Page }: React.PropsWithChildren<InfrastructureLayoutProps>) {
    if (showV2Page) {
        return <>{children}</>;
    }
    return <Level2PageLayout content={children}/>;
}
export const InfrastructureLayoutBusy: React.FC<PaperLayoutProps> = (props: PaperLayoutProps) => {
    return (<InfrastructureLayout>
            <PaperLayoutVNext {...props}/>
        </InfrastructureLayout>);
};
InfrastructureLayoutBusy.displayName = "InfrastructureLayoutBusy"

import type { PageRouteDefinition, QueryParamValues, QueryParamValuesSetter, UnknownQueryParam } from "@octopusdeploy/portal-routes";
import type { ReactElement } from "react";
import type { Loader } from "~/routing/pageRegistrations/Loader";
import type { LoadingContentRender, PageRegistration } from "~/routing/pageRegistrations/PageRegistration";
import type { StandardLoaderContext } from "~/routing/pageRegistrations/StandardLoaderContext";
import type { StandardPageContext } from "~/routing/pageRegistrations/StandardPageContext";
import type { PageIdentity } from "./PageIdentity";
export function createPlatformHubPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[], LoaderData>(route: PageRouteDefinition<RouteParams, QueryParams>, config: {
    render: (parameters: {
        context: StandardPageContext;
        loaderData: LoaderData;
        routeParams: RouteParams;
        queryParams: QueryParamValues<QueryParams>;
        setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>;
    }) => ReactElement;
    pageIdentity: PageIdentity;
    loader?: Loader<LoaderData, StandardLoaderContext, RouteParams, QueryParams>;
    loadingTitle?: string;
    loadingContent?: LoadingContentRender;
}): PageRegistration<RouteParams, QueryParams, LoaderData, StandardLoaderContext, StandardPageContext> {
    return {
        route,
        render: config.render,
        pageIdentity: config.pageIdentity,
        verticalNavigationPageArea: "Blueprints",
        searchKeywords: [],
        loader: config.loader,
        loadingTitle: config.loadingTitle,
        loadingContent: config.loadingContent,
        level: 2,
    };
}

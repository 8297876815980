/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import type { BreadcrumbItem, PageAction } from "@octopusdeploy/design-system-components";
import { CommunicationStyle, Permission, EndpointsHelper } from "@octopusdeploy/octopus-server-client";
import type { CertificateConfigurationResource, ListeningTentacleEndpointResource, MachineResource, ProxyResource, SshEndpointResource, EndpointCommunicationStyle } from "@octopusdeploy/octopus-server-client";
import type { LinkHref, EndpointRegistrationKey } from "@octopusdeploy/portal-routes";
import { links, EndpointRegistrationKeyValues } from "@octopusdeploy/portal-routes";
import { cloneDeep } from "lodash";
import * as React from "react";
import { useLocation } from "react-router";
import URI from "urijs";
import LinuxCategoryDefinition from "~/areas/infrastructure/components/MachineSettings/Endpoints/LinuxCategoryDefinition";
import WindowsCategoryDefinition from "~/areas/infrastructure/components/MachineSettings/Endpoints/WindowsCategoryDefinition";
import { LinuxListeningTentacleCallout, WindowsListeningTentacleCallout } from "~/areas/infrastructure/components/TentacleCallout";
import { repository } from "~/clientInstance";
import FormBaseComponent from "~/components/FormBaseComponent";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import ServerThumbprint from "~/components/ServerThumbprint/ServerThumbprint";
import { ExpandableFormSection, required, Select, Summary, Text } from "~/components/form";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper";
import ParseHelper from "~/utils/ParseHelper";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import endpointRegistry from "../MachineSettings/Endpoints/endpointRegistry";
interface DiscoveryEndpoint {
    key: EndpointRegistrationKey;
    communicationStyle: CommunicationStyle;
    host: string;
    port: number;
    proxyId: string | undefined;
}
interface DiscoveryPageProps {
    spaceId: string;
    breadcrumbItems?: BreadcrumbItem[];
    title: string;
    machineTypeDescription: string;
    createLink: typeof links.createDeploymentTargetPage | typeof links.createWorkerMachinePage;
    discover(host: string, port: number, type: {}, proxyId: string | undefined): Promise<MachineResource>;
    environmentId?: string;
    workerPoolId?: string;
    endpointKey: EndpointRegistrationKey;
}
interface MachineDiscoveryProps extends DiscoveryPageProps {
    initialData: InitialData;
}
interface MachineDiscoveryState extends FormBaseComponentState<DiscoveryEndpoint> {
    communicationStyles: EndpointCommunicationStyle[];
    octopusVersion: string;
    shouldUseProxy: boolean;
    redirectTo?: LinkHref;
    open: boolean;
}
interface InitialData {
    proxies: ProxyResource[];
    globalCertificate: CertificateConfigurationResource;
    communicationStyle: CommunicationStyle;
    queryParams: {
        [k: string]: string;
    };
    key: EndpointRegistrationKey;
    category?: string;
}
interface DeploymentTargetDiscoveryPageProps {
    spaceId: string;
    environmentId?: string;
    endpointKey: EndpointRegistrationKey;
}
interface WorkerMachineDiscoveryPageProps {
    spaceId: string;
    workerPoolId?: string;
    endpointKey: EndpointRegistrationKey;
}
export function DeploymentTargetDiscoveryPage({ spaceId, environmentId, endpointKey }: DeploymentTargetDiscoveryPageProps) {
    const registration = endpointRegistry.getMachine(endpointKey);
    const title = `Create ${registration ? registration.name : "deployment target"}`;
    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: "New Deployment Target",
            pageUrl: environmentId ? links.newDeploymentTargetWithEnvironmentPage.generateUrl({ spaceId, environmentId }) : links.newDeploymentTargetPage.generateUrl({ spaceId }),
        },
    ];
    const discover = (host: string, port: number, type: {}, proxyId: string | undefined) => repository.Machines.discover(host, port, type, proxyId);
    return (<DiscoveryPage title={title} machineTypeDescription={"deployment target"} createLink={links.createDeploymentTargetPage} discover={discover} breadcrumbItems={breadcrumbItems} endpointKey={endpointKey} environmentId={environmentId} spaceId={spaceId}/>);
}
export function WorkerMachineDiscoveryPage({ spaceId, workerPoolId, endpointKey }: WorkerMachineDiscoveryPageProps) {
    const registration = endpointRegistry.getMachine(endpointKey);
    const title = `Create ${registration ? registration.name : "worker"}`;
    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: "New Worker",
            pageUrl: workerPoolId ? links.newWorkerMachineWithWorkerPoolPage.generateUrl({ spaceId, workerPoolId }) : links.newWorkerMachinePage.generateUrl({ spaceId }),
        },
    ];
    const discover = (host: string, port: number, type: {}, proxyId: string | undefined) => repository.Workers.discover(host, port, type, proxyId);
    return <DiscoveryPage title={title} machineTypeDescription={"worker"} createLink={links.createWorkerMachinePage} discover={discover} breadcrumbItems={breadcrumbItems} endpointKey={endpointKey} workerPoolId={workerPoolId} spaceId={spaceId}/>;
}
const DiscoveryLayoutFormPage = FormPage<InitialData>();
function DiscoveryPage(props: DiscoveryPageProps) {
    const location = useLocation();
    return (<DiscoveryLayoutFormPage title={props.title} load={async () => {
            const proxies = repository.Proxies.all();
            const globalCertificate = repository.CertificateConfiguration.global();
            const environmentId = props.environmentId;
            const workerPoolId = props.workerPoolId;
            const search = new URI(location.search).search(true);
            const key = props.endpointKey;
            const communicationStyle = endpointRegistry.getEndpoint(key).communicationStyle;
            const queryParams: {
                [k: string]: string | CommunicationStyle;
            } = {
                type: communicationStyle,
            };
            if (environmentId) {
                queryParams.environment = environmentId;
            }
            if (workerPoolId) {
                queryParams.workerPool = workerPoolId;
            }
            return {
                proxies: await proxies,
                globalCertificate: await globalCertificate,
                communicationStyle,
                queryParams,
                key,
                category: search.category,
            };
        }} renderWhenLoaded={(data) => {
            return <MachineDiscoveryLayoutInternal initialData={data} {...props}/>;
        }}/>);
}
class MachineDiscoveryLayoutInternal extends FormBaseComponent<MachineDiscoveryProps, MachineDiscoveryState, DiscoveryEndpoint> {
    private environmentId: string | null = undefined!; // if adding deploy target from the environment tab otherwise its null (env is specified via wizard)
    private workerPoolId: string | null = undefined!; // if adding deploy target from workerpool tab
    constructor(props: MachineDiscoveryProps) {
        super(props);
        const port = EndpointsHelper.getDefaultPort(props.initialData.communicationStyle);
        const model = {
            key: props.initialData.key,
            communicationStyle: props.initialData.communicationStyle,
            host: "",
            port,
            proxyId: "",
        };
        this.state = {
            communicationStyles: EndpointsHelper.communicationStyles,
            octopusVersion: repository.getServerInformation().version,
            shouldUseProxy: false,
            model,
            open: false,
            cleanModel: cloneDeep(model),
        };
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }
        const pageActions: PageAction[] = [];
        if (EndpointsHelper.canDiscover(this.state.model.communicationStyle)) {
            pageActions.push({ type: "navigate", label: "Enter details manually", path: this.props.createLink.generateUrl({ spaceId: this.props.spaceId }, this.props.initialData.queryParams), buttonType: "secondary" });
        }
        const hideActions = !(EndpointsHelper.canDiscover(this.state.model.communicationStyle) || (this.state.model.communicationStyle && !EndpointsHelper.isPollingTentacle(this.state.model.communicationStyle)));
        return (<LegacyForm model={this.state.model} cleanModel={this.state.cleanModel} disableDirtyFormChecking={true} savePermission={{ permission: Permission.MachineCreate, environment: "*", tenant: "*" }} onSaveClick={async () => this.handleNextAction()}>
                {({ FormContent, createSaveAction }) => (<PaperLayoutVNext breadcrumbsItems={this.props.breadcrumbItems} title={this.props.title} busy={this.state.busy} errors={this.errors} pageActions={hideActions ? undefined : pageActions} primaryAction={createSaveAction({ saveButtonLabel: "Next", saveButtonBusyLabel: EndpointsHelper.canDiscover(this.state.model.communicationStyle) ? "Discovering" : "Saving" })}>
                        <FormContent expandAllOnMount={true}>
                            {this.state.model.key === EndpointRegistrationKeyValues.TentaclePassive && (this.props.initialData.category === WindowsCategoryDefinition.category || this.props.initialData.category === undefined) && (<WindowsListeningTentacleCallout thumbprint={<ServerThumbprint thumbprint={this.props.initialData.globalCertificate.Thumbprint}/>}/>)}
                            {this.state.model.key === EndpointRegistrationKeyValues.TentaclePassive && this.props.initialData.category === LinuxCategoryDefinition.category && (<LinuxListeningTentacleCallout thumbprint={<ServerThumbprint thumbprint={this.props.initialData.globalCertificate.Thumbprint}/>}/>)}

                            {EndpointsHelper.canDiscover(this.state.model.communicationStyle) && (<div>
                                    <ExpandableFormSection errorKey="host" title="Hostname" summary={this.state.model.host ? Summary.summary(this.state.model.host) : Summary.placeholder("No host")} help={<span>
                                                Enter the DNS name or IP of the {this.props.machineTypeDescription} to discover, eg <code>example.com</code>, <code>10.0.1.23</code>.
                                            </span>}>
                                        <Text label="Hostname" value={this.state.model.host} onChange={(host) => this.setModelState({ host })} validate={required("Please enter a hostname")} error={this.getFieldError("host")} type="url" autoFocus={EndpointsHelper.canDiscover(this.state.model.communicationStyle)}/>
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="Port" title="Port" summary={CommonSummaryHelper.portSummary(this.state.model.port)} help="Enter the port on which the endpoint is listening">
                                        <Text label="Port" value={this.state.model.port.toString()} onChange={(x) => {
                        const portAsNumber: number = ParseHelper.safeParseInt(x, undefined);
                        if (portAsNumber) {
                            this.setModelState({ port: portAsNumber });
                        }
                    }} type="number"/>
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="proxyId" title="Proxy" summary={CommonSummaryHelper.resourceSummary(this.state.model.proxyId, this.props.initialData.proxies, "proxy")} help={"Select whether to use a proxy to connect to this " + this.props.machineTypeDescription + "."}>
                                        <BooleanRadioButtonGroup title="Connection method" onChange={(shouldUseProxy) => {
                        const discoveryEndpoint = this.state.model;
                        discoveryEndpoint.proxyId = undefined;
                        this.setState({
                            shouldUseProxy,
                            model: discoveryEndpoint,
                        });
                    }} value={this.state.shouldUseProxy}>
                                            <BooleanRadioButton value={false} label={"Connect to this " + this.props.machineTypeDescription + " directly"} isDefault={true}/>
                                            <BooleanRadioButton value={true} label={"Connect to this " + this.props.machineTypeDescription + " through a proxy server"}/>
                                        </BooleanRadioButtonGroup>
                                        {this.state.shouldUseProxy && (<Select label="Proxy" onChange={(proxyId) => {
                            if (proxyId) {
                                this.setModelState({ proxyId });
                            }
                        }} value={this.state.model.proxyId} items={this.props.initialData.proxies.map((p) => ({ value: p.Id, text: p.Name }))}/>)}
                                    </ExpandableFormSection>
                                </div>)}
                        </FormContent>
                    </PaperLayoutVNext>)}
            </LegacyForm>);
    }
    private async handleNextAction() {
        return this.doBusyTask(async () => {
            const queryParams: {
                [k: string]: string | number | CommunicationStyle;
            } = {
                type: this.state.model.communicationStyle,
            };
            if (this.environmentId) {
                queryParams.environment = this.environmentId;
            }
            if (this.workerPoolId) {
                queryParams.workerPool = this.workerPoolId;
            }
            queryParams["type"] = this.state.model.communicationStyle;
            const endpoint = this.state.model;
            if (EndpointsHelper.canDiscover(endpoint.communicationStyle)) {
                const discoveredMachine = await this.props.discover(endpoint.host, endpoint.port, endpoint.communicationStyle, endpoint.proxyId);
                if (discoveredMachine.Endpoint.CommunicationStyle === CommunicationStyle.TentaclePassive) {
                    const tentacleEndpoint = discoveredMachine.Endpoint as ListeningTentacleEndpointResource;
                    queryParams["uri"] = tentacleEndpoint.Uri;
                    queryParams["thumbprint"] = tentacleEndpoint.Thumbprint;
                    if (tentacleEndpoint.ProxyId) {
                        queryParams["proxyId"] = tentacleEndpoint.ProxyId;
                    }
                }
                else if (discoveredMachine.Endpoint.CommunicationStyle === CommunicationStyle.Ssh) {
                    const sshEndpoint = discoveredMachine.Endpoint as SshEndpointResource;
                    queryParams["uri"] = sshEndpoint.Uri;
                    queryParams["host"] = sshEndpoint.Host;
                    queryParams["port"] = sshEndpoint.Port;
                    queryParams["hostKeyAlgorithm"] = sshEndpoint.HostKeyAlgorithm;
                    queryParams["fingerprint"] = sshEndpoint.Fingerprint;
                    if (sshEndpoint.ProxyId) {
                        queryParams["proxyId"] = sshEndpoint.ProxyId;
                    }
                }
            }
            this.setState({
                redirectTo: this.props.createLink.generateUrl({ spaceId: this.props.spaceId }, queryParams),
            });
        });
    }
    static displayName = "MachineDiscoveryLayoutInternal";
}

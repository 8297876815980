import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { GitRefResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission, canCommitTo } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import { repository } from "~/clientInstance";
import type { GetCommitButtonProps } from "./CommitButton";
import { GetCommitButton } from "./CommitButton";
import type { CommitMessageWithDetails } from "./CommitMessageWithDetails";
export function useCommitButton(isGit: boolean, project: ProjectResource, gitRef: GitRefResource | undefined, resourceName: string, onSaveToNewBranch: (newBranch: GitRefResource) => Promise<void>): [
    ((action: {
        isDisabled: boolean;
        onClick: () => Promise<boolean>;
    }) => PrimaryPageAction) | undefined,
    CommitMessageWithDetails
] {
    const emptyCommitMessage = { summary: "", details: "" };
    const [commitMessage, setCommitMessage] = useState<CommitMessageWithDetails>(emptyCommitMessage);
    if (isGit) {
        const commitAction = GetGitSavePrimaryAction(project, gitRef, resourceName, onSaveToNewBranch, commitMessage, setCommitMessage);
        return [commitAction, commitMessage];
    }
    return [undefined, emptyCommitMessage];
}
function GetGitSavePrimaryAction(project: ProjectResource, gitRef: GitRefResource | undefined, resourceName: string, onSaveToNewBranch: (newBranch: GitRefResource) => Promise<void>, commitMessage: CommitMessageWithDetails, setCommitMessage: (commitMessage: CommitMessageWithDetails) => void): (action: {
    isDisabled: boolean;
    onClick: () => Promise<boolean>;
}) => PrimaryPageAction {
    function getCreateNewBranchAction(gitRef: GitRefResource) {
        return async (branchName: string) => {
            const branch = await repository.ProjectBranches.createBranch(project.Id, branchName, gitRef?.Name);
            onSaveToNewBranch(branch);
        };
    }
    return ({ isDisabled, onClick }) => {
        const commitButtonProps: GetCommitButtonProps = {
            hasPermission: { permission: Permission.ProjectEdit, project: project.Id },
            gitRef: gitRef?.CanonicalName,
            persistenceSettings: project.PersistenceSettings,
            canCommitToGitRef: canCommitTo(gitRef),
            defaultCommitMessage: `Update ${resourceName}`,
            commitMessage: commitMessage,
            updateCommitMessage: setCommitMessage,
            commitMessageAccessibleName: `Commit message for saving ${resourceName}`,
            commitDetailsAccessibleName: `Commit details for saving ${resourceName}`,
            commitButtonAccessibleName: `Commit changes to ${resourceName}`,
            onNewBranchCreating: gitRef ? getCreateNewBranchAction(gitRef) : undefined,
            label: "Commit",
            busyLabel: "Committing",
            onClick: () => onClick(),
            disabled: isDisabled,
        };
        return {
            type: "custom",
            content: <GetCommitButton {...commitButtonProps}/>,
            key: "Get Commit",
        };
    };
}

import { useCallback, useEffect } from "react";
import { client, repository } from "~/clientInstance";
import type { ProjectStatusAction } from "./actions";
import { projectStatusActions } from "./actions";
import type { ProjectStatusState } from "./reducer";
export type ProjectStatus = {
    state: ProjectStatusState;
    dispatch: React.Dispatch<ProjectStatusAction>;
};
export function useProjectStatus(spaceId: string, projectSlug?: string, dispatch?: React.Dispatch<ProjectStatusAction>, dismissed?: boolean): void {
    const getProjectStatus = useCallback(async (projectSlug: string, spaceId: string) => {
        if (!dispatch)
            return;
        const projectStatusFromServer = await repository.Projects.getProjectStatus(projectSlug, spaceId);
        dispatch({ type: projectStatusActions.serverStateLoaded, serverState: projectStatusFromServer });
    }, [dispatch]);
    useEffect(() => {
        if (!spaceId || !projectSlug || dismissed)
            return;
        return client.subscribe((event) => {
            if (event.type === "EnvironmentCreated" || event.type === "DeploymentProcessModified" || event.type === "RunbookProcessModified" || event.type === "ReleaseProgressionModified" || event.type === "ProjectModified") {
                getProjectStatus(projectSlug, spaceId);
            }
        });
    }, [dismissed, getProjectStatus, projectSlug, spaceId]);
    useEffect(() => {
        const fetchProjectStatus = async (projectSlug?: string, spaceId?: string) => {
            if (!spaceId)
                return;
            if (!dispatch)
                return;
            if (!projectSlug) {
                dispatch({ type: projectStatusActions.statusRequestedBeforeProjectExists });
                return;
            }
            await getProjectStatus(projectSlug, spaceId);
        };
        fetchProjectStatus(projectSlug, spaceId);
    }, [dispatch, getProjectStatus, projectSlug, spaceId]);
}

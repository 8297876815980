import { Snackbar } from "@octopusdeploy/design-system-components";
import type { GetDynamicEnvironmentsOverviewActiveProjectFilter, DynamicEnvironmentOverview } from "@octopusdeploy/octopus-server-client";
import { GetDynamicEnvironmentsOverviewRequestV1SortField, GetDynamicEnvironmentsOverviewRequestV1SortOrder } from "@octopusdeploy/octopus-server-client";
import { exhaustiveCheck } from "@octopusdeploy/step-runtime-inputs";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import { InfrastructureLayout } from "../InfrastructureLayout/InfrastructureLayout";
import type { DynamicEnvironmentsOverviewFilter, SortOptions } from "./DynamicEnvironmentsOverview";
import { defaultDynamicEnvironmentsOverviewFilter, DynamicEnvironmentsOverview } from "./DynamicEnvironmentsOverview";
const Title = "Dynamic Environments";
const defaultTake = 10;
function getSortField(sortOption: SortOptions): GetDynamicEnvironmentsOverviewRequestV1SortField {
    switch (sortOption) {
        case "latestActivityAsc":
        case "latestActivityDesc":
            return GetDynamicEnvironmentsOverviewRequestV1SortField.LatestActivity;
        case "activeProjectCountAsc":
        case "activeProjectCountDesc":
            return GetDynamicEnvironmentsOverviewRequestV1SortField.ActiveProjectCount;
        case "environmentNameAsc":
        case "environmentNameDesc":
            return GetDynamicEnvironmentsOverviewRequestV1SortField.EnvironmentName;
        default:
            exhaustiveCheck(sortOption, "Unhandled sort option");
    }
}
function getSortOrder(sortOption: SortOptions): GetDynamicEnvironmentsOverviewRequestV1SortOrder {
    switch (sortOption) {
        case "activeProjectCountAsc":
        case "latestActivityAsc":
        case "environmentNameAsc":
            return GetDynamicEnvironmentsOverviewRequestV1SortOrder.Ascending;
        case "activeProjectCountDesc":
        case "latestActivityDesc":
        case "environmentNameDesc":
            return GetDynamicEnvironmentsOverviewRequestV1SortOrder.Descending;
        default:
            exhaustiveCheck(sortOption, "Unhandled sort option");
    }
}
type DynamicEnvironmentsLayoutInternalProps = {
    spaceId: string;
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    errors: Errors | undefined;
};
function DynamicEnvironmentsLayoutInternal({ spaceId, doBusyTask, busy, errors }: DynamicEnvironmentsLayoutInternalProps) {
    const [dynamicEnvironments, setDynamicEnvironments] = useState<DynamicEnvironmentOverview[] | undefined>(undefined);
    const [totalCount, setTotalCount] = useState<number | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<SortOptions>("latestActivityDesc");
    const [filter, setFilter] = useState<DynamicEnvironmentsOverviewFilter>(defaultDynamicEnvironmentsOverviewFilter);
    const [take, setTake] = useState<number>(defaultTake);
    const { search } = useLocation();
    const [deleteEnvironmentSnackbarOpen, setDeleteEnvironmentSnackbarOpen] = useState(new URLSearchParams(search).get("environmentDeleted") === "true");
    const refresh = useDoBusyTaskEffect(doBusyTask, async () => {
        await refreshOverview();
    }, [spaceId, sortOrder, filter, take]);
    useRefreshLoop(refresh, 6000);
    async function refreshOverview() {
        let activeProjectsFilter: GetDynamicEnvironmentsOverviewActiveProjectFilter | undefined = undefined;
        // The filter supports selecting multiple options as this makes it easier
        // for the user to visualise the options. The api call only supports a single
        // option however as the behaviour is mutually exclusive. If both happen to be
        // selected then that really means no filtering, same as if none are selected.
        if (filter.activeProjects.length === 1) {
            if (filter.activeProjects.includes("WithActiveProjects"))
                activeProjectsFilter = "WithActiveProjects";
            else if (filter.activeProjects.includes("WithoutActiveProjects"))
                activeProjectsFilter = "WithoutActiveProjects";
        }
        const latestOverview = await repository.DynamicEnvironments.getDynamicEnvironmentsOverview({
            spaceId,
            skip: 0,
            take: take,
            sortField: getSortField(sortOrder),
            sortOrder: getSortOrder(sortOrder),
            name: filter.name,
            state: filter.state,
            activeProjects: activeProjectsFilter,
        });
        setDynamicEnvironments(latestOverview.DynamicEnvironmentOverviews);
        setTotalCount(latestOverview.TotalCount);
    }
    function onLoadMore() {
        setTake(take + defaultTake);
    }
    async function onDeleteEnvironment(environmentId: string) {
        await repository.DynamicEnvironments.del(environmentId);
        await refresh();
        setDeleteEnvironmentSnackbarOpen(true);
    }
    async function onDeprovisionEnvironment(environmentId: string) {
        await repository.DynamicEnvironments.deprovision(environmentId);
        await refresh();
    }
    return (<InfrastructureLayout>
            <PaperLayoutVNext title={Title} busy={busy}>
                {dynamicEnvironments && (<DynamicEnvironmentsOverview spaceId={spaceId} dynamicEnvironments={dynamicEnvironments} busy={busy} sortOrder={sortOrder} totalCount={totalCount} errors={errors} filter={filter} onDeleteEnvironment={onDeleteEnvironment} onDeprovisionEnvironment={onDeprovisionEnvironment} onLoadMore={onLoadMore} onSortOrderChange={setSortOrder} onFilterChange={setFilter}/>)}
            </PaperLayoutVNext>
            <Snackbar open={deleteEnvironmentSnackbarOpen} autoHideDuration={3000} content="Environment deleted" onClose={() => setDeleteEnvironmentSnackbarOpen(false)} textAlign="center"/>
        </InfrastructureLayout>);
}
type DynamicEnvironmentsPageProps = {
    spaceId: string;
};
export class DynamicEnvironmentsPage extends DataBaseComponent<DynamicEnvironmentsPageProps> {
    constructor(props: DynamicEnvironmentsPageProps) {
        super(props);
        this.state = {};
    }
    render() {
        return <DynamicEnvironmentsLayoutInternal spaceId={this.props.spaceId} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "DynamicEnvironmentsPage";
}

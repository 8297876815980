import { ActionButton, ActionButtonType, NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import { ProcessType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { processScopedEditPermission } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useProcessQueryStringContext } from "~/areas/projects/components/Process/Contexts/ProcessQueryString/ProcessQueryStringContext";
import { isRunbookProcessIdentifier } from "~/areas/projects/components/Process/types";
import { useProjectContext } from "~/areas/projects/context/index";
import { PermissionCheck } from "~/components/PermissionCheck/index";
interface AddBlueprintStepButtonProps {
    projectId: string;
}
export function AddDeploymentBlueprintStepButton({ projectId }: AddBlueprintStepButtonProps) {
    const processQueryStringContext = useProcessQueryStringContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectId);
    return (<PermissionCheck permission={processScopedEditPermission(ProcessType.Deployment)} project={projectId} wildcard={true}>
            <ActionButton type={ActionButtonType.Secondary} label="Add Blueprint" onClick={() => {
            processQueryStringContext.actions.addBlueprintStep();
            dispatchAction("Add Blueprint Step", { resource: "Deployment Process", action: Action.Add });
        }}/>
        </PermissionCheck>);
}
export function AddRunbookBlueprintStepButton({ projectId }: AddBlueprintStepButtonProps) {
    const processContext = useProcessContext();
    const processIdentifier = processContext.state.processIdentifier;
    const projectContext = useProjectContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectId);
    if (!isRunbookProcessIdentifier(processIdentifier)) {
        return null;
    }
    const routeValues = {
        spaceId: processIdentifier.spaceId,
        projectSlug: projectContext.state.model.Slug,
        processId: processIdentifier.id,
        runbookId: processIdentifier.runbookId,
    };
    const href = links.projectRunbookProcessStepsPage.generateUrl(routeValues, { new: true, actionType: "Octopus.Blueprint" });
    const logAction = () => dispatchAction("Add Blueprint Step", { resource: "Runbook Process", action: Action.Add });
    return (<PermissionCheck permission={processScopedEditPermission(ProcessType.Runbook)} project={projectId} wildcard={true}>
            <NavigationButton label={"Add Blueprint"} href={href} type={NavigationButtonType.Secondary} onClick={logAction}/>
        </PermissionCheck>);
}

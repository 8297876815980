import type { initialServerState } from "./reducer";
const basicActionTypes = {
    environmentsAdded: "environments_added",
    environmentsSkipped: "environments_skipped",
    k8sClusterAdded: "k8s_cluster_added",
    k8sClusterSkipped: "k8s_cluster_skipped",
    guidedSetupDismissed: "guided_setup_dismissed",
    statusRequestedBeforeProjectExists: "status_requested_before_project_exists",
    guidedSetupResumed: "guided_setup_resumed",
} as const;
export const projectStatusActions = {
    ...basicActionTypes,
    projectInitiated: "project_initiated",
    newProjectFormSubmitted: "new_project_form_submitted",
    serverStateChanged: "server_state_changed",
    serverStateLoaded: "server_state_loaded",
} as const;
// simple actions with no payload
type BasicAction = {
    // this is a union of all the values in basicActionTypes
    type: (typeof basicActionTypes)[keyof typeof basicActionTypes];
};
type ProjectInitiatedAction = {
    type: typeof projectStatusActions.projectInitiated;
    hasEnvironments: boolean;
};
type NewProjectFormSubmittedAction = {
    type: typeof projectStatusActions.newProjectFormSubmitted;
    intendsToUseK8s: boolean;
    intendsToUseConfigAsCode: boolean;
    k8sTargetAlreadyExistsInSpace: boolean;
};
export type ServerStateChangedAction = {
    type: typeof projectStatusActions.serverStateChanged;
    key: keyof typeof initialServerState;
    value: boolean;
};
export type ServerStateLoadedAction = {
    type: typeof projectStatusActions.serverStateLoaded;
    serverState: typeof initialServerState;
};
export type ProjectStatusAction = BasicAction | ProjectInitiatedAction | NewProjectFormSubmittedAction | ServerStateChangedAction | ServerStateLoadedAction;

import React, { useReducer } from "react";
import { Route, Switch } from "react-router-dom";
import { projectStatusReducer, initialProjectStatusState } from "~/areas/projects/components/ProjectStatus/reducer";
import { PortalGlobalLayout } from "~/components/GlobalLayout/PortalGlobalLayout";
import RootRoutes from "~/components/RootRoutes/index";
import SpaceLoader from "~/components/SpaceLoader/SpaceLoader";
import { allLegacyRedirectRoutes } from "~/routing/allLegacyRedirectRoutes";
export function AuthenticatedRoutes({ path }: {
    path: string;
}) {
    const [projectStatusState, projectStatusDispatch] = useReducer(projectStatusReducer, initialProjectStatusState);
    return (<Switch>
            {/*We try to match legacy redirects first, because: */}
            {/*- They might partially match against other route segments and we don't want to interleave them with route segments because this adds more complexity. */}
            {/*- If there's ambiguity between a non-legacy route and a legacy route, we prefer the legacy route. For example:*/}
            {/*    - /projects/new vs /projects/{projectSlug} where {projectSlug} is 'new'. If we preferred the non-legacy route, then we would never match against the legacy route, so why even define it?  */}
            {allLegacyRedirectRoutes()}
            <Route path={path} render={() => (<SpaceLoader render={(spaceContext) => (<PortalGlobalLayout spaceContext={spaceContext} projectStatus={{ state: projectStatusState, dispatch: projectStatusDispatch }}>
                                <RootRoutes spaceContext={spaceContext} projectStatus={{ state: projectStatusState, dispatch: projectStatusDispatch }}/>
                            </PortalGlobalLayout>)}/>)}></Route>
        </Switch>);
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import * as React from "react";
import { RoleChip } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import { createAddNewTargetTagEvent } from "~/components/MultiSelect/TargetTagMultiSelect.analytics";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import type { FocusableComponent } from "../VirtualListWithKeyboard/FocusableComponent";
interface TargetTagMultiSelectProps extends FormFieldProps<string[]> {
    items: string[];
    canAdd?: boolean;
    openOnFocus?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    empty?: React.ReactNode;
    error?: string;
    hideFloatingLabel?: boolean;
    accessibleName?: string;
    validate?(value: string[]): string;
    analyticsArea?: string;
    multiSelectRef?(component: FocusableComponent | null): void;
}
const TargetTagTypedMultiSelect = MultiSelect<SelectItem>();
export const TargetTagMultiSelect: React.FC<TargetTagMultiSelectProps> = (props) => {
    const trackEvent = useTrackEvent();
    const onAdd = (value: string) => {
        props.onChange!(props.value!.concat(value));
        // TODO: should we track role names?
        trackEvent(createAddNewTargetTagEvent(props.analyticsArea));
    };
    const chipRenderer = (r: SelectItem, onRequestDelete: () => void) => {
        return <RoleChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${r.Name}`} role={r.Name} showContextualHelp/>;
    };
    const addNewTemplate = (text: string) => (<div>
            <div>
                Press <code>Enter</code> to create "{text}"
            </div>
        </div>);
    return (<TargetTagTypedMultiSelect items={props.items.map((i) => ({ Id: i, Name: i }))} fieldName="target tags" label={props.label} renderChip={chipRenderer} onNew={props.canAdd ? onAdd : null!} value={props.value} onChange={props.onChange} error={props.error} helperText={props.helperText} openOnFocus={props.openOnFocus} autoFocus={props.autoFocus} hideFloatingLabel={props.hideFloatingLabel} addNewTemplate={addNewTemplate} accessibleName={props.accessibleName} empty={props.empty} multiSelectRef={props.multiSelectRef} addNewOnBlur={true} disabled={props.disabled}/>);
};
TargetTagMultiSelect.displayName = "TargetTagMultiSelect"
const dividerStyles = css({
    borderBottom: `1px solid ${themeTokens.color.border.primary}`,
    position: "relative",
    bottom: "-1.25rem",
});

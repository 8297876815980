import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { k8sIconStyles } from "~/areas/projects/components/Observability/K8ResourceIcons/k8sIconStyles";
export function PodSecurityPolicyIcon() {
    return (<svg className={k8sIconStyles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.4827 4L4.39307 7.50531L16.4827 11.0128L20.0747 9.97037C21.7163 8.44812 23.9025 7.50531 26.2963 7.50531C26.8775 7.50531 27.4444 7.56676 27.9967 7.67202L28.5724 7.50531L16.4827 4Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.39307 8.84789V21.7111L14.4556 27.2836V23.3337C14.4556 22.1376 14.9428 21.1537 15.6847 20.3574L15.7132 12.2154L4.39307 8.84789Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18.5277 11.8353L17.2519 12.2154L17.2632 15.0983C17.473 13.919 17.9094 12.8169 18.5277 11.8353ZM26.2963 15.2406C25.4557 15.2406 24.8471 15.8492 24.8471 16.6898V18.1369H27.7433V16.6898C27.7433 15.8493 27.1368 15.2406 26.2963 15.2406Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M26.3043 9.43399C22.6377 9.43399 19.6599 12.4119 19.6599 16.0785V20.0652C18.1985 20.0652 17.0021 21.2617 17.0021 22.723V33.3542C17.0021 34.8155 18.1985 36 19.6599 36H32.9488C34.4102 36 35.6066 34.8155 35.6066 33.3542V22.723C35.6066 21.2617 34.4102 20.0652 32.9488 20.0652V16.0785C32.9488 12.4119 29.9709 9.43399 26.3043 9.43399ZM26.3043 12.0918C28.5096 12.0918 30.291 13.8732 30.291 16.0785V20.0652H22.3176V16.0785C22.3176 13.8732 24.0991 12.0918 26.3043 12.0918Z" fill={themeTokens.color.icon.tertiary}/>
        </svg>);
}

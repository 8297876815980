import { Checkbox } from "@octopusdeploy/design-system-components";
import type { ProjectSettingsMetadata, ExtensionSettingsValues, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { cloneDeep } from "lodash";
import * as React from "react";
import { useRouteMatch } from "react-router-dom";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent/index";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { ExpandableFormSection, FormSectionHeading, Note, Summary } from "~/components/form/index";
import Select from "~/primitiveComponents/form/Select/Select";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface ITSMProvidersSettingsState extends OptionalFormBaseComponentState<ExtensionSettingsValues[]> {
    project: ProjectResource | null;
    metaData: ProjectSettingsMetadata[];
}
interface ServiceNowEnvironmentSettings {
    ExtensionId: "servicenow-integration";
    Values: {
        ServiceNowChangeControlled: boolean;
        ServiceNowConnectionId: string;
        AutomaticStateTransitionTarget: string;
        StandardChangeTemplateName: string;
    };
}
interface JiraServiceManagementSettings {
    ExtensionId: "jiraservicemanagement-integration";
    Values: {
        JsmChangeControlled: boolean;
        JsmConnectionId: string;
        ServiceDeskProjectName: string;
    };
}
type MatchProps = {
    match: NonNullable<ReturnType<typeof useRouteMatch>> | undefined;
};
type ITSMProvidersSettingsInternalProps = MatchProps & WithProjectContextInjectedProps;
class InternalITSMProvidersSettings extends FormBaseComponent<ITSMProvidersSettingsInternalProps, ITSMProvidersSettingsState, ExtensionSettingsValues[]> {
    constructor(props: ITSMProvidersSettingsInternalProps) {
        super(props);
        this.state = {
            project: null,
            metaData: [],
        };
    }
    private async saveProject(project: ProjectResource) {
        const result = await repository.Projects.save(project);
        await this.props.projectContext.actions.onProjectUpdated(result, this.props.projectContext.state.gitRef);
        this.setState(() => {
            return {
                model: result.ExtensionSettings,
                cleanModel: cloneDeep(result.ExtensionSettings),
                project: result,
            };
        });
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const { model: project } = this.props.projectContext.state;
            const metaData = await repository.Projects.getMetadata(project);
            const extensionSettings = project?.ExtensionSettings && project?.ExtensionSettings.length > 0 ? project?.ExtensionSettings : this.defaultSettings();
            this.setState({
                project: project,
                metaData: metaData,
                model: extensionSettings,
                cleanModel: cloneDeep(extensionSettings),
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    defaultSettings = () => {
        const snowDefaultSettings: ServiceNowEnvironmentSettings = {
            ExtensionId: "servicenow-integration",
            Values: {
                ServiceNowChangeControlled: false,
                ServiceNowConnectionId: "",
                AutomaticStateTransitionTarget: "None",
                StandardChangeTemplateName: "",
            },
        };
        const jsmDefaultSettings: JiraServiceManagementSettings = {
            ExtensionId: "jiraservicemanagement-integration",
            Values: {
                JsmChangeControlled: false,
                JsmConnectionId: "",
                ServiceDeskProjectName: "",
            },
        };
        const defaultSettings: ExtensionSettingsValues[] = [snowDefaultSettings, jsmDefaultSettings];
        return defaultSettings;
    };
    render() {
        return (<LegacyForm model={this.state.model} cleanModel={this.state.cleanModel} savePermission={this.editPermission()} onSaveClick={this.handleSaveClick} saveText="ITSM provider settings updated" forceDisableFormSaveButton={false}>
                {({ FormContent, createSaveAction }) => (<PaperLayoutVNext primaryAction={createSaveAction({})} title="ITSM Providers Settings" busy={this.state.busy} errors={this.errors}>
                        <FormContent hideExpandAll={false}>
                            {this.state.metaData === undefined ? (<></>) : (<ExtensionSettings metadata={this.state.metaData} extensionSettings={this.state.model ?? []} refreshModel={() => {
                        this.setState({
                            model: this.state.model,
                        });
                    }}/>)}
                        </FormContent>
                    </PaperLayoutVNext>)}
            </LegacyForm>);
    }
    handleSaveClick = async () => {
        const model = this.state.model;
        if (!model || !this.state.project) {
            throw "no model loaded";
        }
        const project: ProjectResource = {
            ...this.state.project,
            ExtensionSettings: cloneDeep(model),
        };
        await this.doBusyTask(async () => {
            await this.saveProject(project);
        });
    };
    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectEdit,
            project: this.state.project?.Id,
            tenant: "*",
        };
    }
    static displayName = "InternalITSMProvidersSettings";
}
function isServiceNowEnvironmentSettings(extensionSettings: ExtensionSettingsValues): extensionSettings is ServiceNowEnvironmentSettings {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (extensionSettings as ServiceNowEnvironmentSettings).ExtensionId === "servicenow-integration";
}
function isJiraServiceManagementSettings(extensionSettings: ExtensionSettingsValues): extensionSettings is JiraServiceManagementSettings {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (extensionSettings as JiraServiceManagementSettings).ExtensionId === "jiraservicemanagement-integration";
}
const ExtensionSettings: React.FC<{
    metadata: ProjectSettingsMetadata[];
    extensionSettings: ExtensionSettingsValues[];
    refreshModel: () => void;
}> = (props: {
    metadata: ProjectSettingsMetadata[];
    extensionSettings: ExtensionSettingsValues[];
    refreshModel: () => void;
}) => {
    return (<>
            {props.extensionSettings?.map((extensionSettings) => {
            const isSnowSettings = isServiceNowEnvironmentSettings(extensionSettings);
            const metaData = props.metadata.filter((m) => m.ExtensionId === extensionSettings.ExtensionId)[0];
            if (isSnowSettings && metaData !== undefined) {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const serviceNowSettings = extensionSettings as ServiceNowEnvironmentSettings;
                return <ServiceNowSettingsMenu serviceNowEnvironmentSettings={serviceNowSettings} refreshModel={props.refreshModel} metadata={props.metadata.filter((m) => m.ExtensionId === extensionSettings.ExtensionId)[0]}/>;
            }
            const isJsmSettings = isJiraServiceManagementSettings(extensionSettings);
            const jsmMetaData = props.metadata.filter((m) => m.ExtensionId === extensionSettings.ExtensionId)[0];
            if (isJsmSettings && jsmMetaData !== undefined) {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const JsmSettings = extensionSettings as JiraServiceManagementSettings;
                return <JsmSettingsMenu JsmEnvironmentSettings={JsmSettings} refreshModel={props.refreshModel} metadata={props.metadata.filter((m) => m.ExtensionId === extensionSettings.ExtensionId)[0]}/>;
            }
        })}
        </>);
};
ExtensionSettings.displayName = "ExtensionSettings"
interface ServiceNowSettingsMenuProps {
    serviceNowEnvironmentSettings: ServiceNowEnvironmentSettings;
    refreshModel: () => void;
    metadata: ProjectSettingsMetadata;
}
const ServiceNowSettingsMenu: React.FunctionComponent<ServiceNowSettingsMenuProps> = (props: ServiceNowSettingsMenuProps) => {
    const snowMetadata = props.metadata.Metadata.Types.filter((t) => t.Name === "ServiceNow Integration")[0];
    const connectionIdOptions = snowMetadata.Properties.filter((p) => p.Name === "ServiceNowConnectionId")[0].DisplayInfo.Options.Values;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const record = connectionIdOptions as Record<string, string>;
    const serviceNowConnections = Object.keys(record).map((p) => ({ value: p, text: record[p] }));
    const automaticTransitionOptions = snowMetadata.Properties.filter((p) => p.Name === "AutomaticStateTransitionTarget")[0].DisplayInfo.Options.Values;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const automaticTransitionOptionsRecord = automaticTransitionOptions as Record<string, string>;
    const automaticTransitionItems = Object.keys(automaticTransitionOptionsRecord).map((p) => ({
        value: p,
        text: automaticTransitionOptionsRecord[p],
    }));
    const connectionId = props.serviceNowEnvironmentSettings.Values.ServiceNowConnectionId;
    return (<div key={props.serviceNowEnvironmentSettings.ExtensionId}>
            <FormSectionHeading title={"ServiceNow Integration"}/>

            <ExpandableFormSection title="Change Controlled" summary={Summary.summary(<span>{props.serviceNowEnvironmentSettings.Values.ServiceNowChangeControlled ? "Yes" : "No"}</span>)} help="If enabled, deployments from this project to change controlled environments will create or require a change request and wait for approval before deployments can continue." errorKey="Change Controlled">
                <Checkbox value={props.serviceNowEnvironmentSettings.Values.ServiceNowChangeControlled} label="Change Controlled" onChange={(val) => {
            props.serviceNowEnvironmentSettings.Values.ServiceNowChangeControlled = val;
            props.refreshModel();
        }}/>
                <Note>Define which environments are change controlled in each environment’s settings.</Note>
            </ExpandableFormSection>
            {props.serviceNowEnvironmentSettings.Values.ServiceNowChangeControlled && (<>
                    <ExpandableFormSection title="ServiceNow Connection" summary={Summary.summary(<span>{serviceNowConnections.find((i) => i.value === connectionId)?.text ?? connectionId}</span>)} help="Select the ServiceNow instance where Change Requests for this project will live." errorKey="ServiceNow Connection">
                        <Select items={serviceNowConnections} allowClear={false} value={connectionId} label="ServiceNow Connection" onChange={(val) => {
                props.serviceNowEnvironmentSettings.Values.ServiceNowConnectionId = val ? val : "";
                props.refreshModel();
            }}/>
                    </ExpandableFormSection>

                    <ExpandableFormSection title="Standard Change Template Name" summary={Summary.summary(<span>{props.serviceNowEnvironmentSettings.Values.StandardChangeTemplateName}</span>)} help="The name of a standard change template which exists in ServiceNow's Standard Change Catalogue" errorKey="Standard Change Template Name">
                        <DebounceText id={"standardChangeTemplateName"} label="Standard Change Template Name" value={props.serviceNowEnvironmentSettings.Values.StandardChangeTemplateName} onChange={(val: string) => {
                props.serviceNowEnvironmentSettings.Values.StandardChangeTemplateName = val ? val : "";
                props.refreshModel();
            }}/>
                        <Note>If provided, deployments will create a standard change based on the provided template, otherwise a normal change will be created.</Note>
                    </ExpandableFormSection>

                    <ExpandableFormSection title="Automatic Transition" summary={Summary.summary(<span>{props.serviceNowEnvironmentSettings.Values.AutomaticStateTransitionTarget}</span>)} help="Standard changes created by Octopus Deploy for this project will be automatically transitioned through to the desired state, a normal change will be created when selecting None" errorKey="Automatic Transition">
                        <Select items={automaticTransitionItems} allowClear={false} value={props.serviceNowEnvironmentSettings.Values.AutomaticStateTransitionTarget} label="Automatic Transition" onChange={(val) => {
                props.serviceNowEnvironmentSettings.Values.AutomaticStateTransitionTarget = val ? val : "None";
                props.refreshModel();
            }}/>
                        <Note>
                            Automatic transition allows a continuous deployment (CD) workflow where a standard change is used to record the details of a deployment, after the deployment has completed the standard change will be moved to the desired
                            state
                        </Note>
                    </ExpandableFormSection>
                </>)}
        </div>);
};
ServiceNowSettingsMenu.displayName = "ServiceNowSettingsMenu"
interface JsmSettingsMenuProps {
    JsmEnvironmentSettings: JiraServiceManagementSettings;
    refreshModel: () => void;
    metadata: ProjectSettingsMetadata;
}
const JsmSettingsMenu: React.FunctionComponent<JsmSettingsMenuProps> = (props: JsmSettingsMenuProps) => {
    const jsmMetadata = props.metadata.Metadata.Types.filter((t) => t.Name === "Jira Service Management Integration")[0];
    const connectionIdOptions = jsmMetadata.Properties.filter((p) => p.Name === "JsmConnectionId")[0].DisplayInfo.Options.Values;
    const connectionId = props.JsmEnvironmentSettings.Values.JsmConnectionId;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const record = connectionIdOptions as Record<string, string>;
    const JsmConnections = Object.keys(record).map((p) => ({ value: p, text: record[p] }));
    return (<div key={props.JsmEnvironmentSettings.ExtensionId}>
            <FormSectionHeading title={"Jira Service Management Integration"}/>

            <ExpandableFormSection title="Change Controlled" summary={Summary.summary(<span>{props.JsmEnvironmentSettings.Values.JsmChangeControlled ? "Yes" : "No"}</span>)} help="If enabled, deployments from this project to change controlled environments will create or require a change request and wait for approval before deployments can continue." errorKey="JSM Change Controlled">
                <Checkbox value={props.JsmEnvironmentSettings.Values.JsmChangeControlled} label="Change Controlled" onChange={(val) => {
            props.JsmEnvironmentSettings.Values.JsmChangeControlled = val;
            props.refreshModel();
        }}/>
                <Note>Define which environments are change controlled in each environment’s settings.</Note>
            </ExpandableFormSection>
            {props.JsmEnvironmentSettings.Values.JsmChangeControlled && (<>
                    <ExpandableFormSection title="Jira Service Management Connection" summary={Summary.summary(<span>{JsmConnections.find((i) => i.value === connectionId)?.text ?? connectionId}</span>)} help="Select the Jira Service Management instance where Change Requests for this project will live." errorKey="JSM Connection">
                        <Select items={JsmConnections} allowClear={false} value={connectionId} onChange={(val) => {
                props.JsmEnvironmentSettings.Values.JsmConnectionId = val ? val : "";
                props.refreshModel();
            }} label={"Jira Service Management Connection"}/>
                    </ExpandableFormSection>

                    <ExpandableFormSection title="Jira Service Management Service Desk Project" summary={Summary.summary(<span>{props.JsmEnvironmentSettings.Values.ServiceDeskProjectName}</span>)} help="Jira Service Management Service Desk Project" errorKey="Jira Service Management Service Desk Project">
                        <DebounceText id={"JsmServiceDeskProject"} value={props.JsmEnvironmentSettings.Values.ServiceDeskProjectName} onChange={(val: string) => {
                props.JsmEnvironmentSettings.Values.ServiceDeskProjectName = val ? val : "";
                props.refreshModel();
            }} label={"Jira Service Management Service Desk Project"}/>
                    </ExpandableFormSection>
                </>)}
        </div>);
};
JsmSettingsMenu.displayName = "JsmSettingsMenu"
export function ITSMProvidersSettingsPage() {
    const match = useRouteMatch() ?? undefined;
    const projectContext = useProjectContext();
    return <InternalITSMProvidersSettings match={match} projectContext={projectContext}/>;
}

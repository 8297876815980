import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { k8sIconStyles } from "~/areas/projects/components/Observability/K8ResourceIcons/k8sIconStyles";
export function EndpointSliceIcon() {
    return (<svg className={k8sIconStyles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 25.917H12.5809V31.9408H4V25.917Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.7096 25.917H24.2904V31.9408H15.7096V25.917Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M27.4191 25.917H36V31.9408H27.4191V25.917Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.2017 8.05933H26.7983V14.0831H13.2017V8.05933Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M16.232 16.8293L14.8728 15.4693L8.80179 21.5368L10.161 22.8969L16.232 16.8293Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M7.71593 24.0136L9.25246 18.1841L13.5327 22.4295L7.71593 24.0136Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M22.4758 16.8293L23.8351 15.4693L29.9061 21.5368L28.5468 22.8968L22.4758 16.8293Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M30.9919 24.0136L29.4554 18.184L25.1751 22.4294L30.9919 24.0136Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M18.7893 15.9826L20.7121 15.9918L20.6587 22.626L18.7359 22.6168L18.7893 15.9826Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M19.6589 25.1387L22.7283 19.95L16.7 19.8862L19.6589 25.1387Z" fill={themeTokens.color.icon.tertiary}/>
        </svg>);
}

/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Divider } from "@octopusdeploy/design-system-components";
import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, EnvironmentsSummaryResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { EndpointsHelper, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { isEqual } from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action as AnalyticAction, useAnalyticActionDispatch } from "~/analytics/Analytics";
import type { EnvironmentSummaryQuery } from "~/areas/infrastructure/components/EnvironmentsLayout/EnvironmentSummaryQuery";
import type { EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import endpointRegistry from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { useOnClearMachineDispatch } from "~/areas/infrastructure/hooks/useOnClearMachineDispatch";
import { repository } from "~/clientInstance";
import type { FilterSection } from "~/components/AdvancedFilterLayout";
import AdvancedFilterLayout, { AdvancedFilterCheckbox } from "~/components/AdvancedFilterLayout";
import { AdvancedFilterTextInput } from "~/components/AdvancedFilterLayout/Text/AdvancedFilterTextInput";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import type { DialogControls } from "~/components/Dialog/DialogTrigger";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import { Feature, FeatureToggle } from "~/components/FeatureToggle";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import FormPage from "~/components/FormPage/FormPage";
import DeploymentTargetTypeMultiSelect from "~/components/MultiSelect/DeploymentTargetTypeMultiselect";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import { MachineModelHealthStatusMultiSelect } from "~/components/MultiSelect/MachineModelHealthStatusMultiSelect";
import { ShellNameMultiSelect } from "~/components/MultiSelect/ShellNameMultiSelect";
import { TargetTagMultiSelect } from "~/components/MultiSelect/TargetTagMultiSelect";
import { TenantMultiSelect } from "~/components/MultiSelect/TenantMultiSelect";
import { TenantTagMultiSelect } from "~/components/MultiSelect/TenantTagMultiSelect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import NumberedPagingBar from "~/components/PagingBaseComponent/NumberedPagingBar";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import ExpansionButtons from "~/components/form/Sections/ExpansionButtons";
import type { TagIndex } from "~/components/tenantTagsets";
import * as tenantTagsets from "~/components/tenantTagsets";
import MachineHealthStatusHelper from "~/utils/MachineHealthStatusHelper";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import PermissionCheck, { isAllowed } from "../../../../components/PermissionCheck/PermissionCheck";
import InfrastructureLayout from "../InfrastructureLayout";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
import ConfirmTentacleUpgradePanel from "../MachinesLayout/ConfirmTentacleUpgradePanel";
import { AddEnvironmentsDialog } from "./AddEnvironmentsDialog";
import type { EnvironmentSummaryFilter } from "./EnvironmentSummaryFilter";
import { createEnvironmentSummaryArgs, defaultEnvironmentSummaryFilter, environmentSummaryFilterToQuery, environmentSummaryQueryToFilter } from "./EnvironmentSummaryFilter";
import EnvironmentSummarySection from "./EnvironmentSummarySection";
import EnvironmentsSorter from "./EnvironmentsSorter";
import Onboarding from "./Onboarding";
export interface EnvironmentsRouteParams {
    ids: string;
}
type SimplifiedRouteProps<T extends {
    [K in keyof T]?: string;
}> = Omit<RouteComponentProps<T>, "match">;
interface EnvironmentsPageProps {
    spaceId: string;
}
interface EnvironmentsPageInternalProps extends SimplifiedRouteProps<EnvironmentsRouteParams> {
    initialData: InitialData;
    onClearMachine(): void;
    dispatchAction: AnalyticActionDispatcher;
    spaceId: string;
    addEnvironmentDialogControls: DialogControls;
}
interface EnvironmentsPageInternalState extends DataBaseComponentState {
    environmentsSummary: EnvironmentsSummaryResource;
    filter: EnvironmentSummaryFilter;
    queryFilter?: EnvironmentSummaryFilter;
    isSearching?: boolean;
    redirectToTasks: boolean;
    currentPageIndex: number;
    redirectToEnvironmentId?: string;
}
interface InitialData {
    environmentsSummary: EnvironmentsSummaryResource;
    environments: EnvironmentResource[];
    machineRoles: string[];
    tenants: TenantResource[];
    tagIndex: TagIndex;
    machineShellNames: string[];
    endpointRegistrations: EndpointRegistration[];
}
const PageSize = 20;
const Title = "Environments";
const EnvironmentQueryStringFilters = QueryStringFilters.For<EnvironmentSummaryFilter, EnvironmentSummaryQuery>();
const EnvironmentFormPage = FormPage<InitialData>();
export function EnvironmentsPage({ spaceId }: EnvironmentsPageProps) {
    const history = useHistory();
    const location = useLocation();
    const dispatchAction = useAnalyticActionDispatch();
    const onClearMachine = useOnClearMachineDispatch();
    const addEnvironmentDialogControls = useDialogTrigger();
    return (<EnvironmentFormPage title={Title} load={async () => {
            const tagIndex = await tenantTagsets.getTagIndex();
            const args = createEnvironmentSummaryArgs(defaultEnvironmentSummaryFilter, tagIndex);
            const environmentsSummaryPromise = repository.Environments.summary(args);
            const environmentsPromise = repository.Environments.all();
            const machineRolesPromise = repository.MachineRoles.all();
            const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
            const machineShellNamesPromise = repository.MachineShells.all();
            const [environmentsSummary, environments, machineRoles, tenants, machineShellNames, endpointRegistrations] = await Promise.all([
                environmentsSummaryPromise,
                environmentsPromise,
                machineRolesPromise,
                tenantsPromise,
                machineShellNamesPromise,
                endpointRegistry.getAllRegistrations(),
            ]);
            return { environmentsSummary, environments, machineRoles, tenants, tagIndex, machineShellNames, endpointRegistrations };
        }} renderWhenLoaded={(data) => (<EnvironmentsPageInternal initialData={data} location={location} history={history} dispatchAction={dispatchAction} onClearMachine={onClearMachine} spaceId={spaceId} addEnvironmentDialogControls={addEnvironmentDialogControls}/>)} renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args}/>}/>);
}
class FilterLayout extends AdvancedFilterLayout<EnvironmentSummaryFilter> {
}
class EnvironmentsPageInternal extends DataBaseComponent<EnvironmentsPageInternalProps, EnvironmentsPageInternalState> {
    private machineHealthStatuses = MachineHealthStatusHelper.getMachineModelHealthStatusResources();
    private communicationStyles = EndpointsHelper.getCommunicationStyleResources();
    private requestRaceConditioner = new RequestRaceConditioner();
    constructor(props: EnvironmentsPageInternalProps) {
        super(props);
        this.state = {
            filter: defaultEnvironmentSummaryFilter,
            isSearching: false,
            currentPageIndex: 0,
            environmentsSummary: props.initialData.environmentsSummary,
            redirectToTasks: false,
        };
    }
    async componentDidMount() {
        this.props.onClearMachine();
    }
    render() {
        if (this.state.redirectToTasks) {
            return <InternalRedirect to={links.tasksPage.generateUrl()} push={true}/>;
        }
        if (this.state.redirectToEnvironmentId) {
            return <InternalRedirect to={links.infrastructureEnvironmentPage.generateUrl({ spaceId: this.props.spaceId, environmentId: this.state.redirectToEnvironmentId })} push={true}/>;
        }
        const addDeploymentTargetPageAction: PageAction = {
            type: "navigate",
            label: "Add Deployment Target",
            path: links.newDeploymentTargetPage.generateUrl({ spaceId: this.props.spaceId }),
            buttonType: "secondary",
            hasPermissions: isAllowed({
                permission: Permission.MachineCreate,
                environment: "*",
                tenant: "*",
            }),
        };
        const addEnvironmentPageAction: PrimaryPageAction = {
            type: "button",
            label: "Add Environment",
            hasPermissions: isAllowed({
                permission: Permission.EnvironmentCreate,
                environment: "*",
            }),
            onClick: () => {
                this.props.dispatchAction("Add Environment", { resource: "Environment", action: AnalyticAction.Add });
                this.props.addEnvironmentDialogControls.openDialog();
            },
        };
        const hasEnvironments = (environmentsSummary: EnvironmentsSummaryResource): boolean => environmentsSummary && environmentsSummary.EnvironmentSummaries.length > 0;
        const hasAnyEnvironments = hasEnvironments(this.props.initialData.environmentsSummary) || hasEnvironments(this.state.environmentsSummary);
        const legacyOverflowMenuReorderItem = OverflowMenuItems.dialogItem("Reorder", <EnvironmentsSorter saveDone={this.refreshEnvironmentSummaryData}/>, { permission: Permission.EnvironmentEdit, environment: "*" });
        const overflowMenu = OverflowMenuConverterVNext.convertAll([legacyOverflowMenuReorderItem]);
        const environmentSummaries = this.state.environmentsSummary && this.state.environmentsSummary.EnvironmentSummaries;
        const hasAvailableEnvironments = this.props.initialData.environments && this.props.initialData.environments.length > 0;
        const start = this.state.currentPageIndex * PageSize;
        const end = start + PageSize;
        const environmentExpanders = environmentSummaries.slice(start, end).map((environmentsSummary) => {
            return (<React.Fragment key={environmentsSummary.Environment.Id}>
                    <Divider />
                    <EnvironmentSummarySection endpointRegistrations={this.props.initialData.endpointRegistrations} environmentSummary={environmentsSummary} filter={this.state.filter} isFiltering={this.isFiltering()} tenants={this.props.initialData.tenants} tagIndex={this.props.initialData.tagIndex}/>
                </React.Fragment>);
        });
        const filterSections: FilterSection[] = [
            {
                render: (<div>
                        <AdvancedFilterCheckbox label="Disabled only" value={this.state.filter.isDisabled === true} onChange={(x) => {
                        this.setFilterState({ isDisabled: x ? x : undefined }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                        <AdvancedFilterCheckbox label="Hide empty environments" value={this.state.filter.hideEmptyEnvironments} onChange={(x) => {
                        this.setFilterState({ hideEmptyEnvironments: x }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                        <AdvancedFilterTextInput fieldName={"deployment target"} value={this.state.filter.machinePartialName || ""} onChange={(x) => {
                        this.setFilterState({ machinePartialName: x }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                        <EnvironmentMultiSelect environments={this.props.initialData.environments} value={this.state.filter.environmentIds} onChange={(x) => {
                        this.setFilterState({ environmentIds: x }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                        <TargetTagMultiSelect items={this.props.initialData.machineRoles ? this.props.initialData.machineRoles : []} value={this.state.filter.roles} onChange={(x) => {
                        this.setFilterState({ roles: x }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                        <MachineModelHealthStatusMultiSelect items={this.machineHealthStatuses} value={this.state.filter.healthStatuses} onChange={(x) => {
                        this.setFilterState({ healthStatuses: x }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                        <DeploymentTargetTypeMultiSelect key="filterDeploymentTargetType" items={this.props.initialData.endpointRegistrations ?? []} value={this.state.filter.deploymentTargetTypes} onChange={(x) => {
                        this.setFilterState({ deploymentTargetTypes: x }, this.onFilterChange);
                    }}/>
                        <FeatureToggle feature={Feature.MultiTenancy}>
                            <PermissionCheck permission={Permission.TenantView} tenant="*">
                                <TenantMultiSelect value={this.state.filter.tenantIds} items={this.props.initialData.tenants} onChange={(x) => {
                        this.setFilterState({ tenantIds: x }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                                <TenantTagMultiSelect value={this.state.filter.tenantTags} doBusyTask={this.doBusyTask} onChange={(x) => {
                        this.setFilterState({ tenantTags: x }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                            </PermissionCheck>
                        </FeatureToggle>
                        <ShellNameMultiSelect items={this.props.initialData.machineShellNames ? this.props.initialData.machineShellNames : []} value={this.state.filter.shellNames} onChange={(x) => {
                        this.setFilterState({ shellNames: x }, this.onFilterChange);
                    }}/>
                    </div>),
            },
        ];
        const tentacleUpgradesRequiredWarning = environmentSummaries.some((x) => x.TentacleUpgradesRequired === true) && (<ConfirmTentacleUpgradePanel doBusyTask={this.doBusyTask} onTentacleUpgradeComplete={() => this.setState({ redirectToTasks: true })}/>);
        return (<InfrastructureLayout {...this.props}>
                <EnvironmentQueryStringFilters filter={this.state.filter} getQuery={environmentSummaryFilterToQuery} getFilter={environmentSummaryQueryToFilter} onFilterChange={(filter) => this.setState({ filter, queryFilter: filter }, () => this.onFilterChange())}/>
                <PaperLayoutVNext busy={this.state.busy} errors={this.errors} title={Title} primaryAction={addEnvironmentPageAction} pageActions={hasAvailableEnvironments ? [addDeploymentTargetPageAction] : undefined} overflowActions={hasAvailableEnvironments ? overflowMenu.menuItems : undefined}>
                    {overflowMenu.dialogs}
                    <Dialog open={this.props.addEnvironmentDialogControls.isOpen}>
                        <AddEnvironmentsDialog saveDone={async (env) => {
                this.setState({ redirectToEnvironmentId: env.Id });
                this.props.addEnvironmentDialogControls.closeDialog();
            }}/>
                    </Dialog>
                    {!hasAnyEnvironments && <Onboarding />}
                    {hasAnyEnvironments && (<div>
                            {tentacleUpgradesRequiredWarning}
                            <FilterLayout filterSections={filterSections} filter={this.state.filter} queryFilter={this.state.queryFilter} defaultFilter={defaultEnvironmentSummaryFilter} initiallyShowFilter={this.isFiltering()} additionalHeaderFilters={[
                    <FilterSearchBox placeholder={"Search environments..."} value={this.state.filter.partialName} onChange={(x) => {
                            this.setFilterState({ partialName: x }, () => {
                                this.onFilterChange();
                            });
                        }} autoFocus={true}/>,
                ]} onFilterReset={(filter: EnvironmentSummaryFilter) => {
                    this.setState({ filter }, () => {
                        this.onFilterChange();
                        const location = { ...this.props.history, search: "" };
                        this.props.history.replace(location);
                    });
                }} renderContent={() => (<div>
                                        <ExpansionButtons />
                                        {environmentExpanders}
                                        <NumberedPagingBar currentPageIndex={this.state.currentPageIndex} totalItems={this.state.environmentsSummary.EnvironmentSummaries.length} pageSize={PageSize} onPageSelected={(_, currentPageIndex) => this.setState({ currentPageIndex })}/>
                                    </div>)}/>
                        </div>)}
                </PaperLayoutVNext>
            </InfrastructureLayout>);
    }
    private setFilterState<K extends keyof EnvironmentSummaryFilter>(state: Pick<EnvironmentSummaryFilter, K>, callback?: () => void) {
        this.setState((prev) => ({
            filter: { ...(prev.filter as object), ...(state as object) },
        }), callback);
    }
    private isFiltering(): boolean {
        return !isEqual(this.state.filter, defaultEnvironmentSummaryFilter);
    }
    private onFilterChange() {
        this.setState({ isSearching: true }, async () => {
            await this.refreshEnvironmentSummaryData();
            this.setState({ isSearching: false });
        });
    }
    private refreshEnvironmentSummaryData = async () => {
        await this.doBusyTask(() => this.loadEnvironmentSummaries());
    };
    private async loadEnvironmentSummaries() {
        const args = createEnvironmentSummaryArgs(this.state.filter, this.props.initialData.tagIndex);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.Environments.summary(args), (response) => {
            this.setState({
                environmentsSummary: response as EnvironmentsSummaryResource,
                currentPageIndex: 0,
            });
        });
    }
    static displayName = "EnvironmentsPageInternal";
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { css, cx } from "@emotion/css";
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { PageHeaderPrimary } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
import BusyFromPromise from "~/components/BusyFromPromise";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
export const TasksPage = () => {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    const scriptConsoleAction: PrimaryPageAction = {
        type: "navigate",
        label: "Script console",
        path: links.scriptConsolePage.generateUrl(),
        hasPermissions: isAllowed({
            permission: [Permission.AdministerSystem, Permission.TaskCreate],
            wildcard: true,
        }),
    };
    return (<main>
            <Tasks renderLayout={({ busy, errors, hasLoadedOnce, children }) => (<BusyFromPromise promise={busy}>
                        {(busy: boolean) => (<>
                                <PageHeaderPrimary title="Tasks" primaryAction={scriptConsoleAction}/>
                                <div className={cx(containerStyles, { [containerMobileStyles]: !isLargerThanIpad })}>
                                    <PaperLayoutVNext busy={busy} errors={errors} fullWidth={true}>
                                        {children}
                                    </PaperLayoutVNext>
                                </div>
                            </>)}
                    </BusyFromPromise>)}/>
        </main>);
};
const containerStyles = css({
    margin: `${space[16]} ${space[32]} 0`,
});
const containerMobileStyles = css({
    margin: `${space[16]} ${space[16]} 0`,
});

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { space, themeTokens, text } from "@octopusdeploy/design-system-tokens";
import type { KeyboardEventHandler } from "react";
import { useCallback, useEffect } from "react";
import * as React from "react";
interface FocusListItemProps {
    primaryText: React.ReactNode;
    secondaryText?: React.ReactNode;
    isFocused: boolean;
    onRequestClose: (event?: React.MouseEvent<{}, MouseEvent>) => void;
    accessibleRole?: string;
    as?: React.ElementType;
}
export function FocusListItem({ accessibleRole, as: Component = "li", onRequestClose, secondaryText, primaryText, isFocused }: FocusListItemProps) {
    const elementRef = React.useRef<HTMLElement>();
    useEffect(() => {
        if (isFocused) {
            elementRef.current?.focus();
        }
    }, [elementRef, isFocused]);
    const handleKeyUp: KeyboardEventHandler = useCallback((event) => {
        if (event.key === "Enter" && isFocused) {
            onRequestClose();
        }
    }, [isFocused, onRequestClose]);
    return (<Component ref={elementRef} role={accessibleRole} tabIndex={0} className={listItemStyles} onClick={onRequestClose} onKeyUp={handleKeyUp}>
            <FocusListItemContent primaryText={primaryText} secondaryText={secondaryText}/>
        </Component>);
}
interface ListItemContentProps {
    primaryText: React.ReactNode;
    secondaryText?: React.ReactNode;
}
const listItemStyles = css({
    backgroundColor: themeTokens.color.menuList.background.default,
    display: "block",
    padding: "0",
    "&:hover": {
        backgroundColor: themeTokens.color.menuList.background.hover,
    },
    "&:focus-visible": {
        outline: "none",
        backgroundColor: themeTokens.color.menuList.background.hover,
    },
});
function FocusListItemContent({ primaryText, secondaryText }: ListItemContentProps) {
    return (<div className={focusListItemContentStyles}>
            <div className={primaryTextStyles}>{primaryText}</div>
            {secondaryText && <div className={secondaryTextStyles}>{secondaryText}</div>}
        </div>);
}
const focusListItemContentStyles = css({
    padding: `${space[16]} ${space[12]}`,
    overflowWrap: "break-word",
    cursor: "pointer",
});
const primaryTextStyles = css({
    color: themeTokens.color.menuList.text.primary,
    font: text.regular.default.medium,
    lineHeight: "1.5rem",
});
const secondaryTextStyles = css({
    color: themeTokens.color.menuList.text.secondary,
    font: text.regular.default.medium,
    lineHeight: "1rem",
});

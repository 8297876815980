import * as React from "react";
import { CommonConfigurationDeploymentFreezesOverview } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonConfigurationDeploymentFreezesViews";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registry/pageHelpRegistry";
import pageIds from "~/pageIds";
export const ConfigurationDeploymentFreezesRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.deploymentFreezes.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationDeploymentFreezesOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};

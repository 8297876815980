import React from "react";
import type { PaperLayoutVNextProps } from "~/components/PaperLayout/PaperLayoutVNext";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { ProjectPageTitleAccessory } from "./ProjectPageTitleAccessory";
export function ProjectPaperLayout(props: PaperLayoutVNextProps) {
    if (!props.titleAccessory) {
        props = { ...props, titleAccessory: <ProjectPageTitleAccessory /> };
    }
    return <PaperLayoutVNext {...props}/>;
}

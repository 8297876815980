import { css } from "@emotion/css";
import { ActionButton, ActionButtonType, Dialog } from "@octopusdeploy/design-system-components";
import { space, text } from "@octopusdeploy/design-system-tokens";
import { useInlineStatusQuery } from "@octopusdeploy/octopus-react-client";
import { HostingEnvironment } from "@octopusdeploy/octopus-server-client";
import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { useOctopusSessionUser } from "@octopusdeploy/session";
import React from "react";
import { CloseButton } from "~/components/Button";
import useLocalStorage from "~/hooks/useLocalStorage";
import introIllustration from "./VerticalNavigationIntroIllustration.svg";
export function VerticalNavigationIntroDialog() {
    const [isDialogDismissed, setIsDialogDismissed] = useIntroDialogDismissed();
    const trackEvent = useTrackEvent();
    const { result: license } = useInlineStatusQuery((repository) => {
        return repository.Licenses.getCurrentStatus();
    }, [], "License status");
    const dismissDialog = () => {
        trackEvent(createDialogDismissedAnalyticsEvent());
        setIsDialogDismissed(true);
    };
    if (license === null || license?.HostingEnvironment === HostingEnvironment.OctopusCloud) {
        return null;
    }
    return (<Dialog open={!isDialogDismissed} dialogWidth="480px">
            <div className={styles.container}>
                <div className={styles.closeButtonContainer}>
                    <CloseButton onClose={dismissDialog}/>
                </div>
                <img className={styles.introIlustration} src={introIllustration}/>
                <h1 className={styles.headerText}>New Navigation UI is here</h1>
                <p className={styles.text}>
                    The TLDR is that our <span className={styles.boldText}>navigation is vertical</span> and <span className={styles.boldText}>Projects dashboard is faster️</span> ⚡.
                </p>
                <p className={styles.text}>We recommend giving yourself a couple of minutes to look 👁️ around and get familiar with new interface.</p>
                <ActionButton type={ActionButtonType.Primary} label="I'm ready, let's go" onClick={dismissDialog}/>
            </div>
        </Dialog>);
}
function useIntroDialogDismissed() {
    const currentUser = useOctopusSessionUser();
    return useLocalStorage(`${currentUser.Id}/verticalNavigationIntroDismissed`, false);
}
interface VerticalNavigationIntroDialogDismissedEvent extends AnalyticsEvent<"Self-Hosted Vertical Navigation Dialog Dismissed"> {
}
export function createDialogDismissedAnalyticsEvent(): VerticalNavigationIntroDialogDismissedEvent {
    return {
        eventName: "Self-Hosted Vertical Navigation Dialog Dismissed",
    };
}
const styles = {
    container: css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: space[32],
        gap: space[16],
    }),
    closeButtonContainer: css({
        alignSelf: "flex-end",
    }),
    introIlustration: css({
        width: 276,
        height: 246,
    }),
    headerText: css({
        all: "unset",
        font: text.heading.xLarge,
    }),
    text: css({
        all: "unset",
        font: text.regular.default.large,
        textAlign: "center",
    }),
    boldText: css({
        font: text.regular.bold.large,
    }),
};

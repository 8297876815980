/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Repository, MachineModelHealthStatus, isWorkerMachine } from "@octopusdeploy/octopus-server-client";
import type { TenantResource, MachineResource, EnvironmentSummaryResource, WorkerPoolSummaryResource, ResourceCollection, WorkerMachineResource, PagingCollection } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { Dictionary } from "lodash";
import * as React from "react";
import type { ReactNode } from "react";
import { orderedHealthStatuses } from "~/areas/infrastructure/InfrastructureDetails";
import { DisabledMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/DisabledMachineIcon";
import { MachineHealthStatusIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/MachineHealthStatusIcon";
import type { EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { NoResults } from "~/components/NoResults/NoResults";
import List from "~/components/PagingList";
import { Section } from "~/components/Section/Section";
import { FormSectionHeading } from "~/components/form";
import type { TagIndex } from "~/components/tenantTagsets";
import MachineHealthStatusHelper from "~/utils/MachineHealthStatusHelper";
import MachineRow from "../MachineRow/MachineRow";
import { createMachineHealthMap, toPagingCollection } from "../MachinesLayout/MachineFilter";
import styles from "./style.module.less";
interface SmallCloseButtonProps {
    onClose: (e: React.MouseEvent<Element, MouseEvent>) => void;
}
class MachinesList extends List<MachineResource> {
}
export interface BaseMachinesSummaryProps {
    isFiltering: boolean;
    tenants: TenantResource[];
    tagIndex: TagIndex;
}
// TODO: Deal with the state where we have no MachinesResponse - do we even do anything here?
export interface BaseMachinesSummaryState extends DataBaseComponentState {
    machinesResponse: PagingCollection<MachineResource> | null;
    machineHealthStatusFastLookup: Dictionary<PagingCollection<MachineResource>>;
    currentPageIndex: number; // This has a custom endpoint, so we manage our own paging implementation in List/onLoadMore.
    expanded: boolean; // Need to know if we're currently expanded so we can choose to reload when the filter changes or not.
    healthStatusFilter: string;
    isDisabledFilter: boolean | undefined;
    redirectToTaskId?: string;
    redirectToTasks: boolean;
    endpointRegistrations: EndpointRegistration[];
}
abstract class BaseMachinesSummary<Props extends BaseMachinesSummaryProps, State extends BaseMachinesSummaryState> extends DataBaseComponent<Props, State> {
    protected machineListTakeSize = Repository.takeDefaultPageSize;
    protected abstract loadData(): Promise<Pick<State, keyof State> | void>;
    protected reloadDataAndCurrentPageIndex() {
        this.setState({ currentPageIndex: 0 }, async () => {
            await this.doBusyTask(async () => {
                await this.loadData();
            });
        });
    }
    protected renderMachinesList(summary: EnvironmentSummaryResource | WorkerPoolSummaryResource) {
        const machinesResponse: PagingCollection<MachineResource> | null = this.state.machinesResponse;
        return <div key={"allMachines"}>{this.state.expanded && machinesResponse && <div>{this.renderMachinesListGroupedByHealthStatus(summary, machinesResponse)}</div>}</div>;
    }
    protected assembleHealthStatusFastLookup(machinesResponse: ResourceCollection<WorkerMachineResource | MachineResource>) {
        return createMachineHealthMap(machinesResponse, this.machineListTakeSize, this.state.machineHealthStatusFastLookup);
    }
    protected setMachineResponseState(machinesResponse: ResourceCollection<WorkerMachineResource | MachineResource>) {
        const machineHealthStatusFastLookup = this.assembleHealthStatusFastLookup(machinesResponse);
        this.setState({ machinesResponse, machineHealthStatusFastLookup });
    }
    private renderMachine = (machine: MachineResource, needsUpgrading: boolean = false): ReactNode => {
        return <MachineRow registrations={this.state.endpointRegistrations} machine={machine} tenants={this.props.tenants} tagIndex={this.props.tagIndex} needsUpgrading={needsUpgrading}/>;
    };
    private renderMachinesListGroupedByHealthStatus(summary: EnvironmentSummaryResource | WorkerPoolSummaryResource, machinesResponse: PagingCollection<MachineResource>) {
        const allMachines = machinesResponse.Items;
        if (allMachines.length === 0) {
            return (<Section>
                    <NoResults />
                </Section>);
        }
        let machinesNeedUpgrading: string[] = [];
        if (summary.MachineIdsForCalamariUpgrade) {
            machinesNeedUpgrading = machinesNeedUpgrading.concat(summary.MachineIdsForCalamariUpgrade);
        }
        if (summary.MachineIdsForTentacleUpgrade) {
            machinesNeedUpgrading = machinesNeedUpgrading.concat(summary.MachineIdsForTentacleUpgrade);
        }
        return (<div>
                {orderedHealthStatuses.map((status) => this.renderHealthStatusSectionHeading(status, allMachines, machinesNeedUpgrading))}
                {this.renderDisabledSectionHeading(allMachines)}
            </div>);
    }
    private renderHealthStatusSectionTitle(status: MachineModelHealthStatus, machines: MachineResource[], allMachines: MachineResource[]) {
        const title = (<div className={styles.healthStatusCardTitleContainer} key={status}>
                <div className={styles.healthStatusIconContainer}>
                    <MachineHealthStatusIcon healthStatus={status} title={"Health status"}/>
                </div>
                <div className={styles.healthStatusName}>{MachineHealthStatusHelper.getFriendlyName(status)}</div>
                <div className={styles.healthStatusMachinesCount}>{`(${machines.length}/${allMachines.length})`}</div>
            </div>);
        return <FormSectionHeading title={title}/>;
    }
    private renderHealthStatusSectionHeading(status: MachineModelHealthStatus, allMachines: MachineResource[], machineIdsTobeUpgraded: string[]): JSX.Element | null {
        let machines = allMachines.filter((x) => x.HealthStatus === status);
        if (status === MachineModelHealthStatus.Unknown) {
            machines = machines.filter((x) => !x.IsDisabled);
        }
        const componentKey = status.toString();
        return machines.length > 0 ? (<div key={status.toString()}>
                {this.renderHealthStatusSectionTitle(status, machines, allMachines)}
                <MachinesList key={componentKey} initialData={this.state.machineHealthStatusFastLookup[componentKey]} onRow={(machine: MachineResource) => this.renderMachine(machine, machineIdsTobeUpgraded.includes(machine.Id))} onRowRedirectUrl={(machine: MachineResource) => {
                if (machineIdsTobeUpgraded.includes(machine.Id)) {
                    return isWorkerMachine(machine)
                        ? links.workerMachineConnectionPage.generateUrl({ spaceId: machine.SpaceId, machineId: machine.Id })
                        : links.deploymentTargetConnectionPage.generateUrl({ spaceId: machine.SpaceId, machineId: machine.Id });
                }
                return isWorkerMachine(machine)
                    ? links.workerMachineSettingsPage.generateUrl({ spaceId: machine.SpaceId, machineId: machine.Id })
                    : links.deploymentTargetSettingsPage.generateUrl({ spaceId: machine.SpaceId, machineId: machine.Id });
            }} onLoadMore={async () => {
                const newTakeSize = this.getBaseState((state) => state.machineHealthStatusFastLookup[componentKey].ItemsPerPage + this.machineListTakeSize);
                const machineHealthStatusFastLookup = this.getBaseState((state) => state.machineHealthStatusFastLookup);
                const filteredMachines = allMachines.filter((x) => x.HealthStatus === status);
                const machinesForHealthStatus = toPagingCollection(filteredMachines, newTakeSize);
                machineHealthStatusFastLookup[componentKey] = machinesForHealthStatus;
                this.setState({
                    machineHealthStatusFastLookup,
                });
            }}/>
            </div>) : null;
    }
    private renderDisabledSectionTitle(machines: MachineResource[]) {
        const title = (<div className={styles.healthStatusCardTitleContainer} key={status}>
                <div className={styles.healthStatusIconContainer}>
                    <DisabledMachineIcon title={"Health Status"}/>
                </div>
                <div className={styles.healthStatusName}>Disabled</div>
                <div className={styles.healthStatusMachinesCount}>({machines.length})</div>
            </div>);
        return <FormSectionHeading title={title}/>;
    }
    private renderDisabledSectionHeading(allMachines: MachineResource[]): JSX.Element | null {
        const machines = allMachines.filter((x) => x.IsDisabled);
        const componentKey = "Disabled";
        return machines.length > 0 ? (<div>
                {this.renderDisabledSectionTitle(machines)}
                <MachinesList key={componentKey} initialData={this.state.machineHealthStatusFastLookup[componentKey]} onRow={(machine: MachineResource) => this.renderMachine(machine)} onRowRedirectUrl={(machine: MachineResource) => isWorkerMachine(machine) ? links.workerMachineSettingsPage.generateUrl({ spaceId: machine.SpaceId, machineId: machine.Id }) : links.deploymentTargetSettingsPage.generateUrl({ spaceId: machine.SpaceId, machineId: machine.Id })} onLoadMore={async () => {
                const newTakeSize = this.getBaseState((state) => state.machineHealthStatusFastLookup[componentKey].ItemsPerPage + this.machineListTakeSize);
                const machineHealthStatusFastLookup = this.getBaseState((state) => state.machineHealthStatusFastLookup);
                const filteredMachines = allMachines.filter((x) => x.IsDisabled);
                const machinesForHealthStatus = toPagingCollection(filteredMachines, newTakeSize);
                machineHealthStatusFastLookup[componentKey] = machinesForHealthStatus;
                this.setState({
                    machineHealthStatusFastLookup,
                });
            }}/>
            </div>) : null;
    }
    private getBaseState = <TResult extends {}>(accessor: (state: Readonly<BaseMachinesSummaryState>) => TResult) => {
        return accessor(this.state as BaseMachinesSummaryState);
    };
    static displayName = "BaseMachinesSummary";
}
export default BaseMachinesSummary;

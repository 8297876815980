import type { IProcessResource, RunbookProcessResource } from "@octopusdeploy/octopus-server-client";
import { isRunbookProcessResource, ProcessType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { loadProcess } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import type { ProcessIdentifier } from "~/areas/projects/components/Process/types";
import type { ProjectContextState } from "~/areas/projects/context/index";
import { useProjectContext } from "~/areas/projects/context/index";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { DataBaseComponent, useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import InternalRedirect from "~/components/Navigation/InternalRedirect/index";
type RedirectToProcessEditorViaSlugProps = {
    processType: ProcessType;
    projectSlug: string;
    processId?: string;
    stepSlug?: string;
};
const useLoadProcessEffect = (projectContextState: ProjectContextState, processType: ProcessType, id: string, doBusyTask: DoBusyTask, onLoaded: (process: IProcessResource) => Promise<void>) => {
    return useDoBusyTaskEffect(doBusyTask, async () => {
        const { model, gitRef } = projectContextState;
        if (!id) {
            return;
        }
        const processIdentifier = createProcessIdentifier(processType, model.Id, model.Slug, id, model.SpaceId);
        const result: IProcessResource = await loadProcess(processIdentifier, model.PersistenceSettings, gitRef?.CanonicalName);
        if (onLoaded) {
            await onLoaded(result);
        }
    }, [id, processType, projectContextState.gitRef]);
};
const createProcessIdentifier = (processType: ProcessType, projectId: string, projectSlug: string, id: string, spaceId: string): ProcessIdentifier => {
    switch (processType) {
        case ProcessType.Deployment:
            return { type: ProcessType.Deployment, projectId, projectSlug, id, spaceId };
        case ProcessType.Runbook:
            // Runbook name and ID aren't used to load the process
            return { type: ProcessType.Runbook, projectId, projectSlug, id, spaceId, runbookId: "", runbookName: "" };
        default:
            throw new Error("This redirect only supports deployment and runbook processes");
    }
};
const RedirectToProcessEditorViaSlug = (props: RedirectToProcessEditorViaSlugProps & {
    doBusyTask: DoBusyTask;
}) => {
    const projectContext = useProjectContext();
    const [process, setProcess] = React.useState<IProcessResource | undefined>(undefined);
    useLoadProcessEffect(projectContext.state, props.processType, props.processId ?? projectContext.state.model.DeploymentProcessId, props.doBusyTask, async (process) => {
        await setProcess(process);
    });
    if (!process)
        return <></>;
    if (props.processType === ProcessType.Deployment) {
        const deploymentProcessLink = links.deploymentProcessPage.generateUrl({ spaceId: projectContext.state.model.SpaceId, projectSlug: props.projectSlug });
        const stepSlug = props.stepSlug;
        if (!stepSlug) {
            return <InternalRedirect to={deploymentProcessLink}/>;
        }
        const step = process.Steps.find((s) => s.Slug === stepSlug);
        const actionId = step?.Actions?.[0].Id;
        if (!actionId) {
            return <InternalRedirect to={deploymentProcessLink}/>;
        }
        return <InternalRedirect to={links.deploymentProcessStepsPage.generateUrl({ spaceId: projectContext.state.model.SpaceId, projectSlug: props.projectSlug }, { actionId })}/>;
    }
    if (props.processType === ProcessType.Runbook) {
        if (!props.processId || !isRunbookProcessResource(process))
            return <></>;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const runbookProcess = process as RunbookProcessResource;
        const runbookProcessLink = links.projectRunbookProcessStepsPage.generateUrl({ spaceId: projectContext.state.model.SpaceId, processId: props.processId, projectSlug: props.projectSlug, runbookId: runbookProcess.RunbookId });
        const stepSlug = props.stepSlug;
        if (!stepSlug) {
            return <InternalRedirect to={runbookProcessLink}/>;
        }
        const step = process.Steps.find((s) => s.Slug === stepSlug);
        const actionId = step?.Actions?.[0].Id;
        if (!actionId) {
            return <InternalRedirect to={runbookProcessLink}/>;
        }
        const runbookProcessStepLink = links.projectRunbookProcessStepsPage.generateUrl({ spaceId: projectContext.state.model.SpaceId, processId: props.processId, projectSlug: props.projectSlug, runbookId: runbookProcess.RunbookId }, { actionId });
        return <InternalRedirect to={runbookProcessStepLink}/>;
    }
    return <></>;
};
class RedirectToProcessEditorViaSlugPage extends DataBaseComponent<RedirectToProcessEditorViaSlugProps> {
    render() {
        return <RedirectToProcessEditorViaSlug doBusyTask={this.doBusyTask} {...this.props}/>;
    }
    static displayName = "RedirectToProcessEditorViaSlugPage";
}
export default RedirectToProcessEditorViaSlugPage;

import { Button } from "@octopusdeploy/design-system-components";
import type { TimelineItem } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TimelineRow } from "./TimelineRow";
import { loadMoreButton, timeline } from "./styles";
interface TimelineProps {
    timelineData: TimelineItem[];
    loadMore: () => void;
    hasMore: boolean;
}
export const Timeline = (props: TimelineProps) => (<>
        <ul className={timeline}>
            {props.timelineData.map((tli) => (<li key={tli.Id}>
                    <TimelineRow item={tli}/>
                </li>))}
        </ul>
        <div className={loadMoreButton}>{props.hasMore && <Button onClick={props.loadMore} label="Load More" importance="tertiary"/>}</div>
    </>);

import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { k8sIconStyles } from "~/areas/projects/components/Observability/K8ResourceIcons/k8sIconStyles";
export function ServiceIcon() {
    return (<svg className={k8sIconStyles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 25.917H12.5809V31.9408H4V25.917Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.7096 25.917H24.2904V31.9408H15.7096V25.917Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M27.4191 25.917H36V31.9408H27.4191V25.917Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.2017 8.05933H26.7983V14.0831H13.2017V8.05933Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.2193 19.2195V14.0832H20.7806V20.0001C20.7806 20.4312 20.4311 20.7808 20 20.7808H9.07719V25.9095H7.51591V20.0001C7.51591 19.569 7.86542 19.2195 8.29655 19.2195H19.2193Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.2196 20.0001L19.2193 14.0832H20.7806L20.7804 19.2197H31.7034C32.1344 19.2197 32.4838 19.5691 32.4838 20.0001V25.9095H30.923V20.7805L20 20.7808C19.569 20.7808 19.2196 20.4311 19.2196 20.0001Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.1947 20.0038V14.0869H20.756V19.7303C20.7879 19.8154 20.8053 19.9076 20.8053 20.0038V25.9132H19.244V20.2774C19.2121 20.1923 19.1947 20.1001 19.1947 20.0038Z" fill={themeTokens.color.icon.tertiary}/>
        </svg>);
}

import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
interface ConnectedDeploymentTargetsPopoverOpenedEventProperties {
    stepHasUnassignedTargetTags: boolean;
}
interface ConnectedDeploymentTargetsPopoverOpenedEvent extends AnalyticsEvent<"Connected Deployment Targets Popover Opened"> {
    StepHasUnassignedTargetTags: boolean;
}
export function createConnectedDeploymentTargetsPopoverOpenedEvent(properties: ConnectedDeploymentTargetsPopoverOpenedEventProperties): ConnectedDeploymentTargetsPopoverOpenedEvent {
    return {
        eventName: "Connected Deployment Targets Popover Opened",
        StepHasUnassignedTargetTags: properties.stepHasUnassignedTargetTags,
    };
}

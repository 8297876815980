/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import { Button, Checkbox, Dialog, Divider, EditIcon, FilterIcon, IconButton, Tooltip } from "@octopusdeploy/design-system-components";
import { borderRadius, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { GitHubAppConnectionSummary, GitHubRepository, ChannelResource, DeploymentProcessResource, GitTriggerSourceResource, GitDependencyReference, GitPersistenceSettings, DeploymentActionResource, DeploymentStepResource, ProjectResource, } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, IsUsingGitHubAuth } from "@octopusdeploy/octopus-server-client";
import { flatMap, groupBy, uniq } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { GitHubAvatar } from "~/areas/library/components/GitConnections/GitHubInstallationLogo";
import getActionLogoUrl from "~/areas/projects/components/getActionLogoUrl";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import FilterSearchBox from "~/components/FilterSearchBox";
import Logo from "~/components/Logo";
import ExternalLink from "~/components/Navigation/ExternalLink";
import NumberedPagingBar from "~/components/PagingBaseComponent/NumberedPagingBar";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import { Note, Text, type FormFieldProps } from "~/components/form";
import { GitIcon } from "~/primitiveComponents/dataDisplay/Icon";
import GitHubIcon from "~/primitiveComponents/dataDisplay/Icon/GitHubIcon";
import { GitRefChip } from "../../Releases/GitRefChip/GitRefChip";
class FilterLayout extends AdvancedFilterLayout<GitTriggerSourceFilter> {
}
interface GitTriggerSourceFilter {
    searchText: string;
}
const defaultFilter: GitTriggerSourceFilter = { searchText: "" };
type GitTriggerSourceItem = {
    id: string;
    selected: boolean;
    repositoryUrl: string;
    branch: string;
    includeFilePaths: string[];
    excludeFilePaths: string[];
    steps: GitTriggerSourceItemStep[];
};
export interface GitTriggerSourceItemStep {
    type: "project" | "external";
    triggerSource?: GitTriggerSourceResource;
    action: DeploymentActionResource;
    gitDependencyName: string;
    repositoryUrl: string;
    branch: string;
    stepActionNumber: string;
}
interface GitTriggerSourcesProps extends FormFieldProps<GitTriggerSourceResource[]> {
    project: ProjectResource;
    channel?: ChannelResource;
    deploymentProcess?: DeploymentProcessResource;
    connection?: GitHubAppConnectionSummary;
    repository?: GitHubRepository;
}
const actionIsEnabledForSelectedChannel = (action: DeploymentActionResource, channel?: ChannelResource) => action.Channels.length === 0 || channel === undefined || action.Channels.includes(channel.Id);
export function GitTriggerSources({ project, channel, deploymentProcess, value, repository, connection, onChange }: GitTriggerSourcesProps) {
    const [items, setItems] = useState(getGitTriggerSourceItems(project, value, deploymentProcess));
    const [filter, setFilter] = useState(defaultFilter);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const PAGESIZE = 10;
    useEffect(() => {
        // Only reset the page index when the filter is changed as selecting
        //a checkbox causes the items prop to update as it is rebuilt by the parent of this component
        setCurrentPageIndex(0);
    }, [filter.searchText]);
    const notifyOnChange = (items: GitTriggerSourceItem[]) => {
        onChange(flatMap(items
            .filter((item) => item.selected)
            .map((item) => {
            return item.steps.map((i) => {
                return {
                    DeploymentActionSlug: i.action.Slug || i.action.Name,
                    GitDependencyName: i.gitDependencyName,
                    IncludeFilePaths: item.includeFilePaths,
                    ExcludeFilePaths: item.excludeFilePaths,
                };
            });
        })));
    };
    const search = (text: string | undefined, term: string) => text?.toLowerCase().includes(term.toLowerCase());
    const filteredItems = items.filter((item) => {
        return search(item.repositoryUrl, filter.searchText) || item.steps.filter((step) => search(step.action.Name, filter.searchText)).length > 0;
    });
    const pagedItems = filteredItems.slice(PAGESIZE * currentPageIndex, PAGESIZE * (currentPageIndex + 1));
    const styles = {
        searchBoxContainer: css({ minWidth: 275 }),
        triggerSourceItems: css({
            display: "flex",
            flexDirection: "column",
            gap: space[8],
            padding: space[16],
        }),
        triggerSourceItem: css({
            display: "flex",
            flexDirection: "column",
            gap: space[12],
        }),
    };
    const itemElements = (<div className={styles.triggerSourceItems}>
            {pagedItems.map((item, index) => {
            return (<div key={item.id} className={styles.triggerSourceItem}>
                        {index > 0 && <Divider orientation="horizontal"/>}
                        <GitTriggerSourceRepositorySelector item={item} project={project} channel={channel} connection={connection} repository={repository} onChange={(item) => {
                    const newItems = items.map((i) => (i.id === item.id ? item : i));
                    setItems(newItems);
                    notifyOnChange(newItems);
                }}/>
                    </div>);
        })}
        </div>);
    const searchBox = <FilterSearchBox placeholder="Search by step or repository" value={filter.searchText} onChange={(searchText) => setFilter({ ...filter, searchText })} containerClassName={styles.searchBoxContainer} fullWidth={true}/>;
    return (<>
            <FilterLayout filterSections={[]} filter={filter} defaultFilter={defaultFilter} onFilterReset={() => setFilter(defaultFilter)} additionalHeaderFilters={[searchBox]} renderContent={() => itemElements}></FilterLayout>
            {filteredItems.length > PAGESIZE && <NumberedPagingBar totalItems={filteredItems.length} currentPageIndex={currentPageIndex} pageSize={PAGESIZE} onPageSelected={(_, index) => setCurrentPageIndex(index)}/>}
        </>);
}
type GitTriggerSourceStepListProps = {
    item: GitTriggerSourceItem;
    channel?: ChannelResource;
};
function GitTriggerSourceStepList({ item, channel }: GitTriggerSourceStepListProps) {
    const [showAll, setShowAll] = useState(false);
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: space[4],
        }),
    };
    const rowDisabled = (item: GitTriggerSourceItemStep) => item.action.IsDisabled || !actionIsEnabledForSelectedChannel(item.action, channel);
    const disabledMessage = (item: GitTriggerSourceItemStep) => (item.action.IsDisabled || channel === undefined ? "This step is disabled" : `This step is disabled for the ${channel.Name} channel`);
    return (<div className={styles.container}>
            {showAll && item.steps.map((i) => <DeploymentActionWithStepNumber key={i.stepActionNumber} step={i} disabled={rowDisabled(i)} disabledMessage={disabledMessage(i)}/>)}
            <Button onClick={() => setShowAll(!showAll)} importance="quiet" label={showAll ? "Hide steps" : `Show ${item.steps.length} step(s)`}/>
        </div>);
}
interface GitTriggerSourceRepositorySelectorProps {
    item: GitTriggerSourceItem;
    project: ProjectResource;
    channel?: ChannelResource;
    connection?: GitHubAppConnectionSummary;
    repository?: GitHubRepository;
    onChange: (item: GitTriggerSourceItem) => void;
}
function GitTriggerSourceRepositorySelector({ item, project, channel, connection, repository, onChange }: GitTriggerSourceRepositorySelectorProps) {
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "column",
            gap: space[12],
        }),
        detail: css({
            display: "flex",
            flexDirection: "column",
            gap: space[12],
            marginLeft: space[32],
            flexGrow: 1,
        }),
        repository: css({
            display: "flex",
            alignItems: "center",
            gap: space[8],
        }),
    };
    return (<div className={styles.container}>
            <Checkbox value={item.selected} onChange={(selected) => onChange({ ...item, selected })} noMargin={true} label={<div className={styles.repository}>
                        <GitTriggerSourceItemRepositoryDetail item={item} project={project} connection={connection} repository={repository}/>
                        <GitRefChip vcsRef={{ GitRef: item.branch }}/>
                    </div>}/>
            <div className={styles.detail}>
                <GitTriggerSourceFilters item={item} onChange={(item) => onChange(item)}/>
                <GitTriggerSourceStepList item={item} channel={channel}/>
            </div>
        </div>);
}
type GitTriggerSourceFiltersProps = {
    item: GitTriggerSourceItem;
    onChange: (item: GitTriggerSourceItem) => void;
};
function GitTriggerSourceFilters({ item, onChange }: GitTriggerSourceFiltersProps) {
    const [editing, setEditing] = useState(false);
    const styles = {
        addFiltersContainer: css({
            display: "flex",
            justifyItems: "flex-start",
            alignItems: "center",
        }),
        filtersContainer: css({
            display: "flex",
            flexDirection: "column",
            gap: space[8],
            borderRadius: borderRadius.medium,
            boxShadow: themeTokens.shadow.small,
            padding: space[16],
        }),
        header: css({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: space[8],
        }),
        filePathFilters: css({
            display: "flex",
            flexDirection: "column",
            gap: space[8],
        }),
        filePathFilter: css({
            display: "flex",
            alignItems: "center",
            gap: space[4],
        }),
        filePathFilterLabel: css({
            minWidth: space[64],
        }),
    };
    if (!item.selected)
        return null;
    let displayElement = null;
    if (item.includeFilePaths.length === 0 && item.excludeFilePaths.length === 0) {
        displayElement = (<div className={styles.addFiltersContainer}>
                <Button onClick={() => setEditing(true)} importance="secondary" label="Add file path filters" leftIcon={<FilterIcon />}/>
            </div>);
    }
    else {
        displayElement = (<div className={styles.filtersContainer}>
                <div className={styles.header}>
                    <strong>File path filters</strong>
                    <Tooltip content="Edit file path filters">
                        <IconButton onClick={() => setEditing(true)} customIcon={<EditIcon size={20}/>}/>
                    </Tooltip>
                </div>
                <div className={styles.filePathFilters}>
                    {item.includeFilePaths.length > 0 && (<div className={styles.filePathFilter}>
                            <div className={styles.filePathFilterLabel}>Include:</div>
                            <div>{item.includeFilePaths.join(", ")}</div>
                        </div>)}
                    {item.excludeFilePaths.length > 0 && (<div className={styles.filePathFilter}>
                            <div className={styles.filePathFilterLabel}>Exclude:</div>
                            <div>{item.excludeFilePaths.join(", ")}</div>
                        </div>)}
                </div>
            </div>);
    }
    return (<>
            {editing && (<EditGitTriggerSourceFilterDialog item={item} onChange={(item) => {
                onChange(item);
                setEditing(false);
            }} onClose={() => setEditing(false)}/>)}
            {displayElement}
        </>);
}
type EditGitTriggerSourceFilterDialogProps = {
    item: GitTriggerSourceItem;
    onChange: (item: GitTriggerSourceItem) => void;
    onClose: () => void;
};
function EditGitTriggerSourceFilterDialog({ item, onChange, onClose }: EditGitTriggerSourceFilterDialogProps) {
    const [includeFilePaths, setIncludeFilePaths] = useState(item.includeFilePaths.join(", "));
    const [excludeFilePaths, setExcludeFilePaths] = useState(item.excludeFilePaths.join(", "));
    return (<Dialog open={true}>
            <SaveDialogLayout title={item.includeFilePaths.length > 0 || item.excludeFilePaths.length > 0 ? "Edit File Path Filters" : "Add File Path Filters"} busy={false} errors={[]} onSaveClick={async () => {
            const newIncludeFilePaths = includeFilePaths
                .split(",")
                .map((f) => f.trim())
                .filter((f) => f.length > 0);
            const newExcludeFilePaths = excludeFilePaths
                .split(",")
                .map((f) => f.trim())
                .filter((f) => f.length > 0);
            onChange({ ...item, includeFilePaths: newIncludeFilePaths, excludeFilePaths: newExcludeFilePaths });
            return true;
        }} saveButtonDisabled={false} onCancelClick={onClose}>
                <Note>
                    Use wildcard syntax to include or exclude multiple files. See our documentation on <ExternalLink href="ChannelGitProtectionRulesGlobPatterns">using glob patterns in Git triggers</ExternalLink> for more information. Multiple
                    patterns can be separated by a comma.
                </Note>

                <Text label="Include" value={includeFilePaths} onChange={(filePaths) => setIncludeFilePaths(filePaths)} autoFocus/>
                <Text label="Exclude" value={excludeFilePaths} onChange={(filePaths) => setExcludeFilePaths(filePaths)}/>
            </SaveDialogLayout>
        </Dialog>);
}
interface DeploymentActionWithStepNumberProps {
    step: GitTriggerSourceItemStep;
    disabled: boolean;
    disabledMessage: string;
}
function DeploymentActionWithStepNumber({ step, disabled, disabledMessage }: DeploymentActionWithStepNumberProps) {
    const styles = {
        actionLabel: css({ whiteSpace: "nowrap" }),
        darkModeSpacer: css({ width: space[6] }),
    };
    const darkModeSpacer = useThemePaletteType() === "dark" ? <div className={styles.darkModeSpacer}/> : <></>;
    const logoUrl = getActionLogoUrl(step.action);
    return (<CellWrapper disabled={disabled} disabledMessage={disabledMessage}>
            <Logo url={logoUrl} isDisabled={disabled} size={"2rem"}/>
            {darkModeSpacer}
            <span className={styles.actionLabel}>
                {step.stepActionNumber} {step.action.Name}
                {step.gitDependencyName ? ` (${step.gitDependencyName})` : ""}
            </span>
        </CellWrapper>);
}
interface CellWrapperProps {
    disabled: boolean;
    disabledMessage: string;
    children: React.ReactElement | React.ReactElement[];
}
function CellWrapper({ disabled, disabledMessage, children }: CellWrapperProps) {
    const styles = {
        cellWrapper: (disabled: boolean) => css({ display: "flex", flexWrap: "nowrap", alignItems: "center", color: disabled ? themeTokens.color.text.disabled : themeTokens.color.text.primary }),
    };
    const content = <div className={styles.cellWrapper(disabled)}>{children}</div>;
    if (disabled && disabledMessage) {
        return <Tooltip content={disabledMessage}>{content}</Tooltip>;
    }
    else {
        return <>{content}</>;
    }
}
export const getGitTriggerSourceItemSteps = (project: ProjectResource, triggerSources: GitTriggerSourceResource[], deploymentProcess?: DeploymentProcessResource): GitTriggerSourceItemStep[] => {
    return flatMap(deploymentProcess?.Steps.map((step, stepIndex) => step.Actions.flatMap((action, actionIndex) => {
        const selectItems: GitTriggerSourceItemStep[] = [];
        if (action.GitDependencies) {
            selectItems.push(...action.GitDependencies.map((dep) => {
                const triggerSource = triggerSources.find((source) => source.DeploymentActionSlug === action.Slug && source.GitDependencyName === dep.Name);
                return createExternalRepositoryGitTriggerSourceItem(triggerSource, step, stepIndex, action, actionIndex, dep);
            }));
        }
        if (HasGitPersistenceSettings(project.PersistenceSettings)) {
            const isProjectRepository = action.Properties["Octopus.Action.GitRepository.Source"] === "Project";
            if (isProjectRepository) {
                const triggerSource = triggerSources.find((source) => source.DeploymentActionSlug === action.Slug && source.GitDependencyName === "");
                selectItems.push(createProjectRepositoryGitTriggerSourceItem(triggerSource, project.PersistenceSettings, step, stepIndex, action, actionIndex));
            }
        }
        return selectItems;
    })));
};
export const getGitTriggerSourceItems = (project: ProjectResource, triggerSources: GitTriggerSourceResource[], deploymentProcess?: DeploymentProcessResource): GitTriggerSourceItem[] => {
    const steps = getGitTriggerSourceItemSteps(project, triggerSources, deploymentProcess);
    const stepsByRepositoryAndBranch = groupBy(steps, (step) => `${step.repositoryUrl}-${step.branch}-${(step.triggerSource?.IncludeFilePaths ?? []).join(",")}-${(step.triggerSource?.ExcludeFilePaths ?? []).join(",")}`);
    return Object.keys(stepsByRepositoryAndBranch).map((key) => {
        const steps = stepsByRepositoryAndBranch[key];
        return {
            id: key,
            selected: steps.some((step) => step.triggerSource !== undefined),
            repositoryUrl: steps[0].repositoryUrl,
            branch: steps[0].branch,
            includeFilePaths: uniq(steps.flatMap((step) => step.triggerSource?.IncludeFilePaths ?? [])),
            excludeFilePaths: uniq(steps.flatMap((step) => step.triggerSource?.ExcludeFilePaths ?? [])),
            steps,
        };
    });
};
const createExternalRepositoryGitTriggerSourceItem = (triggerSource: GitTriggerSourceResource | undefined, step: DeploymentStepResource, stepIndex: number, action: DeploymentActionResource, actionIndex: number, gitDependency: GitDependencyReference): GitTriggerSourceItemStep => {
    return {
        type: "external",
        action,
        triggerSource,
        gitDependencyName: gitDependency.Name,
        repositoryUrl: gitDependency.RepositoryUri,
        branch: gitDependency.DefaultBranch.startsWith("refs/heads/") ? gitDependency.DefaultBranch : `refs/heads/${gitDependency.DefaultBranch}`,
        stepActionNumber: stepNumber(step, stepIndex, actionIndex),
    };
};
const createProjectRepositoryGitTriggerSourceItem = (triggerSource: GitTriggerSourceResource | undefined, gitPersistenceSettings: GitPersistenceSettings, step: DeploymentStepResource, stepIndex: number, action: DeploymentActionResource, actionIndex: number): GitTriggerSourceItemStep => {
    return {
        type: "project",
        action,
        triggerSource,
        gitDependencyName: "",
        repositoryUrl: gitPersistenceSettings.Url,
        branch: gitPersistenceSettings.DefaultBranch.startsWith("refs/heads/") ? gitPersistenceSettings.DefaultBranch : `refs/heads/${gitPersistenceSettings.DefaultBranch}`,
        stepActionNumber: stepNumber(step, stepIndex, actionIndex),
    };
};
const stepNumber = (step: DeploymentStepResource, stepIndex: number, actionIndex: number) => {
    if (step.Actions.length === 1) {
        return `${stepIndex + 1}.`;
    }
    else {
        return `${stepIndex + 1}.${actionIndex + 1}.`;
    }
};
type GitTriggerSourceItemRepositoryDetailProps = {
    item: GitTriggerSourceItem;
    project: ProjectResource;
    connection?: GitHubAppConnectionSummary;
    repository?: GitHubRepository;
};
function GitTriggerSourceItemRepositoryDetail({ item, project, connection, repository }: GitTriggerSourceItemRepositoryDetailProps) {
    const anyStepIsUsingProjectRepository = item.steps.find((step) => step.type === "project");
    if (anyStepIsUsingProjectRepository && HasGitPersistenceSettings(project.PersistenceSettings)) {
        if (IsUsingGitHubAuth(project.PersistenceSettings.Credentials)) {
            return <GitHubAppRepositoryDetailHeader persistenceSettings={project.PersistenceSettings} connection={connection} repository={repository}/>;
        }
        else if (project.PersistenceSettings.Url.startsWith("https://github.com")) {
            return <GitHubRepositoryDetailHeader url={project.PersistenceSettings.Url}/>;
        }
    }
    else if (item.repositoryUrl.startsWith("https://github.com")) {
        return <GitHubRepositoryDetailHeader url={item.repositoryUrl}/>;
    }
    return <GenericRepositoryDetailHeader url={item.repositoryUrl}/>;
}
type RepositoryDetailHeaderProps = {
    icon: React.ReactNode;
    name: string | React.ReactNode;
};
function RepositoryDetailHeader({ icon, name }: RepositoryDetailHeaderProps) {
    const styles = {
        container: css({
            display: "flex",
            alignItems: "center",
            gap: space[8],
        }),
        icon: css({
            width: space[24],
            height: space[24],
        }),
    };
    return (<div className={styles.container}>
            <div className={styles.icon}>{icon}</div>
            <span>{name}</span>
        </div>);
}
type GenericRepositoryDetailHeaderProps = {
    url: string;
};
function GenericRepositoryDetailHeader({ url }: GenericRepositoryDetailHeaderProps) {
    return <RepositoryDetailHeader icon={<GitIcon />} name={url}/>;
}
type GitHubAppRepositoryDetailHeaderProps = {
    persistenceSettings: GitPersistenceSettings;
    connection?: GitHubAppConnectionSummary;
    repository?: GitHubRepository;
};
function GitHubAppRepositoryDetailHeader({ persistenceSettings, connection, repository }: GitHubAppRepositoryDetailHeaderProps) {
    if (connection && connection.Installation && repository) {
        return (<RepositoryDetailHeader icon={<GitHubAvatar small={true} type={connection.Installation.AccountType} avatarUrl={connection.Installation.AccountAvatarUrl}/>} name={<>
                        {connection.Installation.AccountLogin} / {repository.RepositoryName}
                    </>}/>);
    }
    else {
        return <GitHubRepositoryDetailHeader url={persistenceSettings.Url}/>;
    }
}
type GitHubRepositoryDetailHeaderProps = {
    url: string;
};
function GitHubRepositoryDetailHeader({ url }: GitHubRepositoryDetailHeaderProps) {
    const match = url.match(/https:\/\/github.com\/([^/]+)\/([^/]+)(\/.*)?/);
    const owner = match && match[1];
    const repo = match && match[2];
    return (<RepositoryDetailHeader icon={<GitHubIcon />} name={<>
                    {owner} / {repo?.replace(".git", "")}
                </>}/>);
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, Tooltip, Callout } from "@octopusdeploy/design-system-components";
import type { AccountResource, CertificateResource, EnvironmentResource, ProjectResource, TenantLibraryVariable, TenantMissingVariableResource, TenantProjectVariable, TenantResource, TenantVariableResource, TenantVariableTemplateResource, WorkerPoolResource, } from "@octopusdeploy/octopus-server-client";
import { AccountType, ControlType, Permission } from "@octopusdeploy/octopus-server-client";
import { links, TenantVariableActiveTab } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import { cloneDeep, memoize, sortBy, take, uniq } from "lodash";
import * as React from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import CardTitle from "~/components/CardTitle";
import { ChipIcon, EnvironmentChip } from "~/components/Chips/index";
import DataBaseComponent from "~/components/DataBaseComponent";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import Logo from "~/components/Logo/Logo";
import Lookup from "~/components/Lookup";
import LookupResourceChipComponent from "~/components/LookupResourceChip";
import { ProjectMultiSelect } from "~/components/MultiSelect/ProjectMultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { NoResults } from "~/components/NoResults/NoResults";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { SimplePagingList } from "~/components/PagingList/SimplePagingList";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SectionNote from "~/components/SectionNote/SectionNote";
import SimpleExpander from "~/components/SimpleExpander";
import TenantVariableInputFormElement from "~/components/TenantVariableInput/TenantVariableInputRenderer";
import ExpansionButtons from "~/components/form/Sections/ExpansionButtons";
import { TabItem, UrlNavigationTabsContainer } from "~/primitiveComponents/navigation/Tabs";
import InternalLink from "../../../components/Navigation/InternalLink/InternalLink";
import MissingVariablesIcon from "../MissingVariablesIcon/MissingVariablesIcon";
import { tenantsActions } from "../tenantsArea";
import styles from "./style.module.less";
interface LookupData {
    tenant: TenantResource;
    hasLibraryVariableSetView?: boolean;
    environments: EnvironmentResource[];
    accounts: AccountResource[];
    missingVariables: TenantMissingVariableResource[];
}
interface InitialData {
    lookupData: LookupData;
    variables: TenantVariableResource;
}
const areAnyTemplatesOfType = (predicate: (controlType: string | undefined) => boolean, variables: TenantVariableResource): boolean => {
    const projectTemplatesOfType = !!Object.keys(variables.ProjectVariables).find((p) => !!variables.ProjectVariables[p].Templates.find((t) => predicate(t.DisplaySettings["Octopus.ControlType"])));
    if (projectTemplatesOfType)
        return true;
    const libraryTemplatesOfType = !!Object.keys(variables.LibraryVariables).find((p) => !!variables.LibraryVariables[p].Templates.find((t) => predicate(t.DisplaySettings["Octopus.ControlType"])));
    return libraryTemplatesOfType;
};
type MissingProjectVariableIndex = {
    [projectId: string]: {
        [environmentId: string]: string[];
    };
};
type MissingLibraryVariableIndex = {
    [libraryVariableSetId: string]: string[];
};
interface MissingVariablesIndex {
    projects: MissingProjectVariableIndex;
    libraries: MissingLibraryVariableIndex;
}
const buildMissingVariableIndex = (tenantId: string, variables: TenantMissingVariableResource[]): MissingVariablesIndex => {
    const tenantMissing = variables.find((t) => t.TenantId === tenantId);
    const projectVariables: {
        [projectId: string]: {
            [environmentId: string]: string[];
        };
    } = {};
    const libraryVariables: {
        [libraryVariableSetId: string]: string[];
    } = {};
    if (tenantMissing) {
        tenantMissing.MissingVariables.forEach((variable) => {
            if (!variable.ProjectId) {
                if (variable.LibraryVariableSetId) {
                    if (!libraryVariables[variable.LibraryVariableSetId]) {
                        libraryVariables[variable.LibraryVariableSetId] = [];
                    }
                    libraryVariables[variable.LibraryVariableSetId].push(variable.VariableTemplateId);
                }
            }
            else {
                if (!projectVariables[variable.ProjectId]) {
                    projectVariables[variable.ProjectId] = {};
                }
                if (variable.EnvironmentId) {
                    if (!projectVariables[variable.ProjectId][variable.EnvironmentId]) {
                        projectVariables[variable.ProjectId][variable.EnvironmentId] = [];
                    }
                    projectVariables[variable.ProjectId][variable.EnvironmentId].push(variable.VariableTemplateId);
                }
            }
        });
    }
    return {
        projects: projectVariables,
        libraries: libraryVariables,
    };
};
const TenantVariablesFormPage = FormPage<InitialData>();
const title = "Tenant Variables";
interface TenantVariablesPageProps {
    tenantId: string;
}
export const TenantVariablesPage: React.FC<TenantVariablesPageProps> = ({ tenantId }) => {
    const dispatch = useDispatch();
    const dispatchAction = useAnalyticActionDispatch();
    const onTenantVariablesFetched = useCallback((v?: TenantMissingVariableResource) => {
        dispatch(tenantsActions.tenantMissingVariablesFetched(v));
    }, [dispatch]);
    return (<TenantVariablesFormPage title={title} load={async () => {
            const hasLibraryVariableSetView = isAllowed({
                permission: Permission.LibraryVariableSetView,
                environment: "*",
                tenant: "*",
            });
            const environments = repository.Environments.all();
            const missingVariables = repository.Tenants.missingVariables({ tenantId }, true);
            const tenant = await repository.Tenants.get(tenantId);
            const variables = await repository.Tenants.getVariables(tenant);
            const accounts = areAnyTemplatesOfType((type) => type === ControlType.AzureAccount || type === ControlType.AmazonWebServicesAccount, variables) ? repository.Accounts.all() : Promise.resolve([]);
            notifyTenantVariablesFetched(tenant.Id, await missingVariables, onTenantVariablesFetched);
            const lookupData: LookupData = {
                tenant,
                hasLibraryVariableSetView,
                accounts: await accounts,
                environments: await environments,
                missingVariables: await missingVariables,
            };
            return {
                variables,
                lookupData,
            };
        }} renderWhenLoaded={(data: InitialData) => {
            return <TenantVariablesLayoutInternal {...data} onTenantVariablesFetched={onTenantVariablesFetched} dispatchAction={dispatchAction}/>;
        }}/>);
};
TenantVariablesPage.displayName = "TenantVariablesPage"
class LinkedEnvironmentSummary extends React.Component<{
    linkedEnvironments: string[];
    environments: EnvironmentResource[];
}, {
    showAll: boolean;
}> {
    state = { showAll: false };
    render() {
        const { linkedEnvironments, environments } = this.props;
        if (linkedEnvironments.length === 0) {
            return <span>No linked environments</span>;
        }
        const message = `Linked environment${linkedEnvironments.length === 1 ? "" : "s"}`;
        const LookupEnvironmentChip = LookupResourceChipComponent<EnvironmentResource>();
        const linkedEnvironmentResources = environments.filter((x) => linkedEnvironments.some((l) => l === x.Id));
        if (this.state.showAll) {
            const chips = linkedEnvironmentResources.map((env) => (<LookupEnvironmentChip lookupCollection={linkedEnvironmentResources} key={env.Id} lookupId={env.Id} type={ChipIcon.Environment} chipRender={(item) => <EnvironmentChip environmentName={item.Name}/>}/>));
            return (<div>
                    {message}
                    {chips}
                    <ActionButton label={`Show summary`} onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ showAll: false });
                }}/>
                </div>);
        }
        else {
            const chips = take(linkedEnvironmentResources, 3).map((env) => (<LookupEnvironmentChip lookupCollection={linkedEnvironmentResources} key={env.Id} lookupId={env.Id} type={ChipIcon.Environment} chipRender={(item) => <EnvironmentChip environmentName={item.Name}/>}/>));
            const left = linkedEnvironments.length - chips.length;
            return (<div>
                    {message}
                    {chips}
                    {left > 0 && (<ActionButton label={`${left} more`} onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ showAll: true });
                    }}/>)}
                </div>);
        }
    }
    static displayName = "LinkedEnvironmentSummary";
}
const libraryVariableTitle = (spaceId: string, missingVariables: string[], libraryVariableSet: TenantLibraryVariable) => {
    return (<div className={styles.cardTitle}>
            <div className={styles.libraryVariableSetContainer}>
                <CardTitle>{libraryVariableSet.LibraryVariableSetName}</CardTitle>
                <div className={styles.libraryWarning}>
                    <MissingVariablesIcon show={!!missingVariables}/>
                </div>
            </div>
            <OverflowMenu menuItems={[
            OverflowMenuItems.navItem("Edit Variable Set Template", links.editVariableSetPage.generateUrl({ spaceId, variableSetId: libraryVariableSet.LibraryVariableSetId }), {
                permission: Permission.VariableView,
                wildcard: true,
            }),
        ]}/>
        </div>);
};
interface TenantVariablesLayoutProps {
    lookupData: LookupData;
    variables: TenantVariableResource;
    onTenantVariablesFetched(tenantMissingVariables?: TenantMissingVariableResource): void;
    dispatchAction: AnalyticActionDispatcher;
}
type Model = TenantVariableResource;
interface TenantVariablesFormState extends FormBaseComponentState<Model> {
    projectIds: string[];
    missingVariables: TenantMissingVariableResource[];
    accounts: AccountResource[];
    certificates?: CertificateResource[];
    workerPools?: WorkerPoolResource[];
}
function notifyTenantVariablesFetched(tenantId: string, missingVariables: TenantMissingVariableResource[], onTenantVariablesFetched: (tenantMissingVariables?: TenantMissingVariableResource) => void) {
    const found = missingVariables.find((x) => x.TenantId === tenantId);
    onTenantVariablesFetched(found);
}
class TenantVariablesLayoutInternal extends DataBaseComponent<TenantVariablesLayoutProps, TenantVariablesFormState> {
    private buildMissingVariableIndex = memoize((variables: TenantMissingVariableResource[]) => buildMissingVariableIndex(this.props.lookupData.tenant.Id, variables));
    private memoizedRepositoryCertificatesList = memoize(() => repository.Certificates.listForTenant(this.props.lookupData.tenant.Id));
    private memoizedRepositoryWorkerPoolList = memoize(() => repository.WorkerPools.all());
    constructor(props: TenantVariablesLayoutProps) {
        super(props);
        const initialModel = this.props.variables;
        const initialMissingVariables = this.props.lookupData.missingVariables;
        const initialAccounts = this.props.lookupData.accounts;
        this.state = {
            projectIds: [],
            model: initialModel,
            cleanModel: cloneDeep(initialModel),
            missingVariables: initialMissingVariables,
            accounts: initialAccounts,
        };
    }
    handleSaveClick = async () => {
        const tenant = this.props.lookupData.tenant;
        await this.doBusyTask(async () => {
            const variables = await repository.Tenants.setVariables(tenant, this.state.model);
            const missingVariables = await repository.Tenants.missingVariables({ tenantId: tenant.Id }, true);
            notifyTenantVariablesFetched(this.props.lookupData.tenant.Id, missingVariables, this.props.onTenantVariablesFetched);
            this.props.dispatchAction("Save Variables", { resource: "Variable", action: Action.Save });
            this.setState({
                model: variables,
                cleanModel: cloneDeep(variables),
                missingVariables,
            });
        });
    };
    projectVariableTitle = (projectVariables: TenantProjectVariable, missingVariables: {
        [p: string]: string[];
    }, linkedEnvironments: string[]) => {
        return (<div className={styles.cardTitle}>
                <div className={styles.projectLogoContainer}>
                    <Logo url={projectVariables.Links.Logo} size="2rem"/>
                </div>
                <div className={styles.projectName}>
                    <div className={styles.projectNameAndWarningIconContainer}>
                        <div>
                            <Tooltip content={"Project: " + projectVariables.ProjectName}>
                                <CardTitle>{projectVariables.ProjectName}</CardTitle>
                            </Tooltip>
                        </div>
                        {missingVariables && (<div className={styles.projectCardWarning}>
                                <MissingVariablesIcon show={!!missingVariables}/>
                            </div>)}
                    </div>
                    <div className={cn(styles.helpText, "visible-md")}>
                        {linkedEnvironments.length} linked environment{linkedEnvironments.length === 1 ? "" : "s"}
                    </div>
                </div>
                <div style={{ flex: "1 1 20rem" }} className={cn(styles.helpText, "hide-md")}>
                    <LinkedEnvironmentSummary linkedEnvironments={linkedEnvironments} environments={this.props.lookupData.environments}/>
                </div>
                <OverflowMenu menuItems={[
                OverflowMenuItems.navItem("Edit Project Template", links.projectTenantProjectTemplatesPage.generateUrl({ spaceId: this.props.lookupData.tenant.SpaceId, projectSlug: projectVariables.ProjectId }), {
                    permission: Permission.VariableView,
                    wildcard: true,
                }),
            ]}/>
            </div>);
    };
    renderAllProjectVariables = (missingVariableIndex: MissingVariablesIndex) => {
        const projectVariables = this.state.model.ProjectVariables;
        const projects: TenantProjectVariable[] = sortBy(Object.keys(projectVariables).map((projectId) => projectVariables[projectId]), (project) => project.ProjectName.toLowerCase());
        const projectsAsItems = Object.keys(projectVariables).map((p) => {
            const projectVariable = projectVariables[p];
            return {
                Name: projectVariable.ProjectName,
                Id: projectVariable.ProjectId,
            } as ProjectResource;
        });
        if (projects.length === 0) {
            return (<div>
                    <SectionNote>
                        Project variables allow tenants to have different values for each connected projects linked environments. Learn more about <ExternalLink href="MultiTenantVariables">tenant-specific variables</ExternalLink>.
                    </SectionNote>
                    <NoResults />
                </div>);
        }
        return (<div>
                {Object.keys(missingVariableIndex.projects).length !== 0 && this.renderMissingVariablesMessage()}
                <SectionNote>
                    Project variables allow tenants to have different values for each connected projects linked environments. Learn more about <ExternalLink href="MultiTenantVariables">tenant-specific variables</ExternalLink>.
                </SectionNote>
                <div style={{ width: "15.6rem", paddingLeft: "1rem" }}>
                    <ProjectMultiSelect items={sortBy(projectsAsItems, (project) => project.Name.toLowerCase())} value={this.state.projectIds} label="Filter by project" onChange={(projectIds) => this.setState((prevState) => ({ ...prevState, projectIds }))}/>
                </div>

                {/*
             * SimpleExpanders nested inside SimpleList items results in a janky expansion transition.
             * This change was made as a tactical fix to improve performance, we haven't made the effort to
             * fix the transition as it's lower priority
             */}
                <SimplePagingList pageSize={20} items={projects.filter((p) => this.state.projectIds.length === 0 || this.state.projectIds.indexOf(p.ProjectId) !== -1)} onRow={(item) => this.renderProjectVariable(item, missingVariableIndex)} hideItemPadding/>
            </div>);
    };
    renderMissingVariablesMessage() {
        return (<Callout type={"warning"} title="Provide missing variable values">
                If required variable values are not set it could cause the deployment to fail.
            </Callout>);
    }
    renderAllLibraryVariables = (missingVariableIndex: MissingVariablesIndex) => {
        const variables = sortBy(Object.keys(this.state.model.LibraryVariables).map((libraryVariableSetId) => this.state.model.LibraryVariables[libraryVariableSetId]), (libraryVariableSet) => libraryVariableSet.LibraryVariableSetName).filter((libraryVariable) => libraryVariable.Templates.length > 0);
        if (variables.length === 0) {
            return (<div>
                    <SectionNote>
                        Common variables are values that remain constant across connected projects and linked environments for this tenant eg. tenant alias and contact details. Learn more about{" "}
                        <ExternalLink href="MultiTenantVariables">tenant-specific variables</ExternalLink>.
                    </SectionNote>
                    <NoResults />
                </div>);
        }
        return (<div>
                {Object.keys(missingVariableIndex.libraries).length !== 0 && this.renderMissingVariablesMessage()}
                <SectionNote>
                    Common variables are values that remain constant across connected projects and linked environments for this tenant eg. tenant alias and contact details. Learn more about{" "}
                    <ExternalLink href="MultiTenantVariables">tenant-specific variables</ExternalLink>.
                </SectionNote>
                <ExpansionButtons />
                {variables.map((v) => this.renderLibraryVariables(v, missingVariableIndex))}
            </div>);
    };
    renderLibraryVariables = (libraryVariableSet: TenantLibraryVariable, missingVariableIndex: MissingVariablesIndex) => {
        const missingVariables = missingVariableIndex.libraries[libraryVariableSet.LibraryVariableSetId];
        const libraryVariables = libraryVariableSet.Templates.map((t) => t.Name);
        return (<SimpleExpander title={libraryVariableTitle(this.props.lookupData.tenant.SpaceId, missingVariables, libraryVariableSet)} errorKey={libraryVariableSet.LibraryVariableSetId} key={libraryVariableSet.LibraryVariableSetId}>
                <div>{libraryVariableSet.Templates.map((template) => this.renderLibraryVariable(template, libraryVariableSet.LibraryVariableSetId, libraryVariables, missingVariableIndex))}</div>
            </SimpleExpander>);
    };
    renderProjectVariable = (projectVariables: TenantProjectVariable, missingVariableIndex: MissingVariablesIndex) => {
        const linkedEnvironments = this.props.lookupData.tenant.ProjectEnvironments[projectVariables.ProjectId];
        const missingVariables = missingVariableIndex.projects[projectVariables.ProjectId];
        const tenantId = this.props.lookupData.tenant.Id;
        const linkedEnvironmentsFiltered = this.props.lookupData.environments
            .filter((env) => linkedEnvironments.includes(env.Id))
            .map((env) => {
            return {
                id: env.Id,
                sortOrder: env.SortOrder,
            };
        });
        const linkedEnvironmentsSorted = sortBy(linkedEnvironmentsFiltered, (x) => x.sortOrder).map((env) => env.id);
        return (<>
                <SimpleExpander title={this.projectVariableTitle(projectVariables, missingVariables, linkedEnvironments)} errorKey={projectVariables.ProjectId} key={projectVariables.ProjectId}>
                    <div>
                        {projectVariables.Templates.length === 0 ? (<SectionNote>There are no variable templates for this project.</SectionNote>) : linkedEnvironments.length === 0 ? (<Callout title="No environments have been selected for this project" type={"warning"}>
                                To add environments to this project go to the <InternalLink to={links.tenantOverviewPage.generateUrl({ spaceId: this.props.lookupData.tenant.SpaceId, tenantId })}>overview page</InternalLink>.
                            </Callout>) : (linkedEnvironmentsSorted.map((environmentId) => this.renderProjectEnvironmentVariable(projectVariables, environmentId, missingVariableIndex)))}
                    </div>
                </SimpleExpander>
            </>);
    };
    renderProjectEnvironmentVariable = (projectVariables: TenantProjectVariable, environmentId: string, missingVariableIndex: MissingVariablesIndex) => {
        const variableTemplates = Object.keys(projectVariables.Templates).map((templateId) => (projectVariables.Templates as any)[templateId]) as TenantVariableTemplateResource[];
        const templateNames = uniq(variableTemplates.map((p) => p.Name));
        // check we have an environment, `state.environments` may not contain it due to the users permissions
        return (<Lookup lookupCollection={this.props.lookupData.environments} lookupId={environmentId} getIdFromElement={(element: EnvironmentResource) => element.Id} render={(item: EnvironmentResource) => (<SimpleExpander title={<div className={styles.cardTitle}>
                                <span className={styles.projectEnvironmentVariableTitle}>{`${item.Name} variables`}</span>
                            </div>} errorKey={item.Id} key={item.Id} isExpandedByDefault={true}>
                        <div>{variableTemplates.map((template) => this.renderVariable(template, projectVariables.ProjectId, environmentId, templateNames, missingVariableIndex))}</div>
                    </SimpleExpander>)}/>);
    };
    renderLibraryVariable = (variableTemplate: TenantVariableTemplateResource, libraryVariableSetId: string, otherBindableVariables: string[], missingVariableIndex: MissingVariablesIndex) => {
        const missingVariables = (missingVariableIndex.libraries[libraryVariableSetId] || []).indexOf(variableTemplate.Id) !== -1;
        return (<div className={styles.variableContainer} key={variableTemplate.Id}>
                <TenantVariableInputFormElement variableTemplate={variableTemplate} sourceItems={{
                accounts: {
                    items: this.state.accounts,
                    type: [AccountType.AzureServicePrincipal, AccountType.AzureSubscription, AccountType.AzureOidc],
                    onRequestRefresh: this.refreshAccounts,
                },
                certificates: {
                    items: this.getCertificates,
                    onRequestRefresh: this.refreshCertificates,
                },
                workerPools: {
                    items: this.getWorkerPools,
                    onRequestRefresh: this.refreshWorkerPools,
                },
                tenantId: this.props.lookupData.tenant.Id,
            }} localNames={otherBindableVariables} doBusyTask={this.doBusyTask} value={this.state.model.LibraryVariables[libraryVariableSetId].Variables[variableTemplate.Id]} warning={missingVariables ? "Value required for deployment" : undefined} onChange={(newVal) => this.handleLibraryVariableChanged(libraryVariableSetId, variableTemplate.Id, newVal)}/>
            </div>);
    };
    handleLibraryVariableChanged = (libraryVariableSetId: string, displayPropsId: string, value: any) => {
        this.setState((state) => ({
            model: {
                ...state.model,
                LibraryVariables: {
                    ...state.model.LibraryVariables,
                    [libraryVariableSetId]: {
                        ...state.model.LibraryVariables[libraryVariableSetId],
                        Variables: {
                            ...state.model.LibraryVariables[libraryVariableSetId].Variables,
                            [displayPropsId]: value,
                        },
                    },
                },
            },
        }));
    };
    renderVariable = (variableTemplate: TenantVariableTemplateResource, projectId: string, environmentId: string, otherBindableVariables: string[], missingVariableIndex: MissingVariablesIndex) => {
        const missingVariables = ((missingVariableIndex.projects[projectId] || {})[environmentId] || []).indexOf(variableTemplate.Id) !== -1;
        return (<div className={styles.variableContainer} key={variableTemplate.Id}>
                <TenantVariableInputFormElement variableTemplate={variableTemplate} sourceItems={{
                accounts: {
                    items: this.state.accounts,
                    type: [AccountType.AzureServicePrincipal, AccountType.AzureSubscription, AccountType.AzureOidc],
                    onRequestRefresh: this.refreshAccounts,
                },
                certificates: {
                    items: this.getCertificates,
                    onRequestRefresh: this.refreshCertificates,
                },
                workerPools: {
                    items: this.getWorkerPools,
                    onRequestRefresh: this.refreshWorkerPools,
                },
                tenantId: this.props.lookupData.tenant.Id,
            }} localNames={otherBindableVariables} doBusyTask={this.doBusyTask} value={this.state.model.ProjectVariables[projectId].Variables[environmentId][variableTemplate.Id]} warning={missingVariables ? "Value required for deployment" : undefined} onChange={(newVal) => this.handleProjectVariableChanged(projectId, environmentId, variableTemplate.Id, newVal)}/>
            </div>);
    };
    handleProjectVariableChanged = (projectId: string, environmentId: string, displayPropsId: string, value: any) => {
        this.setState((state) => ({
            model: {
                ...state.model,
                ProjectVariables: {
                    ...state.model.ProjectVariables,
                    [projectId]: {
                        ...state.model.ProjectVariables[projectId],
                        Variables: {
                            ...state.model.ProjectVariables[projectId].Variables,
                            [environmentId]: {
                                ...state.model.ProjectVariables[projectId].Variables[environmentId],
                                [displayPropsId]: value,
                            },
                        },
                    },
                },
            },
        }));
    };
    render() {
        const { tenant } = this.props.lookupData;
        const missingVariableIndex = this.buildMissingVariableIndex(this.state.missingVariables);
        return (<LegacyForm onSaveClick={this.handleSaveClick} savePermission={{
                permission: Permission.VariableEdit,
                tenant: this.props.lookupData.tenant.Id,
                wildcard: true,
            }} cleanModel={this.state.cleanModel} model={this.state.model} saveText="Tenant details updated">
                {({ FormContent, createSaveAction }) => (<PaperLayoutVNext primaryAction={createSaveAction({})} title="Tenant Variables" busy={this.state.busy} errors={this.errors}>
                        <FormContent hideExpandAll={true}>
                            {tenant && (<UrlNavigationTabsContainer<TenantVariableActiveTab> defaultValue={TenantVariableActiveTab.ProjectVariables}>
                                    {this.props.lookupData.hasLibraryVariableSetView && (<TabItem label="Common Variables" warning={Object.keys(missingVariableIndex.libraries).length > 0 ? `Some of the required variable values have not been set` : undefined} value={TenantVariableActiveTab.CommonVariables}>
                                            {this.renderAllLibraryVariables(missingVariableIndex)}
                                        </TabItem>)}{" "}
                                    <TabItem label={"Project Variables"} warning={Object.keys(missingVariableIndex.projects).length > 0 ? `Some of the required variable values have not been set` : undefined} value={TenantVariableActiveTab.ProjectVariables}>
                                        {this.renderAllProjectVariables(missingVariableIndex)}
                                    </TabItem>
                                </UrlNavigationTabsContainer>)}
                        </FormContent>
                    </PaperLayoutVNext>)}
            </LegacyForm>);
    }
    private refreshAccounts = async () => {
        await this.doBusyTask(async () => {
            this.setState({ accounts: await repository.Accounts.all() });
        });
    };
    private getCertificates = async () => {
        let certificates = this.state.certificates;
        if (!certificates) {
            certificates = await this.memoizedRepositoryCertificatesList();
            this.setState({ certificates });
        }
        return certificates;
    };
    private refreshCertificates = () => {
        return this.doBusyTask(async () => {
            this.setState({ certificates: await repository.Certificates.listForTenant(this.props.lookupData.tenant.Id) });
        });
    };
    private getWorkerPools = async () => {
        let workerPools = this.state.workerPools;
        if (!workerPools) {
            workerPools = await this.memoizedRepositoryWorkerPoolList();
            this.setState({ workerPools });
        }
        return workerPools;
    };
    private refreshWorkerPools = () => {
        return this.doBusyTask(async () => {
            this.setState({ workerPools: await repository.WorkerPools.all() });
        });
    };
    static displayName = "TenantVariablesLayoutInternal";
}

import { Divider, RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import type { GitCredentialResource, GitDependencyReference } from "@octopusdeploy/octopus-server-client";
import type { GitSourceReference } from "@octopusdeploy/step-inputs";
import type { ObjectRuntimeInputs, PlainObjectTypeDefinition, PathToInput, RuntimeExternalGitSourceSelection, RuntimeProjectGitSourceSelection } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessor, isBoundValue, isNotBoundValue } from "@octopusdeploy/step-runtime-inputs";
import type { GitSourceSelectorComponent } from "@octopusdeploy/step-ui";
import React, { useState } from "react";
import GitCredentialSelect from "~/areas/projects/components/ProjectSettings/VersionControl/GitCredentialSelect";
import { ExternalGitRepositorySourceContextualHelp, ProjectGitRepositorySourceContextualHelp } from "~/components/GitRepositorySource/GitRepositorySourceContextualHelp";
import { isProjectRepositoryGitSourceSupported } from "~/components/GitRepositorySource/GitRepositorySourceSelector";
import { convertFromRuntimeGitSourceSelection, convertToRuntimeGitSourceSelection } from "~/components/StepPackageEditor/Inputs/Components/GitSourceSelector/GitSourceSelectionConverters";
import { Note as StepPackageNote } from "~/components/StepPackageEditor/Inputs/Note/Note";
import { isProjectStepInputDependencies, type SetGitDependencies, type StepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { required } from "~/components/form/index";
import Note from "~/primitiveComponents/form/Note/Note";
import { RadioButtonWithContextualHelp } from "~/primitiveComponents/form/RadioButton/RadioButtonWithContextualHelp";
export function getGitSourceSelectorSummary<StepInputs>(content: GitSourceSelectorComponent, inputs: ObjectRuntimeInputs<StepInputs>, gitCredentials: GitCredentialResource[]): InputSummary {
    const inputAccessor = createInputValueAccessor(content.input);
    const inputValue = inputAccessor.getInputValue(inputs);
    if (isNotBoundValue(inputValue)) {
        const runtimeGitSourceSelection = convertToRuntimeGitSourceSelection(inputValue);
        if (runtimeGitSourceSelection.type === "project") {
            return { isDefaultValue: false, value: "The repository configured in version control settings" };
        }
        else {
            return { isDefaultValue: false, value: runtimeGitSourceSelection.url ?? "No Git source configured" };
        }
    }
    throw new Error("Git source selection cannot be bound");
}
interface ExternalGitSourceSelectorProps<StepInputs> {
    configuredStepUIProps: GitSourceSelectorComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    setGitDependencies: SetGitDependencies;
    dependencies: StepInputDependencies;
    localNames: string[] | undefined;
    getFieldError: (path: string | PathToInput) => string;
}
function updateGitDependencies(setGitDependencies: SetGitDependencies, gitDependency: GitDependencyReference | undefined): void {
    setGitDependencies((existing) => {
        const updated = (existing || []).filter((gd) => gd.Name !== "");
        if (gitDependency) {
            updated.push(gitDependency);
        }
        return updated;
    });
}
function ExternalGitSourceSelector<StepInputs>(props: ExternalGitSourceSelectorProps<StepInputs>) {
    const inputAccessor = createInputValueAccessor<StepInputs, GitSourceReference>(props.configuredStepUIProps.input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    if (isBoundValue(inputValue)) {
        throw new Error("Git source selection can't be bound");
    }
    const runtimeGitSourceSelection = convertToRuntimeGitSourceSelection(inputValue);
    if (runtimeGitSourceSelection.type !== undefined && runtimeGitSourceSelection.type !== "external") {
        throw new Error("Git source selection is not external");
    }
    const referenceId = runtimeGitSourceSelection.referenceId ?? "";
    const [isAnonymous, setIsAnonymous] = useState(runtimeGitSourceSelection.isAnonymous ?? false);
    const [credential, setCredential] = useState(runtimeGitSourceSelection.gitCredential);
    const [url, setUrl] = useState(runtimeGitSourceSelection.url ?? "");
    const [defaultBranch, setDefaultBranch] = useState(runtimeGitSourceSelection.defaultBranch ?? "");
    function updateInputs(isAnonymous: boolean, gitCredential: string | undefined, url: string, defaultBranch: string) {
        const updatedGitSource: RuntimeExternalGitSourceSelection = { type: "external", isAnonymous, gitCredential, url, defaultBranch, referenceId };
        const updatedInputs = inputAccessor.changeInputValue(props.inputs, convertFromRuntimeGitSourceSelection(updatedGitSource));
        props.setInputs(updatedInputs);
    }
    if (isNotBoundValue(inputValue)) {
        return (<>
                <RadioButtonGroup value={isAnonymous ? "Anonymous" : "Library"} onChange={(val) => {
                updateInputs(val === "Anonymous", credential, url, defaultBranch);
                setIsAnonymous(val === "Anonymous");
                updateGitDependencies(props.setGitDependencies, {
                    Name: "",
                    RepositoryUri: url,
                    DefaultBranch: defaultBranch,
                    GitCredentialId: credential,
                    GitCredentialType: val,
                    StepPackageInputsReferenceId: referenceId,
                    FilePathFilters: [],
                });
            }} accessibleName="GitCredentialType" error={props.getFieldError("GitCredentialType")}>
                    <RadioButton value={"Library"} label="Git credentials" accessibleName="GitCredentialTypeLibrary"/>
                    {!isAnonymous && (<div>
                            <GitCredentialSelect items={props.dependencies.gitCredentials} onRequestRefresh={props.dependencies.refreshGitCredentials} onChange={(newValue) => {
                    updateInputs(isAnonymous, newValue, url, defaultBranch);
                    setCredential(newValue);
                    updateGitDependencies(props.setGitDependencies, {
                        Name: "",
                        RepositoryUri: url,
                        DefaultBranch: defaultBranch,
                        GitCredentialId: newValue,
                        GitCredentialType: isAnonymous ? "Anonymous" : "Library",
                        StepPackageInputsReferenceId: referenceId,
                        FilePathFilters: [],
                    });
                }} 
            /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
            value={credential as string} helperText="Select the Git credential to use" error={props.getFieldError("GitCredentialId")}/>
                            <Note> Use credential from the Git credential library </Note>
                        </div>)}
                    <RadioButton value={"Anonymous"} label="Anonymous" accessibleName="GitCredentialTypeAnonymous"/>
                </RadioButtonGroup>

                <VariableLookupText key="Url" localNames={props.localNames} value={url} onChange={(url) => {
                updateInputs(isAnonymous, credential, url, defaultBranch);
                setUrl(url);
                updateGitDependencies(props.setGitDependencies, {
                    Name: "",
                    RepositoryUri: url,
                    DefaultBranch: defaultBranch,
                    GitCredentialId: credential,
                    GitCredentialType: isAnonymous ? "Anonymous" : "Library",
                    StepPackageInputsReferenceId: referenceId,
                    FilePathFilters: [],
                });
            }} label="Repository URL" accessibleName={"URL for Git repository"} error={props.getFieldError("RepositoryUri")} validate={required("Enter a Git repository URL.")}/>
                <Note>
                    The HTTPS URL to your git repo. E.g.{" "}
                    <code>
                        https://
                        <wbr />
                        github.com/
                        <wbr />
                        OctopusDeploy/
                        <wbr />
                        OctoPetShop.git
                    </code>
                </Note>

                <VariableLookupText key="DefaultBranch" localNames={props.localNames} value={defaultBranch} onChange={(defaultBranch) => {
                updateInputs(isAnonymous, credential, url, defaultBranch);
                setDefaultBranch(defaultBranch);
                updateGitDependencies(props.setGitDependencies, {
                    Name: "",
                    RepositoryUri: url,
                    DefaultBranch: defaultBranch,
                    GitCredentialId: credential,
                    GitCredentialType: isAnonymous ? "Anonymous" : "Library",
                    StepPackageInputsReferenceId: referenceId,
                    FilePathFilters: [],
                });
            }} label="Default branch" accessibleName={"Name of the default branch on the Git repository"} error={props.getFieldError("DefaultBranch")} validate={required("Enter the default branch for your Git repository.")}/>

                <StepPackageNote note={props.configuredStepUIProps.note}/>
            </>);
    }
    else {
        throw new Error("Git source selection can't be bound");
    }
}
interface GitSourceSelectorProps<StepInputs> {
    configuredStepUIProps: GitSourceSelectorComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    setGitDependencies: SetGitDependencies;
    dependencies: StepInputDependencies;
    localNames: string[] | undefined;
    getFieldError: (path: string | PathToInput) => string;
}
export function GitSourceSelector<StepInputs>(props: GitSourceSelectorProps<StepInputs>) {
    const project = isProjectStepInputDependencies(props.dependencies) ? props.dependencies.project : undefined;
    const processType = isProjectStepInputDependencies(props.dependencies) ? props.dependencies.processType : undefined;
    const supportsProjectRepository = isProjectRepositoryGitSourceSupported(project?.PersistenceSettings, processType);
    if (!supportsProjectRepository) {
        return <ExternalGitSourceSelector {...props}/>;
    }
    const inputAccessor = createInputValueAccessor<StepInputs, GitSourceReference>(props.configuredStepUIProps.input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    if (isBoundValue(inputValue)) {
        throw new Error("Git source selection can't be bound");
    }
    const runtimeGitSourceSelection = convertToRuntimeGitSourceSelection(inputValue);
    const gitSourceType = runtimeGitSourceSelection.type ?? "external";
    function handleGitSourceTypeChanged(value: "external" | "project") {
        if (value === "external") {
            const updatedGitSource: RuntimeExternalGitSourceSelection = { type: "external", isAnonymous: true, gitCredential: undefined, url: "", defaultBranch: "", referenceId: runtimeGitSourceSelection.referenceId };
            const updatedInputs = inputAccessor.changeInputValue(props.inputs, convertFromRuntimeGitSourceSelection(updatedGitSource));
            props.setInputs(updatedInputs);
            updateGitDependencies(props.setGitDependencies, {
                Name: "",
                RepositoryUri: "",
                DefaultBranch: "",
                GitCredentialId: undefined,
                GitCredentialType: "Anonymous",
                StepPackageInputsReferenceId: runtimeGitSourceSelection.referenceId,
                FilePathFilters: [],
            });
        }
        else {
            const updatedGitSource: RuntimeProjectGitSourceSelection = { type: "project", referenceId: runtimeGitSourceSelection.referenceId };
            const updatedInputs = inputAccessor.changeInputValue(props.inputs, convertFromRuntimeGitSourceSelection(updatedGitSource));
            props.setInputs(updatedInputs);
            updateGitDependencies(props.setGitDependencies, undefined);
        }
    }
    return (<>
            <RadioButtonGroup value={gitSourceType} onChange={handleGitSourceTypeChanged} accessibleName="GitSourceType" error={props.getFieldError("GitSourceType")}>
                <RadioButtonWithContextualHelp value="project" label="Project" accessibleName="GitSourceTypeProject" isDefault contextualHelp={<ProjectGitRepositorySourceContextualHelp project={project}/>}/>
                <RadioButtonWithContextualHelp value="external" label="External" accessibleName="GitSourceTypeExternal" contextualHelp={<ExternalGitRepositorySourceContextualHelp />}/>
            </RadioButtonGroup>
            {gitSourceType === "external" && (<>
                    <Divider />
                    <ExternalGitSourceSelector {...props}/>
                </>)}
        </>);
}

/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { ProjectResource, ActionTemplateSearchResource, GitRefResource } from "@octopusdeploy/octopus-server-client";
import { Permission, TenantedDeploymentMode, StartTrigger, SpecialVariables } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { useOptionalProjectContext } from "~/areas/projects/context";
import Chip from "~/components/Chips/Chip";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import getActionLogoUrl from "../../getActionLogoUrl";
import { useProcessContext } from "../Contexts/ProcessContext";
import { useProcessErrorSelectors } from "../Contexts/ProcessErrors/ProcessErrorsContext";
import { useProcessWarningSelectors } from "../Contexts/ProcessWarnings/ProcessWarningsContext";
import type { ProcessListLayoutLoaderLookupData } from "../ProcessListLayoutLoader";
import type { StoredStep, StoredAction } from "../types";
import { isDeploymentOrRunbookProcessIdentifier } from "../types";
import ProcessListItem from "./ProcessListItem";
import processListItemStyles from "./ProcessListItem.module.less";
function getTags(action: StoredAction, project: ProjectResource | undefined): string[] {
    if (project && project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
        return [];
    }
    return action.TenantTags;
}
interface ProcessListPageListItemForActionProps {
    lookups: ProcessListLayoutLoaderLookupData;
    actionTemplates: ActionTemplateSearchResource[];
    step: StoredStep;
    action: StoredAction;
    actionIndex: number;
    stepIndex?: number;
    gitRefResource: GitRefResource | undefined;
}
const ProcessListPageListItemForAction: React.FC<ProcessListPageListItemForActionProps> = ({ lookups, actionTemplates, step, action, stepIndex, gitRefResource }) => {
    const projectContext = useOptionalProjectContext();
    const { selectors, state: { processIdentifier }, } = useProcessContext();
    const isChildAction = !!stepIndex;
    let actionTypeName = action.ActionType;
    const actionTemplate = actionTemplates.find((x) => x.Type === action.ActionType);
    if (actionTemplate) {
        actionTypeName = actionTemplate.Name;
    }
    const stepNumber = selectors.getStepNumber(step.Id);
    const actionNumber = selectors.getActionNumber(action.Id);
    const processErrorSelectors = useProcessErrorSelectors();
    const errors = processErrorSelectors.getActionErrors(action.Id, selectors);
    const processWarningSelectors = useProcessWarningSelectors();
    const warnings = processWarningSelectors.getActionWarnings(action.Id, selectors);
    const feedViewPermissionGranted = isAllowed({
        permission: Permission.FeedView,
        project: isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectId : undefined,
        wildcard: true,
    });
    const environmentsById = lookups.environmentsById;
    const channelsById = lookups.channelsById;
    return (<ProcessListItem actionType={actionTypeName} logoUrl={getActionLogoUrl(action)} environmentsLookup={environmentsById
            ? action.Environments.map((key) => ({
                Key: key,
                Environment: environmentsById[key],
            }))
            : undefined} excludedEnvironmentsLookup={environmentsById
            ? action.ExcludedEnvironments.map((key) => ({
                Key: key,
                Environment: environmentsById[key],
            }))
            : undefined} channelsLookup={channelsById
            ? action.Channels.map((key) => ({
                Key: key,
                Channel: channelsById[key],
            }))
            : undefined} tags={getTags(action, projectContext?.state.model)} isDisabled={selectors.isActionDisabled(action.Id)} notes={action.Notes} actionErrors={errors} actionWarnings={warnings} index={`${isChildAction ? `${stepNumber}.${actionNumber}.` : `${stepNumber}.`}`} detailsUrl={selectors.getStepDetailsUrl(gitRefResource, step.Id, action.Id)} isParentGroup={false} name={action.Name} isRunInParallelWithLast={step.StartTrigger === StartTrigger.StartWithPrevious && !selectors.isChildAction(action.Id) && !selectors.isFirstStep(step.Id)}>
            <div className={processListItemStyles.summaryContainer}>
                {action.plugin.summary(action.Properties, isChildAction ? "" : (step.Properties[SpecialVariables.Action.TargetRoles] as string), action.Packages, action.WorkerPoolId ? lookups.workerPoolsById[action.WorkerPoolId] : undefined, action.WorkerPoolVariable ?? undefined, action.GitDependencies ?? undefined)}
                {action.Container.Image && action.Container.FeedId && action.Properties["Octopus.Action.RunOnServer"] === "true" ? (<span style={{ paddingLeft: "0.1em" }}>
                        <Chip description={feedViewPermissionGranted ? `Container Image: ${action.Container.Image}` : undefined}>Runs in a container</Chip>
                    </span>) : ("")}
            </div>
        </ProcessListItem>);
};
ProcessListPageListItemForAction.displayName = "ProcessListPageListItemForAction"
export default ProcessListPageListItemForAction;

import { css } from "@emotion/css";
import { CircularProgress, GreenTickIcon, TriangleExclamationIcon } from "@octopusdeploy/design-system-components";
import { borderRadius, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { MachineModelHealthStatus } from "@octopusdeploy/octopus-server-client";
import classNames from "classnames";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
type DiscoveryProgressProps = React.PropsWithChildren<{
    name: string;
    isFound: boolean;
    healthStatus: MachineModelHealthStatus;
    showHelpLink?: boolean;
    bearerTokenExpired?: boolean;
}>;
export const DiscoveryProgress = ({ showHelpLink = true, ...props }: DiscoveryProgressProps) => {
    let boxStyle = "";
    let healthcheckContent: JSX.Element | null = null;
    const connectionContent: JSX.Element = (<>
            {props.isFound ? (<>
                    <div className={styles.iconContainer}>
                        <GreenTickIcon />
                    </div>
                    <span>
                        <b>{props.name}</b> established a connection
                    </span>
                </>) : (<>
                    <div className={styles.iconContainer}>
                        <CircularProgress size="container" aria-hidden="true"/>
                    </div>
                    <span>
                        Waiting for <b>{props.name}</b> to establish a connection...
                    </span>
                </>)}
        </>);
    if (props.healthStatus !== MachineModelHealthStatus.Unknown) {
        if (props.healthStatus === MachineModelHealthStatus.Unhealthy || props.healthStatus === MachineModelHealthStatus.Unavailable) {
            boxStyle = styles.failure;
            healthcheckContent = (<>
                    <div className={styles.iconContainer}>
                        <em className={classNames("fa-solid fa-exclamation-triangle", styles.alertIcon)}/>
                    </div>
                    <span>
                        <b>{props.name}</b> health check failed
                        {showHelpLink && (<>
                                . For help, <ExternalLink href={"kubernetes-agent"}>read the docs</ExternalLink>
                            </>)}
                    </span>
                </>);
        }
        else {
            boxStyle = styles.success;
            healthcheckContent = (<>
                    <div className={styles.iconContainer}>
                        <GreenTickIcon />
                    </div>

                    <span>
                        <b>{props.name}</b> is healthy
                    </span>
                </>);
        }
    }
    else if (props.bearerTokenExpired) {
        boxStyle = styles.failure;
        healthcheckContent = (<>
                <div className={styles.iconContainer}>
                    <TriangleExclamationIcon size={20} color={"danger"}/>
                </div>
                <span>
                    <strong>Your bearer token expired</strong> before the agent could establish a connection. To try again, add another Kubernetes Agent.
                </span>
            </>);
    }
    else {
        boxStyle = styles.waiting;
        healthcheckContent = props.isFound ? (<>
                <div className={styles.iconContainer}>
                    <CircularProgress size="small" aria-hidden="true"/>
                </div>
                <span>Performing health check...</span>
            </>) : null;
    }
    return (<div className={classNames(styles.box, boxStyle)} role="alert">
            <div className={styles.contentContainer}>{connectionContent}</div>
            {healthcheckContent && <div className={styles.contentContainer}>{healthcheckContent}</div>}
            {props.children && <div>{props.children}</div>}
        </div>);
};
DiscoveryProgress.displayName = "TentacleDiscoveryProgress";
const styles = {
    box: css({
        padding: `${space[12]} ${space[16]}`,
        alignSelf: "stretch",
        display: "grid",
        marginTop: space[16],
        fontSize: "14/16rem",
        color: themeTokens.color.text.primary,
        borderRadius: borderRadius["small"],
    }),
    waiting: css({
        backgroundColor: themeTokens.color.callout.background.info.default,
    }),
    success: css({
        backgroundColor: themeTokens.color.callout.background.success.default,
    }),
    failure: css({
        backgroundColor: themeTokens.color.callout.background.danger.default,
    }),
    contentContainer: css({
        display: "flex",
        alignItems: "center",
        gap: "0.375rem",
        "&:not(:last-child)": {
            marginBottom: space[16],
        },
    }),
    iconContainer: css({
        padding: `${space[4]} ${space[2]} 0 ${space[2]}`,
        marginRight: "0.25rem",
        width: "24px",
    }),
    alertIcon: css({
        fontSize: "23px",
        color: themeTokens.color.icon.danger,
    }),
};

import * as React from "react";
import IconButtonList from "~/components/IconButtonList/IconButtonList";
import type { SelectProps } from "../../../primitiveComponents/form/Select/Select";
import Select from "../../../primitiveComponents/form/Select/Select";
import InputWithActions from "../../InputWithActions/InputWithActions";
import { withBoundField } from "../BoundField/BoundField";
interface SelectWithActionsProps extends SelectProps {
    actions: JSX.Element[];
    inlineButton?: React.ReactNode;
}
export const SelectWithActions: React.FC<SelectWithActionsProps> = ({ actions, inlineButton, ...selectProps }) => {
    return (<InputWithActions input={<Select {...selectProps} inlineButton={inlineButton}/>} actions={<div>
                    <IconButtonList buttons={actions}/>
                </div>}/>);
};
SelectWithActions.displayName = "SelectWithActions"
export const BoundSelectWithActions = withBoundField(SelectWithActions);

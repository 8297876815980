import { css } from "@emotion/css";
import { ActionButton, ActionButtonType, InlineSnackbar, Callout, Tooltip } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { FeedType, getFeedTypeLabel, isOctopusProjectFeed, Permission } from "@octopusdeploy/octopus-server-client";
import type { FeedResource } from "@octopusdeploy/octopus-server-client";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import { useCallback } from "react";
import * as React from "react";
import { useCorrelationId } from "~/analytics/Analytics";
import { ExternalFeedDrawer } from "~/areas/library/components/ExternalFeeds/ExternalFeedDrawer";
import { createAddFeedAnalyticsEvent } from "~/areas/library/components/ExternalFeeds/amplitudeTracking";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList/index";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip/index";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SelectWithAddRefresh, { BoundSelectWithAddRefresh } from "~/components/form/SelectWithAddRefresh/SelectWithAddRefresh";
interface FeedSelectorProps {
    localNames: string[] | undefined;
    allowBoundVariables?: boolean;
    feeds: FeedResource[];
    feedId: string | undefined;
    refreshFeeds: () => Promise<void>;
    onChange: (selectedFeed: FeedResource | string) => void;
    feedSelectLabel?: string;
    autoFocus?: boolean;
    feedIdError?: string;
    feedType?: FeedType[];
}
export const getDefaultFeedId = (feeds: FeedResource[]): string | undefined => {
    if (feeds.length === 0) {
        return undefined;
    }
    const buildtIn = feeds.find((f) => f.FeedType === FeedType.BuiltIn);
    if (buildtIn) {
        return buildtIn.Id;
    }
    return feeds[0].Id;
};
const noFeedsWarningContainerStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space[16],
});
const NoFeedsWarning = ({ feedType, disableAdd, addButtonTooltipText, refreshFeeds, handleAddFeed, }: {
    feedType?: FeedType[];
    disableAdd: boolean;
    addButtonTooltipText?: string | undefined;
    refreshFeeds?: () => Promise<void>;
    handleAddFeed?: () => void;
}) => {
    //We can't just use the passed in refresh feeds handler since it may not expect an event - it's probably more likely to expect a callback
    const handleRefresh = async () => {
        if (refreshFeeds) {
            return refreshFeeds();
        }
    };
    const actions: JSX.Element[] = [];
    const addExternalFeedButton = <ActionButton type={ActionButtonType.Secondary} label={`Add External Feed`} onClick={handleAddFeed} disabled={disableAdd}/>;
    actions.push(disableAdd ? <Tooltip content={addButtonTooltipText}>{addExternalFeedButton}</Tooltip> : addExternalFeedButton);
    actions.push(<IconButtonWithTooltip onClick={handleRefresh} toolTipContent="Refresh" icon="Refresh"/>);
    return (<div className={noFeedsWarningContainerStyles}>
            <div>Add a {`${getFeedTypeLabel(feedType)}`} as an external package feed</div>
            <ActionList alignStart={true} actions={actions}/>
        </div>);
};
export const possibleFeeds = (feeds: FeedResource[], feedType?: FeedType[]) => {
    return feeds.filter((f) => !isOctopusProjectFeed(f.FeedType) && // We will never show the OctopusProject feed when selecting packages
        (!feedType || feedType.length === 0 || feedType.some((feedTypeToCheck) => feedTypeToCheck === f.FeedType)));
};
export const FeedSelector = ({ onChange, refreshFeeds, allowBoundVariables = true, ...props }: FeedSelectorProps) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const trackEvent = useTrackEvent();
    const analyticsCorrelationId = useCorrelationId();
    const defaultFeedId = getDefaultFeedId(props.feeds);
    let selectedFeedId = props.feedId;
    if (selectedFeedId === null) {
        selectedFeedId = defaultFeedId;
    }
    const feeds = possibleFeeds(props.feeds, props.feedType);
    const feedSelectLabel = props.feedSelectLabel || "Package feed";
    const handleOnChange = (feedId: string | undefined) => {
        const feed = props.feeds.find((f) => f.Id === feedId);
        if (feed) {
            onChange(feed);
        }
        else if (feedId !== undefined) {
            onChange(feedId);
        }
    };
    const onClose = () => {
        setIsOpen(false);
    };
    const handleOnSave = useCallback(async (feed: FeedResource) => {
        await refreshFeeds();
        onChange(feed);
        setIsOpen(false);
        setTimeout(() => setShowSnackbar(true), 300);
    }, [refreshFeeds, onChange]);
    const handleAddFeed = () => {
        setIsOpen(true);
        trackEvent(createAddFeedAnalyticsEvent(getFeedTypeLabel(props.feedType), analyticsCorrelationId));
    };
    const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, wildcard: true });
    const feedEditPermissionGranted = isAllowed({ permission: Permission.FeedEdit, wildcard: true });
    const addAddFeedButtonTooltipText = feedEditPermissionGranted ? undefined : "FeedEdit permissions required to add or edit a new feed";
    if (!feedViewPermissionGranted) {
        return (<Callout type={"warning"} title={"Permission required"}>
                You do not have permission to perform this action. Please contact your Octopus administrator. Missing permission: {Permission.FeedView}
            </Callout>);
    }
    if (feeds.length === 0) {
        return (<>
                <NoFeedsWarning feedType={props.feedType} disableAdd={!feedEditPermissionGranted} addButtonTooltipText={addAddFeedButtonTooltipText} refreshFeeds={refreshFeeds} handleAddFeed={handleAddFeed}/>
                <ExternalFeedDrawer isOpen={isOpen} onClose={onClose} onSave={handleOnSave} allowedFeedTypes={props.feedType} analyticsCorrelationId={analyticsCorrelationId}/>
            </>);
    }
    const commonSelectorProps = {
        items: feeds.map((f) => ({ value: f.Id, text: f.Name })),
        value: selectedFeedId,
        onRequestRefresh: refreshFeeds,
        onChange: handleOnChange,
        autoFocus: props.autoFocus,
        error: props.feedIdError,
        label: feedSelectLabel,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        addUrl: links.feedsPage.generateUrl({ spaceId: repository.spaceId! }),
        addAction: handleAddFeed,
        disableAdd: !feedEditPermissionGranted,
        addButtonTooltipText: addAddFeedButtonTooltipText,
    };
    return (<>
            {allowBoundVariables && (<BoundSelectWithAddRefresh {...commonSelectorProps} variableLookup={{
                localNames: props.localNames,
            }} resetValue={defaultFeedId} label={feedSelectLabel}/>)}
            {!allowBoundVariables && <SelectWithAddRefresh {...commonSelectorProps}/>}
            <div className={cn({ snackbarContainerStyles: showSnackbar })}>
                <InlineSnackbar variant="success" show={showSnackbar} content={"Feed Created"} onClose={() => setShowSnackbar(false)} autoHideDuration={3000}/>
            </div>
            <ExternalFeedDrawer isOpen={isOpen} onClose={onClose} onSave={handleOnSave} allowedFeedTypes={props.feedType} analyticsCorrelationId={analyticsCorrelationId}/>
        </>);
};

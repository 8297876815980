import { Tooltip, TriangleExclamationIcon } from "@octopusdeploy/design-system-components";
import * as React from "react";
interface MissingVariablesIcon {
    show: boolean;
    withoutTooltip?: boolean;
}
const MissingVariablesIcon: React.StatelessComponent<MissingVariablesIcon> = (props) => {
    const AlertIcon = () => <TriangleExclamationIcon size={24} color="warning"/>;
    if (!props.show) {
        return null;
    }
    if (props.withoutTooltip) {
        return <AlertIcon />;
    }
    return (<Tooltip content={`Some of the required variable values have not been set`}>
            <AlertIcon />
        </Tooltip>);
};
MissingVariablesIcon.displayName = "MissingVariablesIcon"
export default MissingVariablesIcon;

/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PageAction } from "@octopusdeploy/design-system-components";
import { Callout } from "@octopusdeploy/design-system-components";
import type { ChannelResource, ProjectResource, DeploymentProcessResource, ReferenceDataItem, DeploymentActionPackageResource } from "@octopusdeploy/octopus-server-client";
import { Permission, displayName } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import _, { chain, cloneDeep } from "lodash";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context/index";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { repository } from "~/clientInstance";
import { DeploymentActionPackageChip, MissingChip, ChipIcon } from "~/components/Chips";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import Lookup from "~/components/Lookup";
import { DeploymentActionPackageReferenceDataItem } from "~/components/MultiSelect/DeploymentActionPackageMultiSelect";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import { ExpandableFormSection, ExpansionButtons, Note, Select, Summary } from "~/components/form";
type Model = {
    deploymentActionPackage?: DeploymentActionPackageResource;
    channelId: string;
};
interface EditState extends OptionalFormBaseComponentState<Model> {
    channels: ChannelResource[];
    project: ProjectResource;
    deploymentProcess: DeploymentProcessResource;
    channelId: string;
    redirectTo?: LinkHref;
    actionPackages: DeploymentActionPackageResource[];
}
export type EditAutomaticReleaseCreationProps = EditReleaseCreationFeedTriggerProps & WithProjectContextInjectedProps;
class EditAutomaticReleaseCreationInternalPage extends FormBaseComponent<EditAutomaticReleaseCreationProps, EditState, Model> {
    channelNameMap: {
        [id: string]: ChannelResource;
    } = {};
    constructor(props: EditAutomaticReleaseCreationProps) {
        super(props);
        this.state = {
            model: null!,
            cleanModel: null!,
            channels: [],
            channelId: null!,
            project: null!,
            deploymentProcess: null!,
            actionPackages: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const { projectContextRepository } = this.props.projectContext.state;
            const { model: project } = this.props.projectContext.state;
            let channels: ChannelResource[] = [];
            let trigger: Model | undefined = project.AutoCreateRelease
                ? {
                    channelId: project.ReleaseCreationStrategy.ChannelId!,
                    deploymentActionPackage: project.ReleaseCreationStrategy.ReleaseCreationPackage,
                }
                : undefined;
            if (isAllowed({ permission: Permission.ProcessView, project: project.Id, tenant: "*" })) {
                channels = await this.loadChannels(project);
                channels.forEach((channel) => {
                    this.channelNameMap[channel.Id] = channel;
                });
                if (!trigger) {
                    trigger = { channelId: channels.at(0)?.Id ?? "" };
                }
            }
            const builtInFeed = await repository.Feeds.getBuiltIn();
            const deploymentProcess = await projectContextRepository.DeploymentProcesses.get();
            const actionPackages = _.chain(deploymentProcess.Steps)
                .flatMap((step) => step.Actions)
                .flatMap((action) => _.chain(action.Packages)
                .filter((pkg) => pkg.FeedId === builtInFeed.Id)
                .map((pkg) => ({ DeploymentAction: action.Name, PackageReference: pkg.Name! }))
                .value())
                .value();
            this.setState({
                model: trigger,
                channels,
                project,
                deploymentProcess,
                cleanModel: cloneDeep(trigger),
                actionPackages,
            });
        });
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true}/>;
        }
        const title = this.props.create ? "New Built-in Package Repository Trigger" : "Edit Built-in Package Repository Trigger";
        const legacyOverflowActions = [];
        if (this.state.model && !this.props.create) {
            legacyOverflowActions.push(OverflowMenuItems.deleteItemDefault("Release trigger", () => this.delete(), { permission: Permission.TriggerDelete, project: this.state.project && this.state.project.Id }));
        }
        const overflowMenu = OverflowMenuConverterVNext.convertAll(legacyOverflowActions);
        const saveText: string = this.props.create ? "Built-in package repository trigger created" : "Built-in package repository trigger updated";
        const breadcrumbTitle = "Triggers";
        const breadcrumbPath = links.deploymentTriggersPage.generateUrl({ spaceId: this.props.spaceId, projectSlug: this.props.projectSlug });
        const pageActions: PageAction[] = [];
        const packageActionAppliesToChannel = this.state.deploymentProcess &&
            _.chain(this.state.deploymentProcess.Steps)
                .flatMap((step) => step.Actions)
                .filter((action) => action.Name === this.state.model?.deploymentActionPackage?.DeploymentAction)
                .some((action) => action.Channels.length === 0 || action.Channels.some((channelId) => channelId === this.state.model?.channelId))
                .value();
        return (<LegacyForm model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{
                permission: this.props.create ? Permission.TriggerCreate : Permission.TriggerEdit,
                project: this.state.project && this.state.project.Id,
            }} saveText={saveText} onSaveClick={() => this.save()}>
                {({ FormContent, createSaveAction }) => (<PaperLayoutVNext primaryAction={createSaveAction({})} busy={this.state.busy} errors={this.errors} title={title} breadcrumbsItems={[{ label: breadcrumbTitle, pageUrl: breadcrumbPath }]} overflowActions={overflowMenu.menuItems} pageActions={pageActions}>
                        <FormContent hideExpandAll={true}>
                            {overflowMenu.dialogs}
                            {this.state.model && (<TransitionAnimation>
                                    <ExpansionButtons errors={this.errors?.fieldErrors} expandAllOnMount={this.props.create}/>
                                    {this.state.model.channelId && this.state.model.deploymentActionPackage?.DeploymentAction && !packageActionAppliesToChannel && (<Callout type="warning" title="Package action does not apply to channel">
                                            Package action <strong>{this.state.model.deploymentActionPackage?.DeploymentAction}</strong> does not apply to channel{" "}
                                            <strong>{this.state.channels.find((channel) => channel.Id === this.state.model?.channelId)?.Name}</strong>, this may cause unexpected results when the trigger runs.
                                        </Callout>)}
                                    {this.state.channels.length > 1 && (<ExpandableFormSection errorKey="Channel" title="Channel" focusOnExpandAll summary={this.buildChannelSummary()} help={"Select the channel to use when selecting packages for the release"}>
                                            <Select allowClear={true} items={this.state.channels.map((c) => ({ text: c.Name, value: c.Id }))} value={this.state.model.channelId} onChange={async (channelId) => this.setModelState({ channelId: channelId || "" })}/>
                                            <Note>The release created will use this channel and the pushed package must satisfy the selected channel's versioning rules (if any).</Note>
                                        </ExpandableFormSection>)}
                                    {this.state.actionPackages && (<ExpandableFormSection errorKey="Packages" title="Packages" focusOnExpandAll help="Select steps/package to watch for updates" summary={this.buildActionPackageSummary()}>
                                            <Select value={this.state.model.deploymentActionPackage ? displayName(this.state.model.deploymentActionPackage) : undefined} onChange={(packageActionIndex) => this.setModelState({ deploymentActionPackage: this.state.actionPackages.find((z) => displayName(z) === packageActionIndex) })} items={this.state.actionPackages.map((packageAction) => ({
                            value: displayName(packageAction),
                            text: displayName(packageAction),
                            disabled: this.stepIsDisabled(packageAction.DeploymentAction),
                        }))} label="Versioning package step"/>
                                        </ExpandableFormSection>)}
                                </TransitionAnimation>)}
                        </FormContent>
                    </PaperLayoutVNext>)}
            </LegacyForm>);
    }
    stepIsDisabled = (deploymentAction: string) => {
        const action = chain(this.state.deploymentProcess?.Steps)
            .flatMap((step) => step.Actions)
            .find((x) => x.Name === deploymentAction)
            .value();
        return action?.IsDisabled ?? false;
    };
    chipRenderer = (r: DeploymentActionPackageReferenceDataItem | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={this.state.actionPackages.map((x) => new DeploymentActionPackageReferenceDataItem(x, displayName(x)))} lookupId={r.Id} getIdFromElement={(element: ReferenceDataItem) => element.Id} render={(item: ReferenceDataItem) => {
                const deploymentActionPackageReferenceItem = item as DeploymentActionPackageReferenceDataItem;
                return <DeploymentActionPackageChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} actionPackage={deploymentActionPackageReferenceItem.ActionPackage}/>;
            }} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.StepAction} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    private async loadChannels(project: ProjectResource): Promise<ChannelResource[]> {
        return await this.loadChannelsFromProject(project);
    }
    private async loadChannelsFromProject(project: ProjectResource): Promise<ChannelResource[]> {
        const channelResourceCollection = await repository.Projects.getChannels(project);
        return channelResourceCollection.Items;
    }
    private getChannelNameGivenId = (channelId: string) => {
        const channel = this.channelNameMap[channelId];
        if (channel) {
            return channel.Name;
        }
        return channelId;
    };
    private buildChannelSummary = () => this.state.model?.channelId ? (Summary.summary(<span>
                    Latest packages in channel <strong>{this.getChannelNameGivenId(this.state.model.channelId)}</strong> will be used to create the release
                </span>)) : (<></>);
    private buildActionPackageSummary = () => this.state.model?.deploymentActionPackage ? Summary.summary(`${displayName(this.state.model.deploymentActionPackage)} will be watched for updates`) : Summary.summary("No packages selected to watch for updates");
    private async save() {
        await this.doBusyTask(async () => {
            if (!this.state.model?.deploymentActionPackage)
                return;
            const project = {
                ...this.state.project,
                AutoCreateRelease: true,
                ReleaseCreationStrategy: {
                    ReleaseCreationPackage: this.state.model.deploymentActionPackage,
                    ChannelId: this.state.model?.channelId || this.state.channels[0].Id,
                },
            };
            const result = await repository.Projects.save(project);
            if (this.props.create) {
                const redirectTo = links.editBuiltInFeedTriggerPage.generateUrl({ spaceId: result.SpaceId, projectSlug: this.props.projectSlug });
                this.setState({ redirectTo });
            }
            else {
                const updatedModel = {
                    channelId: result.ReleaseCreationStrategy.ChannelId!,
                    deploymentActionPackage: result.ReleaseCreationStrategy.ReleaseCreationPackage,
                };
                this.setState({
                    model: updatedModel,
                    cleanModel: cloneDeep(updatedModel),
                });
            }
        });
    }
    private async delete() {
        return await this.doBusyTask(async () => {
            if (!this.state.model?.deploymentActionPackage)
                return;
            const project = {
                ...this.state.project,
                AutoCreateRelease: false,
                ReleaseCreationStrategy: {
                    ReleaseCreationPackage: null!,
                    ChannelId: null!,
                },
            };
            const result = await repository.Projects.save(project);
            const redirectTo = links.deploymentTriggersPage.generateUrl({ spaceId: result.SpaceId, projectSlug: this.props.projectSlug });
            this.setState({ redirectTo });
            return true;
        });
    }
    static displayName = "EditAutomaticReleaseCreationInternalPage";
}
interface SharedProps {
    spaceId: string;
    projectSlug: string;
}
interface EditProps extends SharedProps {
    create: false;
}
interface CreateProps extends SharedProps {
    create: true;
}
type EditReleaseCreationFeedTriggerProps = EditProps | CreateProps;
export function EditAutomaticReleaseCreation(props: EditReleaseCreationFeedTriggerProps) {
    const projectContext = useProjectContext();
    return <EditAutomaticReleaseCreationInternalPage projectContext={projectContext} {...props}/>;
}
export default EditAutomaticReleaseCreation;

import { css } from "@emotion/css";
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { PropsWithChildren } from "react";
import * as React from "react";
import Note from "~/primitiveComponents/form/Note/Note";
interface RegardingDocumentsProps {
    onClear: () => void;
}
export function RegardingDocuments({ children, onClear }: PropsWithChildren<RegardingDocumentsProps>) {
    return (<div className={container}>
            <Note style={{ fontSize: "0.875rem" }}>{children}</Note>
            <div>
                <ActionButton type={ActionButtonType.Ternary} onClick={onClear} label="Clear"/>
            </div>
        </div>);
}
const container = css({
    padding: "0.5rem",
    border: `0.0625rem solid ${themeTokens.color.background.secondary.default}`,
    borderRadius: "0.125rem",
});

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { IsDefaultBranch, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { isRunningInAFunctionalTest } from "~/utils/isRunningInAFunctionalTest";
import { repository, session } from "../../clientInstance";
export interface PermissionCheckProps {
    permission: Permission | Permission[];
    children?: React.ReactNode;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    alternate?: any;
    project?: string;
    tenant?: string;
    environment?: string;
    projectGroup?: string;
    wildcard?: boolean;
    render?: () => JSX.Element;
    spaceScope?: SpaceScope;
}
const PermissionCheck: React.SFC<PermissionCheckProps> = ({ render, spaceScope, ...props }) => {
    const allowed = isAllowed(props, spaceScope);
    const alternate = props.alternate || null;
    const children = props.children || null;
    if (render) {
        return allowed ? render() : alternate;
    }
    const renderChildren = () => (children && typeof children === "function" ? children() : children);
    return <>{allowed ? renderChildren() : alternate}</>;
};
PermissionCheck.displayName = "PermissionCheck"
export function hasPermission(permission: Permission) {
    return isAllowed({ permission, wildcard: true });
}
type SpaceScope = "system" | "implicit" | {
    spaceId: string;
};
interface IsAllowedCheck {
    permission: Permission | Permission[];
    project?: string;
    tenant?: string;
    environment?: string;
    projectGroup?: string;
    wildcard?: boolean;
}
export function isAllowed(props: IsAllowedCheck, spaceScope: SpaceScope = "implicit") {
    const spaceId: string | null = spaceScope === "system" ? null : spaceScope === "implicit" ? repository.spaceId! : spaceScope.spaceId;
    return isAllowedForSpace(spaceId, props);
}
function isAllowedForSpace(spaceId: string | null, props: IsAllowedCheck) {
    const pid: string = props.project || (props.wildcard ? "*" : null!);
    const eid: string = props.environment || (props.wildcard ? "*" : null!);
    const gid: string = props.projectGroup || (props.wildcard ? "*" : null!);
    const tid: string = props.tenant || (props.wildcard ? "*" : null!);
    const permissions = props.permission instanceof Array ? props.permission : [props.permission];
    const checks: boolean[] = permissions.map((p) => {
        return getCurrentPermissions().scopeToSpaceAndSystem(spaceId).isAuthorized({
            permission: p,
            projectId: pid,
            environmentId: eid,
            projectGroupId: gid,
            tenantId: tid,
        });
    });
    return checks.some((x) => x);
}
export const isAllowedToRunGitRunbook = (project?: ProjectResource, gitRef?: string) => {
    if (!project) {
        throw new Error("Project is required to check permissions for running a Git Runbook.");
    }
    if (IsDefaultBranch(project.PersistenceSettings, gitRef)) {
        return isAllowed({ permission: Permission.RunbookRunCreate, project: project.Id, wildcard: true });
    }
    return isAllowed({ permission: Permission.RunbookRunCreate, project: project.Id, wildcard: true }) && isAllowed({ permission: Permission.RunbookEdit, project: project.Id, wildcard: true });
};
export function firstAuthorized(permissions: Permission[]): Permission {
    return getCurrentPermissions().scopeToSpaceAndSystem(repository.spaceId).firstAuthorized(permissions)!;
}
export function getCurrentPermissions() {
    const currentPermissions = session.currentPermissions;
    if (!currentPermissions) {
        const extraContextForFunctionalTests = " In your Functional Test, use `setupGlobals` to establish the session and refresh permissions, or call `setupGlobals.all(...)`." +
            " For more information, see https://github.com/OctopusDeploy/OctopusDeploy/blob/master/frontend/docs/functional_tests.md#globalambient-context`";
        const errorMessage = `The session has not been established with permissions.${isRunningInAFunctionalTest() ? extraContextForFunctionalTests : ""}`;
        throw new Error(errorMessage);
    }
    return currentPermissions;
}
export default PermissionCheck;

import { css } from "@emotion/css";
import { Tooltip, TriangleExclamationIcon } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ReactNode } from "react";
import React from "react";
import styles from "./ProcessActionErrorIndicator.module.less";
interface ProcessActionWarningIndicatorProps {
    actionWarnings: (string | ReactNode)[];
    accessibleName?: string;
}
const iconStyles = css({
    marginRight: space[8],
    paddingBottom: space[1], // visually align the warning icon
});
const containerStyles = css({
    alignSelf: "center",
    display: "flex",
});
export const ProcessActionWarningIndicator: React.FC<ProcessActionWarningIndicatorProps> = ({ actionWarnings, accessibleName }) => {
    const items = actionWarnings.map((x, index) => <li key={index}>{x}</li>);
    return (<div className={containerStyles}>
            <Tooltip content={<ul className={styles.itemContainer}>{items}</ul>}>
                <div className={iconStyles} role="img" aria-label={accessibleName}>
                    <TriangleExclamationIcon size={24} color="warning"/>
                </div>
            </Tooltip>
        </div>);
};
ProcessActionWarningIndicator.displayName = "ProcessActionWarningIndicator"

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Level2PageLayout } from "@octopusdeploy/design-system-components";
import * as React from "react";
interface LibraryLayoutProps {
    spaceId: string;
    children: React.ReactNode;
}
export function LibraryLayout({ spaceId, children }: LibraryLayoutProps) {
    return <Level2PageLayout navItems={[]} content={children}/>;
}
export default LibraryLayout;

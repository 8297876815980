/* eslint-disable no-eq-null */
import { css } from "@emotion/css";
import { Level2PageLayout } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { TenantMissingVariableResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { repository } from "~/clientInstance";
import Markdown from "~/components/Markdown";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import type { Errors } from "../../../components/DataBaseComponent";
import DataLoader from "../../../components/DataLoader";
import { useCreatePageSideNavLink } from "../../../hooks/useCreatePageSideNavLink";
import MissingVariablesIcon from "../MissingVariablesIcon/MissingVariablesIcon";
import { tenantsActions } from "../tenantsArea";
import type { TenantState } from "../tenantsArea";
interface TenantLayoutInternalProps {
    spaceId: string;
    tenant: TenantState;
}
interface TenantLayoutLoaderProps {
    tenantId: string;
    spaceId: string;
}
const TenantLoader = DataLoader<{}>();
const TenantLayoutLoader: React.FC<TenantLayoutLoaderProps> = (props) => {
    const tenantState = useSelector<GlobalState, TenantState | null>((s) => s.tenantsArea.currentTenant);
    const dispatch = useDispatch();
    return (<TenantLoader load={async () => {
            if (tenantState?.id !== props.tenantId) {
                const tenant = await repository.Tenants.get(props.tenantId);
                onTenantFetched(tenant);
                const variables = await repository.Tenants.missingVariables({ tenantId: tenant.Id }, false);
                onTenantVariablesFetched(variables.find((t) => t.TenantId === tenant.Id));
            }
            return {};
        }} operationName="TenantLayout" renderWhenLoaded={(_) => {
            if (tenantState == null || tenantState.id !== props.tenantId) {
                throw new Error("Tenant loaded and stored in redux does not exist, or does not match the current route");
            }
            return (<TenantLayoutInternal spaceId={props.spaceId} tenant={tenantState}>
                        {props.children}
                    </TenantLayoutInternal>);
        }} renderAlternate={({ errors }) => {
            return <TenantLoadingLayout errors={errors}/>;
        }}/>);
    function onTenantFetched(tenant: TenantResource) {
        dispatch(tenantsActions.tenantFetched(tenant));
    }
    function onTenantVariablesFetched(tenantMissingVariables?: TenantMissingVariableResource) {
        dispatch(tenantsActions.tenantMissingVariablesFetched(tenantMissingVariables));
    }
};
TenantLayoutLoader.displayName = "TenantLayoutLoader"
interface TenantLoadingLayoutProps {
    errors: Errors | undefined;
}
const TenantLoadingLayout: React.FC<TenantLoadingLayoutProps> = ({ errors }) => {
    return (<main>
            <SkeletonLoadingLayout errors={errors}/>
        </main>);
};
TenantLoadingLayout.displayName = "TenantLoadingLayout"
const TenantLayoutInternal: React.FC<TenantLayoutInternalProps> = ({ spaceId, tenant, children }) => {
    const createPageSideNavLink = useCreatePageSideNavLink();
    const variableLinkStyles = css({
        display: "flex",
        alignItems: "center",
        gap: space[4],
    });
    const variableLink = tenant.hasMissingVariables ? (<div className={variableLinkStyles}>
            <div>Variables</div>
            <MissingVariablesIcon show={true}/>
        </div>) : ("Variables");
    const sidebarLinks = compact([
        createPageSideNavLink("Overview", links.tenantOverviewPage.generateUrl({ spaceId, tenantId: tenant.id })),
        createPageSideNavLink(variableLink, links.tenantVariablesPage.generateUrl({ spaceId, tenantId: tenant.id })),
        createPageSideNavLink("Tasks", links.tenantTasksPage.generateUrl({ spaceId, tenantId: tenant.id }), undefined, {
            permission: Permission.TaskView,
            tenant: tenant.id,
        }),
        createPageSideNavLink("Settings", links.tenantSettingsPage.generateUrl({ spaceId, tenantId: tenant.id })),
    ]);
    const tenantSettingsURL = links.tenantSettingsPage.generateUrl({ spaceId, tenantId: tenant.id });
    return (<main>
            <Level2PageLayout navItems={sidebarLinks} content={children} description={tenant.description ? <Markdown markup={tenant.description}/> : undefined} header={{
            title: tenant.name,
            logoLinkURL: tenantSettingsURL,
            logo: { href: tenant.logoUrl, accessibleName: `${tenant.name} logo}` },
            breadcrumbs: [{ label: "Tenants", pageUrl: links.tenantsPage.generateUrl({ spaceId }) }],
        }}/>
        </main>);
};
TenantLayoutInternal.displayName = "TenantLayoutInternal"
export default TenantLayoutLoader;

import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { k8sIconStyles } from "~/areas/projects/components/Observability/K8ResourceIcons/k8sIconStyles";
export function DemonSetIcon() {
    return (<svg className={k8sIconStyles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.873 6.52413C13.873 5.97187 14.3207 5.52417 14.873 5.52417H18.5138V7.52409H15.873V11.2341H13.873V6.52413ZM21.7956 5.52417H29.0773V7.52409H21.7956V5.52417ZM32.3591 5.52417H35.9999C36.5522 5.52417 36.9998 5.97187 36.9998 6.52413V11.2341H34.9999V7.52409H32.3591V5.52417ZM15.873 16.6543V20.3643H18.5138V22.3642H14.873C14.3207 22.3642 13.873 21.9165 13.873 21.3643V16.6543H15.873ZM36.9998 16.6543V21.3643C36.9998 21.9165 36.5522 22.3642 35.9999 22.3642H32.3591V20.3643H34.9999V16.6543H36.9998ZM21.7956 20.3643H29.0773V22.3642H21.7956V20.3643Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M26.9062 34.6444H3.9999V32.3074H26.9062V34.6444Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.89086 11.0937C8.89086 10.5415 9.33856 10.0938 9.89083 10.0938H13.5316V12.0937H10.8908V15.8037H8.89086V11.0937ZM16.8135 10.0938H24.0951V12.0937H16.8135V10.0938ZM27.3769 10.0938H31.0177C31.57 10.0938 32.0177 10.5415 32.0177 11.0937V15.8037H30.0178V12.0937H27.3769V10.0938ZM32.0177 21.2239V25.9339C32.0177 26.4861 31.57 26.9338 31.0177 26.9338H27.3769V24.9339H30.0178V21.2239H32.0177Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.90845 15.6633C3.90845 15.1111 4.35616 14.6633 4.90845 14.6633H26.0354C26.5877 14.6633 27.0354 15.1111 27.0354 15.6633V30.5035C27.0354 31.0558 26.5877 31.5035 26.0354 31.5035H4.90845C4.35616 31.5035 3.90845 31.0558 3.90845 30.5035V15.6633ZM5.90845 16.6633V29.5035H25.0354V16.6633H5.90845Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.76518 15.7193H25.8921V30.5594H4.76518V15.7193Z" fill={themeTokens.color.icon.tertiary}/>
        </svg>);
}

import type { NavigationButtonProps } from "@octopusdeploy/design-system-components";
import { NavigationButton } from "@octopusdeploy/design-system-components";
import type { GitRefResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { isDeploymentProcessIdentifier, isRunbookProcessIdentifier } from "~/areas/projects/components/Process/types";
import { PermissionCheck } from "~/components/PermissionCheck";
import { processScopedEditPermission } from "../Process/Common/CommonProcessHelpers";
import { useProcessContext } from "../Process/Contexts/ProcessContext";
type AddRunbookStepButtonPropChanges = Omit<NavigationButtonProps, "href" | "label"> & {
    href?: NavigationButtonProps["href"];
    label?: NavigationButtonProps["label"];
};
interface WithLabel {
    label?: string;
}
type AddRunbookStepButtonProps = AddRunbookStepButtonPropChanges & WithLabel & {
    spaceId: string;
    projectId: string;
    processId: string;
    runbookId: string;
    projectSlug: string;
};
const AddRunbookStepButton: React.FC<AddRunbookStepButtonProps> = ({ spaceId, projectId, projectSlug, processId, runbookId, label, ...rest }) => {
    const href = links.projectRunbookProcessStepsPage.generateUrl({ spaceId, projectSlug, runbookId, processId }, { stepTemplates: true });
    const processContext = useProcessContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectId);
    return (<PermissionCheck permission={processScopedEditPermission(processContext.selectors.getProcessType())} project={projectId} wildcard={true}>
            <NavigationButton label={label ?? "Add Step"} href={href} {...rest} onClick={() => dispatchAction("Add Deployment Step", { resource: "Runbook Process", action: Action.Add })}/>
        </PermissionCheck>);
};
AddRunbookStepButton.displayName = "AddRunbookStepButton"
type AddDeploymentsStepButtonProps = {
    spaceId: string;
    projectId: string;
    projectSlug: string;
    gitRef: GitRefResource | undefined;
} & WithLabel;
const AddDeploymentsStepButton: React.FC<AddDeploymentsStepButtonProps> = ({ spaceId, projectId, projectSlug, gitRef, label, ...rest }) => {
    const href = getAddProcessStepUrl({ spaceId, projectSlug, gitRef });
    const processContext = useProcessContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectId);
    return (<PermissionCheck permission={processScopedEditPermission(processContext.selectors.getProcessType())} project={projectId} wildcard={true}>
            <NavigationButton label={label ?? "Add Step"} href={href} {...rest} onClick={() => dispatchAction("Add Deployment Step", { resource: "Deployment Process", action: Action.Add })}/>
        </PermissionCheck>);
};
AddDeploymentsStepButton.displayName = "AddDeploymentsStepButton"
interface GetProcessStepTemplatesUrlOptions {
    spaceId: string;
    projectSlug: string;
    gitRef: GitRefResource | undefined;
}
function getAddProcessStepUrl({ spaceId, projectSlug, gitRef }: GetProcessStepTemplatesUrlOptions) {
    if (gitRef) {
        return links.branchDeploymentProcessStepsPage.generateUrl({ spaceId, projectSlug, branchName: gitRef.CanonicalName }, { stepTemplates: true });
    }
    return links.deploymentProcessStepsPage.generateUrl({ spaceId, projectSlug }, { stepTemplates: true });
}
type ContextAddStepNavigationButtonProps = AddRunbookStepButtonPropChanges & WithLabel & {
    gitRefResource: GitRefResource | undefined;
};
const ContextAddStepNavigationButton: React.FC<ContextAddStepNavigationButtonProps> = (props) => {
    const processContext = useProcessContext();
    const processIdentifier = processContext.state.processIdentifier;
    if (isDeploymentProcessIdentifier(processIdentifier)) {
        return <AddDeploymentsStepButton spaceId={processIdentifier.spaceId} projectSlug={processIdentifier.projectSlug} projectId={processIdentifier.projectId} gitRef={props.gitRefResource} {...props} label={props.label}/>;
    }
    if (isRunbookProcessIdentifier(processIdentifier)) {
        return (<AddRunbookStepButton spaceId={processIdentifier.spaceId} projectSlug={processIdentifier.projectSlug} projectId={processIdentifier.projectId} processId={processIdentifier.id} runbookId={processIdentifier.runbookId} {...props} label={props.label}/>);
    }
    throw new Error(`Unhandled process identifier ${processIdentifier}`);
};
ContextAddStepNavigationButton.displayName = "ContextAddStepNavigationButton"
export default ContextAddStepNavigationButton;

import { css } from "@emotion/css";
import { borderRadius, colorScales, space } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import * as React from "react";
export type MachineIconVariant = "default" | "medium" | "small";
type MachineIconProps = {
    imageUrl: string;
    variant?: MachineIconVariant;
};
const machineImageStyles = css({
    marginRight: "0.5rem",
    backgroundColor: colorScales.white,
    borderRadius: "3.125rem",
    padding: space["8"],
    width: "3.125rem",
    height: "3.125rem",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
});
const machineIconStyles = css({
    width: "2.438rem",
    height: "2.563rem",
});
const machineImageMediumStyles = css({
    width: space["40"],
    height: space["40"],
    borderRadius: borderRadius.small,
    marginRight: "0", // we should remove margins from all variants but to preserve compatibility I am leaving the margin for the other, currently used variants
    [`&>.${machineIconStyles}`]: {
        width: "2rem",
        height: "2 rem",
    },
});
const machineImageSmallStyles = css({
    width: space["16"],
    height: space["16"],
    [`&>.${machineIconStyles}`]: {
        width: space["32"],
        height: space["32"],
    },
});
export function MachineIcon(props: MachineIconProps) {
    return (<div className={cn(machineImageStyles, { [machineImageSmallStyles]: props.variant === "small" }, { [machineImageMediumStyles]: props.variant === "medium" })}>
            <img src={props.imageUrl} className={machineIconStyles} alt="Machine"/>
        </div>);
}

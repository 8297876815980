import { ActionButton, ActionButtonType, Callout } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import type { ActionEvent, AnalyticErrorCallback } from "~/analytics/Analytics";
import { Action, useProjectScopedAnalyticActionDispatch, useProjectScopedAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { ErrorPanel } from "~/components/form";
export interface EnableProjectRunbooksDialogProps {
    project: ProjectResource;
    open: boolean;
    close: () => void;
}
export class EnableProjectRunbooksDialog extends DataBaseComponent<EnableProjectRunbooksDialogProps> {
    constructor(props: EnableProjectRunbooksDialogProps) {
        super(props);
        this.state = {};
    }
    render() {
        return <EnableProjectRunbooksDialogInternal {...this.props} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "EnableProjectRunbooksDialog";
}
interface EnableProjectRunbooksDialogPropsInternal extends EnableProjectRunbooksDialogProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    errors?: Errors;
}
export function EnableProjectRunbooksDialogInternal({ project, open, close, doBusyTask, busy, errors }: EnableProjectRunbooksDialogPropsInternal) {
    const trackAction = useProjectScopedAnalyticTrackedActionDispatch(project.Id);
    const dispatchAction = useProjectScopedAnalyticActionDispatch(project.Id);
    const projectContext = useProjectContext();
    const [enableClicked, setEnabledClicked] = useState<boolean>();
    const enable = <ActionButton key="Enable" type={ActionButtonType.Primary} label={"Enable now"} busyLabel={"Enabling"} onClick={onEnable} disabled={busy || enableClicked}/>;
    const cancel = <ActionButton key="Cancel" label={"Cancel"} disabled={busy} onClick={onCancel}/>;
    function onEnable() {
        const event: ActionEvent = {
            action: Action.Save,
            resource: "Runbooks",
        };
        doBusyTask(async () => {
            setEnabledClicked(true);
            return trackAction("Move Runbooks to Git", event, async (analyticErrorCallback: AnalyticErrorCallback) => {
                await doBusyTask(async () => await repository.Projects.migrateRunbooksToGit(project, {}), {
                    onError: analyticErrorCallback,
                    onSuccess: () => onSuccess(),
                });
            });
        });
    }
    async function onSuccess() {
        await projectContext.actions.refreshModel();
        close();
    }
    async function onCancel() {
        dispatchAction("Cancel Moving Runbooks", { resource: "Runbooks", action: Action.Cancel });
        close();
    }
    return (<Dialog open={open}>
            <DialogLayout closeDialog={close} actions={[cancel, enable]} title={"Enable Config as Code Runbooks"}>
                {errors ? (<>
                        <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} statusCode={errors.statusCode}/>
                        <p>There was an error enabling Config as Code Runbooks for this project.</p>
                    </>) : (<>
                        <Callout title="Nothing to migrate" type={"success"}>
                            There are no Runbooks to migrate.
                        </Callout>
                        <p>You can enable Config as Code Runbooks on this project now.</p>
                        <p>
                            This process cannot be reversed. Read the <ExternalLink href="ConfigAsCodeRunbooks">Config as Code Runbook documentation</ExternalLink> for more details.
                        </p>
                    </>)}
            </DialogLayout>
        </Dialog>);
}

import { Callout } from "@octopusdeploy/design-system-components";
import { TriggerFilterType, type TriggerGitFilterResource, type TriggerResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import React, { useMemo } from "react";
import { ExternalLinkIcon } from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import type { RelatedTriggersDependencies } from "../ProcessCallouts/CalloutReferencedPackageFeedTriggerConnections";
import { useIsGitTriggersEnabled } from "../useIsGitTriggersEnabled";
const getRelatedGitTriggersForPrimaryDependency = (dependencies: RelatedTriggersDependencies | undefined): TriggerResource[] => {
    if (!dependencies)
        return [];
    return getGitTriggersReferencingDependency(dependencies.projectTriggers, dependencies.deploymentActionSlug, "");
};
const getGitTriggersReferencingDependency = (triggers: TriggerResource[] | Readonly<TriggerResource[]>, deploymentActionSlug: string, gitDependencyName: string) => {
    return triggers.filter((trigger) => {
        if (trigger.Filter.FilterType !== TriggerFilterType.GitFilter)
            return false;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const triggerSources = (trigger.Filter as TriggerGitFilterResource).Sources;
        return triggerSources?.some((source) => source.DeploymentActionSlug === deploymentActionSlug && source.GitDependencyName === gitDependencyName);
    });
};
interface LinkProps {
    spaceId: string;
    projectSlug: string;
}
interface GitTriggerCalloutProps extends LinkProps {
    text: ReactNode;
}
interface SingleRelatedGitTriggerCalloutProps extends GitTriggerCalloutProps {
    triggerId: string;
}
export function AvailableGitTriggerCallout({ spaceId, projectSlug }: LinkProps) {
    return (<>
            <Callout type={"new-feature"} hideTitle>
                You can automatically trigger release creation when a new commit is pushed to this repository.{" "}
                <InternalLink to={links.createReleaseCreationGitTriggerPage.generateUrl({ spaceId, projectSlug })} openInSelf={false}>
                    Create a trigger
                    <ExternalLinkIcon />
                </InternalLink>
            </Callout>
        </>);
}
function SingleRelatedGitTriggerCallout({ text, spaceId, projectSlug, triggerId }: SingleRelatedGitTriggerCalloutProps) {
    return (<>
            <Callout type={"information"} hideTitle>
                {text}{" "}
                <InternalLink to={links.editReleaseCreationGitTriggerPage.generateUrl({ spaceId, projectSlug, triggerId })} openInSelf={false}>
                    View trigger
                    <ExternalLinkIcon />
                </InternalLink>
            </Callout>
        </>);
}
function MultipleRelatedGitTriggersCallout({ text, spaceId, projectSlug }: GitTriggerCalloutProps) {
    return (<>
            <Callout type={"information"} hideTitle>
                {text}{" "}
                <InternalLink to={links.deploymentTriggersPage.generateUrl({ spaceId, projectSlug })} openInSelf={false}>
                    View triggers
                    <ExternalLinkIcon />
                </InternalLink>
            </Callout>
        </>);
}
interface RelatedGitTriggerCalloutProps {
    dependencies: RelatedTriggersDependencies;
    showAvailableCallout: boolean;
}
export function RelatedGitTriggerCallout({ dependencies, showAvailableCallout }: RelatedGitTriggerCalloutProps) {
    const { spaceId, projectSlug } = dependencies;
    const isGitTriggersEnabled = useIsGitTriggersEnabled();
    const relatedTriggers = useMemo(() => getRelatedGitTriggersForPrimaryDependency(dependencies), [dependencies]);
    if (!isGitTriggersEnabled) {
        return null;
    }
    if (relatedTriggers.length === 0) {
        if (showAvailableCallout) {
            return <AvailableGitTriggerCallout spaceId={spaceId} projectSlug={projectSlug}/>;
        }
        return null;
    }
    const uniqueTriggerCount = new Set(relatedTriggers.map((trigger) => trigger.Id)).size;
    if (uniqueTriggerCount === 1) {
        const triggerId = relatedTriggers[0].Id;
        return <SingleRelatedGitTriggerCallout triggerId={triggerId} text={"This repository is connected to a Git trigger."} spaceId={spaceId} projectSlug={projectSlug}/>;
    }
    else {
        return <MultipleRelatedGitTriggersCallout text={"This repository is connected to Git triggers."} spaceId={spaceId} projectSlug={projectSlug}/>;
    }
}

import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { k8sIconStyles } from "~/areas/projects/components/Observability/K8ResourceIcons/k8sIconStyles";
export function JobIcon() {
    return (<svg className={k8sIconStyles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2501 27.7322H3.92383V35.8305H12.2501V27.7322Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M24.1632 27.7322H15.8369V35.8305H24.1632V27.7322Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M36.0763 27.7322H27.75V35.8305H36.0763V27.7322Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M36.0763 15.8259H27.75V23.9242H36.0763V15.8259Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M24.2013 15.9509H15.875V24.0492H24.2013V15.9509Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M12.3263 15.9509H4.00002V24.0492H12.3263V15.9509Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M36.0763 4.16968H27.75V12.268H36.0763V4.16968Z" fill={themeTokens.color.icon.tertiary}/>
        </svg>);
}

import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { k8sIconStyles } from "~/areas/projects/components/Observability/K8ResourceIcons/k8sIconStyles";
export function StatefulSetIcon() {
    return (<svg className={k8sIconStyles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.7204 8.25041C13.7204 7.78891 14.0945 7.41479 14.556 7.41479H17.9677V9.08603H15.3916V12.705H13.7204V8.25041ZM21.4485 7.41479H28.2719V9.08603H21.4485V7.41479ZM31.7527 7.41479H35.1644C35.6259 7.41479 36 7.78891 36 8.25041V12.705H34.3288V9.08603H31.7527V7.41479ZM15.3916 20.3659V21.8906H17.9677L17.7555 22.2313L14.556 21.5614C14.0945 21.5614 13.7204 22.6928 13.7204 22.2313V20.7259L15.3916 20.3659ZM36 18.2717V22.7263C36 23.1878 35.6259 23.5619 35.1644 23.5619H31.7527V21.8906H34.3288V18.2717H36ZM25.0418 21.8906H28.2719V23.5619H25.444L25.0418 21.8906Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.86047 12.7079C8.86047 12.2464 9.23459 11.8722 9.69609 11.8722H13.1078V13.5435H10.5317V17.1624H8.86047V12.7079ZM16.5886 11.8722H23.4119V13.5435H16.5886V11.8722ZM26.8928 11.8722H30.3045C30.766 11.8722 31.1401 12.2464 31.1401 12.7079V17.1624H29.4688V13.5435H26.8928V11.8722ZM10.5317 24.6953V26.3481H13.1078V28.0193H9.69609C9.23459 28.0193 8.86047 27.6452 8.86047 27.1837V24.2438L10.5317 24.6953ZM31.1401 22.7291V27.1837C31.1401 27.6452 30.766 28.0193 30.3045 28.0193H26.8928V26.3481H29.4688V22.7291H31.1401ZM16.5886 26.3481H21.9811L22.1693 27.7428L16.5886 28.0193V26.3481Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M7.35505 27.9186V23.3012C7.78417 23.5077 8.27921 23.686 8.81509 23.8387C10.4684 24.3097 12.704 24.5899 15.1391 24.5899C17.5741 24.5899 19.8098 24.3097 21.4631 23.8387C21.999 23.686 22.4941 23.5077 22.9232 23.3012V27.9186C22.9109 27.9445 22.852 28.0445 22.6146 28.2044C22.2813 28.4289 21.7441 28.6639 21.0052 28.8744C19.5388 29.2922 17.4645 29.5617 15.1391 29.5617C12.8136 29.5617 10.7394 29.2922 9.273 28.8744C8.53411 28.6639 7.99689 28.4289 7.66361 28.2044C7.42618 28.0445 7.36735 27.9445 7.35505 27.9186Z" fill={themeTokens.color.icon.tertiary}/>
            <path d="M22.6146 21.0359C22.8063 21.165 22.8816 21.2551 22.91 21.2986C22.8816 21.3422 22.8063 21.4322 22.6146 21.5614C22.2813 21.7859 21.7441 22.0208 21.0052 22.2313C19.5388 22.6491 17.4645 22.9186 15.1391 22.9186C12.8136 22.9186 10.7394 22.6491 9.27301 22.2313C8.53411 22.0208 7.99689 21.7859 7.66361 21.5614C7.47188 21.4322 7.39661 21.3422 7.36826 21.2986C7.39661 21.2551 7.47188 21.165 7.66361 21.0359C7.99689 20.8114 8.53411 20.5764 9.273 20.3659C10.7394 19.9481 12.8136 19.6786 15.1391 19.6786C17.4646 19.6786 19.5388 19.9481 21.0052 20.3659C21.7441 20.5764 22.2813 20.8114 22.6146 21.0359Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.83565 16.4381C4.37413 16.4381 4 16.8123 4 17.2738V31.7496C4 32.2111 4.37413 32.5853 4.83565 32.5853H25.444C25.9055 32.5853 26.2796 32.2111 26.2796 31.7496V17.2738C26.2796 16.8123 25.9055 16.4381 25.444 16.4381H4.83565ZM24.5945 27.9417V21.2986C24.5945 20.5343 24.0577 19.9928 23.5483 19.6497C23.0092 19.2866 22.2841 18.9924 21.4631 18.7585C19.8098 18.2875 17.5741 18.0073 15.1391 18.0073C12.704 18.0073 10.4684 18.2875 8.81509 18.7585C7.99413 18.9924 7.26902 19.2866 6.72994 19.6497C6.22051 19.9928 5.68375 20.5343 5.68375 21.2986V27.9417C5.68375 28.706 6.22051 29.2475 6.72994 29.5906C7.26902 29.9537 7.99413 30.2479 8.81509 30.4818C10.4684 30.9528 12.704 31.233 15.1391 31.233C17.5741 31.233 19.8098 30.9528 21.4631 30.4818C22.2841 30.2479 23.0092 29.9537 23.5483 29.5906C24.0577 29.2475 24.5945 28.706 24.5945 27.9417Z" fill={themeTokens.color.icon.tertiary}/>
        </svg>);
}

import { css, cx } from "@emotion/css";
import { borderRadius, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links, type LinkHref } from "@octopusdeploy/portal-routes";
import React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import useLocalStorage from "~/hooks/useLocalStorage";
import { CloseButton } from "./CloseButton";
import { ProjectStatusItem } from "./ProjectStatusItem";
import type { ProjectStatusPopover } from "./ProjectStatusPopover";
import { ConfirmTargetDiscoverySetup, GetTargetDiscoverySetupKey } from "./ProjectStatusUtils";
import type { ProjectStatusAction } from "./actions";
import type { StatusItem, ProjectStatusState } from "./reducer";
import { statusItems } from "./reducer";
import { useProjectStatus } from "./useProjectStatus";
interface ProjectStatusProps {
    projectSlug: string | undefined;
    spaceId: string;
    hideCloseButton?: boolean;
    state?: ProjectStatusState;
    dispatch?: React.Dispatch<ProjectStatusAction>;
    showBeaconOnDeploymentTargetItem?: boolean;
    enableLinks?: boolean;
    onEnvironmentsClick?: () => void;
}
export type TaskState = "Current" | "Pending" | "Done";
export const ProjectStatusBar = ({ state, dispatch, projectSlug, spaceId, hideCloseButton, showBeaconOnDeploymentTargetItem = false, enableLinks = false, onEnvironmentsClick }: ProjectStatusProps): JSX.Element | null => {
    const dispatchAction = useAnalyticActionDispatch();
    const [dismissed, setDismissed] = useLocalStorage(`Octopus.Project.${spaceId}.${projectSlug}.Status.Dismissed`, false);
    const [userConfiguredTargetDiscoverySetup] = useLocalStorage<boolean>(GetTargetDiscoverySetupKey(spaceId, projectSlug), false);
    const hasPermissionToCreateMachines = hasPermission(Permission.MachineCreate);
    const hasPermissionToViewMachines = hasPermission(Permission.MachineView);
    useProjectStatus(spaceId, projectSlug, dispatch, dismissed);
    const closeProjectStatus = () => {
        setDismissed(true);
        dispatchAction("Project Status Bar Dismissed", { action: Action.Toggle, resource: "Project Status Bar" });
    };
    // To prevent a flash of the status bar when it should be dismissed,
    // render nothing until we have fetched the status data
    const finishedLoading = state?.hasLoadedServerState || state?.statusRequestedBeforeProjectExists;
    const runbookOnly = !state?.serverState.HasSteps && state?.serverState.HasRunbooks;
    if (!finishedLoading || dismissed || runbookOnly || state.serverState.HasBeenSuccessfullyDeployed)
        return null;
    if (!spaceId)
        return null;
    return (<div className={styles.wrapper}>
            <div className={cx(styles.container)}>
                {state?.items.map((item, index) => {
            const currentItemIsDeploymentTarget = item.label === statusItems.target.label;
            const activeItemIsDeploymentTarget = currentItemIsDeploymentTarget && state.indexOfActiveItem === index;
            return currentItemIsDeploymentTarget && userConfiguredTargetDiscoverySetup ? null : (<ProjectStatusItem name={item.label} state={getItemState(index, state.indexOfActiveItem)} href={enableLinks ? getLink(item.label, spaceId, projectSlug) : undefined} onClick={item.label === statusItems.environments.label ? () => onEnvironmentsClick?.() : undefined} key={item.label} showIndicator={activeItemIsDeploymentTarget && showBeaconOnDeploymentTargetItem} popover={getTargetPopover(item, spaceId, hasPermissionToCreateMachines, hasPermissionToViewMachines, state.serverState.CloudTargetDiscoveryEnabled, state.serverState.UnassociatedTargetRoles, projectSlug)} eventName="Click On Project Status Bar" eventAction={item.eventAction}/>);
        })}
                {!hideCloseButton && <CloseButton onClick={closeProjectStatus}/>}
            </div>
        </div>);
};
function getItemState(index: number, activeItem: number): TaskState {
    if (index < activeItem)
        return "Done";
    if (index > activeItem)
        return "Pending";
    return "Current";
}
function getTargetPopover(statusItem: StatusItem, spaceId: string, hasPermissionToCreateMachines: boolean | undefined, hasPermissionToViewMachines: boolean | undefined, cloudTargetDiscoveryEnabled: boolean, unassociatedTargetRoles: string[], projectSlug?: string): ProjectStatusPopover | undefined {
    // only apply popover to the Deployment Target item
    if (statusItem.label !== statusItems.target.label || !projectSlug)
        return;
    if (!hasPermissionToCreateMachines || !hasPermissionToViewMachines) {
        return { popoverType: "InsufficientPermissions" };
    }
    if (cloudTargetDiscoveryEnabled) {
        if (unassociatedTargetRoles.length) {
            return {
                popoverType: "TargetDiscovery",
                onCustomAction: () => ConfirmTargetDiscoverySetup(spaceId, projectSlug),
            };
        }
        return { popoverType: "TargetDiscoveryMissingTargetRole" };
    }
    if (unassociatedTargetRoles.length) {
        return {
            popoverType: "CanAddDeploymentTargets",
            unassociatedTargetRoles: unassociatedTargetRoles,
        };
    }
    return undefined;
}
function getLink(label: string, spaceId: string, projectSlug?: string): LinkHref | undefined {
    switch (label) {
        case statusItems.project.label:
            return links.projectsPage.generateUrl({ spaceId });
        case statusItems.environments.label:
            return links.infrastructureEnvironmentsPage.generateUrl({ spaceId });
        case statusItems.process.label:
            return projectSlug ? links.deploymentProcessPage.generateUrl({ spaceId, projectSlug }) : undefined;
        case statusItems.target.label:
            return projectSlug ? links.newDeploymentTargetPage.generateUrl({ spaceId }) : undefined;
        case statusItems.release.label:
            return projectSlug ? links.createReleasePage.generateUrl({ spaceId, projectSlug }) : undefined;
        case statusItems.vcs.label:
            return projectSlug ? links.projectVersionControlSettingsPage.generateUrl({ spaceId, projectSlug }) : undefined;
        default:
            return undefined;
    }
}
const styles = {
    wrapper: css({
        overflowX: "auto",
        background: themeTokens.color.background.secondary.default,
        borderRadius: borderRadius.small,
    }),
    container: css({
        font: text.regular.default.xSmall,
        display: "flex",
        gap: space[8],
        alignItems: "center",
        justifyContent: "start",
        padding: `${space[8]} ${space[12]}`,
        userSelect: "none",
    }),
};

import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { k8sIconStyles } from "~/areas/projects/components/Observability/K8ResourceIcons/k8sIconStyles";
export function ReplicaSetIcon() {
    return (<svg className={k8sIconStyles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.8163 8.28567C13.8163 7.82627 14.1887 7.45386 14.6481 7.45386H18.0449V9.11749H15.4799V12.721H13.8163V8.28567ZM21.5113 7.45386H28.305V9.11749H21.5113V7.45386ZM31.7714 7.45386H35.1683C35.6277 7.45386 36.0001 7.82627 36.0001 8.28567V12.721H34.3364V9.11749H31.7714V7.45386ZM15.4799 18.2643V21.8678H18.0449V23.5314H14.6481C14.1887 23.5314 13.8163 23.159 13.8163 22.6996V18.2643H15.4799ZM36.0001 18.2643V22.6996C36.0001 23.159 35.6277 23.5314 35.1683 23.5314H31.7714V21.8678H34.3364V18.2643H36.0001ZM21.5113 21.8678H28.305V23.5314H21.5113V21.8678Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.9768 12.724C8.9768 12.2645 9.34931 11.892 9.80884 11.892H13.2059V13.5561H10.6409V17.1596H8.9768V12.724ZM16.6719 11.892H23.466V13.5561H16.6719V11.892ZM26.9319 11.892H30.329C30.7885 11.892 31.1611 12.2645 31.1611 12.724V17.1596H29.497V13.5561H26.9319V11.892ZM10.6409 22.7025V26.3059H13.2059V27.97H9.80884C9.34931 27.97 8.9768 27.5975 8.9768 27.138V22.7025H10.6409ZM31.1611 22.7025V27.138C31.1611 27.5975 30.7885 27.97 30.329 27.97H26.9319V26.3059H29.497V22.7025H31.1611ZM16.6719 26.3059H23.466V27.97H16.6719V26.3059Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.9697 17.1624H25.4899V31.5764H4.9697V17.1624Z" fill={themeTokens.color.icon.tertiary}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4 17.1624C4 16.6269 4.43415 16.1927 4.9697 16.1927H25.4899C26.0254 16.1927 26.4596 16.6269 26.4596 17.1624V31.5764C26.4596 32.1119 26.0254 32.5461 25.4899 32.5461H4.9697C4.43415 32.5461 4 32.1119 4 31.5764V17.1624ZM5.93939 18.1321V30.6067H24.5202V18.1321H5.93939Z" fill={themeTokens.color.icon.tertiary}/>
        </svg>);
}

/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import { text } from "@octopusdeploy/design-system-tokens";
import { PackageRequirement } from "@octopusdeploy/octopus-server-client";
import type { ActionProperties } from "@octopusdeploy/octopus-server-client";
import { generateSlug } from "@octopusdeploy/utilities";
import { isEqual } from "lodash";
import { useRef } from "react";
import * as React from "react";
import { useAnalyticsStepEditorDispatch } from "~/analytics/Analytics";
import PackageRequirementExpander from "~/areas/projects/components/Process/Common/PackageRequirementExpander";
import { ProcessSubPageLayout } from "~/areas/projects/components/Process/ProcessSubPageLayout";
import ActionList from "~/components/ActionList";
import { RoleChip } from "~/components/Chips";
import type { FieldErrors } from "~/components/DataBaseComponent/Errors";
import { ExpandableContainer } from "~/components/Expandable";
import { useExpandExpanders } from "~/components/Expandable/useExpandExpanders";
import { StepRolling } from "~/components/Images/Process/StepRolling";
import { TargetTagMultiSelect } from "~/components/MultiSelect/TargetTagMultiSelect";
import { OverflowMenu } from "~/components/OverflowMenu/OverflowMenu";
import type { FocusableComponent } from "~/components/VirtualListWithKeyboard/FocusableComponent";
import { ExpandableFormSection, Summary, FormSectionHeading, ExpansionButtons } from "~/components/form";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import ParseHelper from "~/utils/ParseHelper/ParseHelper";
import RunTriggerExpander from "../Process/Common/RunTriggerExpander";
import StartTriggerExpander from "../Process/Common/StartTriggerExpander";
import StepName from "../Process/Common/StepName";
import { processScopedEditPermission, deleteActionAndRedirect, getDeleteProcessMenuItem } from "./Common/CommonProcessHelpers";
import { ErrorsForAction } from "./Common/ErrorsForAction";
import { MaxParallelismOptions } from "./Common/MaxParallelismOptions";
import { SuggestedTargetRoles } from "./Common/TargetRolesFormSection";
import { WarningsForAction } from "./Common/WarningsForAction";
import { useProcessContext } from "./Contexts/ProcessContext";
import { useProcessErrorSelectors } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import { useProcessQueryStringContext } from "./Contexts/ProcessQueryString/ProcessQueryStringContext";
import { useProcessWarningSelectors } from "./Contexts/ProcessWarnings/ProcessWarningsContext";
import styles from "./ProcessParentStepDetails.module.less";
import type { StoredStep } from "./types";
import { isDeploymentOrRunbookProcessIdentifier } from "./types";
export interface ProcessParentStepDetailsProps {
    stepNumber: string;
    step: StoredStep;
    cleanStep?: StoredStep | null;
    machineRoles: string[];
    isFirstStep: boolean;
    isNew: boolean;
    errors: FieldErrors | undefined;
    currentStepName: string;
    setCurrentStepName: (name: string) => void;
    releaseCreationPackageStepId: string | undefined;
}
const AutoExpandStepErrors: React.FC<{
    stepId: string;
}> = (props) => {
    const expandExpanders = useExpandExpanders();
    const selectors = useProcessErrorSelectors();
    const errors = Object.keys(selectors.getStepFieldErrors(props.stepId));
    React.useEffect(() => {
        expandExpanders(errors);
    }, [expandExpanders, errors]);
    return null;
};
AutoExpandStepErrors.displayName = "AutoExpandStepErrors"
const AutoExpandStepWarnings: React.FC<{
    stepId: string;
}> = (props) => {
    const expandExpanders = useExpandExpanders();
    const selectors = useProcessWarningSelectors();
    const warnings = Object.keys(selectors.getStepFieldWarnings(props.stepId));
    React.useEffect(() => {
        expandExpanders(warnings);
    }, [expandExpanders, warnings]);
    return null;
};
AutoExpandStepWarnings.displayName = "AutoExpandStepWarnings"
const ProcessParentStepDetails: React.FC<ProcessParentStepDetailsProps> = ({ stepNumber, step, cleanStep, machineRoles, isFirstStep, isNew, errors, currentStepName, setCurrentStepName, releaseCreationPackageStepId }) => {
    const processContext = useProcessContext();
    const { actions: contextActions, selectors: contextSelectors } = processContext;
    const { actions: queryStringActions } = useProcessQueryStringContext();
    const processType = processContext.selectors.getProcessType();
    const processIdentifier = processContext.state.processIdentifier;
    const processEditPermission = {
        permission: processScopedEditPermission(processType),
        project: isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectId : undefined,
        wildcard: true,
    };
    const menuActions = [
        getDeleteProcessMenuItem("parent step", async () => deleteActionAndRedirect(step, undefined, true, contextActions, contextSelectors, queryStringActions), processEditPermission, releaseCreationPackageStepId, processIdentifier, step, undefined, processContext),
    ];
    const processErrorSelectors = useProcessErrorSelectors();
    const actionErrors = processErrorSelectors.getStepErrors(step.Id);
    const processWarningSelectors = useProcessWarningSelectors();
    const actionWarnings = processWarningSelectors.getStepWarnings(step.Id);
    const multiSelectRef = useRef<FocusableComponent | null>(null);
    const analyticsStepEditorDispatch = useAnalyticsStepEditorDispatch(step.Id, isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectId : undefined);
    const actions = [];
    actions.push(<OverflowMenu menuItems={menuActions}/>);
    React.useEffect(() => {
        // We need to keep these properties in sync for routing purposes.
        if (!isEqual(currentStepName, step.Name)) {
            setCurrentStepName(step.Name);
        }
    }, [setCurrentStepName, currentStepName, step.Name]);
    const getFieldError = React.useCallback((value: string) => {
        const error = processErrorSelectors.getStepFieldError(step.Id, value);
        return error;
    }, [processErrorSelectors, step.Id]);
    const getFieldWarning = React.useCallback((value: string) => {
        const warning = processWarningSelectors.getStepFieldWarning(step.Id, value);
        return warning;
    }, [processWarningSelectors, step.Id]);
    const setStepProperties = React.useCallback((properties: Partial<ActionProperties>) => {
        processContext.actions.setStepProperties(step.Id, properties);
    }, [step.Id, processContext.actions]);
    const setStepMetaProperties = <K extends keyof StoredStep>(state: Pick<StoredStep, K>, callback?: () => void) => {
        processContext.actions.setStepMetaProperties(step.Id, (prev) => ({ ...prev, ...state }));
        if (callback) {
            callback();
        }
    };
    const executionPlanSummary = () => {
        const summary = [<span>This step will run</span>];
        const roles = ParseHelper.parseCSV(step.Properties["Octopus.Action.TargetRoles"] as string);
        const parallelism = step.Properties["Octopus.Action.MaxParallelism"];
        if (roles.length > 0) {
            summary.push(roles.length > 1 ? <span> with tags</span> : <span> with tag</span>);
            roles.forEach((r) => {
                summary.push(<RoleChip role={r} key={"role-" + r}/>);
            });
        }
        if (parallelism) {
            summary.push(<span>
                    {" "}
                    as <strong>rolling step</strong> that will run on <strong>{parallelism}</strong> target{parallelism !== "1" ? "s" : ""} at a time
                </span>);
        }
        else {
            summary.push(<span>
                    {" "}
                    with all targets deployed in <strong>parallel</strong>
                </span>);
        }
        return Summary.summary(React.Children.toArray(summary));
    };
    const nameSummary = (stepName: string, stepSlug: string | undefined) => (<div className={styles.nameSummaryContainer}>
            <NameSummaryWithSlug name={stepName} slug={stepSlug}/>
        </div>);
    const parsedMachineRoles = ParseHelper.parseCSV(step.Properties["Octopus.Action.TargetRoles"] as string);
    return (<ExpandableContainer containerKey={step.Id}>
            <ExpansionButtons containerKey={step.Id} errors={errors} expandAllOnMount={isNew}/>
            <ProcessSubPageLayout title={<StepName name={step.Name} number={stepNumber} stepType="Parent Step"/>} titleLogo={<div className={styles.parentStepIcon}>
                        <StepRolling width={"3.1rem"} height={"3.1rem"}/>
                    </div>} sectionControl={<ActionList actions={actions}/>}>
                <AutoExpandStepErrors stepId={step.Id}/>
                <AutoExpandStepWarnings stepId={step.Id}/>
                <ErrorsForAction actionErrors={actionErrors}/>
                <WarningsForAction actionWarnings={actionWarnings}/>

                <ExpandableFormSection errorKey="Name" title="Step Name" focusOnExpandAll summary={step.Name ? Summary.summary(nameSummary(step.Name, step.Slug)) : Summary.placeholder("Please enter a name for your step")} help="A short, memorable, unique name for this step.">
                    <DebounceText value={step.Name} onChange={(name) => setStepMetaProperties({ Name: name })} label="Step name" autoFocus={true}/>
                    {!isNew && <SlugEditor value={step.Slug ?? generateSlug(step.Name)} originalSlug={cleanStep?.Slug ?? ""} name={step.Name} onChange={(slug) => setStepMetaProperties({ Slug: slug })} label="Step slug"/>}
                </ExpandableFormSection>

                <ExpandableFormSection isExpandedByDefault={!step.Name} errorKey="Octopus.Action.TargetRoles" title="Execution Plan" summary={executionPlanSummary()} help="Where should this step run?">
                    <TargetTagMultiSelect label="Runs on targets with tags" onChange={(roles) => setStepProperties({ ["Octopus.Action.TargetRoles"]: ParseHelper.encodeCSV(roles) })} value={parsedMachineRoles} validate={(roles) => (roles.length === 0 ? "Please enter one or more tags" : "")} error={getFieldError("Octopus.Action.TargetRoles")} items={machineRoles} accessibleName={"Target tags selector"} analyticsArea="Deployment Process Editor" canAdd={true} empty={<div className={targetRolesEmptyStyles}>
                                {machineRoles ? (<>Add a new target tag</>) : (<>
                                        Create your first target tag, for example:{" "}
                                        {<SuggestedTargetRoles targetRoles={machineRoles} onTargetRolesChanged={(roles) => setStepProperties({ ["Octopus.Action.TargetRoles"]: ParseHelper.encodeCSV(roles) })} analyticsStepEditorDispatch={(eventName, ev) => analyticsStepEditorDispatch(eventName, { ...ev, stepTemplate: "Parent Step" })} projectSlug={isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectSlug : processIdentifier.blueprintId} isKubernetesStep={false} onClick={() => {
                        if (multiSelectRef.current)
                            multiSelectRef.current.focus();
                    }}/>}
                                    </>)}
                            </div>} multiSelectRef={(component) => (multiSelectRef.current = component)}/>
                    <MaxParallelismOptions maxParallelism={step.Properties["Octopus.Action.MaxParallelism"] as string} setStepProperties={setStepProperties}/>
                </ExpandableFormSection>

                <FormSectionHeading title="Conditions"/>
                <RunTriggerExpander isFirstStep={isFirstStep} condition={step.Condition} onConditionChange={(condition) => setStepMetaProperties({ Condition: condition })} variableExpression={step.Properties["Octopus.Step.ConditionVariableExpression"] as string} onVariableExpressionChange={(x) => setStepProperties({ ["Octopus.Step.ConditionVariableExpression"]: x })} variableExpressionError={getFieldError("ConditionVariableExpression")}/>

                {!isFirstStep && <StartTriggerExpander startTrigger={step.StartTrigger} onChange={(startTrigger) => setStepMetaProperties({ StartTrigger: startTrigger })}/>}
                {contextSelectors.shouldShowPackageRequirementOptionForStep(step.Id) && (<PackageRequirementExpander packageRequirement={step.PackageRequirement} onChange={(val) => {
                setStepMetaProperties({ PackageRequirement: val });
                if (val === PackageRequirement.AfterPackageAcquisition)
                    processContext.actions.resetPackageRequirementAfterPackageAcquisitionStep();
            }}/>)}
            </ProcessSubPageLayout>
        </ExpandableContainer>);
};
ProcessParentStepDetails.displayName = "ProcessParentStepDetails"
export default ProcessParentStepDetails;
const targetRolesEmptyStyles = css({
    font: text.regular.default.medium,
});

import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { WindowSizeContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import { Note } from "~/components/form";
import { VariableLookupText } from "~/components/form/VariableLookupText";
interface MaxParallelismProps {
    value: string;
    onChange(newValue: string): void;
}
const MaxParallelism: React.StatelessComponent<MaxParallelismProps> = (props) => (<div>
        <div className={helpContainerStyles}>
            Specify the window size Octopus should use for this step
            <WindowSizeContextualHelp />
        </div>
        <VariableLookupText localNames={[]} value={props.value} onChange={props.onChange} label="Window size"/>
        <Note>Octopus will execute the step as quickly as possible using this value as the maximum degree of parallelism.</Note>
    </div>);
MaxParallelism.displayName = "MaxParallelism"
export default MaxParallelism;
const helpContainerStyles = css({
    marginBottom: space[16],
});

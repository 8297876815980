import { Tooltip } from "@octopusdeploy/design-system-components";
import { Permission, TaskState, type TimelineItem } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import InternalLink from "~/components/Navigation/InternalLink";
import { OverflowMenuItems, OverflowMenu } from "~/components/OverflowMenu/OverflowMenu";
import DateFormatter from "~/utils/DateFormatter/DateFormatter";
import TaskStatusIcon from "../../../TaskStatusIcon";
import { deploymentStatus, deploymentTitle, menu, statusColumn, statusIcon, timelineRow, timestamp, historicalDeploymentTitle } from "./styles";
interface TimelineRowProps {
    item: TimelineItem;
}
export const TimelineRow = (props: TimelineRowProps) => {
    const handleDeleteConfirm = async () => {
        const toDelete = await repository.Deployments.get(props.item.Id);
        await repository.Deployments.del(toDelete);
    };
    const getStateText = (item: TimelineItem): string => {
        if (item.HasPendingInterruptions) {
            return "requires manual intervention";
        }
        switch (item.TaskState) {
            case TaskState.Queued:
            case TaskState.Executing:
            case TaskState.Failed:
            case TaskState.Canceled:
            case TaskState.Cancelling:
                return item.TaskState.toLowerCase();
            case TaskState.TimedOut:
                return "timed out";
            case TaskState.Success:
                return "successful";
        }
    };
    const getTitle = (item: TimelineItem) => {
        if (item.IsHistorical) {
            return (<Tooltip content="This release has been deleted" position="top">
                    <span className={historicalDeploymentTitle}>
                        {item.Version} {item.Iteration}
                    </span>
                </Tooltip>);
        }
        const content = (<span className={deploymentTitle}>
                {item.Version} {item.Iteration}
            </span>);
        const pageUrl = links.deploymentDetailsPage.generateUrl({ spaceId: item.SpaceId, deploymentId: item.Id, projectSlug: item.ProjectId, releaseVersion: item.Version });
        return <InternalLink to={pageUrl}>{content}</InternalLink>;
    };
    const getOverflowMenuItems = () => {
        const redeployItem = OverflowMenuItems.navItem("Redeploy...", links.createDeploymentPage.generateUrl({ spaceId: props.item.SpaceId, projectSlug: props.item.ProjectId, releaseVersion: props.item.Version }, { previousDeploymentId: props.item.Id }), { permission: Permission.DeploymentCreate, project: props.item.ProjectId, environment: props.item.EnvironmentId, tenant: props.item.TenantId });
        const deployToItem = OverflowMenuItems.navItem("Deploy to...", links.createDeploymentPage.generateUrl({ spaceId: props.item.SpaceId, projectSlug: props.item.ProjectId, releaseVersion: props.item.Version }), {
            permission: Permission.DeploymentCreate,
            project: props.item.ProjectId,
            spaceScope: { spaceId: props.item.SpaceId },
        });
        const deleteDeploymentItem = OverflowMenuItems.deleteItem("Delete deployment...", "Are you sure you want to delete this deployment?", handleDeleteConfirm, <div>
                <p>Deleting this deployment is permanent. There is no going back.</p>
                <p>Do you wish to continue?</p>
            </div>, { permission: Permission.DeploymentDelete });
        const taskLogItem = OverflowMenuItems.navItem("View task log", links.deploymentDetailsPage.generateUrl({ spaceId: props.item.SpaceId, deploymentId: props.item.Id, projectSlug: props.item.ProjectId, releaseVersion: props.item.Version }, { activeTab: "taskLog" }));
        return [redeployItem, deployToItem, deleteDeploymentItem, taskLogItem];
    };
    return (<div className={timelineRow}>
            <div className={timestamp}>
                <div>{DateFormatter.dateToCustomFormat(props.item.Created, "dddd")}</div>
                <div>{DateFormatter.dateToCustomFormat(props.item.Created, "LL")}</div>
                <div>{DateFormatter.dateToCustomFormat(props.item.Created, "hh:mm A Z")}</div>
            </div>
            <div className={statusIcon}>
                <TaskStatusIcon size={28} iconOnly={true} item={{ State: props.item.TaskState, HasPendingInterruptions: props.item.HasPendingInterruptions, IsCompleted: props.item.IsCompleted, HasWarningsOrErrors: props.item.HasWarningsOrErrors }}/>
            </div>
            <div className={statusColumn}>
                {getTitle(props.item)}
                <div className={deploymentStatus}>Deployment {getStateText(props.item)}</div>
            </div>
            <div className={menu}>{!props.item.IsHistorical ? <OverflowMenu menuItems={getOverflowMenuItems()} key={props.item.Id}/> : <></>}</div>
        </div>);
};

import type { GitRef, GitRefResource, ProjectResource, Repository, RunbookProcessResource, ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import { HasRunbooksInGit, ProcessType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { ProcessPaperLayout } from "~/areas/projects/components/Process/CustomPaperLayouts/ProcessPaperLayout";
import ProcessListLayoutDataLoader from "~/areas/projects/components/Process/ProcessListLayoutLoader";
import { useRunbookContext } from "~/areas/projects/components/Runbooks/RunbookContext";
import useRunbookProcessState from "~/areas/projects/components/Runbooks/useRunbookProcessState";
import { lastAccessedGitRef } from "~/areas/projects/context/LastAccessedGitRef";
import type { ProjectContextProps } from "~/areas/projects/context/index";
import { PageHeaderBranchSelectorVNext } from "../BranchSelector/PageHeaderBranchSelector";
import { type GitRefQueryParamsProps, useProjectRunbooksGitRef } from "../BranchSelector/useProjectGitRef";
export async function runbookProcessLoader(repository: Repository, spaceId: string, processId: string, projectContext: Promise<ProjectContextProps>, gitRef?: string | undefined): Promise<LoaderData> {
    const { state: { model: project }, } = await projectContext;
    let runbookProcess = undefined;
    let gitRefValidationResult: ValidateGitRefV2Response | undefined = undefined;
    if (HasRunbooksInGit(project.PersistenceSettings)) {
        // If we don't have the GitRef in the query parameter, fill it with the last accessed one.
        if (!gitRef) {
            gitRef = lastAccessedGitRef.get(project);
        }
        gitRefValidationResult = await projectGitRefLoader(repository, project, gitRef);
        runbookProcess = await repository.Runbooks.getRunbookProcess(project.Id, spaceId, processId, gitRefValidationResult.GitRef?.CanonicalName ?? gitRef);
    }
    else {
        runbookProcess = await repository.Runbooks.getRunbookProcess(project.Id, spaceId, processId, undefined);
    }
    return {
        process: runbookProcess,
        gitRefValidationResult,
    };
}
async function projectGitRefLoader(repository: Repository, project: ProjectResource, gitRef: string): Promise<ValidateGitRefV2Response> {
    return await repository.Projects.validateGitRef(project, gitRef);
}
interface LoaderData {
    process: RunbookProcessResource;
    gitRefValidationResult?: ValidateGitRefV2Response | undefined;
}
export interface RunbookProcessPageProps extends GitRefQueryParamsProps {
    loaderData: LoaderData;
    projectContext: ProjectContextProps;
    spaceId: string;
    processId: string;
}
export function RunbookProcessListPage({ queryParams, setQueryParams, projectContext, loaderData, spaceId, processId }: RunbookProcessPageProps) {
    const project = projectContext.state.model;
    const { state: { runbook }, } = useRunbookContext();
    const [gitRef, setGitRef, gitRefValidationResult] = useProjectRunbooksGitRef(project, queryParams, setQueryParams, loaderData.gitRefValidationResult);
    const { runbookProcessIdentifier, runbookProcess, reloadRunbookProcess, modifyRunbookProcess } = useRunbookProcessState(project, runbook, spaceId, processId, gitRef, loaderData.process);
    const onProcessSave = (gitRef: GitRefResource | string | undefined) => projectContext.actions.onProjectUpdated(project, gitRef);
    const changeGitRef = (gitRef: string) => projectContext.actions.changeGitRef(gitRef);
    const refreshModel = (gitRef?: GitRef) => projectContext.actions.refreshModel(gitRef);
    const branchSelector = <PageHeaderBranchSelectorVNext project={project} gitRef={gitRef} setGitRef={setGitRef}/>;
    if (!runbook || !runbookProcessIdentifier) {
        return <ProcessPaperLayout processType={ProcessType.Runbook} busy={true}/>;
    }
    return (<ProcessListLayoutDataLoader project={project} onProcessSave={onProcessSave} changeGitRef={changeGitRef} refreshModel={refreshModel} gitRef={gitRef} gitRefResource={gitRefValidationResult?.GitRef} titleAccessory={branchSelector} processIdentifier={runbookProcessIdentifier} process={runbookProcess} reloadProcess={reloadRunbookProcess} modifyProcess={modifyRunbookProcess}/>);
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Callout } from "@octopusdeploy/design-system-components";
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { LicenseStatusResource, ResourceCollection, ScopedUserRoleResource, SpaceResource, TeamResource } from "@octopusdeploy/octopus-server-client";
import { Permission, PermissionsMode } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { QueryParamValuesSetter, SpacePartitionQueryParams } from "@octopusdeploy/portal-routes";
import type { NonEmptyArray } from "@octopusdeploy/type-utils";
import { isArrayLengthAtLeast } from "@octopusdeploy/type-utils";
import { fromPairs, isEqual } from "lodash";
import MobileDetect from "mobile-detect";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { repository, session } from "~/clientInstance";
import { AdvancedFilterCheckbox } from "~/components/AdvancedFilterLayout";
import { AdvancedFilters } from "~/components/AdvancedFilterLayout/AdvancedFilters";
import { SpaceChip } from "~/components/Chips";
import type { Errors } from "~/components/DataBaseComponent";
import { DataBaseComponent, useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import FilterSearchBox from "~/components/FilterSearchBox";
import { SpaceMultiSelect } from "~/components/MultiSelect/SpaceMultiSelect";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { PageContent } from "~/components/PageContent/PageContent";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { restrictedPermissionsCalloutConfig } from "~/components/RestrictedPermissionsCallout";
import { useDeepCompareMemo } from "~/hooks/useDeepCompareMemo";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import AddTeam from "./AddTeam";
import TeamList from "./TeamList";
import styles from "./style.module.less";
interface QueryParams extends SpacePartitionQueryParams {
    name: string;
}
interface Filter extends QueryParams {
    spaces: NonEmptyArray<string> | "all";
    includeSystem: boolean;
}
interface TeamsPageProps {
    queryParams: QueryParams;
    setQueryParams: QueryParamValuesSetter<QueryParams>;
}
export class TeamsPage extends DataBaseComponent<TeamsPageProps> {
    constructor(props: TeamsPageProps) {
        super(props);
        this.state = {};
    }
    render() {
        return <TeamsPageInternal doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors} queryParams={this.props.queryParams} setQueryParams={this.props.setQueryParams}/>;
    }
    static displayName = "TeamsPage";
}
interface TeamsPageInternalProps {
    queryParams: QueryParams;
    setQueryParams: QueryParamValuesSetter<QueryParams>;
    doBusyTask: DoBusyTask;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
}
export function TeamsPageInternal({ doBusyTask, busy, errors, queryParams, setQueryParams }: TeamsPageInternalProps) {
    const initialData = useInitialData(doBusyTask);
    const defaultFilter = useDefaultFilter();
    const filter = useFilter(defaultFilter, queryParams, setQueryParams);
    const teamsAndScopedUserRoles = useTeamsAndScopedUserRoles(doBusyTask, filter);
    const { isOpen: isAddTeamDialogOpen, openDialog: openAddTeamDialog } = useDialogTrigger();
    const primaryPageAction: PrimaryPageAction | undefined = initialData?.spaces && {
        type: "button",
        hasPermissions: isAllowed({ permission: Permission.TeamCreate }),
        label: "Add Team",
        onClick: openAddTeamDialog,
    };
    const currentSpace = getCurrentSpace(initialData?.spaces);
    const isFiltered = !isEqual(filter, defaultFilter);
    const showRestrictedPermissionsCallout = initialData !== null && initialData.licenseStatus.PermissionsMode === PermissionsMode.Restricted;
    return (<>
            <PageContent header={{ title: "Teams", primaryAction: primaryPageAction }} busy={busy} errors={errors} callout={showRestrictedPermissionsCallout ? restrictedPermissionsCalloutConfig : undefined} filters={teamsAndScopedUserRoles !== null
            ? {
                inputs: [
                    <FilterSearchBox placeholder="Filter by name..." value={filter.name} autoFocus={!new MobileDetect(window.navigator.userAgent).isPhoneSized()} onChange={(name: string) => setQueryParams((prev) => ({ ...prev, name }))}/>,
                ],
                advancedFilters: {
                    content: (<AdvancedFilters>
                                          {session.currentPermissions!.scopeToSystem().isAuthorized({ permission: Permission.TeamView }) && (<AdvancedFilterCheckbox label="Include system teams" value={filter.includeSystem} onChange={(includeSystem) => setQueryParams((prev) => ({ ...prev, includeSystem }))}/>)}
                                          <SpaceSelector spaces={initialData?.spaces ?? []} onChange={(spaces) => setQueryParams((prev) => ({ ...prev, spaces: isArrayLengthAtLeast(spaces, 1) ? spaces : "all" }))} selectedSpaces={filter.spaces === "all" ? [] : filter.spaces}/>
                                      </AdvancedFilters>),
                    isResetEnabled: isFiltered,
                    onResetFilter: () => setQueryParams(defaultFilter),
                },
                filtersSummary: <FilterSummary spaces={initialData?.spaces} selectedSpaces={filter.spaces} includeSystem={filter.includeSystem}/>,
            }
            : undefined}>
                {teamsAndScopedUserRoles !== null ? (<div className={styles.lists}>
                        {initialData !== null && teamsAndScopedUserRoles !== null && (<TeamList initialTeams={teamsAndScopedUserRoles.teams} additionalRequestParams={getAdditionalRequestParams(filter)} initialScopedUserRolesLookup={teamsAndScopedUserRoles.initialTeamsScopedUserRoles!} spaces={initialData.spaces} loadScopedUserRolesForTeams={loadScopedUserRolesForTeams}/>)}
                    </div>) : null}
            </PageContent>
            {currentSpace && <TeamsPageDialogs currentSpace={currentSpace} isAddTeamDialogOpen={isAddTeamDialogOpen}/>}
        </>);
}
function useFilter(defaultFilter: Filter, queryParams: QueryParams, setQueryParams: QueryParamValuesSetter<QueryParams>): Filter {
    // If there's anything that needs to be adjusted to match the default values, we do it here...
    const filter: Filter = useMemo(() => {
        return {
            ...queryParams,
            // Explicitly set up these two properties. They are a bit special because they have unique default values.
            spaces: queryParams.spaces === "unspecified" ? defaultFilter.spaces : queryParams.spaces,
            includeSystem: queryParams.includeSystem === undefined ? defaultFilter.includeSystem : queryParams.includeSystem,
        };
    }, [defaultFilter, queryParams]);
    // ...If anything has changed after we adjust the filter, we set it back on the URL
    // This causes the URL to unambiguously reflect the current state of the filter
    useEffect(() => {
        if (!isEqual(filter, queryParams))
            setQueryParams(filter);
    }, [queryParams, filter, setQueryParams]);
    // If this component is rendered when the query params have "unspecified" values for spaces/includeSystem,
    // then a subsequent render will be fired using a new queryParams object due to the above useEffect.
    // This translates to a new filter object too.
    // If any downstream code relies on this filter object in a dependency array (e.g. to load data), then that hook would
    // be refired (e.g. another api query) without this call to useDeepCompareMemo.
    return useDeepCompareMemo(filter);
}
function useDefaultFilter(): Filter {
    return useMemo(() => {
        const hasTeamViewInCurrentSpace = session.currentPermissions!.scopeToSpace(repository.spaceId).isAuthorized({ permission: Permission.TeamView });
        const shouldFilterToCurrentSpace = repository.spaceId && hasTeamViewInCurrentSpace;
        return {
            includeSystem: true,
            name: "",
            spaces: shouldFilterToCurrentSpace ? [repository.spaceId!] : "all",
        };
    }, []);
}
function useInitialData(doBusyTask: DoBusyTask) {
    const [initialData, setInitialData] = useState<{
        spaces: SpaceResource[];
        licenseStatus: LicenseStatusResource;
    } | null>(null);
    useDoBusyTaskEffect(doBusyTask, async () => {
        // Spaces here are used as a lookup for scoped user roles
        // You will only be able to see a scoped user role if you have access *within* the space
        // for which the scoped user role applies
        // Therefore only need to load spaces that you have access *within*
        // rather than any space you can see (i.e. if you had SpaceView permission)
        const spacesAsync = repository.Users.getSpaces(session.currentUser!);
        const currentStatus = repository.Licenses.getCurrentStatus();
        setInitialData({ spaces: await spacesAsync, licenseStatus: await currentStatus });
    }, [], { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    return initialData;
}
function useTeamsAndScopedUserRoles(doBusyTask: DoBusyTask, filter: Filter) {
    const [teamsAndScopedUserRoles, setTeamsAndScopedUserRoles] = useState<{
        teams: ResourceCollection<TeamResource>;
        initialTeamsScopedUserRoles: Record<string, ScopedUserRoleResource[]>;
    } | null>(null);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const result = await loadTeamsAndScopedUserRoles(filter);
        setTeamsAndScopedUserRoles(result);
    }, [filter]);
    return teamsAndScopedUserRoles;
}
function getCurrentSpace(spaces: SpaceResource[] | undefined) {
    if (!spaces)
        return null;
    return spaces.find((s) => s.Id === repository.spaceId);
}
async function loadTeamsAndScopedUserRoles(filter: Filter) {
    const teams = await repository.Teams.list({
        includeSystem: filter.includeSystem,
        partialName: filter.name,
        spaces: getSpacesFilter(filter),
    });
    const initialTeamsScopedUserRoles = await loadScopedUserRolesForTeams(teams.Items);
    return {
        teams,
        initialTeamsScopedUserRoles,
    };
}
//eslint-disable-next-line @typescript-eslint/no-explicit-any
function getAdditionalRequestParams(filter: Filter): Map<string, any> {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const additionalRequestParams = new Map<string, any>();
    additionalRequestParams.set("includeSystem", filter.includeSystem);
    additionalRequestParams.set("spaces", getSpacesFilter(filter));
    additionalRequestParams.set("partialName", filter.name);
    return additionalRequestParams;
}
function getSpacesFilter(filter: Filter): string[] {
    const hasTeamViewInAnySpace = session.currentPermissions!.isAuthorizedInAnySpace({ permission: Permission.TeamView });
    if (filter.spaces === "all") {
        return hasTeamViewInAnySpace ? ["all"] : [];
    }
    return filter.spaces;
}
async function loadScopedUserRolesForTeams(team: TeamResource[]): Promise<Record<string, ScopedUserRoleResource[]>> {
    const teamsAndScopedUserRoles = await Promise.all(team.map(async (t) => {
        const scopedUserRoles = await repository.Teams.listScopedUserRoles(t);
        return { team: t, scopedUserRoles };
    }));
    return fromPairs<ScopedUserRoleResource[]>(teamsAndScopedUserRoles.map<[
        string,
        ScopedUserRoleResource[]
    ]>((a) => [a.team.Id, a.scopedUserRoles.Items]));
}
function FilterSummary({ spaces, selectedSpaces, includeSystem }: {
    spaces: SpaceResource[] | undefined;
    selectedSpaces: NonEmptyArray<string> | "all";
    includeSystem: boolean;
}) {
    const selectedSpaceChips = spaces && selectedSpaces !== "all"
        ? selectedSpaces
            .map((spaceId) => {
            const space = spaces.find((s) => s.Id === spaceId);
            return space ? <SpaceChip key={space.Id} space={space}/> : null;
        })
            .filter((s) => s !== undefined)
        : [];
    const spacesText = selectedSpaceChips.length === 1 ? "space" : "spaces";
    const spacesFilter = selectedSpaceChips.length > 0 ? (<>
                {selectedSpaceChips} {spacesText} and
            </>) : null;
    return (<span>
            Filtering by:{" "}
            <span>
                {spacesFilter} <strong>{includeSystem ? "includes" : "excludes"}</strong> system&nbsp;teams
            </span>
        </span>);
}
function SpaceSelector({ spaces, onChange, selectedSpaces }: {
    spaces: SpaceResource[];
    selectedSpaces: string[];
    onChange: (spaces: string[]) => void;
}) {
    const hasTeamViewInAnySpace = session.currentPermissions!.isAuthorizedInAnySpace({ permission: Permission.TeamView });
    if (!hasTeamViewInAnySpace) {
        return (<div style={{ margin: "1rem 0 0 0" }}>
                <Callout type={"information"} title={"Permission required"}>
                    You do not have {Permission.TeamView} permission in any given Space.
                </Callout>
            </div>);
    }
    return <SpaceMultiSelect items={spaces} onChange={onChange} value={selectedSpaces}/>;
}
interface TeamsPageDialogsProps {
    currentSpace: SpaceResource;
    isAddTeamDialogOpen: boolean;
}
function TeamsPageDialogs({ currentSpace, isAddTeamDialogOpen }: TeamsPageDialogsProps) {
    const { navigate } = useSpaceAwareNavigation();
    return (<Dialog open={isAddTeamDialogOpen}>
            <AddTeam currentSpace={currentSpace} onTeamCreated={(id) => navigate(links.editTeamPage.generateUrl({ teamId: id }))}/>
        </Dialog>);
}

import { useMutation, useQuery } from "@octopusdeploy/octopus-react-client";
import type { ModifyProcessCommand, ProjectResource, RunbookProcessResource, RunbookResource } from "@octopusdeploy/octopus-server-client";
import { HasRunbooksInGit, isRunbookProcessResource } from "@octopusdeploy/octopus-server-client";
import { useMemo } from "react";
import { toRunbookProcessIdentifier } from "~/areas/projects/components/Process/types";
export default function useRunbookProcessState(project: ProjectResource, runbook: RunbookResource | undefined, spaceId: string, processId: string, gitRef: string | undefined, initialProcess: RunbookProcessResource) {
    const { result: runbookProcess, refetch: reloadRunbookProcess } = useQuery((repository) => repository.Runbooks.getRunbookProcess(project.Id, spaceId, processId, gitRef), [gitRef, processId, project.Id, spaceId], "Load runbook process", {
        initialResult: initialProcess,
    });
    const { execute: modifyRunbookProcess } = useMutation({
        name: "Modify runbook process",
        action: (repository, process: ModifyProcessCommand, gitRef: string | undefined) => {
            if (isRunbookProcessResource(process)) {
                if (HasRunbooksInGit(project.PersistenceSettings)) {
                    return repository.Runbooks.modifyRunbookProcess(process, gitRef);
                }
                else {
                    return repository.Runbooks.modifyRunbookProcess(process);
                }
            }
            throw new Error("Attempted to modify a process that isn't a runbook process");
        },
        afterComplete: async () => reloadRunbookProcess(),
    });
    const runbookProcessIdentifier = useMemo(() => (runbook ? toRunbookProcessIdentifier(project, runbook) : undefined), [project, runbook]);
    return {
        runbookProcess,
        runbookProcessIdentifier,
        reloadRunbookProcess,
        modifyRunbookProcess,
    };
}

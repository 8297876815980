import { css } from "@emotion/css";
import { Level2PageContent, useIsBusy } from "@octopusdeploy/design-system-components";
import type { BreadcrumbItem, CalloutType, PageAction, PrimaryPageAction, SimpleMenuItem, ErrorInfo } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ApiOperationStatus, ValidationError } from "@octopusdeploy/octopus-react-client";
import { isValidationError, useAggregateAPIOperationStatus } from "@octopusdeploy/octopus-react-client";
import { OctopusError } from "@octopusdeploy/octopus-server-client";
import type { ReactElement, ReactNode } from "react";
import React from "react";
import { type Errors } from "../DataBaseComponent/Errors";
import Markdown from "../Markdown";
interface PageContentBaseProps {
    header: {
        title: string;
        titleComplementaryText?: string;
        logo?: ReactElement;
        chip?: ReactElement;
        contextSelector?: ReactElement;
        breadcrumbs?: BreadcrumbItem[];
        showBreadcrumbBackIcon?: boolean;
        primaryAction?: PrimaryPageAction;
        pageActions?: PageAction[];
        overflowActions?: SimpleMenuItem[];
    };
    /**
     * @deprecated Pages should use the new data access hooks. Busy state will be handled automatically.
     */
    busy?: Promise<unknown>;
    /**
     * @deprecated Pages should use the new data access hooks. Busy state will be handled automatically.
     */
    legacyStatus?: ApiOperationStatus;
    description?: string;
    callout?: {
        title?: ReactNode;
        content: ReactNode;
        type: CalloutType;
        onClose?: () => void;
    };
    /**
     * @deprecated Pages should use the new data access hooks. Errors will be handled automatically.
     */
    errors?: Errors;
    fullWidth?: boolean;
}
interface ContentProps {
    filters?: {
        inputs: ReactNode[];
        filtersSummary?: ReactNode;
        advancedFilters?: {
            content: ReactNode;
            onResetFilter: () => void;
            isResetEnabled: boolean;
            isExpandedByDefault?: boolean;
        };
    };
    pagination?: {
        ui: ReactNode;
        placement: "top" | "bottom" | "topAndBottom";
    };
    /**
     * @deprecated Avoid using sidebars for new pages; Consult your designer or Frontend Foundations Team for alternative solutions.
     */
    sidebar?: ReactNode;
}
type PageContentPropsWithoutTabs = PageContentBaseProps & ContentProps & {
    children: ReactNode;
};
type PageContentPropsWithTabs<TabValue extends string> = PageContentBaseProps & {
    tabs: Tab<TabValue>[];
    onTabChanged: (value: TabValue) => void;
    currentTab: TabValue;
};
interface Tab<TabValue extends string> extends ContentProps {
    value: TabValue;
    label: string;
    content: ReactNode;
}
export type PageContentProps<TabValue extends string = never> = PageContentPropsWithTabs<TabValue> | PageContentPropsWithoutTabs;
export function PageContent<TabValue extends string>({ header, busy, errors: legacyErrors, legacyStatus, description, callout, fullWidth, ...props }: PageContentProps<TabValue>) {
    const isBusy = useIsBusy(busy);
    const { errors, isInProgress } = useAggregateAPIOperationStatus(legacyStatus);
    const legacyErrorInfo = legacyErrors ? createErrorInfoFromErrors(legacyErrors) : undefined;
    const combinedErrorInfos = errors.map(createErrorInfoFromError);
    if (legacyErrorInfo) {
        combinedErrorInfos.push(legacyErrorInfo);
    }
    const combinedIsInProgress = isInProgress || isBusy;
    // TODO: this should get the level of the page from somewhere (the page registration?) then render level 1 or 2 page content appropriately
    if ("tabs" in props) {
        return (<Level2PageContent header={header} callout={callout} errors={combinedErrorInfos} busy={combinedIsInProgress} description={<Description description={description}/>} fullWidth={fullWidth} tabs={props.tabs} currentTab={props.currentTab} onTabChanged={props.onTabChanged}/>);
    }
    return (<Level2PageContent header={header} callout={callout} errors={combinedErrorInfos} busy={combinedIsInProgress} description={<Description description={description}/>} fullWidth={fullWidth} filters={props.filters} pagination={props.pagination} sidebar={props.sidebar}>
            {props.children}
        </Level2PageContent>);
}
function createErrorInfoFromError(error: Error): ErrorInfo {
    // For some reason, webstorm thinks that the following type guard is unsound, even though OctopusError inherits from Error
    // noinspection SuspiciousTypeOfGuard
    if (error instanceof OctopusError) {
        return createErrorInfoFromOctopusError(error);
    }
    if (isValidationError(error)) {
        return createErrorInfoFromValidationError(error);
    }
    return {
        message: error.message,
        details: (error.stack?.split(" at ") ?? []).map((detail) => ({ text: detail })),
    };
}
function createErrorInfoFromValidationError(err: ValidationError): ErrorInfo {
    return {
        message: err.message,
        details: [],
    };
}
function createErrorInfoFromOctopusError(err: OctopusError): ErrorInfo {
    return {
        message: err.ErrorMessage,
        details: (err.Errors || []).map((detail, index) => ({
            text: detail,
            link: err.ParsedHelpLinks && err.ParsedHelpLinks?.length > index ? err.ParsedHelpLinks[index] : undefined,
        })),
        help: {
            text: err.HelpText,
            link: err.HelpLink,
        },
    };
}
function createErrorInfoFromErrors(err: Errors): ErrorInfo {
    return {
        message: err.message,
        details: (err.errors || []).map((detail, index) => ({
            text: detail,
            link: err.parsedHelpLinks && err.parsedHelpLinks?.length > index ? err.parsedHelpLinks[index] : undefined,
        })),
        help: {
            text: err.helpText,
            link: err.helpLink,
        },
    };
}
function Description({ description }: {
    description: string | undefined;
}) {
    if (!description) {
        return null;
    }
    return (<div className={descriptionStyles}>
            <Markdown markup={description} mutedTextColor/>
        </div>);
}
const descriptionStyles = css({
    padding: space[16],
});

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProjectPaperLayout } from "app/areas/projects/components/ProjectPaperLayout";
import * as React from "react";
import type { PaperLayoutVNextProps } from "~/components/PaperLayout/PaperLayoutVNext";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
export type TabbedPaperLayoutProps = PaperLayoutVNextProps & {
    tabNavigation?: React.ComponentType<any>;
    hideTabNavigation?: boolean;
    sidebar?: React.ReactNode;
};
export const TabbedPaperLayout: React.FC<TabbedPaperLayoutProps> = (props) => {
    const { tabNavigation: TabNavigationComponent, hideTabNavigation = false, children, ...rest } = props;
    return (<ProjectPaperLayout {...rest}>
            {TabNavigationComponent && !hideTabNavigation && <TabNavigationComponent />}
            <SidebarLayout sideBar={props.sidebar} hideTopDivider={true}>
                {children}
            </SidebarLayout>
        </ProjectPaperLayout>);
};
TabbedPaperLayout.displayName = "TabbedPaperLayout"
TabbedPaperLayout.defaultProps = {
    fullWidth: true,
};
export default TabbedPaperLayout;

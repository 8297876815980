import { css } from "@emotion/css";
import { ActionButton, ActionButtonType, Callout } from "@octopusdeploy/design-system-components";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ActionTemplateParameterResource, BlueprintResource, GitRefResource, PackageReference } from "@octopusdeploy/octopus-server-client";
import { flatten } from "lodash";
import * as React from "react";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import type { StoredAction } from "~/areas/projects/components/Process/types";
import { useProjectContext } from "~/areas/projects/context";
import type { ProjectSourceItems } from "~/components/ActionTemplateParameterInput/ActionTemplateParameterInput";
import ActionTemplateParameterInput from "~/components/ActionTemplateParameterInput/ActionTemplateParameterInput";
import { createSummaryFor } from "~/components/ActionTemplateParameterInput/ActionTemplateParameterInputExpandableFormElement";
import { getHelpText, getLabel } from "~/components/ActionTemplateParameterInput/ActionTemplateParameterInputRenderer";
import { type DoBusyTask } from "~/components/DataBaseComponent/index";
import convertPropertyValueResourceToString from "~/components/convertPropertyValueResourceToString";
import { ExpandableFormSection, FormSection, FormSectionHeading } from "~/components/form/index";
import selectOptionsToItems from "~/primitiveComponents/form/Select/Options";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
interface BlueprintActionDetailsProps {
    action: StoredAction;
    doBusyTask: DoBusyTask;
    onParameterValueChanged: (parameterName: string, value: string) => void;
    blueprint: BlueprintResource | undefined;
    gitRefResource: GitRefResource | undefined;
}
export function BlueprintActionDetails({ action, doBusyTask, onParameterValueChanged, blueprint, gitRefResource }: BlueprintActionDetailsProps) {
    const projectContext = useProjectContext();
    const processContext = useProcessContext();
    const actionNames: string[] = flatten(Object.values(processContext.state.model.actions.byId).map((a) => a.Name));
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    if (!blueprint) {
        return null;
    }
    const sourceItems = {
        stepNames: actionNames,
        projectId: projectContext.state.model.Id,
        gitRef: gitRefResource,
        packages: {
            items: blueprint ? blueprint.Steps.flatMap((s) => s.Actions.flatMap((a) => a.Packages)) : [],
            feeds: feeds,
            onRequestRefresh: async () => {
                await doBusyTask(async () => {
                    await refreshFeeds();
                });
            },
            setPackages: (packages: PackageReference[]) => {
                // TODO: Untested
                processContext.actions.setActionMetaProperties(action.Id, (prev) => ({ ...prev, Packages: packages }));
            },
        },
    };
    const parameterNamesInAction = Object.keys(action.Properties).filter((p) => !p.startsWith("Octopus.Action"));
    const parameterNamesInBlueprint = new Set(blueprint.Parameters.map((p) => p.Name));
    const parametersRemovedFromBlueprint = parameterNamesInAction.filter((p) => !parameterNamesInBlueprint.has(p));
    const clearValues = () => {
        processContext.actions.removeActionProperties(action.Id, parametersRemovedFromBlueprint);
    };
    return (<>
            <FormSectionHeading title="Blueprint Details"/>
            <ReadonlyBlueprintDetails blueprint={blueprint}/>
            {blueprint.Parameters.length > 0 ? (<>
                    <FormSectionHeading title="Parameters"/>
                    {parametersRemovedFromBlueprint.length > 0 ? (<Callout type={"information"} title={"Clear old parameter values"}>
                            <div className={styles.calloutContainer}>
                                <div>One or more parameters have been removed from the current version of the blueprint. We recommend clearing these values to avoid any unexpected behavior.</div>
                                <div>
                                    <ActionButton type={ActionButtonType.Primary} label={"Clear values"} onClick={clearValues}/>
                                </div>
                            </div>
                        </Callout>) : null}
                    {blueprint.Parameters.map((parameter) => (<BlueprintParameter key={parameter.Name} parameter={parameter} sourceItems={sourceItems} action={action} onParameterValueChanged={onParameterValueChanged} doBusyTask={doBusyTask}/>))}
                    {parametersRemovedFromBlueprint.map((parameter) => (<FormSection key={parameter} title={<span className={styles.removedParameter}>{parameter}</span>} includeBorder={true} help={<span className={styles.removedParameter}>{action.Properties[parameter]}</span>}></FormSection>))}
                </>) : null}
        </>);
}
interface ReadonlyBlueprintDetailsProps {
    blueprint: BlueprintResource;
}
function ReadonlyBlueprintDetails({ blueprint }: ReadonlyBlueprintDetailsProps) {
    return (<>
            <FormSection title={"Name"} includeBorder={true} help={<NameWithSlug blueprint={blueprint}/>}></FormSection>
            <FormSection title={"Description"} includeBorder={true} help={blueprint.Description}></FormSection>
        </>);
}
interface NameWithSlugProps {
    blueprint: BlueprintResource;
}
function NameWithSlug({ blueprint }: NameWithSlugProps) {
    return (<div className={styles.nameSummaryContainer}>
            <NameSummaryWithSlug name={blueprint.Name} slug={blueprint.Slug}/>
        </div>);
}
interface BlueprintParameterProps {
    parameter: ActionTemplateParameterResource;
    sourceItems: ProjectSourceItems;
    action: StoredAction;
    onParameterValueChanged: (parameterName: string, value: string) => void;
    doBusyTask: DoBusyTask;
}
function BlueprintParameter({ parameter, sourceItems, action, onParameterValueChanged, doBusyTask }: BlueprintParameterProps) {
    const projectContext = useProjectContext();
    const label = getLabel(parameter);
    const type = parameter.DisplaySettings["Octopus.ControlType"];
    const options = selectOptionsToItems(parameter.DisplaySettings["Octopus.SelectOptions"]);
    const packageParameterIsUsed = !!sourceItems.packages.items.find((p) => p.Properties["PackageParameterName"] === parameter.Name);
    const value = action.Properties[parameter.Name];
    return type ? (<ExpandableFormSection errorKey={parameter.Name} title={label} isExpandedByDefault={false} help={getHelpText(parameter, undefined, packageParameterIsUsed)} summary={createSummaryFor(label, type, options, sourceItems.packages.feeds, packageParameterIsUsed)(value)}>
            <ActionTemplateParameterInput projectId={projectContext.state.model.Id} parameter={parameter} sourceItems={sourceItems} doBusyTask={doBusyTask} value={value} onChange={(value) => onParameterValueChanged(parameter.Name, convertPropertyValueResourceToString(value))} actionType={action.ActionType}/>
        </ExpandableFormSection>) : null;
}
const styles = {
    calloutContainer: css({
        display: "flex",
        flexDirection: "column",
        gap: space[8],
    }),
    removedParameter: css({
        color: themeTokens.color.text.disabled,
    }),
    nameSummaryContainer: css({
        display: "flex",
    }),
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, RadioButtonGroup, RadioButton, Callout } from "@octopusdeploy/design-system-components";
import type { ActionProperties } from "@octopusdeploy/octopus-server-client";
import { clone, isEqual } from "lodash";
import * as React from "react";
import { BoundRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/BoundRadioButtonGroup";
import { ValueInPropertiesOrErrorsHasChanged } from "~/utils/ShouldUpdate/ValueInPropertiesHasChanged";
import { BoundStringCheckbox } from "../../../primitiveComponents/form/Checkbox/StringCheckbox";
import Note from "../../../primitiveComponents/form/Note/Note";
import type { ActionEditProps } from "../../Actions/pluginRegistry";
import pluginRegistry from "../../Actions/pluginRegistry";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { DisabledChip } from "../../Chips";
import DialogOpener from "../../Dialog/DialogOpener";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import { RemoveItemsList } from "../../RemoveItemsList/RemoveItemsList";
import ThumbprintText from "../../ThumbprintText";
import { ExpandableFormSection, Summary } from "../../form";
import isBound from "../../form/BoundField/isBound";
import { VariableLookupText } from "../../form/VariableLookupText";
import ApplicationPool from "./applicationPool";
import BindingDialog from "./bindingDialog";
import type { Binding } from "./bindingHelpers";
import { bindingsFromString, bindingsToString } from "./bindingHelpers";
import styles from "./style.module.less";
class BindingList extends RemoveItemsList<any> {
}
interface IISWebSiteState {
    looksLikeVDir: boolean;
    looksLikeFullPath: boolean;
    isIISStep: boolean;
    iisBindings: Binding[];
    editBinding?: Binding | null;
    editBindingIndex?: number | null;
    certificateMode: {
        managedByOctopus: boolean;
    };
}
const StringProperties = {
    "Octopus.Action.IISWebSite.DeploymentType": "",
    "Octopus.Action.IISWebSite.VirtualDirectory.CreateOrUpdate": "",
    "Octopus.Action.IISWebSite.VirtualDirectory.WebSiteName": "",
    "Octopus.Action.IISWebSite.VirtualDirectory.VirtualPath": "",
    "Octopus.Action.IISWebSite.VirtualDirectory.PhysicalPath": "",
    "Octopus.Action.IISWebSite.WebApplication.CreateOrUpdate": "",
    "Octopus.Action.IISWebSite.WebApplication.WebSiteName": "",
    "Octopus.Action.IISWebSite.WebApplication.VirtualPath": "",
    "Octopus.Action.IISWebSite.WebApplication.PhysicalPath": "",
    "Octopus.Action.IISWebSite.CreateOrUpdateWebSite": "",
    "Octopus.Action.IISWebSite.StartApplicationPool": "",
    "Octopus.Action.IISWebSite.StartWebSite": "",
    "Octopus.Action.IISWebSite.Bindings": "",
    "Octopus.Action.IISWebSite.ExistingBindings": "",
    "Octopus.Action.IISWebSite.WebRootType": "",
    "Octopus.Action.IISWebSite.WebSiteName": "",
    "Octopus.Action.IISWebSite.WebRoot": "",
    "Octopus.Action.IISWebSite.EnableAnonymousAuthentication": "",
    "Octopus.Action.IISWebSite.EnableBasicAuthentication": "",
    "Octopus.Action.IISWebSite.EnableWindowsAuthentication": "",
    "Octopus.Action.IISWebSite.ApplicationPoolName": "",
    "Octopus.Action.IISWebSite.ApplicationPoolFrameworkVersion": "",
    "Octopus.Action.IISWebSite.ApplicationPoolIdentityType": "",
    "Octopus.Action.IISWebSite.ApplicationPoolUsername": "",
    "Octopus.Action.IISWebSite.ApplicationPoolPassword": "",
    "Octopus.Action.IISWebSite.WebApplication.ApplicationPoolName": "",
    "Octopus.Action.IISWebSite.WebApplication.ApplicationPoolFrameworkVersion": "",
    "Octopus.Action.IISWebSite.WebApplication.ApplicationPoolIdentityType": "",
    "Octopus.Action.IISWebSite.WebApplication.ApplicationPoolUsername": "",
    "Octopus.Action.IISWebSite.WebApplication.ApplicationPoolPassword": "",
    "Octopus.Action.WebApplication.WebRootType": "",
};
type IISWebSiteProperties = {
    [P in keyof typeof StringProperties]: string;
};
class IISWebSiteEdit extends BaseComponent<ActionEditProps<IISWebSiteProperties>, IISWebSiteState> {
    constructor(props: ActionEditProps<IISWebSiteProperties>) {
        super(props);
        this.state = {
            looksLikeVDir: false,
            looksLikeFullPath: false,
            isIISStep: false,
            iisBindings: [],
            editBinding: null,
            editBindingIndex: null,
            certificateMode: { managedByOctopus: false },
        };
    }
    shouldComponentUpdate(newProps: ActionEditProps<IISWebSiteProperties>, newState: IISWebSiteState): boolean {
        return ValueInPropertiesOrErrorsHasChanged(StringProperties, newProps, this.props) || !isEqual(newState, this.state);
    }
    handleWebRootTypeChanged = (type: "packageRoot" | "relativeToPackageRoot", property: string, resetProperty: string) => {
        this.props.setProperties({ [property]: type });
        switch (type) {
            case "packageRoot": {
                this.resetPhysicalPath(resetProperty);
            }
        }
    };
    handleDeploymentTypeChanged = (type: "webSite" | "virtualDirectory" | "webApplication") => {
        switch (type) {
            case "webSite": {
                this.selectWebSite();
                break;
            }
            case "virtualDirectory": {
                this.selectVirtualDirectory();
                break;
            }
            case "webApplication": {
                this.selectWebApplication();
                break;
            }
        }
    };
    selectVirtualDirectory = () => {
        this.props.setProperties({
            ["Octopus.Action.IISWebSite.DeploymentType"]: "virtualDirectory",
            ["Octopus.Action.IISWebSite.VirtualDirectory.CreateOrUpdate"]: "True",
            ["Octopus.Action.IISWebSite.WebApplication.CreateOrUpdate"]: "False",
            ["Octopus.Action.IISWebSite.CreateOrUpdateWebSite"]: "False",
        });
    };
    selectWebSite = () => {
        this.props.setProperties({
            ["Octopus.Action.IISWebSite.DeploymentType"]: "webSite",
            ["Octopus.Action.IISWebSite.VirtualDirectory.CreateOrUpdate"]: "False",
            ["Octopus.Action.IISWebSite.WebApplication.CreateOrUpdate"]: "False",
            ["Octopus.Action.IISWebSite.CreateOrUpdateWebSite"]: "True",
        });
    };
    selectWebApplication = () => {
        this.props.setProperties({
            ["Octopus.Action.IISWebSite.DeploymentType"]: "webApplication",
            ["Octopus.Action.IISWebSite.VirtualDirectory.CreateOrUpdate"]: "False",
            ["Octopus.Action.IISWebSite.WebApplication.CreateOrUpdate"]: "True",
            ["Octopus.Action.IISWebSite.CreateOrUpdateWebSite"]: "False",
        });
    };
    checkWebSiteName = (name: string) => {
        this.setState({ looksLikeVDir: name ? name.indexOf("/") !== -1 || name.indexOf("\\") !== -1 : false });
    };
    checkWebRoot = (path: string) => {
        this.setState({ looksLikeFullPath: path ? path.indexOf(":") !== -1 : false });
    };
    resetPhysicalPath = (variableName: string) => {
        this.props.setProperties({ [variableName]: "" });
        this.setState({ looksLikeFullPath: false });
    };
    removeBinding = (b: Binding) => {
        const bindings = [...this.state.iisBindings];
        bindings.splice(this.state.iisBindings.indexOf(b), 1);
        this.props.setProperties({ ["Octopus.Action.IISWebSite.Bindings"]: bindingsToString(bindings) });
    };
    editBinding = (binding: Binding) => {
        this.setState({
            editBinding: clone(binding),
            editBindingIndex: this.state.iisBindings.indexOf(binding),
        });
    };
    addBinding = () => {
        const binding: Binding = {
            protocol: "http",
            port: "80",
            ipAddress: "*",
            host: "",
            thumbprint: null,
            enabled: "True",
            requireSni: "False",
        };
        this.setState({
            editBinding: binding,
            editBindingIndex: undefined,
        });
    };
    saveBinding(binding: Binding) {
        const bindings = [...this.state.iisBindings];
        if (this.state.editBindingIndex) {
            bindings[this.state!.editBindingIndex!] = binding;
        }
        else {
            bindings.push(binding);
        }
        this.props.setProperties({ ["Octopus.Action.IISWebSite.Bindings"]: bindingsToString(bindings) });
        this.resetSelectedBinding();
        return true;
    }
    resetSelectedBinding = () => {
        this.setState({
            editBinding: null,
            editBindingIndex: null,
        });
    };
    isPropTrue = (name: string) => {
        if ((this.props.properties as any)[name]) {
            return (this.props.properties as any)[name] === "True";
        }
        return true;
    };
    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            if (!this.props.properties["Octopus.Action.IISWebSite.DeploymentType"]) {
                this.selectWebSite();
            }
            const hasPhysicalPath = this.props.properties["Octopus.Action.IISWebSite.WebRoot"] || this.props.properties["Octopus.Action.IISWebSite.WebApplication.PhysicalPath"] || this.props.properties["Octopus.Action.IISWebSite.VirtualDirectory.PhysicalPath"];
            if (!this.props.properties["Octopus.Action.IISWebSite.WebRootType"] && hasPhysicalPath) {
                this.props.setProperties({ ["Octopus.Action.IISWebSite.WebRootType"]: "relativeToPackageRoot" });
            }
            else if (!this.props.properties["Octopus.Action.IISWebSite.WebRootType"] && !hasPhysicalPath) {
                this.props.setProperties({ ["Octopus.Action.IISWebSite.WebRootType"]: "packageRoot" });
            }
            if (!this.props.properties["Octopus.Action.IISWebSite.StartApplicationPool"]) {
                this.props.setProperties({ ["Octopus.Action.IISWebSite.StartApplicationPool"]: "True" });
            }
            if (!this.props.properties["Octopus.Action.IISWebSite.StartWebSite"]) {
                this.props.setProperties({ ["Octopus.Action.IISWebSite.StartWebSite"]: "True" });
            }
            this.setState({
                isIISStep: this.props.plugin.actionType === "Octopus.IIS",
                iisBindings: !!this.props.properties["Octopus.Action.IISWebSite.Bindings"] ? bindingsFromString(this.props.properties["Octopus.Action.IISWebSite.Bindings"]) : [],
            });
        });
    }
    UNSAFE_componentWillReceiveProps(nextProps: ActionEditProps<IISWebSiteProperties>) {
        if (this.props.properties["Octopus.Action.IISWebSite.Bindings"] !== nextProps.properties["Octopus.Action.IISWebSite.Bindings"]) {
            this.setState({ iisBindings: bindingsFromString(nextProps.properties["Octopus.Action.IISWebSite.Bindings"]) });
        }
    }
    deploymentTypeSummary() {
        const type = this.props.properties["Octopus.Action.IISWebSite.DeploymentType"];
        if (type === "webSite") {
            return Summary.summary("IIS Web Site");
        }
        else if (type === "virtualDirectory") {
            return Summary.summary("IIS Virtual Directory");
        }
        else if (type === "webApplication") {
            return Summary.summary("IIS Web Application");
        }
        return Summary.placeholder("Deployment type not configured");
    }
    webSiteSummary() {
        const properties = this.props.properties;
        if (properties["Octopus.Action.IISWebSite.CreateOrUpdateWebSite"] === "False") {
            return Summary.placeholder("The web site will not be created or updated");
        }
        if (!properties["Octopus.Action.IISWebSite.WebSiteName"]) {
            return Summary.placeholder("The web site name has not been configured");
        }
        const nodes = [];
        nodes.push(<span>
                Web site <strong>{properties["Octopus.Action.IISWebSite.WebSiteName"]}</strong> will be created or updated
            </span>);
        if (properties["Octopus.Action.IISWebSite.WebRootType"] === "packageRoot") {
            nodes.push(<span>. Content will be served from the root of the installation directory</span>);
        }
        else {
            nodes.push(<span>
                    . Content will be served from <strong>{properties["Octopus.Action.IISWebSite.WebRoot"] || "/"}</strong> relative to the installation directory
                </span>);
        }
        return Summary.summary(React.Children.toArray(nodes));
    }
    authenticationSummary() {
        const modes = [];
        if (this.props.properties["Octopus.Action.IISWebSite.EnableAnonymousAuthentication"] !== "False") {
            modes.push("Anonymous");
        }
        if (this.props.properties["Octopus.Action.IISWebSite.EnableBasicAuthentication"] !== "False") {
            modes.push("Basic");
        }
        if (this.props.properties["Octopus.Action.IISWebSite.EnableWindowsAuthentication"] !== "False") {
            modes.push("Windows");
        }
        if (modes.length === 0) {
            return Summary.placeholder("No authentication modes are enabled");
        }
        if (modes.length === 1) {
            return Summary.summary(<span>
                    <strong>{modes[0]}</strong> authentication mode will be enabled
                </span>);
        }
        if (modes.length === 2) {
            return Summary.summary(<span>
                    <strong>{modes[0]}</strong> and <strong>{modes[1]}</strong> authentication modes will be enabled
                </span>);
        }
        if (modes.length === 3) {
            return Summary.summary(<span>
                    <strong>{modes[0]}</strong>, <strong>{modes[1]}</strong> and <strong>{modes[2]}</strong> authentication modes will be enabled
                </span>);
        }
    }
    bindingsSummary() {
        const length = this.state.iisBindings.length;
        if (length === 0) {
            return Summary.placeholder("No bindings have been configured");
        }
        const first = this.state.iisBindings[0];
        const address = first.ipAddress || "*";
        const summary = first.protocol + "://" + address + ":" + first.port;
        const nodes = [];
        nodes.push(<span>
                The site will listen on <strong>{summary}</strong>
            </span>);
        if (length === 2) {
            nodes.push(<span> and one other binding</span>);
        }
        if (length > 2) {
            nodes.push(<span> and {length - 1} other bindings</span>);
        }
        return Summary.summary(React.Children.toArray(nodes));
    }
    virtualDirectorySummary() {
        const properties = this.props.properties;
        if (properties["Octopus.Action.IISWebSite.VirtualDirectory.CreateOrUpdate"] === "False") {
            return Summary.placeholder("The virtual directory will not be created or updated");
        }
        if (!properties["Octopus.Action.IISWebSite.VirtualDirectory.WebSiteName"]) {
            return Summary.summary("The parent web site name has not been configured");
        }
        if (!properties["Octopus.Action.IISWebSite.VirtualDirectory.VirtualPath"]) {
            return Summary.summary("The virtual path has not been configured");
        }
        const nodes = [];
        nodes.push(<span>
                The virtual directory will be created under parent site <strong>{properties["Octopus.Action.IISWebSite.VirtualDirectory.WebSiteName"]}</strong>
            </span>);
        nodes.push(<span>
                {" "}
                at virtual path <strong>{properties["Octopus.Action.IISWebSite.VirtualDirectory.VirtualPath"]}</strong>
            </span>);
        if (properties["Octopus.Action.IISWebSite.WebRootType"] === "packageRoot") {
            nodes.push(<span>. Content will be served from the root of the installation directory</span>);
        }
        else {
            nodes.push(<span>
                    . Content will be served from <strong>{properties["Octopus.Action.IISWebSite.VirtualDirectory.PhysicalPath"] || "/"}</strong> relative to the installation directory
                </span>);
        }
        return Summary.summary(React.Children.toArray(nodes));
    }
    webApplicationSummary() {
        const properties = this.props.properties;
        if (properties["Octopus.Action.IISWebSite.WebApplication.CreateOrUpdate"] === "False") {
            return Summary.placeholder("The web application will not be created or updated");
        }
        if (!properties["Octopus.Action.IISWebSite.WebApplication.WebSiteName"]) {
            return Summary.summary("The parent web site name has not been configured");
        }
        if (!properties["Octopus.Action.IISWebSite.WebApplication.VirtualPath"]) {
            return Summary.summary("The virtual path has not been configured");
        }
        const nodes = [];
        nodes.push(<span>
                The web application will be created under parent site <strong>{properties["Octopus.Action.IISWebSite.WebApplication.WebSiteName"]}</strong>
            </span>);
        nodes.push(<span>
                {" "}
                at virtual path <strong>{properties["Octopus.Action.IISWebSite.WebApplication.VirtualPath"]}</strong>
            </span>);
        if (properties["Octopus.Action.WebApplication.WebRootType"] === "packageRoot") {
            nodes.push(<span>. Content will be served from the root of the installation directory</span>);
        }
        else {
            nodes.push(<span>
                    . Content will be served from <strong>{properties["Octopus.Action.IISWebSite.WebApplication.PhysicalPath"] || "/"}</strong> relative to the installation directory
                </span>);
        }
        return Summary.summary(React.Children.toArray(nodes));
    }
    render() {
        const properties = this.props.properties;
        const editBindingDialog = (<DialogOpener open={!!this.state.editBinding} onClose={this.resetSelectedBinding}>
                <BindingDialog binding={this.state.editBinding!} projectId={this.props.projectId!} gitRef={this.props.gitRef} doBusyTask={this.props.doBusyTask} localNames={this.props.localNames!} onAdd={(binding) => this.saveBinding(binding)}/>
            </DialogOpener>);
        return (<div>
                {editBindingDialog}
                <ExpandableFormSection errorKey="Octopus.Action.IISWebSite.DeploymentType" isExpandedByDefault={this.props.expandedByDefault} title="IIS Deployment Type" summary={this.deploymentTypeSummary()} help="Configure the type of deployment.">
                    <Note>
                        Learn more about deploying <ExternalLink href="IISWebsitesAndApplications">IIS Websites and Applications</ExternalLink>.
                    </Note>
                    <RadioButtonGroup value={properties["Octopus.Action.IISWebSite.DeploymentType"]} error={this.props.getFieldError("Octopus.Action.IISWebSite.DeploymentType")} onChange={(val) => this.handleDeploymentTypeChanged(val as any)}>
                        <RadioButton value="webSite" label="IIS Web Site"/>
                        <Note>Create or update an IIS Web Site</Note>
                        <RadioButton value="virtualDirectory" label="IIS Virtual Directory"/>
                        <Note>Create or update an IIS Virtual Directory as a child of an existing IIS Web Site</Note>
                        <RadioButton value="webApplication" label="IIS Web Application"/>
                        <Note>Create or update an IIS Web Application as a child of an existing IIS Web Site</Note>
                    </RadioButtonGroup>
                </ExpandableFormSection>

                {properties["Octopus.Action.IISWebSite.DeploymentType"] === "webSite" && (<div>
                        <ExpandableFormSection errorKey="Octopus.Action.IISWebSite.WebSiteName|Octopus.Action.IISWebSite.WebRoot" isExpandedByDefault={this.props.expandedByDefault} title="Web Site" summary={this.webSiteSummary()} help="Configure the details of the IIS Web Site.">
                            {!this.state.isIISStep && (<div>
                                    <BoundStringCheckbox variableLookup={{
                        localNames: this.props.localNames,
                    }} resetValue={"False"} value={properties["Octopus.Action.IISWebSite.CreateOrUpdateWebSite"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.IISWebSite.CreateOrUpdateWebSite"]: x })} label="Create or update an IIS Web Site and Application Pool" note={<span>If enabled, Tentacle will use the PowerShell Web Administration module to attempt to create or modify an IIS Web Site and Application Pool using the settings below.</span>}/>
                                </div>)}
                            <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.IISWebSite.WebSiteName"]} onChange={(x) => {
                    this.props.setProperties({ ["Octopus.Action.IISWebSite.WebSiteName"]: x });
                    this.checkWebSiteName(x);
                }} error={this.props.getFieldError("Octopus.Action.IISWebSite.WebSiteName")} label="Web site name"/>
                            {this.state.looksLikeVDir && (<Callout type={"information"} title={"Configuring a Virtual Directory?"}>
                                    It looks like you might be trying to configure a Virtual Directory. <em>IIS Web Site</em> doesn't support Virtual Directories. You might consider using either the <em>IIS Virtual Directory</em> or{" "}
                                    <em>IIS Web Application</em> deployment type for that, or check <ExternalLink href="https://library.octopusdeploy.com">the template library</ExternalLink> for more options.
                                </Callout>)}
                            <Note>The display name of the IIS Web Site to create or reconfigure.</Note>
                            <RadioButtonGroup title="Physical path" value={properties["Octopus.Action.IISWebSite.WebRootType"]} error={this.props.getFieldError("Octopus.Action.IISWebSite.WebRootType")} onChange={(val) => this.handleWebRootTypeChanged(val as any, "Octopus.Action.IISWebSite.WebRootType", "Octopus.Action.IISWebSite.WebRoot")}>
                                <RadioButton value="packageRoot" label="Package installation directory"/>
                                <Note>
                                    {" "}
                                    Configure this IIS Web Site to serve content from the folder that Octopus extracted the package to, or the <ExternalLink href="CustomInstallationDirectory">Custom Installation Directory</ExternalLink> if specified.
                                </Note>
                                <RadioButton value="relativeToPackageRoot" label="Relative path under package installation directory"/>
                                {properties["Octopus.Action.IISWebSite.WebRootType"] === "relativeToPackageRoot" && (<VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.IISWebSite.WebRoot"]} onChange={(x) => {
                        this.props.setProperties({ ["Octopus.Action.IISWebSite.WebRoot"]: x });
                        this.checkWebRoot(x);
                    }} error={this.props.getFieldError("Octopus.Action.IISWebSite.WebRoot")} label="Package installation directory"/>)}
                                {this.state.looksLikeFullPath && (<Callout type={"information"} title={"Absolute path?"}>
                                        It looks like you might be trying to configure the <em>Physical Path</em> as an absolute path. This will only work if the{" "}
                                        <ExternalLink href="CustomInstallationDirectory">Custom Installation Directory</ExternalLink> feature is enabled or if you are serving content that is not part of your package.
                                    </Callout>)}
                                <Note>
                                    Configure this IIS Web Site to serve content from a path that is relative to the folder that Octopus extracted the package to, or the{" "}
                                    <ExternalLink href="CustomInstallationDirectory">Custom Installation Directory</ExternalLink> if specified.
                                </Note>
                            </RadioButtonGroup>
                            <BoundStringCheckbox variableLookup={{
                    localNames: this.props.localNames,
                }} resetValue={"False"} value={this.props.properties["Octopus.Action.IISWebSite.StartWebSite"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.IISWebSite.StartWebSite"]: x })} label="Start IIS Web Site"/>
                            <Note>Whether the deployment step should start the IIS Web Site after a successful deployment or not.</Note>
                        </ExpandableFormSection>

                        <ApplicationPool properties={this.props.properties} setProperties={this.props.setProperties} localNames={this.props.localNames!} pathToRootProperty="Octopus.Action.IISWebSite" getFieldError={this.props.getFieldError} expandedByDefault={this.props.expandedByDefault}/>

                        <ExpandableFormSection errorKey="bindings" isExpandedByDefault={this.props.expandedByDefault} title="Bindings" summary={this.bindingsSummary()} help="Bindings define what protocols, ports and host names this site will listen on">
                            <BindingList listActions={[<ActionButton key="add" label="Add" onClick={() => this.addBinding()}/>]} data={this.state.iisBindings} onRow={(binding) => (<div className={binding.enabled === "False" ? styles.disabled : null}>
                                        {binding.protocol && (<p>
                                                Protocol: <strong>{binding.protocol}</strong>
                                            </p>)}
                                        {binding.port && (<p>
                                                Port: <strong>{binding.port}</strong>
                                            </p>)}
                                        {binding.ipAddress && (<p>
                                                IP Address: <strong>{binding.ipAddress}</strong>
                                            </p>)}
                                        {binding.host && (<p>
                                                Host: <strong>{binding.host}</strong>
                                            </p>)}
                                        {binding.thumbprint && (<p>
                                                SSL certificate thumbprint: <ThumbprintText thumbprint={binding.thumbprint}/>
                                            </p>)}
                                        {binding.certificateVariableName && (<p>
                                                SSL certificate variable: <strong>{binding.certificateVariableName}</strong>
                                            </p>)}
                                        {binding.protocol && binding.protocol.toLowerCase() !== "http" && binding.requireSni && (<p>
                                                Require Server Name Indication (SNI): <strong>{binding.requireSni}</strong>
                                            </p>)}
                                        {binding.enabled === "True" || binding.enabled === true ? null : binding.enabled === "False" ? (<DisabledChip />) : (<p>
                                                Enabled: <strong>{binding.enabled}</strong>
                                            </p>)}
                                    </div>)} onRowTouch={(binding) => this.editBinding(binding)} onRemoveRow={(binding) => this.removeBinding(binding)}/>
                            <BoundRadioButtonGroup variableLookup={{
                    localNames: this.props.localNames,
                }} resetValue="Replace" title="Existing Bindings" value={properties["Octopus.Action.IISWebSite.ExistingBindings"] || "Replace"} error={this.props.getFieldError("Octopus.Action.IISWebSite.ExistingBindings")} onChange={(val) => this.props.setProperties({ ["Octopus.Action.IISWebSite.ExistingBindings"]: val })}>
                                <RadioButton value="Replace" label="Replace existing bindings" isDefault={true}/>
                                <Note>Remove existing bindings on the IIS Web Site and replace them with the bindings above.</Note>
                                <RadioButton value="Merge" label="Merge with existing bindings"/>
                                <Note>Combine the existing bindings on the IIS Web Site with the bindings above.</Note>
                            </BoundRadioButtonGroup>
                        </ExpandableFormSection>

                        <ExpandableFormSection errorKey="iisauthentication" isExpandedByDefault={this.props.expandedByDefault} title="IIS Authentication" summary={this.authenticationSummary()} help="Set which authentication modes will be enabled in IIS.">
                            <BoundStringCheckbox variableLookup={{
                    localNames: this.props.localNames,
                }} resetValue={"False"} value={properties["Octopus.Action.IISWebSite.EnableAnonymousAuthentication"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.IISWebSite.EnableAnonymousAuthentication"]: x })} label="Enable Anonymous authentication" note={<span>Whether IIS should allow anonymous authentication.</span>}/>
                            <BoundStringCheckbox variableLookup={{
                    localNames: this.props.localNames,
                }} resetValue={"False"} value={properties["Octopus.Action.IISWebSite.EnableBasicAuthentication"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.IISWebSite.EnableBasicAuthentication"]: x })} label="Enable Basic authentication" note={<span>Whether IIS should allow basic authentication with a 401 challenge.</span>}/>
                            <BoundStringCheckbox variableLookup={{
                    localNames: this.props.localNames,
                }} resetValue={"False"} value={properties["Octopus.Action.IISWebSite.EnableWindowsAuthentication"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.IISWebSite.EnableWindowsAuthentication"]: x })} label="Enable Windows authentication" note={<span>Whether IIS should allow integrated Windows authentication with a 401 challenge.</span>}/>
                        </ExpandableFormSection>
                    </div>)}

                {properties["Octopus.Action.IISWebSite.DeploymentType"] === "virtualDirectory" && (<div>
                        <ExpandableFormSection errorKey="Octopus.Action.IISWebSite.VirtualDirectory.WebSiteName|Octopus.Action.IISWebSite.VirtualDirectory.VirtualPath" isExpandedByDefault={this.props.expandedByDefault} title="Virtual Directory" summary={this.virtualDirectorySummary()} help="Configure the details of the IIS Virtual Directory.">
                            {!this.state.isIISStep && (<div>
                                    <BoundStringCheckbox variableLookup={{
                        localNames: this.props.localNames,
                    }} resetValue={"False"} value={properties["Octopus.Action.IISWebSite.VirtualDirectory.CreateOrUpdate"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.IISWebSite.VirtualDirectory.CreateOrUpdate"]: x })} label="Create or update an IIS Virtual Directory" note={<span>If enabled, Tentacle will use the PowerShell Web Administration module to attempt to create or modify an IIS Virtual Directory and Application Pool using the settings below.</span>}/>
                                </div>)}
                            <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.IISWebSite.VirtualDirectory.WebSiteName"]} onChange={(x) => {
                    this.props.setProperties({ ["Octopus.Action.IISWebSite.VirtualDirectory.WebSiteName"]: x });
                    this.checkWebSiteName(x);
                }} error={this.props.getFieldError("Octopus.Action.IISWebSite.VirtualDirectory.WebSiteName")} label="Parent web site name"/>
                            {this.state.looksLikeVDir && (<Callout type={"information"} title={"Virtual Directory?"}>
                                    It looks like you might be trying to enter a Virtual Directory. Enter the parent web site name in this field, and the virtual path details below.
                                </Callout>)}
                            <Note>The name of the parent Web Site for this Virtual Directory in IIS. The Web Site should already exist when this step runs.</Note>
                            <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.IISWebSite.VirtualDirectory.VirtualPath"]} onChange={(x) => {
                    this.props.setProperties({ ["Octopus.Action.IISWebSite.VirtualDirectory.VirtualPath"]: x });
                }} error={this.props.getFieldError("Octopus.Action.IISWebSite.VirtualDirectory.VirtualPath")} label="Virtual path"/>
                            <Note>
                                The virtual path in IIS relative to the parent Web Site. E.g. <code>/application1/directory2/mydirectory</code>.
                                <br />
                                Note: All parent directories/applications must already exist.
                            </Note>
                            <RadioButtonGroup title="Physical path" value={properties["Octopus.Action.IISWebSite.WebRootType"]} error={this.props.getFieldError("Octopus.Action.IISWebSite.WebRootType")} onChange={(val) => this.handleWebRootTypeChanged(val as any, "Octopus.Action.IISWebSite.WebRootType", "Octopus.Action.IISWebSite.VirtualDirectory.PhysicalPath")}>
                                <RadioButton value="packageRoot" label="Package installation directory"/>
                                <Note>
                                    {" "}
                                    Configure this IIS Web Site to serve content from the folder that Octopus extracted the package to, or the <ExternalLink href="CustomInstallationDirectory">Custom Installation Directory</ExternalLink> if specified.
                                </Note>
                                <RadioButton value="relativeToPackageRoot" label="Relative path under package installation directory"/>
                                {properties["Octopus.Action.IISWebSite.WebRootType"] === "relativeToPackageRoot" && (<VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.IISWebSite.VirtualDirectory.PhysicalPath"]} onChange={(x) => {
                        this.props.setProperties({ ["Octopus.Action.IISWebSite.VirtualDirectory.PhysicalPath"]: x });
                        this.checkWebRoot(x);
                    }} error={this.props.getFieldError("Octopus.Action.IISWebSite.VirtualDirectory.PhysicalPath")} label="Package installation directory"/>)}
                                {this.state.looksLikeFullPath && (<Callout type={"information"} title={"Absolute path?"}>
                                        It looks like you might be trying to configure the <em>Physical Path</em> as an absolute path. This will only work if the{" "}
                                        <ExternalLink href="CustomInstallationDirectory">Custom Installation Directory</ExternalLink> feature is enabled or if you are serving content that is not part of your package.
                                    </Callout>)}
                                <Note>
                                    Configure this Virtual Directory to serve content from a path that is relative to the folder that Octopus extracted the package to, or the{" "}
                                    <ExternalLink href="CustomInstallationDirectory">Custom Installation Directory</ExternalLink> if specified.
                                </Note>
                            </RadioButtonGroup>
                        </ExpandableFormSection>
                    </div>)}

                {properties["Octopus.Action.IISWebSite.DeploymentType"] === "webApplication" && (<div>
                        <ExpandableFormSection errorKey="Octopus.Action.IISWebSite.WebApplication.WebSiteName|Octopus.Action.IISWebSite.WebApplication.VirtualPath" isExpandedByDefault={this.props.expandedByDefault} title="Web Application" summary={this.webApplicationSummary()} help="Configure the details of the IIS Web Application">
                            {!this.state.isIISStep && (<div>
                                    <BoundStringCheckbox variableLookup={{
                        localNames: this.props.localNames,
                    }} resetValue={"False"} value={properties["Octopus.Action.IISWebSite.WebApplication.CreateOrUpdate"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.IISWebSite.WebApplication.CreateOrUpdate"]: x })} label="Create or update an IIS Web Application" note={<span>If enabled, Tentacle will use the PowerShell Web Administration module to attempt to create or modify an IIS Web Application and Application Pool using the settings below.</span>}/>
                                </div>)}
                            <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.IISWebSite.WebApplication.WebSiteName"]} onChange={(x) => {
                    this.props.setProperties({ ["Octopus.Action.IISWebSite.WebApplication.WebSiteName"]: x });
                    this.checkWebSiteName(x);
                }} error={this.props.getFieldError("Octopus.Action.IISWebSite.WebApplication.WebSiteName")} label="Parent web site name"/>
                            {this.state.looksLikeVDir && (<Callout type={"information"} title={"Virtual Directory?"}>
                                    It looks like you might be trying to enter a Virtual Directory. Enter the parent web site name in this field, and the virtual path details below.
                                </Callout>)}
                            <Note>The name of the parent Web Site for this Web Application in IIS. The Web Site should already exist when this step runs.</Note>
                            <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.IISWebSite.WebApplication.VirtualPath"]} onChange={(x) => {
                    this.props.setProperties({ ["Octopus.Action.IISWebSite.WebApplication.VirtualPath"]: x });
                }} error={this.props.getFieldError("Octopus.Action.IISWebSite.WebApplication.VirtualPath")} label="Virtual path"/>
                            <Note>
                                The virtual path in IIS relative to the parent Web Site. E.g. <code>/myapplication</code>.
                                <br />
                                Note: All parent directories/applications must already exist.
                            </Note>
                            <RadioButtonGroup title="Physical path" value={properties["Octopus.Action.WebApplication.WebRootType"]} error={this.props.getFieldError("Octopus.Action.WebApplication.WebRootType")} onChange={(val) => this.handleWebRootTypeChanged(val as any, "Octopus.Action.WebApplication.WebRootType", "Octopus.Action.IISWebSite.WebApplication.PhysicalPath")}>
                                <RadioButton value="packageRoot" label="Package installation directory"/>
                                <Note>
                                    {" "}
                                    Configure this IIS Web Application to serve content from the folder that Octopus extracted the package to, or the <ExternalLink href="CustomInstallationDirectory">Custom Installation Directory</ExternalLink> if
                                    specified.
                                </Note>
                                <RadioButton value="relativeToPackageRoot" label="Relative path under package installation directory"/>
                                {properties["Octopus.Action.WebApplication.WebRootType"] === "relativeToPackageRoot" && (<VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.IISWebSite.WebApplication.PhysicalPath"]} onChange={(x) => {
                        this.props.setProperties({ ["Octopus.Action.IISWebSite.WebApplication.PhysicalPath"]: x });
                        this.checkWebRoot(x);
                    }} error={this.props.getFieldError("Octopus.Action.IISWebSite.WebApplication.PhysicalPath")} label="Package installation directory"/>)}
                                {this.state.looksLikeFullPath && (<Callout type={"information"} title={"Absolute path?"}>
                                        It looks like you might be trying to configure the <em>Physical Path</em> as an absolute path. This will only work if the{" "}
                                        <ExternalLink href="CustomInstallationDirectory">Custom Installation Directory</ExternalLink> feature is enabled or if you are serving content that is not part of your package.
                                    </Callout>)}
                                <Note>
                                    Configure this Web Application to serve content from a path that is relative to the folder that Octopus extracted the package to, or the{" "}
                                    <ExternalLink href="CustomInstallationDirectory">Custom Installation Directory</ExternalLink> if specified.
                                </Note>
                            </RadioButtonGroup>
                        </ExpandableFormSection>

                        <ApplicationPool properties={this.props.properties} setProperties={this.props.setProperties} localNames={this.props.localNames!} pathToRootProperty="Octopus.Action.IISWebSite.WebApplication" getFieldError={this.props.getFieldError} expandedByDefault={this.props.expandedByDefault}/>
                    </div>)}
            </div>);
    }
    static displayName = "IISWebSiteEdit";
}
pluginRegistry.registerFeature({
    featureName: "Octopus.Features.IISWebSite",
    title: "IIS Web Site and Application Pool",
    description: "Creates or reconfigures an IIS website and application pool (requires IIS7+)",
    edit: IISWebSiteEdit,
    priority: 8,
    enable: (properties: ActionProperties) => {
        properties["Octopus.Action.IISWebSite.DeploymentType"] = "webSite";
        properties["Octopus.Action.IISWebSite.CreateOrUpdateWebSite"] = "True";
        properties["Octopus.Action.IISWebSite.Bindings"] = bindingsToString([{ protocol: "http", port: "80", host: "", enabled: "True" }]);
        setupApplicationPoolDefaults(properties, "Octopus.Action.IISWebSite");
        properties["Octopus.Action.IISWebSite.EnableAnonymousAuthentication"] = "False";
        properties["Octopus.Action.IISWebSite.EnableBasicAuthentication"] = "False";
        properties["Octopus.Action.IISWebSite.EnableWindowsAuthentication"] = "True";
        setupApplicationPoolDefaults(properties, "Octopus.Action.IISWebSite.WebApplication");
        function setupApplicationPoolDefaults(p: any, pathToRootProperty: string) {
            p[pathToRootProperty + ".ApplicationPoolFrameworkVersion"] = "v4.0";
            p[pathToRootProperty + ".ApplicationPoolIdentityType"] = "ApplicationPoolIdentity";
        }
    },
    preSave: (properties: ActionProperties) => {
        normalizeApplicationPoolData(properties, "Octopus.Action.IISWebSite");
        normalizeApplicationPoolData(properties, "Octopus.Action.IISWebSite.WebApplication");
        function normalizeApplicationPoolData(p: any, pathToRootProperty: string) {
            if (p[pathToRootProperty + ".ApplicationPoolIdentityType"] !== "SpecificUser" && !isBound(p[pathToRootProperty + ".ApplicationPoolIdentityType"])) {
                p[pathToRootProperty + ".ApplicationPoolUsername"] = null;
                p[pathToRootProperty + ".ApplicationPoolPassword"] = null;
            }
        }
    },
    disable: (properties: ActionProperties) => {
        Object.keys(properties)
            .filter((name) => {
            return name.indexOf("Octopus.Action.IISWebSite.") === 0;
        })
            .forEach((name) => {
            delete properties[name];
        });
    },
});
